import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService, LocalizationService } from '@app/core';
import { passwordMinCharcount, vinterLogoWithoutTextPath, vinterPureLogoRotatedPath } from '@app/shared/common/constants';
import { SpinnerHandlerService } from '../loader/spinner-handler.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  vinterLogoWithoutTextPath: string = vinterLogoWithoutTextPath;
  vinterPureLogoRotatedPath: string = vinterPureLogoRotatedPath;
  token: string;
  password: string;
  passwordMinCount = passwordMinCharcount;
  mailForm: FormGroup;
  passForm: FormGroup;
  hasToken = false;
  regexp = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');

  constructor(
    private service: AuthenticationService,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerHandlerService,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.token = this.route.snapshot.queryParamMap.get('token');
      }
    );
    if (this.token !== null) {
      this.hasToken = true
    }
    this.setFormGroup();
  }

  resetPassword(){
    if(!this.hasToken){
      const response$ = this.service.sendResetPasswordMail(this.mailForm.controls['mail'].value)
      lastValueFrom(response$).then(data => {
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], {
          replaceUrl: true, queryParams: { mail: this.mailForm.controls['mail'].value }
        });
      }).catch(error => {
        this.spinner.hide();
      });
    }
    else{
      const response$ = this.service.resetPassword({token: this.token, newPassword: this.passForm.controls['password'].value})
      lastValueFrom(response$).then(data => {
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/login']);
      }).catch(error => {
        this.spinner.hide();
      });
    }
  }

  setFormGroup() {

      this.passForm = new FormGroup(
        {
          password: new FormControl('', [
            Validators.required,
            this.trimValidator,
            this.passwordValidator()
          ])
        }
      );
      this.mailForm = new FormGroup(
        {
          mail: new FormControl('', [
            Validators.required,
            this.trimValidator,
            this.mailValidator
          ])
        }
      );
  }

  mailValidator: ValidatorFn = (control: FormControl) => {
    if (!this.regexp.test(control.value)) {
      return {
        error: { value: 'Key.NotValidEmail' }
      };
    }
  }

  trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value !== null && control.value.startsWith(' ')) {
      return {
        error: { value: 'Key.StartsWithErr' }
      };
    }
    if (control.value !== null && control.value.endsWith(' ')) {
      return {
        error: { value: 'Key.EndsWithErr' }
      };
    }
    return null;
  }

  checkFormValidity(form: FormGroup, formControlName: string) {
    if (
      (form.get(formControlName).touched &&
        form.get(formControlName).errors) ||
      form.errors?.['mismatch']
    ) {
      return true;
    }
    return false;
  }

  getFormValidityMessage(form: FormGroup, formControlName: string) {
    if (
      form.get(formControlName).touched &&
      form.get(formControlName).errors?.['error']
    ) {
      return this.localizationService.translateTextWithoutKey(
        form.get(formControlName).errors?.['error'].value
      );
    } else if (
      form.get(formControlName).touched &&
      form.get(formControlName).errors?.['required']
    ) {
      return this.localizationService.translateText('RequiredMassage');
    }
    else if (
      (form.get(formControlName).touched &&
        form.errors?.['mismatch']) &&
      (formControlName == 'password' || formControlName == 'passwordAgain') &&
      form.get('password').value &&
      form.get('passwordAgain').value
    ) {
      return this.localizationService.translateText('MismatchMessage');
    }
    else if (
      form.get(formControlName).touched &&
      form.get(formControlName).errors?.['invalidPassword']
    ) {
      return 'Key.InvalidRegistirationPassword';
    }
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /\d/.test(value);
      const isLengthValid = value?.length >= passwordMinCharcount;

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && isLengthValid;

      return !passwordValid ? { invalidPassword: true } : null;
    };
  }

}
