import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { GPTEvalStatus, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';

@Component({
  selector: 'app-competence-step-result',
  templateUrl: './competence-step-result.component.html',
  styleUrls: ['./competence-step-result.component.scss']
})
export class CompetenceStepResultComponent implements OnInit{

  @Input() applicationStepId: string;

  stepInfo: any;
  filteredStepInfo: any;

  iconPath = 'assets/vinter/icons/';
  iconPathEnding = '-icon-circled.svg';
  questionCollectionLanguageList: any[] = [];
  questionCollectionCategories = QuestionCollectionCategoryEnum;
  questionExpectedAnswerType = QuestionExpectedAnswerType;
  isWaitingForAI = false;
  sidebarVisible: boolean = false;



  constructor(private applicationStepService :ApplicationStepService,
    private localizationService: LocalizationService,
    private appInfoService: AppInfoService
   ) {
  }

  ngOnInit(): void {
    this.questionCollectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();
    this.getResultData();
  }

  getResultData(){
    this.applicationStepService.GetApplicationStepResult({ id: this.applicationStepId }).subscribe(result => {
      this.stepInfo = result;
      this.stepInfo.questionCollectionResult.questionCollectionQuestionRelations.forEach(element => {
        element.question["questionCategorySvgPath"] = this.iconPath + this.getQuestionTypeSvgIconRef(element.question.expectedAnswerType) + this.iconPathEnding;
        element.question["svgAlt"] = this.getQuestionTypeSvgIconRef(element.question.expectedAnswerType);
        element.question["shortLangText"] = this.getShortLangText(element.question.languageID);
        element.question["progressBarColor"] = this.getProgressBarColor(element.question.gptScore);
        element.question["scoreIntPart"] = this.getIntegerPart(element.question.gptScore);
        element.question["scoreDecPart"] = this.getDecimalPart(element.question.gptScore);
        element["isAnyCheatLogExist"] = this.checkIfQuestionLogExists(element);
      });
      this.stepInfo.questionCollectionResult["isTechnicalOrCompetenceTest"] = this.checkTechnicalOrCompetenceTest(this.stepInfo.questionCollectionResult.questionCollectionCategory);
      this.filteredStepInfo = this.stepInfo;
      this.isWaitingForAI = this.filteredStepInfo.questionCollectionResult.questionCollectionQuestionRelations?.some(q => q.question?.gptEvalStatus === GPTEvalStatus.Waiting) ?? false;
      
    });
  }

  checkIfQuestionLogExists(relation){
    return !!(relation?.isFaceDetected || 
      relation?.isMultipleFaceDetected || 
      relation?.isImproprietySpeech || 
      relation?.isSwitchedTab || 
      !relation?.faceDetectionStatus ||
      relation?.isMultipleMonitor);
  }

  checkTechnicalOrCompetenceTest(category){
    return (category === this.questionCollectionCategories.TechnicalTest || category == this.questionCollectionCategories.CompetenceTest) ? true: false;
  }

  getQuestionTypeSvgIconRef(type){
    return Object.keys(QuestionExpectedAnswerType)[Object.values(QuestionExpectedAnswerType).indexOf(type)].toLowerCase();
  }

  getShortLangText(languageID){
    const keyName = this.questionCollectionLanguageList.find(x=> x.id === languageID)?.name;
    const text = keyName ?? false ? this.localizationService.translatedTextList['QCollectionShort'+keyName] : '';
    return text;
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

  getIntegerPart(score: number): string {
    let value = "00";
    if(score > 0)
      value = Math.floor(score).toString();
    return value;
  }

  getDecimalPart(score: number): string {
    const decimalPart = score.toFixed(2).split('.')[1];
    return decimalPart || '00'; 
  }

  filterList(answerType) {
    this.filteredStepInfo.questionCollectionResult.questionCollectionQuestionRelations = this.stepInfo.questionCollectionResult.questionCollectionQuestionRelations
    .filter(item => {
      if(answerType === item.question.expectedAnswerType){
        return true;
      }
    }).sort(x => x.Order);
  }
}
