<div class="landing-navbar">
    <img class="landingLogo" src="../../../../../assets/vinter/images/landing/landingVinterLogo.png ">
    <div style="display: flex;float: right;height: 100%;align-items: center;margin-right: 5%;">
        <div style="color: var(--gray-50);font-size: 14px;font-weight: 500;cursor: pointer;" class="mr-3">
            <span (click)="toggle()">Login</span>
            <p-overlay [(visible)]="overlayVisible" appendTo="body" contentStyleClass="shadow-2 bg-black-alpha-40 border-round p-3 pb-1 mt-6 overlay-back"
                (onAnimationStart)="onBeforeShowHandler($event)">
                <div class="arrow"></div>
                <div class="button-container button-text">
                    <a class="w-full flex m-2 cursor-pointer backToButton" (click)="goToUrl('hr')">
                        As Company
                    </a>
                </div>
                <div class="button-container button-text">
                    <a class="w-full flex m-2 cursor-pointer backToButton" (click)="goToUrl('applicant')">
                        As Applicant
                    </a>
                </div>  
            </p-overlay>
        </div>

        <div (click)="showGetContactForm = true"  style="cursor: pointer;color: #ffffff;font-size: 14px;font-weight: 500;background: var(--blue-500);padding: 12px;border-radius: 9px;margin-left: 10px">
            Contact Us
        </div>
    </div>
</div>

<div class="landing-main">
    <div class="grid" style="justify-content: center;align-items: center;margin: 0;">
        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight">
            <div class="col-12 md:col-6 first-container" style="display: flex;flex-direction: column;justify-content: center;">
                <div style="color: var(--blue-200);font-size: 22px;font-weight: 500;    margin-bottom: 12px;">
                    POWERED BY AI
                </div>
                <div style="color: #ffffff;font-size: 40px;font-weight: 700;width: 50%;">
                    TRANSFORMED HIRING!
                </div>
                <div
                    style="color: var(--blue-200, #A4BCFF);
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 27.5px; /* 102.5% */
                    letter-spacing: 1.6px;
                    margin-top: 3rem;
                    margin-bottom: 3rem;">
                    It's friendlier, smarter, and more effective than ever!
                </div>
                <div
                    style="color: #FBFBFB;
                    font-family: 'Nunito';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: 150%; /* 30px */
                    letter-spacing: 0.2px;   margin-bottom: 2rem;">
                    A SaaS platform that enables employers to manage their recruitment processes, facilitates candidate
                    assessments to be conducted in a more agile, objective, efficient, and flexible manner.
                </div>
                    <div (click)="showGetContactForm = true" style="cursor: pointer;color: var(--blue-500);font-size: 14px;font-weight: 500;background: #ffffff;padding: 12px;border-radius: 9px;margin-top: 14px;width: 130px;">
                        Contact Us
                        <i  class="pi pi-arrow-right" style="font-size: 1rem;margin-left: 3px;"></i>
                    </div>
            </div>

            <div class="col-12 md:col-6"
                style="display: flex;align-items: center;justify-content: center; position: relative; min-height: 500px;">
                <div class="avatar-five-star slide-diagonal-up-left">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-five-star.svg" alt="avatar-five-star">
                </div>
                <div class="avatar-like slide-diagonal-down-right">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-like.svg" alt="avatar-like">
                </div>
                <div class="avatar-man slide-diagonal-down-left">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-man.svg" alt="avatar-man">
                </div>
                <div class="avatar-rating slide-diagonal-up-right">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-rating.svg"alt="avatar-rating">
                </div>
                <div class="avatar-score slide-up">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-score.svg" alt="avatar-score">
                </div>
                <div class="avatar-suggest slide-right">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-suggest.svg"alt="avatar-suggest">
                </div>
                <div class="avatar-trusted slide-left">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-trusted.svg"alt="avatar-trusted">
                </div>
                <div class="avatar-woman slide-down">
                    <img src="../../../../../assets/vinter/images/landing/landing-avatar-woman.svg" alt="avatar-woman">
                </div>
                <!-- <img class="userIconImage" style="width: 80%;" src="../../../../../assets/vinter/images/landing/landingUserIcon1.png"> -->
                <div style="position: absolute; top: 6px; right: 73px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse1.svg" alt="avatar-five-star">
                </div>
                <div style="position: absolute; top: 75px; right: 451px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse2.svg" alt="avatar-five-star">
                </div>
                <div style="position: absolute; top: 194px; right: 287px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse3.svg" alt="avatar-five-star">
                </div>
                <div style="position: absolute; top: 275px; right: 159px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse4.svg" alt="avatar-five-star">
                </div>
                <div class="destroyable-ellipse-2" style="position: absolute; top: 376px; right: 459px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse5.svg" alt="avatar-five-star">
                </div>
                <div class="destroyable-ellipse" style="position: absolute; top: 568px; right: 257px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse6.svg" alt="avatar-five-star">
                </div>
                <div class="destroyable-ellipse" style="position: absolute; top: 679px; right: 68px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse7.svg" alt="avatar-five-star">
                </div>
                <div class="destroyable-ellipse"style="position: absolute; top: 737px; right: 396px;">
                    <img src="../../../../../assets/vinter/images/landing/Ellipse8.svg" alt="avatar-five-star">
                </div>
            </div>

        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>
</div>

<div class="scrolling-text-container"
    style="background-color: var(--blue-500);height: 200px;height: 110px;display: flex;overflow: hidden;align-items: center;">
    <div class="vinterTextWhite">VINTER</div>
    <div class="vinterTextBlack">VINTER</div>
    <div class="vinterTextWhite">VINTER</div>
    <div class="vinterTextBlack">VINTER</div>
    <div class="vinterTextWhite">VINTER</div>
    <div class="vinterTextBlack">VINTER</div>
    <div class="vinterTextWhite">VINTER</div>
    <div class="vinterTextBlack">VINTER</div>
</div>

<div style="padding-top: 5%;padding-bottom: 5%;">
    <div class="grid ellipsBackground" style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                ">

        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight">
            <div class="col-6 lg:col-3 landing-card">
                <div class="landing-div-desc-header">
                    9x
                </div>
                <div
                    style="background-color: var(--blue-400);width: 10px;height: 10px;border-radius: 10px;margin-top: 10px;margin-bottom: 20px;">
                </div>
                <div style="color: var(--blue-500);font-size: 22px;font-weight: 700;">
                    FASTER HIRING PROCESS
                </div>
                <div class="landing-div-desc">
                    Eliminating stages such as manual application review, phone interviews, and in-person interviews
                    reduces time spent.
                </div>
            </div>
            <div class="col-6 lg:col-3 landing-card">
                <div class="landing-div-desc-header">
                    80%
                </div>
                <div
                    style="background-color: var(--blue-400);width: 10px;height: 10px;border-radius: 10px;margin-top: 10px;margin-bottom: 20px;">
                </div>
                <div style="color: var(--blue-500);font-size: 22px;font-weight: 700;">
                    COSTING SAVING
                </div>
                <div class="landing-div-desc">
                    By eliminating stages like providing interview venues and equipment, travel, and accommodation, it
                    reduces recruitment costs.
                </div>
            </div>
            <div class="col-6  lg:col-3 landing-card">
                <div class="landing-div-desc-header">
                    100+
                </div>
                <div
                    style="background-color: var(--blue-400);width: 10px;height: 10px;border-radius: 10px;margin-top: 10px;margin-bottom: 20px;">
                </div>
                <div style="color: var(--blue-500);font-size: 22px;font-weight: 700;">
                    JOB POSITION
                </div>
                <div class="landing-div-desc">
                    Assesses candidates for each position based on assigned criteria and assigns scores.
                </div>
            </div>
            <div class="col-6 lg:col-3 landing-card">
                <div class="landing-div-desc-header">
                    1000+
                </div>
                <div
                    style="background-color: var(--blue-400);width: 10px;height: 10px;border-radius: 10px;margin-top: 10px;margin-bottom: 20px;">
                </div>
                <div style="color: var(--blue-500);font-size: 22px;font-weight: 700;">
                    QUESTIONS
                </div>
                <div class="landing-div-desc">
                    With its pool of thousands of ready-made questions, it assists human resources personnel in finding
                    the right candidate.
                </div>
            </div>
        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>

</div>


<div style="background-color: var(--gray-900);padding-top: 5%;">

    <div class="grid" style="justify-content: center;align-items: center;margin: 0;">
        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight">
            <div class="col-12 md:col-6">
                <div style="color: var(--blue-500);font-size: 22px;font-weight: 500;">
                    VINTER
                </div>
                <div style="color: #ffffff;font-size: 48px;font-weight: 700;">
                    HOW TO WORK
                </div>
            </div>
            <div class="col-12 md:col-6" style="    padding-top: 3rem;">
                <div style="color:var(--gray-200);font-size: 20px;font-weight: 500;">
                    You can easily manage recruitment processes in just 5 steps, saving time and budget.
                </div>
            </div>
        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>

</div>

<div style="background-color: var(--gray-900);padding-top: 5%;padding-bottom: 5%;">

    <div class="grid" style="justify-content: center;align-items: center;margin: 0;">
        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight">
            <div class="col-12 md:col-3" style="display: flex;">
                <div class="verticalSteps">
                    <div class="landingStepNumberDiv vertical">
                        <div (click)="onStepClicked(1)" class="landingStepNumber vertical" [ngClass]="{'landingStepNumberActive': stepIndex >0}">
                            1
                        </div>
                        <div class="landingStepNumberLine vertical" [ngClass]="{'landingStepNumberLineActive': stepIndex >0}"></div>
                        <div (click)="onStepClicked(2)" class="landingStepNumber vertical" [ngClass]="{'landingStepNumberActive': stepIndex >1}">
                            2
                        </div>
                        <div class="landingStepNumberLine vertical" [ngClass]="{'landingStepNumberLineActive': stepIndex >1}"></div>
                        <div (click)="onStepClicked(3)" class="landingStepNumber vertical" [ngClass]="{'landingStepNumberActive': stepIndex >2}">
                            3
                        </div>
                        <div class="landingStepNumberLine vertical" [ngClass]="{'landingStepNumberLineActive': stepIndex >2}"></div>
                        <div (click)="onStepClicked(4)" class="landingStepNumber vertical" [ngClass]="{'landingStepNumberActive': stepIndex >3}">
                            4
                        </div>
                        <div class="landingStepNumberLine vertical" [ngClass]="{'landingStepNumberLineActive': stepIndex >3}"></div>
                        <div (click)="onStepClicked(5)" class="landingStepNumber vertical" [ngClass]="{'landingStepNumberActive': stepIndex >4}">
                            5
                        </div>
                    </div>
                    <div class="landingStepTextDiv vertical">
                        <div (click)="onStepClicked(1)" class="landingStepText vertical" [ngClass]="{'landingStepTextActive': stepIndex >0}">
                            Create the Position
                        </div>
                        <div class="landingStepTextLine vertical"></div>
                        <div (click)="onStepClicked(2)" class="landingStepText vertical" [ngClass]="{'landingStepTextActive': stepIndex >1}">
                            Invite Applicant
                        </div>
                        <div class="landingStepTextLine vertical"></div>
                        <div (click)="onStepClicked(3)" class="landingStepText vertical" [ngClass]="{'landingStepTextActive': stepIndex >2}">
                            Applicants Solve Questions
                        </div>
                        <div class="landingStepTextLine vertical"></div>
                        <div (click)="onStepClicked(4)" class="landingStepText vertical" [ngClass]="{'landingStepTextActive': stepIndex >3}">
                            Score the Applicants
                        </div>
                        <div class="landingStepTextLine vertical"></div>
                        <div (click)="onStepClicked(5)" class="landingStepText vertical" [ngClass]="{'landingStepTextActive': stepIndex >4}">
                            Manage the Process
                        </div>
                    </div>
                </div>
                <div class="horizontalSteps">
                    <div class="landingStepNumberDiv horizontal">
                        <div (click)="onStepClicked(1)" class="landingStepNumber horizontal" [ngClass]="{'landingStepNumberActive': stepIndex >0}">
                            1
                        </div>
                        <div class="landingStepNumberLine horizontal" [ngClass]="{'landingStepNumberLineActive': stepIndex >0}"></div>
                        <div (click)="onStepClicked(2)" class="landingStepNumber horizontal" [ngClass]="{'landingStepNumberActive': stepIndex >1}">
                            2
                        </div>
                        <div class="landingStepNumberLine horizontal" [ngClass]="{'landingStepNumberLineActive': stepIndex >1}"></div>
                        <div (click)="onStepClicked(3)" class="landingStepNumber horizontal" [ngClass]="{'landingStepNumberActive': stepIndex >2}">
                            3
                        </div>
                        <div class="landingStepNumberLine horizontal" [ngClass]="{'landingStepNumberLineActive': stepIndex >2}"></div>
                        <div (click)="onStepClicked(4)" class="landingStepNumber horizontal" [ngClass]="{'landingStepNumberActive': stepIndex >3}">
                            4
                        </div>
                        <div class="landingStepNumberLine horizontal" [ngClass]="{'landingStepNumberLineActive': stepIndex >3}"></div>
                        <div (click)="onStepClicked(5)" class="landingStepNumber horizontal" [ngClass]="{'landingStepNumberActive': stepIndex >4}">
                            5
                        </div>
                    </div>
                    <div class="landingStepTextDiv horizontal" style="height: 3rem">
                        <div (click)="onStepClicked(1)" class="landingStepText horizontal" [ngClass]="{'landingStepTextActive': stepIndex >0}">
                            Create the Position
                        </div>
                        <div class="landingStepTextLine horizontal"></div>
                        <div (click)="onStepClicked(2)" class="landingStepText horizontal" [ngClass]="{'landingStepTextActive': stepIndex >1}">
                            Invite Applicant
                        </div>
                        <div class="landingStepTextLine horizontal"></div>
                        <div (click)="onStepClicked(3)" class="landingStepText horizontal" [ngClass]="{'landingStepTextActive': stepIndex >2}">
                            Applicants Solve Questions
                        </div>
                        <div class="landingStepTextLine horizontal"></div>
                        <div (click)="onStepClicked(4)" class="landingStepText horizontal" [ngClass]="{'landingStepTextActive': stepIndex >3}">
                            Score the Applicants
                        </div>
                        <div class="landingStepTextLine horizontal"></div>
                        <div (click)="onStepClicked(5)" class="landingStepText horizontal" [ngClass]="{'landingStepTextActive': stepIndex >4}" style="width: 10px;">
                            Manage the Process
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-9">
                <div style="height: 100%;overflow: hidden;">
                    <div *ngIf="stepIndex==1" class="slide-down-to-up landingPageStepImage">
                        <div  style="padding-left:5%;padding-top:3%;height: 15%">
                            <i style="color: var(--blue-500);font-size: 2rem;" class="pi pi-arrow-right"></i>
                        </div>
                        <div class="landing-step-div-header">
                            Create the position as flexible steps that fit your process.
                            <div style="display: flex;">
                                <div style="width: 100%;padding-top: 5%;">
                                    <img style="width: 100%;" src="../../../../../assets/vinter/images/landing/stepImage1.png">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="stepIndex==2" class="slide-down-to-up landingPageStepImage">
                        <div  style="padding-left:5%;padding-top:3%;height: 15%">
                            <i style="color: var(--blue-500);font-size: 2rem;" class="pi pi-arrow-right"></i>
                        </div>
                        <div class="landing-step-div-header">
                            Invite applicants to take the test and identify the right fit for your team.
                            <div style="display: flex;">
                                <div style="width: 100%;padding-top: 5%;">
                                    <img style="width: 100%;" src="../../../../../assets/vinter/images/landing/stepImage2.png">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="stepIndex==3" class="slide-down-to-up landingPageStepImage">
                        <div  style="padding-left:5%;padding-top:3%;height: 15%">
                            <i style="color: var(--blue-500);font-size: 2rem;" class="pi pi-arrow-right"></i>
                        </div>
                        <div class="landing-step-div-header">
                            Ensure applicants solve the qualified questions explicitly prepared for their positions.
                            <div style="display: flex;">
                                <div style="width: 100%;padding-top: 5%;">
                                    <img style="width: 100%;" src="../../../../../assets/vinter/images/landing/stepImage3.png">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="stepIndex==4" class="slide-down-to-up landingPageStepImage">
                        <div  style="padding-left:5%;padding-top:3%;height: 15%">
                            <i style="color: var(--blue-500);font-size: 2rem;" class="pi pi-arrow-right"></i>
                        </div>
                        <div class="landing-step-div-header">
                            Check Vinter's report on their responses and rate their performance.
                            <div style="display: flex;">
                                <div style="width: 100%;padding-top: 5%;">
                                    <img style="width: 100%;" src="../../../../../assets/vinter/images/landing/stepImage4.png">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="stepIndex==5" class="slide-down-to-up landingPageStepImage">
                        <div  style="padding-left:5%;padding-top:3%;height: 15%">
                            <i style="color: var(--blue-500);font-size: 2rem;" class="pi pi-arrow-right"></i>
                        </div>
                        <div class="landing-step-div-header">
                            Transparently manage the entire evaluation process with the help of Vinter-AI.
                            <div style="display: flex;">
                                <div style="width: 100%;padding-top: 5%;">
                                    <img style="width: 100%;" src="../../../../../assets/vinter/images/landing/stepImage5.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>

</div>

<div style="background-color: #FCFCFF;height: 110vh;padding-top: 5%;">

    <div class="grid" style="justify-content: center;align-items: center;margin: 0;">
        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight" style="display: flex;justify-content: center;">
            <div style="display: flex;flex-direction: column;">
                <div style="color: var(--gray-900);font-size: 22px;font-weight: 500;text-align: center;">
                    VINTER IS
                </div>
                <div style="color: var(--blue-500);font-size: 40px;font-weight: 700;text-align: center;">
                    FLEXIBLE. BIAS FREE. SECURE.
                </div>
                <div class="landing-div-desc-font" style="color: var(--gray-200);font-size: 20px;font-weight: 500;text-align: center;padding-bottom: 10%;padding-left: 10%;padding-right: 10%;padding-top: 2%;">
                    Vinter makes the pre-hiring process transparent with explainable artificial intelligence (XAI) support. It eliminates bias and can fairly evaluate and analyze up to 1000 candidates simultaneously. Information regarding companies and candidates remains confidential, not shared with third parties.
                </div>
                <div style="display: flex;justify-content: center;">
                    <div style="position: relative;">
                        <img (click)="onApplicantScreenImageClicked()" style="width: 100%;" src="../../../../../assets/vinter/images/landing/applicantScreen1.png">
                        <img [ngClass]="showApplicantScreenImages ? 'slide-applicant-left-to-right' : 'slide-applicant-left-to-right-reverse'"
                             [ngStyle]="{'display': (hideApplicantScreenImages) ? 'none' : 'block'}"
                             style="position: absolute;" src="../../../../../assets/vinter/images/landing/applicantScreen2.png">
                        <img [ngClass]="showApplicantScreenImages ? 'slide-applicant-right-to-left' : 'slide-applicant-right-to-left-reverse'"
                             [ngStyle]="{'display': (hideApplicantScreenImages) ? 'none' : 'block'}"
                             style="position: absolute;" src="../../../../../assets/vinter/images/landing/applicantScreen3.png">
                        <img [ngClass]="showApplicantScreenImages ? 'slide-applicant-down-to-up' : 'slide-applicant-down-to-up-reverse'"
                             [ngStyle]="{'display': (hideApplicantScreenImages) ? 'none' : 'block'}"
                             style="position: absolute;" src="../../../../../assets/vinter/images/landing/applicantScreen4.png">
                        <img [ngClass]="showApplicantScreenImages ? 'slide-applicant-down-to-up-right' : 'slide-applicant-down-to-up-right-reverse'"
                             [ngStyle]="{'display': (hideApplicantScreenImages) ? 'none' : 'block'}"
                             style="position: absolute;" src="../../../../../assets/vinter/images/landing/applicantScreen5.png">
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>

</div>


<div style="background-image: url(../../../../../assets/vinter/images/landing/landingBackgroundWithLine.png);background-repeat: no-repeat;background-size: cover;min-height: 100vh;padding-top: 100px;padding-bottom: 150px;">

    <div class="grid" style="justify-content: center;align-items: center;margin: 0;">
        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight" style="display: flex;justify-content: center;">
            <div style="display: flex;flex-direction: column;">
                <div style="color: #ffffff;font-size: 40px;font-weight: 700;text-align: center;">
                    CURRENTLY TECHNICAL, TECHNICALLY EFFICIENT
                </div>
                <div style="color: #99A9D5;font-size: 20px;font-weight: 500;text-align: center;" class="landing-div-desc-font">
                    Vinter offers you the most efficient experience possible by implementing all advancements in software and business development technologies.
                </div>
            </div>
        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>
    <div #slider
    (mousedown) ="startSliderDragging($event)"
    (mouseenter) ="startSliderDragging($event)"
    (mouseup) ="stopSliderDragging($event)"
    (mouseleave) ="stopSliderDragging($event)"
    (mousemove)="moveSliderEvent($event)"
    (touchstart) ="startTouchSliderDragging($event)"
    (touchend) ="stopTouchSliderDragging($event)"
    (touchmove)="moveTouchSliderEvent($event)"
     class="grid" style="margin-top: 3rem;margin-left: 0;margin-right: 0;overflow-x: hidden;flex-wrap: nowrap;padding-bottom: 3rem;">
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    SAAS INFRASTRUCTURE
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Data is securely stored in a cloud-based system. New features can be added with an expandable product infrastructure, and it can be accessed from anywhere.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    EXTENSIVE LIBRARY
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Qualified questions tailored to positions were prepared by collaborating with professionals from different disciplines and departments.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    PROACTIVE APPROACH
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    In contrast to standardized questions, video, audio, visual, and written question-answer support is provided to objectively assess candidates' qualifications.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;" >
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    DYNAMIC MANAGEMENT
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    The flexible system structure allows for easy adaptation and management of processes, thereby enhancing efficiency by creating customized structures for the organization.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    SAAS INFRASTRUCTURE
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Data is securely stored in a cloud-based system. New features can be added with an expandable product infrastructure, and it can be accessed from anywhere.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    EXTENSIVE LIBRARY
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Qualified questions tailored to positions were prepared by collaborating with professionals from different disciplines and departments.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    PROACTIVE APPROACH
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    In contrast to standardized questions, video, audio, visual, and written question-answer support is provided to objectively assess candidates' qualifications.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;" >
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    DYNAMIC MANAGEMENT
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    The flexible system structure allows for easy adaptation and management of processes, thereby enhancing efficiency by creating customized structures for the organization.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    SAAS INFRASTRUCTURE
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Data is securely stored in a cloud-based system. New features can be added with an expandable product infrastructure, and it can be accessed from anywhere.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    EXTENSIVE LIBRARY
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Qualified questions tailored to positions were prepared by collaborating with professionals from different disciplines and departments.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    PROACTIVE APPROACH
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    In contrast to standardized questions, video, audio, visual, and written question-answer support is provided to objectively assess candidates' qualifications.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;" >
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    DYNAMIC MANAGEMENT
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    The flexible system structure allows for easy adaptation and management of processes, thereby enhancing efficiency by creating customized structures for the organization.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    SAAS INFRASTRUCTURE
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Data is securely stored in a cloud-based system. New features can be added with an expandable product infrastructure, and it can be accessed from anywhere.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    EXTENSIVE LIBRARY
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    Qualified questions tailored to positions were prepared by collaborating with professionals from different disciplines and departments.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;">
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    PROACTIVE APPROACH
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    In contrast to standardized questions, video, audio, visual, and written question-answer support is provided to objectively assess candidates' qualifications.
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-3 lg:col-4 md:col-6" [ngClass]="{'landingBoxContainer': !stopSlider}" style="padding-left: 0;padding-right: 0;" >
            <div class="landingBoxDiv">
                <div class="landingBoxInnerDiv">
                    <img style="height: 30px;" src="../../../../../assets/vinter/icons/landingFileIcon.svg" />
                </div>
                <div class="landingBoxHeaderDiv">
                    DYNAMIC MANAGEMENT
                </div>
                <div class="landingBoxLineDiv"></div>
                <div class="landingBoxDescDiv">
                    The flexible system structure allows for easy adaptation and management of processes, thereby enhancing efficiency by creating customized structures for the organization.
                </div>
            </div>
        </div>
    </div>
</div>

<div style="background-color: var(--gray-900);padding-top: 1%;padding-bottom: 1%;color:#ffffff">

    <div style="background-color: var(--blue-300);width: 100%;display: flex;overflow: hidden;transform: rotate(4deg) translateY(-90px);height: 84px;align-items: center;margin-left: -9px">
        <div class="landingScrollingTextRight" style="display: flex;align-items: center">
            <span style="width: 384px;padding-left: 10px">Technical Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 417px;padding-left: 10px">Video Interview </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 470px;padding-left: 10px">Competence Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 436px;padding-left: 10px">Personality Test</span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 400px;padding-left: 10px">Language Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 384px;padding-left: 10px">Technical Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 417px;padding-left: 10px">Video Interview </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 470px;padding-left: 10px">Competence Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 436px;padding-left: 10px">Personality Test</span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 400px;padding-left: 10px">Language Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 384px;padding-left: 10px">Technical Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 417px;padding-left: 10px">Video Interview </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 470px;padding-left: 10px">Competence Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 436px;padding-left: 10px">Personality Test</span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 400px;padding-left: 10px">Language Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 384px;padding-left: 10px">Technical Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 417px;padding-left: 10px">Video Interview </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 470px;padding-left: 10px">Competence Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 436px;padding-left: 10px">Personality Test</span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 400px;padding-left: 10px">Language Test </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />

        </div>
    </div>
    <div style="background-color: #ffffff;width: 100%;display: flex;overflow: hidden;transform: rotate(4deg) translateY(-90px);height: 84px;align-items: center;margin-left: -9px">
        <div class="landingScrollingText" style="display: flex;align-items: center">
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 415px;padding-left: 10px">Create Position </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 486px;padding-left: 10px">New Question List </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />
            <span style="width: 590px;padding-left: 10px">Use Position Template </span>
            <img style="height: 45px;" src="../../../../../assets/vinter/icons/landingScrollingIcon.svg" />

        </div>
    </div>

    <div class="grid" style="margin:0">
        <div class="col-12 md:col-2 destroy-on-tight"></div>
        <div class="col-12 md:col-8 grid full-on-tight" style="display: flex;">
            <div style="display: flex;flex-direction: column;">
                <div style="color: #ffffff;font-size: 40px;font-weight: 700;">
                    GET IN TOUCH
                </div>
                <div style="color: var(--gray-200);font-size: 20px;font-weight: 500;padding-top: 2rem;padding-bottom: 1rem;" class="landing-div-desc-font">
                    To have access to the demo version and be among the first to try, please get in touch with us.
                </div>
                <div (click)="showGetContactForm = true" style="cursor: pointer;color: var(--blue-500);font-size: 14px;font-weight: 500;background: #ffffff;padding: 12px;border-radius: 9px;margin-top: 14px;width: 130px;margin-bottom:7rem">
                    Contact Us
                    <i  class="pi pi-arrow-right" style="font-size: 1rem;margin-left: 3px;"></i>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-2 destroy-on-tight"></div>
    </div>
</div>

<div style="background-color: var(--gray-900);padding-top: 1%;padding-bottom: 1%;padding-left: 5%;color:#ffffff">

    <div class="grid" style="justify-content: center;align-items: center;margin: 0;">
        <div class="col-12" style="opacity: 0.6;">
            © 2023 Vinter All Rights Reserved
        </div>
    </div>

</div>

<p-sidebar [(visible)]="showGetContactForm" position="right" styleClass="landingSidebarDiv">

    <ng-template pTemplate="header">
        <i *ngIf="!showMembershipAgreement && !showConfidentialityAgreement" (click)="onSidebarCloseClicked()" style="color: var(--gray-900);font-size: 1.5rem;cursor: pointer;" class="pi pi-times"></i>

        <span *ngIf="showMembershipAgreement" style="color: var(--blue-500);display: flex;align-items: center;">
            <i (click)="onSidebarBackClicked()" style="color: var(--blue-500);font-size: 1.5rem;cursor: pointer;" class="pi pi-arrow-left"></i>
            <span style="font-size: 14px;font-weight: 500;margin-left: 1rem;">Back to Get in Touch</span>
        </span>

        <span *ngIf="showConfidentialityAgreement" style="color: var(--blue-500);display: flex;align-items: center;">
            <i (click)="onSidebarBackClicked()" style="color: var(--blue-500);font-size: 1.5rem;cursor: pointer;" class="pi pi-arrow-left"></i>
            <span style="font-size: 14px;font-weight: 500;margin-left: 1rem;">Back to Get in Touch</span>
        </span>

    </ng-template>

    <div style="padding-left: 5%;padding-right: 5%;">

        <div *ngIf="!showMembershipAgreement && !showConfidentialityAgreement">
            <div *ngIf="!showSpinner && !showSuccess">
                <div style="color:var(--gray-900);font-size: 40px;font-weight:700;">
                    GET IN TOUCH
                </div>
                <div style="color:var(--gray-400);font-size: 14px;font-weight:400;    padding-top: 1.5rem;">
                    To have access to the demo version and be among the first to try, please get in touch with us.
                </div>
                <div class="getInTouhcFormDiv">
                    <form [formGroup]="getInTouhcForm">
                        <div class="grid" style="padding-top: 2rem;">
                            <div class="col-12 md:col-6 formDiv">
                                <div class="p-fluid">
                                    <div class="field">
                                        <label class="formLabel" htmlFor="name">Name</label><p class="requiredField" style="display: inline;margin-left: 5px;" *ngIf="getInTouhcForm.controls.name.touched && getInTouhcForm.controls.name.errors?.required">*</p>
                                        <input pInputText id="name" formControlName="name" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 formDiv">
                                <div class="p-fluid">
                                    <div class="field">
                                        <label class="formLabel" htmlFor="surname">Surname</label><p class="requiredField" style="display: inline;margin-left: 5px;" *ngIf="getInTouhcForm.controls.surname.touched && getInTouhcForm.controls.surname.errors?.required">*</p>
                                        <input pInputText id="surname" formControlName="surname" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 formDiv">
                                <div class="p-fluid">
                                    <div class="field">
                                        <label class="formLabel" htmlFor="company">Company Info (optional)</label>
                                        <input pInputText id="company" formControlName="company" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 formDiv" >
                                <div class="p-fluid">
                                    <div class="field">
                                        <label class="formLabel" htmlFor="email">Email</label><p class="requiredField" style="display: inline;margin-left: 5px;" *ngIf="getInTouhcForm.controls.email.touched && (getInTouhcForm.controls.email.errors?.required || getInTouhcForm.controls.email.errors?.pattern)">*</p>
                                        <input pInputText id="email" formControlName="email" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 md:col-6 formDiv">
                                <div class="p-fluid">
                                    <div class="field">
                                        <label class="formLabel" htmlFor="phone">Phone (optional)</label>
                                        <input pInputText id="phone" formControlName="phone" type="text" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 formDiv">
                                <div class="p-fluid">
                                    <div class="field">
                                        <p-checkbox
                                            formControlName="isAgreementAccepted"
                                            inputId="agreement"
                                            [binary]="true"></p-checkbox>
                                        <p class="agreementText">
                                            I accept the
                                            <a (click)="onMembershipAgreementClicked()" class="agreementLink cursor-pointer">Membership Agreement</a>
                                            <!-- and -->
                                            <!-- <a (click)="onConfidentialityAgreementClicked()" class="agreementLink cursor-pointer">Confidentiality Agreement</a> -->
                                            <!-- <a  class="agreementLink cursor-pointer">Confidentiality Agreement</a> -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 formDiv">
                                <div class="p-fluid">
                                    <p-button [disabled]="getInTouhcForm.invalid" (click)="onSendGetContactForm()" [style]="{'background':'var(--blue-500)','width': '100px'}" label="Send" icon="pi pi-send" iconPos="right"></p-button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="showSpinner && !showSuccess" style="text-align: center;padding-top: 40%;">
                <p-progressSpinner strokeWidth="5"></p-progressSpinner>
            </div>

            <div *ngIf="!showSpinner && showSuccess" style="text-align: center;padding-top: 40%;">
                <i class="pi pi-check" style="color: var(--blue-500);font-size: 5rem;font-weight: 900;"></i>
                <div style="font-size: 2rem;color: var(--blue-500);font-weight: 600;margin-bottom: 1rem;margin-top: 0.5rem;">
                    Sent!
                </div>
                <div style="    color: var(--gray-300);font-weight: 400;">
                    Thanks for reaching out to us. We will get back to you soon.
                </div>
            </div>

        </div>

        <div *ngIf="showMembershipAgreement && !showConfidentialityAgreement">
            <div
        [ngStyle]="{
            'max-height':'144',
            'overflow-y':'auto',
            'text-left':'left',
            'font-size':'xs',
            'font-family':'sans-serif',
            'color':'gray'
        }"

        class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
text-gray-700"
    >

    <h1>Membership Agreement</h1>
        <h2 class="text-gray-800 font-semibold text-base">Definitions</h2>

        <p>In this clarification text;</p>

        <p>
            <span class="font-semibold">Personal Data: </span>

            Concerning all kinds of information of an identifiable or identifiable natural
            person,
        </p>

        <p>
            <span class="font-semibold">
                Personal Data Protection Law (“KVKK”): </span
            >Personal Data Protection Law No. 6698, which was published in the Official Gazette on April 7,
            2016 and became secret,
        </p>

        <p>
            <span class="font-semibold">Data Processor: </span>
            A natural or personal person who produces Personal Data on his or her own behalf,
            based on the authority given by the data controller,
        </p>

        <p>
            <span class="font-semibold">Data Controller: </span>
            Can use the purposes and tools of processing Personal Data,
            and can use the real or personal person responsible for the creation of the data recording system.
        </p>

        <p>
            <span class="font-semibold">Relevant Person: </span>

            Refers to the real person whose personal data is processed.
        </p>

        <p>
            <a
                class="text-xs"
                href="https://www.normdigital.com/"
                target="_blank"
                rel="noreferrer"
            >
                As NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ and VINTER AI RECRUITMENT LTD (“Our Companies”) </a
            >, protecting private lives,
            operating and protecting information security in order to protect fundamental rights and freedoms are among our ethical values and regime.
            Accordingly, our explanations below are presented for your information within the scope of
            fulfilling our duty of clarification arising from Article 10 of the Personal Data Protection Law (“KVKK”).
        </p>

        <h2 class="text-gray-800 font-semibold text-base">
            Data Controller
        </h2>

        <p>
            “Data Controller” in terms of your personal data; It is registered at the Izmir Trade Registry with the registration number 227198,
            can enter Mersis at 0631152363600001, the company headquarters is 'Adatepe Mahallesi Doğuş Caddesi No:207 / AG İç Kapı No:1 Buca'
            NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ.
        </p>

        <h2 class="text-gray-800 font-semibold text-base">
            Parties Whose Data is Collected
        </h2>

        <p>
            We process separately the parties of our custodians who have a business relationship with our
            company within the scope of the Personal Data Protection Law. These;
        </p>

        <ul class="list-disc list-inside">
            <li>Our employees and employee candidates,</li>

            <li>
                Family members and relatives of our employees and employee candidates,
            </li>

            <li>Our customers,</li>

            <li>Our suppliers,</li>

            <li>Our consultants,</li>

            <li>Our business partners,</li>

            <li>Our shareholders,</li>

            <li>Our company members,</li>

            <li>Our company representatives,</li>

            <li>
                Person(s) and employees who separate contractual relations
            </li>

            <li>Person(s) who are the addressees of legal proceedings,</li>

            <li>Survey meetings,</li>

            <li>Our visitors.</li>
        </ul>

        <h2 class="text-gray-800 font-semibold text-base">
            Data Collected and Processed by Our Company from the Parties
        </h2>

        <p>
            We process the following data from the company parties
            within the scope of Articles 5 and 6 of the Personal Data Protection Law. These;
        </p>

        <ul class="list-disc list-inside">
            <li>Family and social life information,</li>

            <li>Education and training information,</li>

            <li>Employment information,</li>

            <li>Information regarding request/complaint management,</li>

            <li>Information regarding legal affairs,</li>

            <li>Information regarding ethical values and legal compliance,</li>

            <li>Financial information,</li>

            <li>Audit information,</li>

            <li>Electronic media usage information,</li>

            <li>
                Information regarding the goods and services provided and supplied,
            </li>

            <li>Business activities information,</li>

            <li>Information regarding trade and other licenses and permits,</li>

            <li>International venue security information,</li>

            <li>
                Visual and business information (photo, camera, audio recording),
            </li>

            <li>Telecommunications noted,</li>

            <li>The use of e-mail and information systems services is registered,</li>

            <li>They logged in,</li>

            <li>Health reports and health information,</li>

            <li>Biometric data,</li>

            <li>Criminal record information..</li>
        </ul>

        <h2 class="text-gray-800 font-semibold text-base">
            Our Company's Data Processing Purposes
        </h2>

        <p>
            Within the scope of Articles 5 and 6 of the Personal Data Protection Law,
            we process data in order to achieve our Company purposes stated below, limited to the relevant purposes. These;
        </p>

        <ul class="list-disc list-inside">
            <li>Carrying out our company's commercial activities,</li>

            <li>
                Ability to carry out business processes related to commercial activities,
            </li>

            <li>
                Management and execution of relationships with business partners and/or suppliers,
            </li>

            <li>Technical management of our company's websites,</li>

            <li>Customer management and tracking of complaints,</li>

            <li>
                Product surveys and follow-up of the questions you send to our Company,
            </li>

            <li>
                Carrying out the necessary work by our business units to benefit you from the products and services offered by our company,
            </li>

            <li>
                Planning and execution of sales, marketing and after-sales processes of products and/or services,
            </li>

            <li>
                Providing information about the contents of products and services,
            </li>

            <li>
                Sending commercial electronic messages with separate approval in accordance with legal regulations,
            </li>

            <li>Conducting competitions, events and other organizations,</li>

            <li>
                Conducting legal and commercial relations with our Company and people who have business relations with our Company and ensuring the security of these relations,
            </li>

            <li>
                Administrative operations regarding communication carried out by our company,
            </li>

            <li>Employee administration and management,</li>

            <li>
                Ensuring physical security and control of company locations,
            </li>

            <li>Planning logistics activities,</li>

            <li>Conducting reputation research processes,</li>

            <li>
                Compliance with ethical values and law and execution of legal affairs and procedures,
            </li>

            <li>Following up contract processes and/or legal requests,</li>

            <li>
                Planning and executing Human Resources and personnel recruitment processes and monitoring and carrying out education and training activities,
            </li>

            <li>
                Planning and/or execution of occupational health and/or safety processes,
            </li>

            <li>
                Ensuring that our Company can benefit from incentives by carrying out research and development activities,
            </li>

            <li>
                Planning and execution of corporate communication and corporate management activities,
            </li>

            <li>Execution of information security management services,</li>

            <li>
                Monitoring and auditing finance and/or accounting affairs and carrying out activities aimed at detecting customers' financial risks,
            </li>

            <li>
                Determining and implementing our company's commercial and business strategies,
            </li>

            <li>Creating and tracking visitor records,</li>

            <li>
                For other purpose or purposes to be notified to the relevant person during the collection of information,
            </li>

            <li>
                To ensure the fulfillment of legal obligations as required or required by the relevant legislation
            </li>
        </ul>

        <h2 class="text-gray-800 font-semibold text-base">
            Transfer of Data Processed by Our Company Within Its Purposes
        </h2>

        <p>
            Within the framework of the personal data processing conditions and purposes specified in Articles 8 and 9 of the Personal Data Protection Law,
            your personal data processed by our Company may be transferred to our subsidiaries, shareholders,
            business partners (only anonymously) within the scope of the purposes detailed above,
            legally authorized public institutions and private persons. It can be shared with other people.
        </p>

        <h2 class="text-gray-800 font-semibold text-base">
            Method and Legal Reason for Processing Data by Our Company
        </h2>

        <p>
            Personal data is collected and processed by our Company in connection with the legitimate purposes clearly stated above,
            in accordance with the law and the rules of honesty, by verbally,
            written and/or electronically,
            by providing verbal, written and/or electronic information to personal data owners in a clear and understandable manner and,
            when necessary, by obtaining their explicit consent.
            It is used, recorded, stored and processed within the framework of the principle of proportionality,
            provided that it is limited. Your personal data is processed automatically from you personally, through our Company's website,
            within the scope of our contractual relationship. With this;
            Audio and visual records of our employees or employee candidates regarding the planning and execution of Human Resources and personnel recruitment processes and the monitoring
            and realization of training and education activities are automatically processed through the mobile application if contacted through our Company's "Vinter"
            mobile application. Your collected personal data is processed by the Company in line with the purpose stated in this disclosure document,
            within the personal data processing conditions specified in Articles 5 and 6 of the KVKK, in order to plan and execute the
            Company's human resources policies and processes; It may be transferred to our suppliers, legally authorized public institutions and legally
            authorized private persons within the scope of the personal data processing conditions and purposes specified in Articles 8 and 9 of the Law.
        </p>

        <p>
            For the purposes mentioned above, interviews are carried out through servers abroad. While the relevant processing and transfer activities are carried out,
            reasonable administrative and technical measures are taken to ensure the security and privacy of your personal data.
        </p>

        <h2 class="text-gray-800 font-semibold text-base">
            Storage Period of Data Processed by Our Company
        </h2>

        <p>
            Your personal data is stored for the retention periods specified in the relevant legal legislation,
            or if no period is specified in the relevant legal legislation, in accordance with our Company's practices and commercial life practices,
            or for the period required by the above-mentioned processing purposes,
            and is subsequently deleted and destroyed in accordance with Article 7 of the Personal Data Protection Law. or is made anonymous.
        </p>

        <h2 class="text-gray-800 font-semibold text-base">
            Security of Your Data Processed by Our Company
        </h2>

        <p>
            In order to prevent your personal data from being exposed to unauthorized access,
            lost or damaged in the environments where they are processed and stored, the technical and
            administrative measures of the Information Security Management System (ISO 27001 Standard
            and good practice booklet 27018) encouraged by our management, the Personal Data Protection
            Management System (Bureau Veritas - Data The requirements of the Protection Technical Standard,
            BS 10012 Data Protection Personal Information Management System Standard) as well as the requirements
            of the Personal Data Security Guide published by the KVKK board are constantly operated and developed within the scope of continuous improvement.
        </p>

        <h2 class="text-gray-800 font-semibold text-base">
            Rights of the Relevant Person whose Data is Processed
        </h2>

        <p>
            In accordance with Article 11 of the Personal Data Protection Law, everyone can contact the data controller and obtain;
        </p>

        <ol class="list-[lower-alpha] list-inside">
            <li>Learning whether personal data is being processed or not,</li>

            <li>Requesting information if personal data has been processed,</li>

            <li>
                Learning the purpose of processing personal data and whether they are used for their intended purpose,
            </li>

            <li>
                Knowing the third parties to whom personal data is transferred at home or abroad,
            </li>

            <li>
                Requesting correction of personal data if it is incomplete or incorrectly processed,
            </li>

            <li>
                Requesting the deletion or destruction of personal data within the framework of the conditions stipulated in Article 7 of the Personal Data Protection Law,
            </li>

            <li>
                Requesting that transactions made in accordance with clauses (d) and (e) be notified to third parties to whom personal data is transferred
            </li>

            <li>
                Objecting to the emergence of a result that is unfavorable to the individual by analyzing the processed data exclusively through automatic systems,
            </li>

            <li>
                In case of damage due to unlawful processing of personal data, they have the right to demand compensation for the damage.
            </li>
        </ol>

        <h2 class="text-gray-800 font-semibold text-base">
            Application Methods Within the Framework of the Rights of the Interested Person
        </h2>

        <p>
            In accordance with the 1st paragraph of Article 13 of the Personal Data Protection Law,
            you can make your request to exercise your above-mentioned rights with the following methods and information,
            in accordance with the "Communiqué on the Procedures and Principles of Application to the Data Controller"
            published on 10 March 2018, No. 30356.
        </p>

        <p>Required information in the application content;</p>

        <ol class="list-decimal list-inside">
            <li>Name and Surname of the applicant</li>

            <li>
                If the applicant is a citizen of the Republic of Turkey, TR ID Number, if not, Passport number and Nationality or ID number, if available.
            </li>

            <li>
                Residence or workplace address of the applicant as basis for notification
            </li>

            <li>
                The applicant's notification e-mail address, telephone or fax number
            </li>

            <li>Subject of the applicant's request</li>

            <li>
                Information and documents regarding the applicant's request
            </li>
        </ol>

        <p class="font-semibold">Application Methods;</p>

        <ol class="list-decimal list-inside">
            <li>
                The applicant can personally fill out the "Application Form" to the addresses of NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ and
                VINTER AI RECRUITMENT LTD and deliver it to the information office with a handwritten report in a sealed envelope and with
                a note of "Information Request in Pursuance of the Personal Data Protection Law" on the envelope.
            </li>

            <li>
                The applicant can send notifications to the addresses of NORM DİJİTAL HİZMETLER TİCARET ANONİM
                ŞİRKETİ and VINTER AI RECRUITMENT LTD through a notary public, but the note
                "Information Request in Pursuance of the Personal Data Protection Law" must be added on the notification envelope.
            </li>

            <li>
                With the "Secure Electronic Signature" defined in the Electronic Signature Law No. 5070,
                the applicant can personally apply to our company's Registered Electronic Mail address

                <a
                    class="text-xs text-indigo-600"
                    href="mailto:normdigital@hs02.kep.tr"
                    target="_blank"
                    rel="noreferrer"
                >
                    normdigital@hs02.kep.tr </a
                > with the note "Information Request in Pursuance of the Personal Data Protection Law" in the subject line.
            </li>
        </ol>

        <p>
            <span class="font-semibold">Address: </span>Adatepe Mah. Doğuş
            Cad. No:207 AG İç Kapı No: 1 BUCA/İZMİR
        </p>

        <!-- <p>
            Yukarıda belirtilen haklarınız ile ilgili başvuru formumuza ise

            <a
                class="text-xs text-indigo-600"
                href="https://vinter.ml/uploads/images/ndigital-veri-sahibi-basvuru-formu.doc"
                download
            >
                buradan </a
            > ulaşabilirsiniz.
        </p> -->
    </div>
        </div>

        <div *ngIf="!showMembershipAgreement && showConfidentialityAgreement">

        </div>
    </div>
</p-sidebar>
