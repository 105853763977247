import { EntityStateEnum } from "@app/shared/common/enums";
import { QuestionListItem } from "./question-list-item";
import { QuestionCollectionQuestionRelations } from "./question-collection-question-relation-item";

export class QuestionCollectionFormItem {
    id: string;
    title: string;
    description: string;
    questionCollectionCategory: string;
    filteredQuestionCollectionCategory: string;
    languageId: string;
    questionCollectionLanguageId: string;
    duration: number;
    responsible: string;
    stepDurationType: number;
    showToApplicant: boolean;
    organizationID: string;
    subCategory: number;
    isTemprature: boolean;
    xmin: number;
    entityState: EntityStateEnum;
    questionCollectionQuestionRelations: QuestionCollectionQuestionRelations[] = [];
    level?: number;
}

