export class SelectedQuestionCollectionItem {
    id: string;
    title: string;
    description: string;
    duration: number;
    organizationID: string;
    category: string;
    subCategory : number;
    languageID: string;
    level?: number;
}
