<div class="cont_principal">
  <div class="cont_error">

    <h1>Oops </h1>
    <p>{{'Key.NotFoundedMessage' | translate}}</p>
    <br>
    <button pButton pRipple type="button" label="{{'Key.Home' | translate}}" class="p-button-rounded buttonFont" [routerLink]="['/']" routerLinkActive="router-link-active">
      <i class="pi pi-arrow-left myk-icon"></i>
    </button>

  </div>
  <div class="cont_aura_1"></div>
  <div class="cont_aura_2"></div>
</div>