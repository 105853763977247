<div>
    <app-applicant-pdf-report  [applicationStepsData]="applicationStepsData" [positionId]="kanbanData.positionId"
        [positionTitle]="positionTitle" [imageURL]="imageURL" [imageURLBase64]="imageURLBase64" [applicantFirstName]="applicantFirstName"
        [applicantLastName]="applicantLastName" [applicantFullName]="applicantFullName"
        [applicantEmail]="applicantEmail" [applicantPhoneNumber]="applicantPhoneNumber"
        [linkedinProfileURL]="linkedinProfileURL" [overallRating]="overallRating"
        [applicantInvitedPositions]="applicantInvitedPositions"
        [applicationId]="applicationId"
        (dialogVisibiltyEmitter)="showLicenseLockedDialog()">

    </app-applicant-pdf-report>

</div>

<app-custom-pdf-viewer *ngIf="isPdfViewerVisible" [isDownloadVisible]="true" [pdfFileName]="pdfFileName" [pdfFileUrl]="pdfAsLink" [renderText]="true"
    (onClose)="closePdfViewPage()"></app-custom-pdf-viewer>

<div class="grid">
    <div class="col-12 flex align-items-center pb-0" style="height: 8rem;">
        <div *ngIf="imageURL ?? false" class="flex justify-content-center ml-3 p-0">
            <img src="{{imageURL}}" alt="vinter"
                class="image-avatar m-2 align-items-center justify-content-center block img-float">
        </div>
        <div *ngIf="!(imageURL ?? false) && (applicantFirstName !== undefined) && (applicantLastName !== undefined)"
            class="flex justify-content-center ml-3 p-0">
            <div class="image-avatar-applicant  m-2 flex align-items-center justify-content-center block img-float">
                {{ applicantFirstName[0]?.toUpperCase() + applicantLastName[0]?.toUpperCase()}} </div>
        </div>
        <div class="col-4 flex chip-width">
            <div class="grid overflow-x-hidden">
                <div class="pl-3 flex align-items-center" style="max-width: -webkit-fill-available;">
                    <div class="h1-font" pTooltip='{{applicantFullName}}' tooltipPosition="top">
                        {{applicantFullName}}
                    </div>
                </div>
                <div class="responsible-col1 flex">
                    <div *ngIf="generalApplicationFormInfo?.cvFileScanStatus === 2"
                        class="border-round border-dashed border-blue-200 border-sm p-2 cursor-pointer flex justify-content-center align-items-center square"
                        (click)="onClickSeePdf()" (mouseover)="cvButtonHover = true" (mouseleave)="cvButtonHover = false">
                        <p *ngIf="!cvButtonHover" class="text-blue-500">Cv</p>
                        <img *ngIf="cvButtonHover" [src]="eyeBlueIconPath"
                            class="text-blue-500" />
                    </div>
                </div>
                <div class="applicant-label flex flex-wrap pt-2">
                    <div class="custom-date-display flex align-content-center">
                        <img class="flex align-items-center justify-content-center"
                            src="assets/vinter/icons/time-icon-outline.svg" width="18px" height="18px">
                        <p class="date-text flex align-items-center justify-content-center">{{ createdAt |
                            date:'dd.MM.yy' }}</p>
                    </div>

                    <div class="date-vertical-line"></div>

                    <div class="custom-date-display flex align-content-center">
                        <img class="flex align-items-center justify-content-center"
                            src="assets/vinter/icons/eye-outline.svg" width="18px" height="18px">
                        <p class="date-text flex align-items-center justify-content-center">{{"Key.LastActivityDay" |
                            translate: { value: daysDiff } }}</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="vertical-line"></div>

        <div class="col-4 flex mail-width">
            <div class="grid overflow-x-hidden">
                <div class="applicant-label">
                    <h5 class="text-justify contact-typo">
                        {{'Key.ContactInfo' | translate}}
                    </h5>
                </div>
                <div class="applicant-label flex mb-1 mt-1">
                    <img src="assets/vinter/icons/pozisyon-detay-fluent-mail-20-filled.svg" style="width: 1.5rem;">
                    <div class="text-justify mail-text ml-2 m-3 h5-font overflow-x-hidden text-overflow-ellipsis">
                        {{applicantEmail}}
                    </div>
                    <img *ngIf="!isCopied" class="clickboard-icon cursor-pointer"
                        src="assets/vinter/icons/pozisyon-detay-fluent-copy-24-regular.svg"
                        pTooltip='{{"Key.Copy" | translate}}' tooltipPosition="top" alt="Copy Email"
                        (click)="copyText(applicantEmail)" />
                    <img *ngIf="isCopied" class="clickboard-icon cursor-pointer"
                        src="assets/vinter/icons/pozisyon-detay-fluent-copy-24-regular.svg"
                        pTooltip='{{"Key.Copied" | translate}}' tooltipPosition="top" alt="Copy Email"
                        (click)="copyText(applicantEmail)" />

                </div>
                <div class="applicant-label flex">
                    <img src="assets/vinter/icons/applicant-detail-phone-filled-black.svg">
                    <h5 class="text-justify mail-text ml-2 m-auto h5-font">
                        {{applicantPhoneNumber}}
                    </h5>
                </div>
            </div>
        </div>
        <div class="vertical-line ml-0"></div>



        <div class="col-4 flex socialPlatformHeight">
            <div *ngIf="userAccountLinks.length > 0" class="social-info-col2 flex">
                <div class="grid overflow-x-hidden">
                    <div class="applicant-label ml-1">
                        <h5 class="text-justify contact-typo">
                            {{'Key.SocialInfo' | translate}}
                        </h5>
                    </div>
                <div>
                    <ng-container
                        *ngFor="let accountLink of userAccountLinks; let i = index">
                        <ng-container [ngSwitch]="accountLink.linkTypeId">
                            <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Linkedin"
                                class="applicant-label flex mb-1 mt-3 gap-2">
                                <span *ngIf="i <= 1" class="flex gap-2">
                                    <h5 class="text-justify mail-text ml-2 mb-1 h5-font">
                                        <a href="{{ 'https://www.linkedin.com/in/' + accountLink.url }}" target="_blank"
                                        class="no-style flex align-items-center text-gray-400">
                                            <img style="width: 16px;margin-right: 8px;"
                                                src="../../../../../assets/vinter/icons/linkedin_input.svg">
                                            {{accountLink.url}}
                                        </a>
                                    </h5>
                                <span (click)="openSocialPlatformDialog()" class="otherAccountCount cursor-pointer" *ngIf="userAccountLinks.length > 2 &&
                                      userAccountLinks[1].linkTypeId === EGeneralApplicationFormAccountLinkType.Linkedin">+{{userAccountLinks.length - 2}}</span>
                                </span>
                            </div>
                            <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Github"
                                class="applicant-label flex mb-1 mt-3 gap-2">
                                <span *ngIf="i <= 1"  class="flex gap-2">
                                    <h5 class="text-justify mail-text ml-2 mb-1 h5-font">
                                        <a href="{{ 'https://github.com/' + accountLink.url }}" target="_blank"
                                        class="no-style flex align-items-center text-gray-400">
                                            <img style="width: 16px;margin-right: 8px;"
                                                src="../../../../../assets/vinter/icons/github_input.svg">
                                            {{accountLink.url}}
                                        </a>
                                    </h5>
                                    <span (click)="openSocialPlatformDialog()"
                                    class="otherAccountCount cursor-pointer" *ngIf="userAccountLinks.length > 2 &&
                                    userAccountLinks[1].linkTypeId === EGeneralApplicationFormAccountLinkType.Github">+{{userAccountLinks.length - 2}}</span>
                                </span>
                            </div>

                                <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Behance"
                                    class="applicant-label flex mb-1 mt-3 gap-2">
                                      <span *ngIf="i <= 1"  class="flex gap-2">
                                        <h5 class="text-justify mail-text ml-2 mb-1 h5-font">
                                            <a href="{{ 'https://www.behance.net/' + accountLink.url }}" target="_blank"
                                            class="no-style flex align-items-center text-gray-400">
                                                <img style="width: 16px;margin-right: 8px;"
                                                    src="../../../../../assets/vinter/icons/behance_input.svg">
                                                {{accountLink.url}}
                                            </a>
                                        </h5>
                                        <span (click)="openSocialPlatformDialog()" class="otherAccountCount cursor-pointer" *ngIf="userAccountLinks.length > 2 &&
                                         userAccountLinks[1].linkTypeId === EGeneralApplicationFormAccountLinkType.Behance">+{{userAccountLinks.length - 2}}</span>
                                     </span>
                            </div>

                            <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Dribbble"
                                class="applicant-label flex mb-1 mt-3 gap-2">
                                <span *ngIf="i <= 1"  class="flex gap-2">
                                    <h5 class="text-justify mail-text ml-2 mb-1 h5-font">
                                        <a href="{{ 'https://dribbble.com/' + accountLink.url }}" target="_blank"
                                        class="no-style flex align-items-center text-gray-400">
                                            <img style="width: 16px;margin-right: 8px;"
                                                src="../../../../../assets/vinter/icons/dribble_input.svg">
                                            {{accountLink.url}}
                                        </a>
                                    </h5>
                                    <span (click)="openSocialPlatformDialog()" class="otherAccountCount cursor-pointer" *ngIf="userAccountLinks.length > 2 && userAccountLinks[1].linkTypeId === EGeneralApplicationFormAccountLinkType.Dribbble">+{{userAccountLinks.length - 2}}</span>
                                </span>
                            </div>

                            <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Medium"
                                class="applicant-label flex mb-1 mt-3 gap-2">
                                <span *ngIf="i <= 1"  class="flex gap-2">
                                    <h5 class="text-justify mail-text ml-2 mb-1 h5-font">
                                        <a href="{{ 'https://medium.com/@' + accountLink.url }}" target="_blank"
                                        class="no-style flex align-items-center text-gray-400">
                                            <img style="width: 16px;margin-right: 8px;"
                                                src="../../../../../assets/vinter/icons/medium_input.svg">
                                            {{accountLink.url}}
                                        </a>
                                    </h5>
                                    <span (click)="openSocialPlatformDialog()" class="otherAccountCount" *ngIf="userAccountLinks.length > 2 && userAccountLinks[1].linkTypeId === EGeneralApplicationFormAccountLinkType.Medium">+{{userAccountLinks.length - 2}}</span>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

                </div>
      </div>

            <div *ngIf="userAccountLinks.length > 0" class="vertical-line ml-2"></div>

            <div class="responsible-col2 flex">
                <div class="grid">
                    <div class="applicant-label ml-1 ">
                        <h5 class="text-justify contact-typo">
                            {{'Key.Responsible' | translate}}
                        </h5>
                    </div>
                    <div class="flex position-card-header mt-3 mb-4">
                        <app-user-avatar [UserFirstName]="responsibleFirstName" [UserSurname]="responsibleLastName"
                            [UserImageURL]="responsibleImageURL" [UserTitle]="responsibleTitle"></app-user-avatar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr>

<div class="grid" style="height: 4.5rem;">

    <div class="col-2 flex align-items-center pb-0 pt-0">
        <div class="score-width pl-0 flex">
            <div class="applicant-label ml-1">
                <h1 class="text-justify score-text ml-2 m-3">
                    {{overallRating | number : '1.2-2'}}
                </h1>
            </div>
        </div>
        <div class="vertical-line-score ml-0 mr-4"></div>
        <div class="col-1 flex">
            <div class="grid pt-2">
                <div class="applicant-label pl-0 ml-1">
                    <h5 class="text-justify contact-typo">
                        {{'Key.YourRate' | translate}}
                    </h5>
                </div>

                <div class="mt-2" *ngIf="overallRating !== null && overallRating !== undefined">
                    <app-vinter-rating class="cursor" [starNumber]="5" [disabled]="true" size="md" [value]="overallRating">
                    </app-vinter-rating>
                </div>
            </div>

        </div>

    </div>

    <div class="col-10 pt-2 flex justify-content-end align-items-center">

        <p-dropdown [disabled]="currentStepId == PositionStepKanbanStaticTypes.Archived ||
                    currentStepId == PositionStepKanbanStaticTypes.Rejected ||
                    currentStepId == PositionStepKanbanStaticTypes.Invitation ||
                    currentStepId == PositionStepKanbanStaticTypes.Hired"
            [options]="getSelectableSteps(kanbanSteps, currentStepId)" [(ngModel)]="currentStepId" optionLabel="title"
            optionValue="id" (onChange)="applicantStepChanged($event)" [style]="{'width':'18rem'}">
            <ng-template let-option pTemplate="item">
                <i class="pi pi-circle-fill" [ngStyle]="{'color': getStepColor(option.id), 'font-size': '0.8rem'}"></i>
                {{option.title}}
            </ng-template>
            <ng-template let-option pTemplate="selectedItem">
                <i class="pi pi-circle-fill" [ngStyle]="{'color': getStepColor(option.id), 'font-size': '0.8rem'}"></i>
                {{option.title}}
            </ng-template>
        </p-dropdown>

    </div>

</div>
<hr>

<p-dialog [visible]="!isKanbanChanged" (visibleChange)="isKanbanChanged = $event" [draggable]="false" [modal]="true" showEffect="fade" [style]="{width: '30vw'}"
    [breakpoints]="{'960px': '75vw'}" class="dialogPage" [closable]="false">
    <div class="grid">
        <div class="col-12" style="display: flex;justify-content: center;">
            <img style="width: 4rem;" src="/assets/vinter/icons/kanban-change-err.svg">
        </div>
        <div class="col-12 complete-dialog-header">
            <span class="ml-3 justify-content-center flex">
                {{ 'Key.StepChangeError' | translate }}
            </span>
        </div>
        <div class="col-12 complete-dialog-text pt-0">
            {{ 'Key.StepChangeErrorText' | translate }}
        </div>
        <div class="col-12 complete-dialog-header">
            <div class="col-6 flex justify-content-end">
                <p-button label="{{'Key.Ok' | translate}}" (onClick)="cancelStepChange()" styleClass="mr-2 mb-2">
                </p-button>
            </div>

            <div class="col-6 flex justify-content-start">
                <p-button label="{{'Key.Cancel' | translate}}" (onClick)="cancelStepChange()"
                    styleClass="mr-2 mb-2 p-button-outlined"></p-button>
            </div>

        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="socialPlatformsDialogVisible" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage socialPlatformHeader"  [closable]=" true">
    <div class="grid">
        <div class="col-12 socialPlatformHeaderText">
            {{'Key.SocialInfo' | translate}}
        </div>
        <div class="col-12 px-6">
            <div class="grid overflow-x-hidden justify-content-center">
                <ng-container
                    *ngFor="let accountLink of userAccountLinks; let i = index">
                    <ng-container [ngSwitch]="accountLink.linkTypeId">
                        <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Linkedin" style="display: contents">
                            <hr class="w-8rem flex m-1">
                                <div class="w-full justify-content-center flex mb-1 mt-2 gap-2">
                                    <h5 class="text-justify mail-text ml-2 mb-1 h5-font overflow-x-hidden text-overflow-ellipsis">
                                        <a href="{{ 'https://www.linkedin.com/in/' + accountLink.url }}" target="_blank"
                                            class="no-style flex align-items-center text-gray-400">
                                            <img style="width: 16px;margin-right: 8px;"
                                                src="../../../../../assets/vinter/icons/linkedin_input.svg">
                                            {{accountLink.url}}
                                        </a>
                                    </h5>
                                </div>
                        </div>

                        <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Github" style="display: contents">
                            <hr class="w-8rem flex m-1">
                            <div class="w-full justify-content-center flex mb-1 mt-2 gap-2">
                                <h5 class="text-justify mail-text ml-2 mb-1 h5-font overflow-x-hidden text-overflow-ellipsis">
                                    <a href="{{ 'https://github.com/' + accountLink.url }}" target="_blank"
                                    class="no-style flex align-items-center text-gray-400">
                                        <img style="width: 16px;margin-right: 8px;"
                                            src="../../../../../assets/vinter/icons/github_input.svg">
                                        {{accountLink.url}}
                                    </a>
                                </h5>
                            </div>
                        </div>
                            <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Behance" style="display: contents">
                                <hr class="w-8rem flex m-1">
                                <div class="w-full justify-content-center flex mb-1 mt-2 gap-2">
                                    <h5 class="text-justify mail-text ml-2 mb-1 h5-font overflow-x-hidden text-overflow-ellipsis">
                                        <a href="{{ 'https://www.behance.net/' + accountLink.url }}" target="_blank"
                                        class="no-style flex align-items-center text-gray-400">
                                            <img style="width: 16px;margin-right: 8px;"
                                                src="../../../../../assets/vinter/icons/behance_input.svg">
                                            {{accountLink.url}}
                                        </a>
                                    </h5>
                                </div>
                          </div>
                        <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Dribbble" style="display: contents">
                            <hr class="w-8rem flex m-1">
                            <div class="w-full justify-content-center flex mb-1 mt-2 gap-2">
                                <h5 class="text-justify mail-text ml-2 mb-1 h5-font overflow-x-hidden text-overflow-ellipsis">
                                    <a href="{{ 'https://dribbble.com/' + accountLink.url }}" target="_blank"
                                    class="no-style flex align-items-center text-gray-400">
                                        <img style="width: 16px;margin-right: 8px;"
                                            src="../../../../../assets/vinter/icons/dribble_input.svg">
                                        {{accountLink.url}}
                                    </a>
                                </h5>
                            </div>
                        </div>
                        <div *ngSwitchCase="EGeneralApplicationFormAccountLinkType.Medium" style="display: contents">
                            <hr class="w-8rem flex m-1">
                            <div class="w-full justify-content-center flex mb-1 mt-2 gap-2">
                                <h5 class="text-justify mail-text ml-2 mb-1 h5-font overflow-x-hidden text-overflow-ellipsis">
                                    <a href="{{ 'https://medium.com/@' + accountLink.url }}" target="_blank"
                                    class="no-style flex align-items-center text-gray-400">
                                        <img style="width: 16px;margin-right: 8px;"
                                            src="../../../../../assets/vinter/icons/medium_input.svg">
                                        {{accountLink.url}}
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</p-dialog>
<app-license-locked-dialog *ngIf="isLockedLicenseDialogVisible" (dialogVisibiltyEmitter)="closeLicenseLockedDialog()"></app-license-locked-dialog>
