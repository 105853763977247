<p-sidebar *ngIf="sidebarVisible && isResponse && selectedQuestion" [(visible)]="sidebarVisible"
    (onHide)="onHideSidebar()" position="right" [showCloseIcon]="false" [blockScroll]="true">
    <div class="w-full flex pt-4" *ngIf="!changeScoreSidebarVisible">
        <div class="w-6 flex">
            <p-button class="back-button" icon="pi pi-arrow-left" (click)="goToPreviousQuestion()"></p-button>
            <p-button class="next-button" icon="pi pi-arrow-right" (click)="goToNextQuestion()"></p-button>
        </div>
        <div class="w-6 flex justify-content-end" (click)="openChangeVinterScoreSidebar()">
            <app-vinter-score-card [isWaitingForAI]="isWaitingForAI" [score]="vinterScore + scoreChange" [labelKey]="'QuestionScore'"
                class="justify-content-center flex"></app-vinter-score-card>
        </div>
    </div>
    <app-change-vinter-score-sidebar *ngIf="changeScoreSidebarVisible" [vinterScore]="vinterScore"
        [answerContentID]="answerContentID" [applicantID]="applicantID"
        [(changeScoreSidebarVisible)]="changeScoreSidebarVisible" [(isScoreChanged)]="isScoreChanged"  [(scoreChange)]="scoreChange">
    </app-change-vinter-score-sidebar>
    <app-question-detail *ngIf="!changeScoreSidebarVisible" [question]="selectedQuestion"></app-question-detail>
    <app-answer-base-component *ngIf="!changeScoreSidebarVisible" [question]="selectedQuestion"
        [codingAnswer]="codingAnswer" [multipleChoiceAnswer]="multipleChoiceAnswer" [openEndedAnswer]="openEndedAnswer"
        [videoAnswer]="videoAnswer" [speakingAnswer]="speakingAnswer"
        [pronunciationVideoAnswer]="pronunciationVideoAnswer" [writingAnswer]="writingAnswer"
        [questionCollectionCategoryId]="questionCollectionCategoryId"></app-answer-base-component>
</p-sidebar>