import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    constructor(
        private localizationService:LocalizationService,
        private messageService:MessageService
    ) { }

    notifyError(error) {
        this.messageService.clear();
        this.messageService.add({
            severity: 'error',
            summary: this.localizationService.translatedTextList.Error,
            detail: error,
            life: 3000,
          });
    }

    notifySuccess(message) {
        this.messageService.clear();
        this.messageService.add({
            severity: 'success',
            summary: this.localizationService.translatedTextList.Successful,
            detail: message,
            life: 3000,
          });
    }
}
