
<div class="test-content">
    <div class="test-description">
        <div class="description-text-wrapper">
            <p class="description-text" [innerHTML]="report.reportText"></p>      
        </div>
    </div>
</div>

<div >
    <div id="ocean">
        <div class="my-4">
            <div #area class="mb-3 avoid w-36rem">
                <div class="ocean-grid">
                    <div class="grid flex pb-3" style="border: none;">
                        <div class="col-1 ocean-icon-width align-content-center custom-display">
                            <div class="py-3 px-2" style="width: 5%">
                                <p-badge class="o-color" size="large" value="O"></p-badge>
                            </div>

                        </div>
                        <div
                            class="col-5 pl-2 pr-0 font-medium text-sm flex flex-wrap align-content-center o-text-color w-auto">
                            {{"Key.OpennessToExperience" | translate}}
                        </div>
                    </div>

                    <app-ocean-progressbar [progressBarOceanScore]="step?.stepPerTestResult?.o"
                        [oceanCategory]="'o'" [oceanScore]="step?.stepPerTestResult?.o?.toFixed(0)"
                        [oceanParent]="true"></app-ocean-progressbar>

                    <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                        class="flex justify-content-between">
                        <div
                            class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                            <p class="pdf-text-size h-full">
                                {{"Key.OpennessLowScoreDescription" | translate}}
                            </p>

                        </div>
                        <div
                            class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                            <p class="pdf-text-size text-right h-full ">
                                {{"Key.OpennessHighScoreDescription" | translate}}
                            </p>

                        </div>
                    </div>
                </div>
                <div class="grid mx-0 pb-4 mt-0">
                    <p class="text-justify pdf-text-size">
                        {{step?.stepPerTestResult?.reportTextO}}
                    </p>
                </div>

                <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120" class="pl-2 w-39rem">
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center o-text-color">
                            {{"Key.Imagination" | translate}}
                        </div>
                        <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.imagination"
                                    [oceanCategory]="'o'"
                                    [oceanScore]="step?.stepPerTestResult?.imagination?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>

                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center o-text-color">
                            {{"Key.ArtisticInterest" | translate}}
                        </div>
                        <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.artisticInterests"
                                    [oceanCategory]="'o'"
                                    [oceanScore]="step?.stepPerTestResult?.artisticInterests?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center o-text-color">
                            {{"Key.Emotionality" | translate}}
                        </div>
                        <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.emotionality"
                                    [oceanCategory]="'o'"
                                    [oceanScore]="step?.stepPerTestResult?.emotionality?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center o-text-color">
                            {{"Key.Adventurousness" | translate}}
                        </div>
                        <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.adventurousness"
                                    [oceanCategory]="'o'"
                                    [oceanScore]="step?.stepPerTestResult?.adventurousness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center o-text-color">
                            {{"Key.Intellect" | translate}}
                        </div>
                        <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.intellect"
                                    [oceanCategory]="'o'"
                                    [oceanScore]="step?.stepPerTestResult?.intellect?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center o-text-color">
                            {{"Key.Liberalism" | translate}}
                        </div>
                        <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.liberalism"
                                    [oceanCategory]="'o'"
                                    [oceanScore]="step?.stepPerTestResult?.liberalism?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div #area class="mb-3 avoid w-36rem">
                <div class="ocean-grid">
                    <div class="grid flex pb-3" style="border: none;">
                        <div class="col-1 ocean-icon-width align-content-center custom-display">
                            <div class="py-3 px-2" style="width: 5%">
                                <p-badge class="c-color" size="large" value="C"></p-badge>
                            </div>
                        </div>
                        <div
                            class="col-4 pl-2 pr-0 font-medium text-sm  flex flex-wrap align-content-center c-text-color w-auto">
                            {{"Key.Conscientiousness" | translate}}
                        </div>
                    </div>

                    <app-ocean-progressbar [progressBarOceanScore]="step?.stepPerTestResult?.c"
                        [oceanCategory]="'c'" [oceanScore]="step?.stepPerTestResult?.c?.toFixed(0)"
                        [oceanParent]="true"></app-ocean-progressbar>

                    <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                        class="flex justify-content-between">

                        <div
                            class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                            <p class="pdf-text-size h-full">
                                {{"Key.ConscientiousnessLowScoreDescription" | translate}}
                            </p>

                        </div>
                        <div
                            class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                            <p class="pdf-text-size text-right h-full">
                                {{"Key.ConscientiousnessHighScoreDescription" | translate}}
                            </p>

                        </div>
                    </div>
                </div>
                <div class="grid mx-0 pb-4 mt-0">
                    <p class="text-justify pdf-text-size">
                        {{step?.stepPerTestResult?.reportTextC}}
                    </p>
                </div>

                <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120" class="pl-2 w-39rem">
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center c-text-color">
                            {{"Key.SelfEfficacy" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.selfEfficacy"
                                    [oceanCategory]="'c'"
                                    [oceanScore]="step?.stepPerTestResult?.selfEfficacy?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>

                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center c-text-color">
                            {{"Key.Orderliness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.orderliness"
                                    [oceanCategory]="'c'"
                                    [oceanScore]="step?.stepPerTestResult?.orderliness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center c-text-color">
                            {{"Key.Dutifulness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.dutifulness"
                                    [oceanCategory]="'c'"
                                    [oceanScore]="step?.stepPerTestResult?.dutifulness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center c-text-color">
                            {{"Key.AchievementStriving" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.achievementStriving"
                                    [oceanCategory]="'c'"
                                    [oceanScore]="step?.stepPerTestResult?.achievementStriving?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center c-text-color">
                            {{"Key.SelfDiscipline" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.selfDiscipline"
                                    [oceanCategory]="'c'"
                                    [oceanScore]="step?.stepPerTestResult?.selfDiscipline?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center c-text-color">
                            {{"Key.Cautiousness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.cautiousness"
                                    [oceanCategory]="'c'"
                                    [oceanScore]="step?.stepPerTestResult?.cautiousness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div #area class="mb-3 avoid w-36rem">
                <div class="ocean-grid">
                    <div class="grid flex pb-3" style="border: none;">
                        <div class="col-1 ocean-icon-width align-content-center custom-display">
                            <div class="py-3 px-2" style="width: 5%">
                                <p-badge class="e-color" size="large" value="E"></p-badge>
                            </div>
                        </div>
                        <div
                            class="col-4 pl-2 pr-0 font-medium text-sm  flex flex-wrap align-content-center blue-600 w-auto">
                            {{"Key.Extraversion" | translate}}
                        </div>
                    </div>

                    <app-ocean-progressbar [progressBarOceanScore]="step?.stepPerTestResult?.e"
                        [oceanCategory]="'e'" [oceanScore]="step?.stepPerTestResult?.e?.toFixed(0)"
                        [oceanParent]="true"></app-ocean-progressbar>

                    <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                        class="flex justify-content-between">
                        <div
                            class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                            <p class="pdf-text-size h-full">
                                {{"Key.ExtraversionLowScoreDescription" | translate}}
                            </p>

                        </div>
                        <div
                            class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                            <p class="pdf-text-size text-right h-full">
                                {{"Key.ExtraversionHighScoreDescription" | translate}}
                            </p>

                        </div>
                    </div>
                </div>
                <div class="grid mx-0 pb-4 mt-0">
                    <p class="text-justify pdf-text-size">
                        {{step?.stepPerTestResult?.reportTextE}}
                    </p>
                </div>

                <div
                    *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120" class="pl-2 w-39rem">
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center blue-600">
                            {{"Key.Friendliness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.friendliness"
                                    [oceanCategory]="'e'"
                                    [oceanScore]="step?.stepPerTestResult?.friendliness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>

                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center blue-600">
                            {{"Key.Assertiveness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.assertiveness"
                                    [oceanCategory]="'e'"
                                    [oceanScore]="step?.stepPerTestResult?.assertiveness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center blue-600">
                            {{"Key.Gregariousness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.gregariousness"
                                    [oceanCategory]="'e'"
                                    [oceanScore]="step?.stepPerTestResult?.gregariousness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center blue-600">
                            {{"Key.ActivityLevel" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.activityLevel"
                                    [oceanCategory]="'e'"
                                    [oceanScore]="step?.stepPerTestResult?.activityLevel?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center blue-600">
                            {{"Key.ExcitementSeeking" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.excitementSeeking"
                                    [oceanCategory]="'e'"
                                    [oceanScore]="step?.stepPerTestResult?.excitementSeeking?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center blue-600">
                            {{"Key.Cheerfulness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.cheerfulness"
                                    [oceanCategory]="'e'"
                                    [oceanScore]="step?.stepPerTestResult?.cheerfulness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div #area class="mb-3 avoid w-36rem">
                <div class="ocean-grid">
                    <div class="grid flex pb-3" style="border: none;">
                        <div class="col-1 ocean-icon-width align-content-center custom-display">
                            <div class="py-3 px-2" style="width: 5%">
                                <p-badge class="a-color" size="large" value="A"></p-badge>
                            </div>
                        </div>
                        <div
                            class="col-4 pl-2 pr-0 font-medium text-sm  flex flex-wrap align-content-center a-text-color w-auto">
                            {{"Key.Agreeableness" | translate}}
                        </div>
                    </div>

                    <app-ocean-progressbar [progressBarOceanScore]="step?.stepPerTestResult?.a"
                        [oceanCategory]="'a'" [oceanScore]="step?.stepPerTestResult?.a?.toFixed(0)"
                        [oceanParent]="true"></app-ocean-progressbar>

                    <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                        class="flex justify-content-between">
                        <div
                            class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                            <p class="pdf-text-size h-full">
                                {{"Key.AgreeablenessLowScoreDescription" | translate}}
                            </p>

                        </div>
                        <div
                            class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                            <p class="pdf-text-size text-right h-full">
                                {{"Key.AgreeablenessHighScoreDescription" | translate}}
                            </p>

                        </div>
                    </div>
                </div>
                <div class="grid mx-0 pb-4 mt-0">
                    <p class="text-justify pdf-text-size">
                        {{step?.stepPerTestResult?.reportTextA}}
                    </p>
                </div>

                <div
                    *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120" class="pl-2 w-39rem">
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center a-text-color">
                            {{"Key.Trust" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.trust"
                                    [oceanCategory]="'a'"
                                    [oceanScore]="step?.stepPerTestResult?.trust?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>

                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center a-text-color">
                            {{"Key.Morality" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.morality"
                                    [oceanCategory]="'a'"
                                    [oceanScore]="step?.stepPerTestResult?.morality?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center a-text-color">
                            {{"Key.Altruism" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.altruism"
                                    [oceanCategory]="'a'"
                                    [oceanScore]="step?.stepPerTestResult?.altruism?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center a-text-color">
                            {{"Key.Cooperation" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.cooperation"
                                    [oceanCategory]="'a'"
                                    [oceanScore]="step?.stepPerTestResult?.cooperation?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center a-text-color">
                            {{"Key.Modesty" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.modesty"
                                    [oceanCategory]="'a'"
                                    [oceanScore]="step?.stepPerTestResult?.modesty?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center a-text-color">
                            {{"Key.Sympathy" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.sympathy"
                                    [oceanCategory]="'a'"
                                    [oceanScore]="step?.stepPerTestResult?.sympathy?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #area class="mb-3 avoid w-36rem">
                <div class="ocean-grid">
                    <div class="grid flex pb-3" style="border: none;">
                        <div class="col-1 ocean-icon-width align-content-center custom-display">
                            <div class="py-3 px-2" style="width: 5%">
                                <p-badge class="n-color" size="large" value="N"></p-badge>
                            </div>
                        </div>
                        <div
                            class="col-4 pl-2 pr-0 font-medium text-sm  flex flex-wrap align-content-center n-text-color w-auto">
                            {{"Key.Neuroticism" | translate}}
                        </div>
                    </div>

                    <app-ocean-progressbar [progressBarOceanScore]="step?.stepPerTestResult?.n"
                        [oceanCategory]="'n'" [oceanScore]="step?.stepPerTestResult?.n?.toFixed(0)"
                        [oceanParent]="true"></app-ocean-progressbar>

                    <div *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                        class="flex justify-content-between">
                        <div
                            class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                            <p class="pdf-text-size h-full">
                                {{"Key.NeuroticismLowScoreDescription" | translate}}
                            </p>

                        </div>
                        <div
                            class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                            <p class="pdf-text-size text-right h-full">
                                {{"Key.NeuroticismHighScoreDescription" | translate}}
                            </p>

                        </div>
                    </div>
                </div>
                <div class="grid mx-0 pb-4 mt-0">
                    <p class="text-justify pdf-text-size">
                        {{step?.stepPerTestResult?.reportTextN}}
                    </p>
                </div>

                <div
                    *ngIf="step.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120" class="pl-2 w-39rem">
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center n-text-color">
                            {{"Key.Anxiety" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.anxiety"
                                    [oceanCategory]="'n'"
                                    [oceanScore]="step?.stepPerTestResult?.anxiety?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>

                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center n-text-color">
                            {{"Key.Anger" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.anger"
                                    [oceanCategory]="'n'"
                                    [oceanScore]="step?.stepPerTestResult?.anger?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center n-text-color">
                            {{"Key.Depression" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.depression"
                                    [oceanCategory]="'n'"
                                    [oceanScore]="step?.stepPerTestResult?.depression?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center n-text-color">
                            {{"Key.SelfConsciousness" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.selfConsciousness"
                                    [oceanCategory]="'n'"
                                    [oceanScore]="step?.stepPerTestResult?.selfConsciousness?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center n-text-color">
                            {{"Key.Immoderation" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.immoderation"
                                    [oceanCategory]="'n'"
                                    [oceanScore]="step?.stepPerTestResult?.immoderation?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>

                        </div>
                    </div>
                    <div class="grid flex flex-nowrap p-0" style="border: none;">
                        <div
                            class="col-3 pt-2 text-padding font-medium text-xs flex flex-wrap align-content-center n-text-color">
                            {{"Key.Vulnerability" | translate}}
                        </div>
                        <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                            <div class="w-18rem ml-7">
                                <app-ocean-progressbar
                                    [progressBarOceanScore]="step?.stepPerTestResult?.vulnerability"
                                    [oceanCategory]="'n'"
                                    [oceanScore]="step?.stepPerTestResult?.vulnerability?.toFixed(0)"
                                    [oceanParent]="false"></app-ocean-progressbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>