<div class="formgrid grid h-full flex  mt-4">
    <div class="text-left w-full pl-3 pb-3">
        <p-button styleClass="buttonFont" [label]="isEditMode ? ('Key.Update' | translate) : ('Key.Create' | translate)"
            [disabled]="isSubmitDisabled()" (click)="onAddQuestion()"></p-button>
    </div>
    <div class=" col py-0 h-full overflow-y-scroll">
        <p class="create-question">{{(isEditMode ? 'Key.EditQuestion' : 'Key.CreateNewQuestion') | translate}}</p>
        <div *ngIf="selectedLanguageName.length != 0 " class="mt-4 flex justify-content-start create-question-warning">
            <span class="pi pi-exclamation-triangle warning-icon flex align-item-center"></span>
            <label class="flex font-medium message-color ml-2">{{ "Key.LanguageWarnAddQuestion" | translate: {value:
                selectedLanguageName} }}</label>
        </div>

        <div class="pb-3 pt-3">
            <p class="create-question">{{"Key.Question" | translate}}</p>
        </div>
        <label *ngIf="questionCollectionCategory === foreignLanguageCategory"
            class="flex label-color font-medium m-2 ml-0 mb-3">{{ "Key.QuestionCategory" | translate }}</label>
        <div *ngIf="questionCollectionCategory === foreignLanguageCategory" class="flex flex-wrap gap-3 mb-4">
            <div *ngFor="let category of questionCategories" class="flex align-items-center">
                <p-radioButton value={{category.id}} [(ngModel)]="selectedCategory"></p-radioButton>
                <label class="ml-2">{{category.name}}</label>
            </div>
        </div>
        <div class="field pb-3">
            <label class="flex label-color font-medium m-2  ml-0">{{ "Key.SelectAnQuestionType" | translate }}</label>
            <p-selectButton [options]="answerTypes" [(ngModel)]="selectedAnswerType" [multiple]="false"
                optionLabel="name" optionValue="id" (onChange)="clearMediaContentOnAnswerTypeChange($event.value)">
                <ng-template let-item pTemplate>
                    <app-custom-chip *ngIf="item.id === 1" [svgUrl]="'assets/vinter/icons/multipleChoice.svg'"
                        [alt]="'OpenEnded'" [bgColorClass]="'purple'" [imgSize]="'sm'"></app-custom-chip>
                    <app-custom-chip *ngIf="item.id === 2" [svgUrl]="'assets/vinter/icons/openEndedQuestion.svg'"
                        [alt]="'MultipleChoice'" [bgColorClass]="'light-blue'" [imgSize]="'md'"></app-custom-chip>
                    <app-custom-chip *ngIf="item.id === 3" [svgUrl]="'assets/vinter/icons/videoQuestion.svg'"
                        [alt]="'VideoQuestion'" [bgColorClass]="'dark-blue'" [imgSize]="'md'"></app-custom-chip>
                    <span>{{item.name}}</span>
                </ng-template>
            </p-selectButton>
        </div>
        <div class="field pb-3">
            <div class="flex ">
                <label class="flex label-color font-medium m-2  ml-0">{{ "Key.QuestionText" | translate }}</label>
                <div class="error-message flex">
                    <p class="require-tag" *ngIf="questionInput.touched && questionInput.errors?.required">*</p>
                </div>
            </div>
            <!-- <span class="p-float-label">
                        <textarea class="w-full" pInputTextarea [(ngModel)]="questionText"></textarea>
                    </span> -->
            <p-editor (onTextChange)="questionCount($event)" [formats]="formats" class="w-full questionEditor"
                [(ngModel)]="questionText" #questionInput="ngModel" required>

                <ng-template pTemplate="header">
                    <span class="ql-formats">
                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                        <span class="ql-formats">
                            <button value="ordered" aria-label="Ordered List" type="button" class="ql-list"></button>
                            <button value="bullet" aria-label="Unordered List" type="button" class="ql-list"></button>
                        </span>
                    </span>
                </ng-template>
            </p-editor>
            <div class="flex justify-content-end pr-1 mt-1">
                <p class="text-red-400 ml-2 mb-0" *ngIf="questionInputLength > 750">{{"Key.MaxCharLimitWarning" |
                    translate}}!</p>
                <p class="text-red-400 ml-2 mb-0" *ngIf="questionInput.touched && questionInputLength < 5">
                    {{"Key.MinCharLimitWarningFive" | translate }}!</p>
                <p class="text-gray-400 ml-2 mb-0"><span [ngClass]="{
                                'text-red-400': questionInputLength > 750 && questionInputLength >= 5,
                                'text-blue-400': !(questionInputLength >= 750 && questionInput.touched && questionInputLength >= 5)
                                }">
                        {{ questionInputLength }}/750</span>
                </p>
            </div>
        </div>
        <div class="mb-4 flex flex-wrap">
            <div class="col-6 pl-0 pr-2">
                <label class="flex label-color font-medium m-2  ml-0">{{ "Key.QuestionConcept" | translate }}</label>
                <p-dropdown
                    *ngIf="questionCollectionCategory == EQuestionCollectionCategoryEnum.CompetenceTest || questionCollectionCategory == EQuestionCollectionCategoryEnum.MTCompetenceTest"
                    [options]="questionConceptList" [(ngModel)]="selectedConcept" [filter]="true" filterBy="name"
                    optionLabel="name" [showClear]="true"
                    placeholder="{{'Key.SelectQuestionConcept' | translate}}"></p-dropdown>
                <input *ngIf="questionCollectionCategory == EQuestionCollectionCategoryEnum.TechnicalTest" type="text"
                    placeholder="{{'Key.QuestionConcept' | translate}}" [(ngModel)]="newConceptName" pInputText>
            </div>
            <div class="col-6 pr-0 pl-2">
                <label class="flex label-color font-medium m-2  ml-0">{{ "Key.QuestionLanguage" | translate }}</label>
                <p-dropdown [options]="questionLanguageList" (onChange)="onQuestionLanguageChange()"
                    [(ngModel)]="selectedLanguageQuestion" [filter]="false" optionLabel="name" optionValue="id"
                    [showClear]="false" placeholder="{{'Key.SelectQuestionLanguage' | translate}}"></p-dropdown>
            </div>
            <div *ngIf="stepDurationType === stepDurationTypeEnum.QuestionBased || questionListMode === QuestionListRootEnum.MyLibrary"
                class="col-6 pl-0 pr-2">
                <label class="flex label-color font-medium m-2  ml-0">{{ "Key.QuestionTime" | translate }}</label>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-stopwatch text-blue-400"></i>
                    <input type="number" min="1" placeholder="{{'Key.WriteQuestionTime' | translate}}"
                        [(ngModel)]="questionDuration" pInputText (input)="validateWholeNumber($event)">
                </span>
            </div>
            <div [ngClass]="stepDurationType === stepDurationTypeEnum.QuestionBased || questionListMode === QuestionListRootEnum.MyLibrary ? 'pr-0 pl-2' : 'pr-2 pl-0' "
                class="col-6">
                <label class="flex label-color font-medium m-2  ml-0">{{ "Key.QuestionWeight" | translate }}</label>
                <span class="p-input-icon-left w-full">
                    <i class="ndigital-question-weight-icon ndigital-size-xs"></i>
                    <input type="number" min="1" max="5" pattern="^[1-5]*$"
                        placeholder="{{'Key.WriteQuestionWeight' | translate}}" [(ngModel)]="questionWeight" pInputText
                        (input)="validateWeight(questionWeight)">
                </span>
            </div>

        </div>
        <div *ngIf="selectedAnswerType === MultipleChoiceAnswerType">
            <div class="field col pl-0">
                <label class="p-float-label flex label-color">{{ "Key.AddMedia" | translate }}</label>

                <div class="flex flex-wrap radio-gap pt-3">
                    <div *ngFor="let category of questionContentTypes" class="field-checkbox">
                        <p-radioButton [inputId]="category.id" [value]="category.id"
                            [(ngModel)]="selectedQuestionContentType"
                            (onClick)="changeClickableData($event)"></p-radioButton>
                        <label [for]="category.id" class="ml-2">{{ category.name }}</label>
                    </div>
                </div>
            </div>

            <!-- Mevcut medya içeriği varsa göster -->
            <div *ngIf="isEditMode && uploadedPictureUrl && !isMediaChanged && selectedQuestionContentType === questionToEdit?.questionContent?.contentURLType"
                class="mt-3 mb-3 upload-border">
                <!-- Resim içeriği -->
                <div *ngIf="selectedQuestionContentType === ImageQuestionContentType"
                    class="flex justify-content-between align-items-center">
                    <div class="flex-1">
                        <img class="image-style-small" [src]="uploadedPictureUrl" alt="question-image"
                            (error)="handleImageError($event)">
                    </div>
                    <div class="flex-none ml-3">
                        <p-button icon="pi pi-pencil" styleClass="p-button-text p-button-sm" (click)="changeMedia()"
                            [label]="'Key.ChangeMedia' | translate"></p-button>
                    </div>
                </div>

                <!-- Video içeriği -->
                <div *ngIf="selectedQuestionContentType === VideoQuestionContentType"
                    class="flex justify-content-between align-items-center">
                    <div class="flex-1">
                        <video class="video-style-small" controls (error)="handleVideoError($event)">
                            <source [src]="uploadedPictureUrl" type="video/webm">
                        </video>
                    </div>
                    <div class="flex-none ml-3">
                        <p-button icon="pi pi-pencil" styleClass="p-button-text p-button-sm" (click)="changeMedia()"
                            [label]="'Key.ChangeMedia' | translate"></p-button>
                    </div>
                </div>

                <!-- Ses içeriği -->
                <div *ngIf="selectedQuestionContentType === AudioQuestionContentType"
                    class="flex justify-content-between align-items-center">
                    <div class="flex-1">
                        <audio class="audio-style-small" controls (error)="handleAudioError($event)">
                            <source [src]="uploadedPictureUrl" type="audio/mpeg">
                        </audio>
                    </div>
                    <div class="flex-none ml-3">
                        <p-button icon="pi pi-pencil" styleClass="p-button-text p-button-sm" (click)="changeMedia()"
                            [label]="'Key.ChangeMedia' | translate"></p-button>
                    </div>
                </div>
            </div>

            <!-- Yeni yüklenen medya içeriği varsa göster -->
            <div *ngIf="uploadedPictureUrl && (isMediaChanged || selectedQuestionContentType !== questionToEdit?.questionContent?.contentURLType)"
                class="mt-3 mb-3 upload-border">
                <!-- Resim içeriği -->
                <div *ngIf="selectedQuestionContentType === ImageQuestionContentType"
                    class="flex justify-content-between align-items-center">
                    <div class="flex-1">
                        <img class="image-style-small" [src]="uploadedPictureUrl" alt="question-image"
                            (error)="handleImageError($event)">
                    </div>
                    <div class="flex-none ml-3">
                        <p-button icon="pi pi-pencil" styleClass="p-button-text p-button-sm" (click)="changeMedia()"
                            [label]="'Key.ChangeMedia' | translate"></p-button>
                    </div>
                </div>

                <!-- Video içeriği -->
                <div *ngIf="selectedQuestionContentType === VideoQuestionContentType"
                    class="flex justify-content-between align-items-center">
                    <div class="flex-1">
                        <video class="video-style-small" controls (error)="handleVideoError($event)">
                            <source [src]="uploadedPictureUrl" type="video/webm">
                        </video>
                    </div>
                    <div class="flex-none ml-3">
                        <p-button icon="pi pi-pencil" styleClass="p-button-text p-button-sm" (click)="changeMedia()"
                            [label]="'Key.ChangeMedia' | translate"></p-button>
                    </div>
                </div>

                <!-- Ses içeriği -->
                <div *ngIf="selectedQuestionContentType === AudioQuestionContentType"
                    class="flex justify-content-between align-items-center">
                    <div class="flex-1">
                        <audio class="audio-style-small" controls (error)="handleAudioError($event)">
                            <source [src]="uploadedPictureUrl" type="audio/mpeg">
                        </audio>
                    </div>
                    <div class="flex-none ml-3">
                        <p-button icon="pi pi-pencil" styleClass="p-button-text p-button-sm" (click)="changeMedia()"
                            [label]="'Key.ChangeMedia' | translate"></p-button>
                    </div>
                </div>
            </div>

            <!-- Medya yükleme bileşenleri - Sadece bir tane göster -->
            <ng-container *ngIf="!uploadedPictureUrl || isMediaChanged">
                <!-- Hiçbir medya tipi seçilmediğinde veya varsayılan durumda -->
                <app-picture-uploader-simple
                    *ngIf="selectedQuestionContentType === 0 || selectedQuestionContentType === null"
                    [isUnsigned]="false" (closeDialog)="closeDialog()" [disabled]="true"
                    (uploadedPictureUrl)="getUploadedUrl($event)"></app-picture-uploader-simple>

                <!-- Resim yükleme bileşeni -->
                <app-picture-uploader-simple *ngIf="selectedQuestionContentType === ImageQuestionContentType"
                    [isUnsigned]="false" (closeDialog)="closeDialog()"
                    (uploadedPictureUrl)="getUploadedUrl($event)"></app-picture-uploader-simple>

                <!-- Ses yükleme bileşeni -->
                <app-audio-uploader-simple *ngIf="selectedQuestionContentType === AudioQuestionContentType"
                    (closeDialog)="closeDialog()" (uploadAudioUrl)="getUploadedUrl($event)"></app-audio-uploader-simple>

                <!-- Video yükleme bileşeni -->
                <app-video-uploader-simple *ngIf="selectedQuestionContentType === VideoQuestionContentType"
                    (closeDialog)="closeDialog()" (uploadVidoeUrl)="getUploadedUrl($event)"></app-video-uploader-simple>
            </ng-container>
        </div>
        <p *ngIf="selectedAnswerType ?? false" class="create-question mt-5">{{"Key.Answer" | translate}}</p>

        <div *ngIf="selectedAnswerType===OpenEndedAnswerType || selectedAnswerType===VideoAnswerType || selectedAnswerType===MultipleChoiceAnswerType"
            class="field pb-3 mt-5">
            <div class="flex ">
                <label class="flex label-color font-medium m-2 ml-0">{{ "Key.Keywords" | translate }}</label>
                <div class="error-message flex">
                    <p class="require-tag"
                        *ngIf="keywordsInput.touched && keywords?.length < 3 && selectedAnswerType!==MultipleChoiceAnswerType && (questionCollectionCategory === EQuestionCollectionCategoryEnum.CompetenceTest || questionCollectionCategory === EQuestionCollectionCategoryEnum.MTCompetenceTest)">
                        *</p>
                </div>
            </div>
            <span class="p-float-label">
                <p-chips [(ngModel)]="keywords" #keywordsInput="ngModel" separator=","
                    placeholder="{{'Key.HintKeyword' | translate}}" [max]="5" placeholder="Maximum 5 items"
                    required></p-chips>
            </span>
            <div class="mt-4 flex justify-content-start flex-wrap">
                <label
                    *ngIf="questionCollectionCategory === EQuestionCollectionCategoryEnum.CompetenceTest || questionCollectionCategory === EQuestionCollectionCategoryEnum.MTCompetenceTest"
                    [ngClass]="!isKeywordError ? 'message-color' : 'message-color-red' "
                    class="flex font-medium pb-2 ml-2">{{ "Key.EnterKeyword" | translate }}</label>
            </div>
        </div>
        <div *ngIf="selectedAnswerType===MultipleChoiceAnswerType" class="pb-3 mt-4">
            <label class="flex label-color font-medium pb-2">{{ "Key.AnswerOptions" | translate }}</label>
            <p-orderList [value]="choiceContentArray" [dragdrop]="true" class="w-full">
                <ng-template let-choiceContent let-index="rowIndex" pTemplate="item">
                    <div class="flex w-full flex-wrap align-items-stretch">
                        <div class="w-full bordered-left flex align-items-center justify-content-center lg:w-auto">
                            <p-radioButton name="correctAnswerGroup" [value]="true" [ngModel]="choiceContent.isCorrect"
                                (onClick)="onCorrectAnswerChange(choiceContent)"></p-radioButton>
                        </div>
                        <div class="flex-1 bordered-middle flex align-items-center">
                            <p class="mb-2 text-font"> {{ choiceContent.choiceText }}</p>
                        </div>
                        <div class="flex flex-row justify-content-center align-items-center bordered-right">
                            <p-button icon="pi pi-trash"
                                styleClass="buttonFont p-button-rounded p-button-danger p-button-text"
                                (click)="onDelete(choiceContent.choiceText)"></p-button>
                            <span class="pi pi-align-justify cursor-move"></span>
                        </div>
                    </div>
                </ng-template>
            </p-orderList>
            <div class="mt-4">
                <div *ngIf="!isMaxChoiceCountReached()" class="flex choice-input flex-wrap align-items-stretch">
                    <div class="w-full bordered-left flex align-items-center justify-content-center lg:w-auto">
                        <p-radioButton name="newAnswerRadio" [value]="true" [(ngModel)]="choiceContent.isCorrect"
                            [disabled]="false"></p-radioButton>
                    </div>
                    <div class="flex-1 bordered-middle-new flex align-items-center p-0">
                        <input type="text" class="answer-input-class w-full"
                            placeholder="{{'Key.ChoiceText' | translate}}" [(ngModel)]="choiceContent.choiceText"
                            pInputText>
                    </div>
                    <button pButton icon="pi pi-plus" class="p-button-outlined h-full ml-2"
                        (click)="onAddChoiceText()"></button>
                </div>
            </div>
            <div #bottomDiv class="mt-4 pb-8 flex justify-content-start flex-wrap align-items-center">
                <div *ngIf="choiceContentArray.length > 0" class="ml-3 flex align-items-center">
                    <span *ngIf="checkCorrectAnswer" class="flex align-items-center text-green-500 font-medium">
                        <i class="pi pi-check mr-2"></i>
                        {{ 'Key.CorrectAnswerSelected' | translate }}
                    </span>
                    <span *ngIf="!checkCorrectAnswer" class="flex align-items-center text-yellow-500 font-medium">
                        <i class="pi pi-exclamation-triangle mr-2"></i>
                        {{ 'Key.SelectCorrectChoice' | translate }}
                    </span>
                </div>
                <div class="ml-auto flex align-items-center">
                    <span class="text-500">{{ choiceContentArray.length }}/5</span>
                </div>
            </div>
        </div>
    </div>
    <p-toast position="bottom-center"></p-toast>