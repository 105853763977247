import { Injectable } from '@angular/core';
import { Configuration } from '../shared/common/interfaces';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    private configuration: Configuration;

    public loadConfigurations(http: HttpClient): Promise<void> {

         // Use environment to get correct config file
         const configUrl = environment.configUrl;

        return lastValueFrom(http.get(configUrl)).then((data: Configuration) => {
            this.configuration = data;
        });
    }

    get config(): Configuration {
        return this.configuration;
    }
}
