<div>
    <app-cheating-analysis [item]="item"> </app-cheating-analysis>
</div>

<hr>

<div class="col-12 grid p-0 height-fit">
    <div class="xl:col-3 lg:col-4 md:col-6 pl-0 pt-1 flex align-items-center">
        <div class="flex">
            <div class="applicant-label pl-0">
                <h5 class="text-justify score-text ml-2 m-3">
                    {{item.averageVinterScore | number : '1.2-2'}}
                </h5>
            </div>
        </div>
        <div class="vertical-line-score ml-0"></div>
        <div class="col-4 flex">
            <div class="grid">
                <div class="applicant-label ml-1 flex flex-nowrap">
                    <h5 class="text-justify contact-typo mr-2">
                        {{'Key.VinterScore' | translate}}
                    </h5>
                    <span [pTooltip]="getWeightAvarageScoreTooltip()" tooltipPosition="bottom" tooltipStyleClass="tooltip-score" [escape]="false">
                        <img alt="" [src]="questionWeightToolTipIconPath" width="15" >
                    </span>
                </div>

                <div class="col-12 pb-0 pt-3">
                    <div class="progress w-12rem">
                        <div class="progress-bar" [style.width]="item?.averageVinterScore + '%'"
                            [style.backgroundColor]="getProgressBarColor(item?.averageVinterScore)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="xl:col-3 lg:col-3 md:col-6  pt-1 flex align-items-center custom-score">
        <div class="flex">
            <div class="applicant-label">
                <h5 class="text-justify score-text ml-2 m-3">
                    {{item.averageRating | number : '1.2-2'}}
                </h5>
            </div>
        </div>
        <div class="vertical-line-score ml-0"></div>
        <div class="col-3 flex">
            <div class="grid">
                <div class="applicant-label ml-1 flex flex-nowrap">
                    <h5 class="text-justify contact-typo mr-2">
                        {{'Key.Score' | translate}}
                    </h5>
                    <span [pTooltip]="getWeightAvarageScoreTooltip()" tooltipPosition="bottom" tooltipStyleClass="tooltip-score" [escape]="false">
                        <img alt="" [src]="questionWeightToolTipIconPath" width="15" >
                    </span>
                </div>

                <div class="col-12 pb-0 pt-2">
                    <app-vinter-rating class="cursor" [starNumber]="5" [disabled]="true" size="md" [value]="item.averageRating"></app-vinter-rating>
                </div>
            </div>
        </div>
    </div>
    <div class="xl:col-6 lg:col-5 md:col-12 pt-1 pr-0 mr-0 flex custom-checkbox align-items-center mb-5">
        <div class="custom-checkbox-pr flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedOpenEnded" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerOpenEndedCount"
                            [svgUrl]="'assets/vinter/icons/icon-park_text-style-open-ended.svg'" [alt]="'OpenEnded'"
                            [bgColorClass]="'light-blue'" [imgSize]="'16px'"></app-custom-chip>
            </span>

        </div>
        <div class="custom-checkbox-pr flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedMultipleChoice" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount"
                            [svgUrl]="'assets/vinter/icons/icon-park-outline_choice.svg'" [alt]="'MultipleChoice'"
                            [bgColorClass]="'purple'" [imgSize]="'16px'"></app-custom-chip>
            </span>
        </div>
        <div class="custom-checkbox-pr flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedVideo" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerVideoCount"
                            [svgUrl]="'assets/vinter/icons/icon-park_text-tabler_video.svg'" [alt]="'VideoQuestion'"
                            [bgColorClass]="'dark-blue'" [imgSize]="'16px'"></app-custom-chip>
            </span>
        </div>
        <div *ngIf="item.positionStep.questionCollection.expectedAnswerCodingCount > 0" class="flex">
            <div class="mr-2 custom-check-margin">
                <p-checkbox [(ngModel)]="checkedCodingQuestion" [binary]="true" (onChange)="filterList()" inputId="binary"></p-checkbox>
            </div>
            <span class="custom-chip-margin">
                <app-custom-chip [label]="item.positionStep.questionCollection.expectedAnswerCodingCount"
                            [svgUrl]="'assets/vinter/icons/pozisyon-detay-tabler-coding-question.svg'" [alt]="'CodingQuestion'"
                            [bgColorClass]="'blue'" [imgSize]="'16px'"></app-custom-chip>
            </span>
        </div>
    </div>
</div>

<div class="mt-0 pt-0">
    <div class="grid flex flex-column ">
        <ng-container *ngIf="item.positionStep.questionCollection.questionCollectionQuestionRelations.length > 0">
            <div *ngFor="let question of filteredList; let i = index"
                class="question-container col-12 flex pb-0 align-items-center justify-content-center"
                [ngClass]="{ 'active': selectedId == question.question.id }">
                <div class="grid flex col pb-0 pr-5 pl-5 question-text-size">
                    <div class="question-number question-number-color col-1 mr-0 pr-0" style="width: 30px;">
                        {{ question.order }}
                    </div>

                    <div class="col-1 pt-1 order-icon-width h-5rem block">
                        <div class="grid">
                            <div class="col-12 p-0">
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.Video">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/videoQuestion.svg" />
                                </div>
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.OpenEnded">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/openEndedQuestion.svg" />

                                </div>
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.MultipleChoice">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/Quiz.svg" />
                                </div>
                                <div *ngIf="question.question.expectedAnswerType === QuestionExpectedAnswerType.Coding">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/codingQuestionIcon.svg" />
                                </div>

                            </div>
                            <div class="col-12 p-0 flex justif-content-center">
                                <div *ngIf="question.isFaceDetected || question.isMultipleFaceDetected || question.question.isImproprietySpeech || checkIfQuestionLogExists(question.question.id)">
                                    <app-question-behaiviour-info [cheatingQuestionLog]="cheatingQuestionLog" [question]="question"></app-question-behaiviour-info>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="question-container-text pr-5 pl-5 col pb-0 h-3rem pt-2" (click)="getAnswerByQuestionId(i)">
                        <div class="flex flex-nowrap">
                            <span
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="font-medium text-sm mr-2 lang-text">
                                {{ question.question?.langShort}}
                            </span>
                            <div
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight ml-2 mr-2 pb-3"></div>
                            <span class="concept-text">
                                {{ question.question.questionConcept?.name}}
                            </span>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight ml-2 mr-2 pb-3"></div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="flex flex-nowrap pb-3 custom-margin-top">
                                <img alt="" [src]="questionWeightIconPath">
                                <span class="text-blue-800 font-medium text-sm ml-2">{{question.weight}}</span>
                            </div>
                        </div>
                        <div class="question-content truncate-text ml-0" [innerHTML]="question.question.questionContent.questionText">
                        </div>
                    </div>

                </div>

                <div class="col-4 flex p-0 justify-content-end align-items-center" style="width: 36%;">

                    <div *ngIf="question.question.expectedAnswerType !== 1 && question.question.gptEvalStatus !== 1 && question.question.gptEvalStatus !== 3 && question.question.isAnswered"
                        class="col-4 flex question-container-rating-list mr-0">
                        <div *ngIf="question?.question.ratingType !== RatingType.VinterTrust">
                            <img src='{{vinterLogo}}' alt="Vinter logo" class="img-list" (click)="getAnswerForRatingByQuestionId(question.question)"
                            pTooltip='{{"Key.TrustVinter" | translate}}'
                            tooltipPosition="top">
                            <h5 class="h5-font-list mt-1 trust-vinter-text-list ml-3 cursor-pointer"
                                (click)="getAnswerForRatingByQuestionId(question.question)">{{'Key.TrustVinter' | translate}}</h5>
                        </div>
                        <div *ngIf="question?.question.ratingType === RatingType.VinterTrust">
                            <i class="pi pi-check img-list text-2xl ml-6" style="color: #2962FF" pTooltip='{{"Key.VinterTrusted" | translate}}'
                            tooltipPosition="top"></i>
                            <h5 class="h5-font-list mt-1 trusted-vinter-text-list ml-1">{{'Key.VinterTrusted' | translate}}</h5>
                        </div>
                    </div>
                    <div *ngIf="question.question.expectedAnswerType !== QuestionExpectedAnswerType.MultipleChoice
                        && question.question.gptEvalStatus !== GPTEvalStatus.Waiting
                        && question.question.gptEvalStatus !== GPTEvalStatus.Fail
                        && question.question.isAnswered" class="vertical-line-rating-list"></div>
                    <div *ngIf="question.question.expectedAnswerType !== QuestionExpectedAnswerType.MultipleChoice
                        && question.question.gptEvalStatus !== GPTEvalStatus.Waiting
                        && question.question.gptEvalStatus !== GPTEvalStatus.Fail
                        && question.question.isAnswered" class="col-3 question-container-rating-list mr-0">
                            <app-vinter-rating
                                tooltipPosition="top"
                                pTooltip='{{"Key.HRPoint" | translate}}'
                                (click)="showPopup($event, question?.question)"
                                [starNumber]="1"
                                [disabled]="true" size="sm" [value]="question?.question.ratingScore === 0 ? 0 : 1"></app-vinter-rating>

                                <h5 *ngIf="question?.question.ratingScore === 0" class="h5-font-list mt-2 trust-vinter-text-list flex rate-text-list justify-content-center"
                                style="color: #363D47;">
                                {{'Key.Rate' | translate}}
                                </h5>
                                <h5 *ngIf="question?.question.ratingScore !== 0" class="h5-font-list mt-2 trust-vinter-text-list rate-text-list flex justify-content-center" style="color: #363D47;">{{question?.question.ratingScore}}
                                </h5>
                    </div>
                    <div *ngIf="question.question.expectedAnswerType !== QuestionExpectedAnswerType.MultipleChoice && question.question.gptEvalStatus !== 1" class="vertical-line-rating-list"></div>
                    <div class="col-4 question-container-rating-list pt-3 pb-0 mr-0">
                        <div class="progress-list">
                            <div class="progress-bar-list" [style.width]="question?.question.gptScore + '%'"
                                [style.backgroundColor]="getProgressBarColor(question?.question.gptScore)"></div>
                        </div>
                        <h5 class="h5-font flex justify-content-center mt-2 vinter-score-list trust-vinter-text-list" style="color: #363D47;">
                            {{question.question.gptScore.toFixed(2)}}</h5>
                    </div>
                    <div class="col-1 flex mb-2">

                        <div *ngIf="question.question.isAnswered">
                            <p-radioButton class="answered" [disabled]="true" [value]="question.question.isAnswered"
                                [(ngModel)]="question.question.isAnswered"></p-radioButton>

                        </div>
                        <div *ngIf="!question.question.isAnswered">
                            <p-radioButton class="not-answered" [disabled]="true" [value]="question.question.isAnswered"
                                [(ngModel)]="question.question.isAnswered"></p-radioButton>

                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <p-sidebar [(visible)]="sidebarVisible" position="right" [showCloseIcon]="false" [blockScroll]="true">
        <div class="col-12 flex pl-0 pb-0">
            <div class="col-6 flex pl-0 pt-1">
                <p-button class="back-button" icon="pi pi-arrow-left" (onClick)="getAnswerByQuestionId(questionOrder - 2)"></p-button>
                <p-button class="next-button" icon="pi pi-arrow-right" (onClick)="getAnswerByQuestionId(questionOrder)"></p-button>
            </div>
            <div  class="col-6 flex justify-content-end pt-1">
                <div *ngIf="answerType ===QuestionExpectedAnswerType.MultipleChoice " class="question-container-rating pt-4">
                    <div *ngIf="!isQuestionAnswered">
                        <div class="progress margin-top-04">
                            <div class="progress-bar" [style.width]="0 + '%'"
                                [style.backgroundColor]="getProgressBarColor(0)"></div>
                        </div>
                        <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                            {{0}}</h5>
                    </div>
                    <div *ngIf="isQuestionAnswered">
                        <div *ngIf="choiceAnswers.isCorrect === true">
                            <div class="progress margin-top-04">
                                <div class="progress-bar" [style.width]="100 + '%'"
                                    [style.backgroundColor]="getProgressBarColor(100)"></div>
                            </div>
                            <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                                {{100}}</h5>
                        </div>
                        <div *ngIf="choiceAnswers.isCorrect === false">
                            <div class="progress margin-top-04">
                                <div class="progress-bar" [style.width]="0 + '%'"
                                    [style.backgroundColor]="getProgressBarColor(0)"></div>
                            </div>
                            <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                                {{0}}</h5>
                        </div>
                    </div>
                </div>

                <div *ngIf="answerType ===QuestionExpectedAnswerType.OpenEnded
                        || answerType ===QuestionExpectedAnswerType.Coding
                        || answerType ===QuestionExpectedAnswerType.Video"
                        class="flex">
                    <div *ngIf="gptEvalStatus !== gptEvalStatusEnum.Waiting && gptEvalStatus !== GPTEvalStatus.Fail && isQuestionAnswered" class="col-4 flex question-container-rating" style="min-width: 11rem;">

                        <div *ngIf="questionRatingType !== RatingType.VinterTrust" class="flex w-full flex-wrap justify-content-center ">
                            <div class="w-full flex justify-content-center">
                                <img src='{{vinterLogo}}' alt="Vinter logo" class="w-2">
                            </div>
                            <h5 class="h5-font mt-1 trust-vinter-text w-full cursor-pointer text-center"
                                (click)="trustVinter(answerContent.id)">{{'Key.TrustVinter' | translate}}</h5>
                        </div>
                        <div *ngIf="questionRatingType === RatingType.VinterTrust">
                            <i class="pi pi-check text-xl ml-6" style="color: #2962FF"></i>
                            <h5 class="h5-font mt-1 trust-vinter-text ml-1">{{'Key.VinterTrusted' | translate}}</h5>
                        </div>
                    </div>
                    <div *ngIf="gptEvalStatus !== gptEvalStatusEnum.Waiting && gptEvalStatus !== GPTEvalStatus.Fail  && isQuestionAnswered" class="vertical-line-rating"></div>
                    <div *ngIf="gptEvalStatus !== gptEvalStatusEnum.Waiting && gptEvalStatus !== GPTEvalStatus.Fail  && isQuestionAnswered" class="col-3 flex question-container-rating">
                        <div *ngIf="selectedStar === 0">
                            <app-vinter-rating
                                tooltipPosition="top"
                                pTooltip='{{"Key.HRPoint" | translate}}'
                                (click)="showPopup($event,selectedQuestion)"
                                [starNumber]="1"
                                [disabled]="true" size="sm" [value]="1"></app-vinter-rating>
                            <h5 class="h5-font mt-2 trust-vinter-text" style="color: #363D47;text-align: right;">{{'Key.Rate' | translate}}</h5>
                        </div>
                        <div *ngIf="selectedStar !== 0" class="ml-4">
                            <app-vinter-rating
                                tooltipPosition="top"
                                pTooltip='{{"Key.HRPoint" | translate}}'
                                (click)="showPopup($event,selectedQuestion)"
                                [starNumber]="1"
                                [disabled]="true" size="sm" [value]="0"></app-vinter-rating>
                            <h5 class="h5-font mt-2 ml-1 trust-vinter-text" style="color: #363D47;">{{selectedStar}}
                                </h5>
                        </div>
                    </div>
                    <div *ngIf="gptEvalStatus !== gptEvalStatusEnum.Waiting && gptEvalStatus !== GPTEvalStatus.Fail  && isQuestionAnswered" class="vertical-line-rating"></div>
                    <div *ngIf="gptEvalStatus !== gptEvalStatusEnum.Waiting && gptEvalStatus !== GPTEvalStatus.Fail  && isQuestionAnswered" class="col-4 question-container-rating">
                        <div class="progress margin-top-04">
                            <div class="progress-bar" [style.width]="gptEvalScore + '%'"
                                [style.backgroundColor]="getProgressBarColor(gptEvalScore)"></div>
                        </div>
                        <h5 class="h5-font flex justify-content-center trust-vinter-text" style="color: #363D47; margin-top: 0.75rem">
                            {{gptEvalScore.toFixed(2)}}</h5>
                    </div>
                </div>
            </div>
        </div>



        <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.MultipleChoice">
            <div class="col-12 flex pt-0 pb-0 pl-0">
                <div class="col-6 flex pt-0 pb-0 pl-0">
                    <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg" />
                    </div>
                    <div class="col-7 pt-0 pl-0">
                        <h5 style="margin-top: 0.6rem">
                            {{"Key.QuestionOrder" | translate: { value1: questionOrder } }}
                        </h5>

                    </div>
                </div>
            </div>
            <div [ngSwitch]="questionContent?.contentURLType">

                <div *ngSwitchCase="!QuestionContentType.Image || !QuestionContentType.Audio || !QuestionContentType.Video">
                    <div class="question-container-sideBar-text flex">
                        <div class="flex flex-nowrap">
                            <span
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="font-medium text-sm mr-2 mt-2 lang-text">
                                {{ selectedQuestion?.langShort}}
                            </span>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div class="concept-text mt-2">
                                {{ questionConcept}}
                            </div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="flex flex-nowrap mt-2">
                                <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                                <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                            </div>
                        </div>
                        <div class="question-text-label"  [innerHTML]="questionText">
                            <!-- {{questionText}} -->
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Image">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text flex">
                        <div class="col-6 p-0">
                            <p-image src={{questionContent.contentURL}} [imageStyle]="{'width': '100%'}"
                                [preview]="true"></p-image>
                        </div>
                        <div class="col-6">
                            <div class="question-text-label pl-3 p-0" [innerHTML]="questionText"></div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Audio">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text flex">
                        <div class="col-6 p-0">
                            <audio src={{questionContent.contentURL}} style="width: 100%;" controls
                                controlslist="nodownload"></audio>
                        </div>
                        <div class="col-6 question-text-label">
                            <div class="question-text-label pl-3 p-0" [innerHTML]="questionText"></div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Video">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text flex">
                        <div class="col-6 p-0">
                            <video src={{questionContent.contentURL}} style="border-radius:1rem;width: 100%;" controls
                                controlslist="nodownload" disablepictureinpicture></video>
                        </div>
                        <div class="col-6 question-text-label">
                            <div class="question-text-label pl-3 p-0" [innerHTML]="questionText"></div>
                        </div>
                    </div>
                </div>

                <div *ngIf="questionKeywords.length > 0" class="question-container-sideBar-text">
                    <div class="flex mb-3">
                        <div class="keyword-text">
                            {{'Key.Keywords' | translate}}
                        </div>
                        <div>
                            <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                        </div>
                    </div>
                    <div>
                        <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                            <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                        </div>
                    </div>
                </div>

                <div class="answer-text">
                    <div class="flex">
                        <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                        <h5 class="text-justify answer-title text-lg ml-2 m-3 h5-font">
                            {{"Key.Answer" | translate}}
                        </h5>
                    </div>
                    <div class="answer-container">
                        <div class="flex">
                            <div class="flex flex-column gap-2">
                                <div *ngFor="let choice of choiceContents; let i = index"
                                    class="radio-button-card field-checkbox "
                                    [ngClass]="{'correct-answer': isCorrectAnswer(choice.id), 'wrong-answer': isWrongAnswer(choice.id), 'correct-choice': choice.isCorrect&&selectedRadioValue!==null, 'empty-correct-choice': choice.isCorrect&&selectedRadioValue===null,'background-white': choice.id !== choiceContentId, 'background-blue': choice.id === choiceContentId} ">
                                    <div class="flex">
                                        <p-radioButton [disabled]="true" [value]="choice"
                                            [(ngModel)]="selectedAnswerChoice"></p-radioButton>
                                        <label [for]="choice" class="ml-2" [innerHTML]="choice.choiceText"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ml-5">
                        <div class="flex" *ngIf="isQuestionAnswered && checkAnswer(selectedAnswer) === true">
                            <img src="assets/vinter/icons/correct-answer.svg">
                            <p class="ml-2" style="color: #3A9B18;">{{"Key.CorrectAnswer" | translate}}</p>
                        </div>
                        <div class="flex" *ngIf="isQuestionAnswered && (selectedAnswer ?? false) && checkAnswer(selectedAnswer) === false">
                            <img src="assets/vinter/icons/wrong-answer.svg">
                            <p class="ml-2" style="color: #F20505;">{{"Key.WrongAnswer" | translate}}</p>
                        </div>
                        <div class="flex" *ngIf="!isQuestionAnswered">
                            <img src="assets/vinter/icons/not-answered.svg">
                            <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
                        </div>
                    </div>

                    <div class="ml-5 flex" *ngIf="questionPageHiddenLog > 0">
                        <img src="assets/vinter/icons/cheating-icon.svg">
                        <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog} }}</p>
                    </div>

                </div>


            </div>
        </ng-container>

        <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.OpenEnded">
            <div class="col-12 flex pt-0 pb-0 pl-0">
                <div class="col-6 pt-0 pl-0 pb-0 flex">
                    <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/openEndedQuestion.svg" />
                    </div>
                    <div class="col-7 pt-0 pl-0">
                        <h5 style="margin-top: 0.6rem">
                            {{"Key.QuestionOrder" | translate: { value1: questionOrder } }}
                        </h5>

                    </div>
                </div>
            </div>

            <div *ngIf="choiceAnswers !== null" [ngSwitch]="questionContent?.contentURLType">
                <div *ngSwitchCase="!QuestionContentType.Image || !QuestionContentType.Audio || !QuestionContentType.Video">
                    <div class="question-container-sideBar-text">
                        <div class="flex flex-nowrap">
                            <span
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="font-medium text-sm mr-2 mt-2 lang-text">
                                {{ selectedQuestion?.langShort}}
                            </span>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div class="concept-text mt-2">
                                {{ questionConcept}}
                            </div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="flex flex-nowrap mt-2">
                                <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                                <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                            </div>
                        </div>
                        <div class="flex question-text-label mb-0" [innerHTML]="questionText" style="display:block !important">
                            <!-- {{questionText}} -->
                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex mt-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>
                        </div>
                        <div *ngIf="questionKeywords.length > 0">
                            <div class="inline-flex mt-4 -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="QuestionContentType.Image">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0 pr-3">
                                <p-image src={{questionContent.contentURL}} [imageStyle]="{'width': '100%'}"
                                    [preview]="true"></p-image>
                            </div>
                            <div class="col-6 p-0 pl-1">
                                <div class="mb-0 question-text-label" [innerHTML]="questionText"></div>
                            </div>
                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex mb-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>

                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex">
                            <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>


                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Audio">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0 pr-3">
                                <audio src={{questionContent.contentURL}} style="width: 100%;" controls
                                    controlslist="nodownload"></audio>
                            </div>
                            <div class="col-6 p-0 pl-1">
                                <div class="mb-0 question-text-label" [innerHTML]="questionText"></div>
                            </div>
                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex mb-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>

                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex">
                            <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>


                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Video">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0 pr-3">
                                <video src={{questionContent.contentURL}} style="border-radius:1rem;width: 100%;"
                                    controls controlslist="nodownload" disablepictureinpicture></video>
                            </div>
                            <div class="col-6 p-0 pl-1">
                                <div class="mb-0 question-text-label" [innerHTML]="questionText"></div>
                            </div>
                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex mb-3 mt-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>

                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex">
                            <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>


                    </div>
                </div>


            </div>

            <div class="answer-text">
                <ng-container>
                    <div class="flex">
                        <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                        <h5 class="text-justify answer-title text-lg ml-2 mb-2 m-3 h5-font">
                            {{'Key.Answer' | translate}}
                        </h5>
                    </div>

                    <div *ngIf="answerContent ?? false" class="answer-container">
                        <div class="answer-text-label">
                            {{ answerContent.answerText }}
                        </div>
                    </div>
                    <div class="ml-5 flex" *ngIf="answerContent === null">
                        <img src="assets/vinter/icons/not-answered.svg">
                        <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
                    </div>

                    <div class="ml-5 flex" *ngIf="questionPageHiddenLog>0">
                        <img src="assets/vinter/icons/cheating-icon.svg">
                        <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog} }}</p>
                    </div>




                </ng-container>
            </div>

            <div *ngIf="answerContent !== null" class="answer-text pt-0">
                <ng-container>
                    <div class="flex">
                        <h5 class="text-justify answer-title text-lg ml-2 mb-2 pl-4 m-3 h5-font">
                            {{'Key.VinterComment' | translate}}
                        </h5>
                    </div>

                    <div class="answer-container flex" [ngClass]="{'justify-content-center': gptEvalStatus === gptEvalStatusEnum.Retry}">
                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">
                            {{ answerContent.gptEvalText }}
                        </div>

                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.ClientFail">
                            {{ answerContent.aiFinalLog }}
                        </div>

                        <ng-container *ngIf="gptEvalStatus === gptEvalStatusEnum.Retry">
                            <div class="retry-container">
                                <div class="grid">
                                    <div class="col-12 flex justify-content-center">
                                        <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                    </div>
                                    <div class="col-12 flex justify-content-center text-gray-700 text-xs font-medium pt-0 text-center">
                                        {{"Key.GptRetryMessage" | translate}}
                                    </div>
                                    <div class="col-12 flex justify-content-center">
                                        <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                        <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                    </div>

                                </div>
                            </div>

                        </ng-container>

                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Fail">
                            {{"Key.GptFailStatusMessage" | translate}}
                        </div>

                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Waiting || gptEvalStatus === 99">
                            {{"Key.WaitForAIComment" | translate}}
                        </div>
                    </div>
                </ng-container>
            </div>


        </ng-container>

        <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.Video">
            <div class="col-12 flex pt-0 pb-0 pl-0">
                <div class="col-6 pt-0 pl-0 pb-0 flex">
                    <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/videoQuestion.svg" />
                    </div>
                    <div class="col-7 pt-0  pl-0">
                        <h5 style="margin-top: 0.6rem">
                            {{"Key.QuestionOrder" | translate : {value1: questionOrder} }}
                        </h5>

                    </div>
                </div>
            </div>
            <div [ngSwitch]="questionContent?.contentURLType">

                <div *ngSwitchCase="!QuestionContentType.Image || !QuestionContentType.Audio || !QuestionContentType.Video">
                    <div class="question-container-sideBar-text">
                        <div class="flex flex-nowrap">
                            <span
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="font-medium text-sm mr-2 mt-2 lang-text">
                                {{ selectedQuestion?.langShort}}
                            </span>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div class="concept-text mt-2">
                                {{ questionConcept}}
                            </div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="flex flex-nowrap mt-2">
                                <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                                <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                            </div>
                        </div>
                        <div class="question-text-label mb-0" [innerHTML]="questionText">
                            <!-- {{questionText}} -->
                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex mt-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>
                        </div>
                        <div *ngIf="questionKeywords.length > 0">
                            <div class="inline-flex mt-4 -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="QuestionContentType.Image">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0">
                                <p-image src={{questionContent.contentURL}} [imageStyle]="{'width': '100%'}"
                                    [preview]="true"></p-image>
                            </div>
                            <div class="col-6 pt-0 pr-0">
                                <div class="question-text-label mb-0"  [innerHTML]="questionText"></div>
                                <!-- {{questionText}} -->
                            </div>
                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex mb-3 mt-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>

                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex">
                            <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Audio">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0">
                                <audio src={{questionContent.contentURL}} style="width: 100%;" controls
                                    controlslist="nodownload"></audio>
                            </div>
                            <div class="col-6 pt-0 pr-0">
                                <div class="question-text-label mb-0"  [innerHTML]="questionText"></div>
                            </div>
                        </div>

                        <div *ngIf="questionKeywords.length > 0" class="flex mb-3 mt-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>

                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex">
                            <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Video">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium text-sm mr-2 mt-2 lang-text">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0">
                                <video src={{questionContent.contentURL}} style="border-radius:1rem;width: 100%;"
                                    controls controlslist="nodownload" disablepictureinpicture></video>
                            </div>
                            <div class="col-6 pt-0 pr-0">
                                <div class="question-text-label mb-0"  [innerHTML]="questionText"></div>
                            </div>
                        </div>

                        <div *ngIf="questionKeywords.length > 0" class="flex mb-3 mt-3">
                            <div class="keyword-text">
                                {{'Key.Keywords' | translate}}
                            </div>
                            <div>
                                <p-badge class="radius" value={{questionKeywords.length}}></p-badge>
                            </div>

                        </div>
                        <div *ngIf="questionKeywords.length > 0" class="flex">
                            <div class="inline -ml-2" *ngFor="let item of questionKeywords">
                                <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="answer-text">
                <div class="flex">
                    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                    <h5 class="text-justify answer-title text-lg ml-2 m-3 h5-font">
                        {{"Key.Answer" | translate}}
                    </h5>
                </div>
            </div>

            <ng-container *ngIf="answerContent !== null">
                <ng-container [ngSwitch]="questionCategory">

                    <div *ngSwitchCase="QuestionCategories.Pronunciation" class="answer-container">
                        <div class="grid flex flex-wrap p-4">
                            <div class="custom-col-6 flex height-fit">
                                <div>
                                    <ng-container *ngIf="answerContent ?? false">
                                        <vg-player *ngIf="answerContent.contentURL ?? false" style="border-radius: 15px;">
                                            <video #pronunciationVideo [vgMedia]="$any(pronunciationMedia)" #pronunciationMedia id="pronunciationVideo" preload="auto"
                                                controls (timeupdate)="highlightPronunciation($event.target)">
                                                <source [src]="answerContent.contentURL" type="video/webm">
                                                <source [src]="answerContent.contentURL" type="video/mp4">
                                            </video>
                                        </vg-player>

                                    </ng-container>
                                    <div *ngIf="answerContent === null" class="no-questions-message">
                                        {{"Key.NoAnswer" | translate}}
                                    </div>
                                </div>
                            </div>

                            <div class="custom-col-6 flex flex-column pr-0 align-items-center justify-content-center">
                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" style="display: flex;padding: 0;" class="col-12">
                                    <ng-container  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.pronunciationScore !=null">
                                            <div class="col-6" style="padding-left: 0;padding-right: 0;color: var(--gray-700);font-size: 14px;font-weight: 600;">
                                                {{"Key.PronunciationScore" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.PronunciationScoreInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding-left: 0;">
                                                <p-progressBar class="pronunciationAnalysisProgressBar" [showValue]="false"
                                                    [value]="this.answerContent.answerContentPronunciationAnalysis.pronunciationScore"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value" style="color:var(--blue-500);padding-left: 0;padding-right: 0;">
                                                {{this.answerContent.answerContentPronunciationAnalysis.pronunciationScore.toFixed(2)}}
                                            </div>
                                    </ng-container>
                                </div>
                                <hr>
                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" style="padding: 0;" class="col-12">
                                    <div class="col-12" style="padding-left: 0;padding-right: 0;color: var(--gray-700);font-size: 14px;font-weight: 600;">
                                        {{"Key.ScoreBreakdown" | translate}}
                                    </div>
                                    <div style="display: flex;padding: 0; padding-top: 9px;"  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.completeness !=null">
                                            <div class="col-6" style="padding:0;color: var(--gray-600);font-size: 12px;font-weight: 500;">
                                                {{"Key.Completeness" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.CompletenessInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                                <p-progressBar class="pronunciationAnalysisProgressBar" [showValue]="false"
                                                    [value]="this.answerContent.answerContentPronunciationAnalysis.completeness"></p-progressBar>
                                            </div>
                                            <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                                {{this.answerContent.answerContentPronunciationAnalysis.completeness.toFixed(2)}}
                                            </div>
                                    </div>

                                    <div style="display: flex;padding: 0; padding-top: 15px;"  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.accuracy !=null">
                                        <div class="col-6" style="padding:0;color: var(--gray-600);font-size: 12px;font-weight: 500;">
                                            {{"Key.Accuracy" | translate}}
                                            <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                pTooltip="{{'Key.AccuracyInfo' | translate}}" tooltipPosition="top"
                                                width="15px" height="15px" />
                                        </div>
                                        <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                            <p-progressBar class="pronunciationAnalysisProgressBar" [showValue]="false"
                                                [value]="this.answerContent.answerContentPronunciationAnalysis.accuracy"></p-progressBar>
                                        </div>
                                        <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                            {{this.answerContent.answerContentPronunciationAnalysis.accuracy.toFixed(2)}}
                                        </div>
                                    </div>

                                    <div style="display: flex;padding: 0; padding-top: 15px;"  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.fluencyScore !=null">
                                        <div class="col-6" style="padding:0;color: var(--gray-600);font-size: 12px;font-weight: 500;">
                                            {{"Key.FluencyScore" | translate}}
                                            <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                pTooltip="{{'Key.FluencyScoreInfo' | translate}}" tooltipPosition="top"
                                                width="15px" height="15px" />
                                        </div>
                                        <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                            <p-progressBar class="pronunciationAnalysisProgressBar" [showValue]="false"
                                                [value]="this.answerContent.answerContentPronunciationAnalysis.fluencyScore"></p-progressBar>
                                        </div>
                                        <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                            {{this.answerContent.answerContentPronunciationAnalysis.fluencyScore.toFixed(2)}}
                                        </div>
                                    </div>

                                    <div style="display: flex;padding: 0;padding-top: 15px;"  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.fluencyTimeScore !=null">
                                        <div class="col-6" style="padding:0;color: var(--gray-600);font-size: 12px;font-weight: 500;">
                                            {{"Key.FluencyTimeScore" | translate}}
                                            <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                pTooltip="{{'Key.FluencyTimeScoreInfo' | translate}}" tooltipPosition="top"
                                                width="15px" height="15px" />
                                        </div>
                                        <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                            <p-progressBar class="pronunciationAnalysisProgressBar" [showValue]="false"
                                                [value]="this.answerContent.answerContentPronunciationAnalysis.fluencyTimeScore"></p-progressBar>
                                        </div>
                                        <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                            {{this.answerContent.answerContentPronunciationAnalysis.fluencyTimeScore.toFixed(2)}}
                                        </div>
                                    </div>

                                    <div style="display: flex;padding: 0;padding-top: 15px;padding-bottom: 16px"  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.fluencyDisruptorScore !=null">
                                        <div class="col-6 min-w-max" style="padding:0;color: var(--gray-600);font-size: 12px;font-weight: 500;">
                                            {{"Key.FluencyDisruptorScore" | translate}}
                                            <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                pTooltip="{{'Key.FluencyDisruptorScoreInfo' | translate}}" tooltipPosition="top"
                                                width="15px" height="15px" />
                                        </div>
                                        <div class="col-5 justify-content-center" style="padding:0;padding-right: 1rem">
                                            <p-progressBar class="pronunciationAnalysisProgressBar" [showValue]="false"
                                                [value]="this.answerContent.answerContentPronunciationAnalysis.fluencyDisruptorScore"></p-progressBar>
                                        </div>
                                        <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                            {{this.answerContent.answerContentPronunciationAnalysis.fluencyDisruptorScore.toFixed(2)}}
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="gptEvalStatus === gptEvalStatusEnum.Retry">
                                    <div class="retry-container">
                                        <div class="grid">
                                            <div class="col-12 flex justify-content-center">
                                                <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                            </div>
                                            <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                                                {{"Key.GptRetryMessage" | translate}}
                                            </div>
                                            <div class="col-12 pt-4 flex justify-content-center">
                                                <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                                <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                            </div>

                                        </div>
                                    </div>

                                </ng-container>

                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.ClientFail">
                                    <div class="client-fail-container">
                                        <div class="grid">
                                            <div class="col-12 pb-0 flex justify-content-center">
                                                <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg" class="w-2rem pt-3" >
                                            </div>

                                            <div class="col-12 flex justify-content-center answer-text-label text-center text-base font-medium">
                                                {{ answerContent.aiFinalLog }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Fail">
                                    {{"Key.GptFailStatusMessage" | translate}}
                                </div>

                                <ng-container *ngIf="gptEvalStatus === gptEvalStatusEnum.Waiting || gptEvalStatus === 99">
                                    {{"Key.WaitForAIComment" | translate}}
                                </ng-container>
                            </div>
                        </div>

                        <div class="mt-3 flex" *ngIf="questionPageHiddenLog >0">
                            <img src="assets/vinter/icons/cheating-icon.svg">
                            <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog} }}</p>
                        </div>
                        <div *ngIf="answerContent.isFaceDetected" class="mt-2 flex-nowrap flex">
                            <img src="assets/vinter/icons/face-detection-icon.svg" width="18px" height="18px">
                            <p class="ml-2 -mt-1 font-medium" style="color: var(--danger-main, #F20505);">{{"Key.FaceDetected" | translate }}</p>
                        </div>
                        <div *ngIf="answerContent.isMultiFaceDetected" class="mt-2 -mb-2 flex-nowrap flex">
                            <img src="assets/vinter/icons/multiface-detected-icon.svg" width="18px" height="18px">
                            <p class="ml-2 -mt-1 font-medium" style="color: #F28505">{{"Key.MultiFaceDetected" | translate }}</p>
                        </div>

                        <hr *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">

                        <div class="grid flex flex-wrap p-4">
                            <div class="custom-col-6 flex" style="flex-direction: column;">
                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" class="col-12" style="padding-left: 0;padding-right: 0;color: var(--gray-700);font-size: 14px;font-weight: 600;">
                                    {{"Key.RecognizedSpeech" | translate}}
                                </div>
                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">
                                    <ng-container  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.analysisResultDetail !=null">
                                        <div #pronunciationDiv style="height: 16rem;overflow-y: scroll;">
                                            <div *ngIf="pronunciationWords.length > 0" class="transcript-text" style="text-align: unset;cursor: pointer;border-right: var(--gray-50) 1px solid;padding-right: 10px;">
                                                <span *ngFor="let word of pronunciationWords" [id]="'p' + word.index"
                                                    (click)="jumpToSecondPronunciation(word.start);" class="pronunciationText "
                                                    [ngClass]="word.styleClass"
                                                    [ngStyle]="
                                                    (word.highlighted)?
                                                    { 'background-color': 'var(--gray-500)','color': '#fff'} :
                                                    (word.errorType=='Mispronunciation' && !showMispronunciation) ?
                                                    { 'background-color': 'unset','color': 'var(--gray-400)'} :
                                                    (word.errorType=='Omission' && !showOmission) ?
                                                    { 'background-color': 'unset','color': 'var(--gray-400)'} :
                                                    (word.errorType=='Insertion' && !showInsertion) ?
                                                    { 'background-color': 'unset','color': 'var(--gray-400)'} :
                                                    null
                                                    "
                                                    (click)="jumpToSecondPronunciation(word.start);"
                                                    tooltipPosition="top"
                                                    [pTooltip]="getPronunciationTooltip(word)"
                                                    tooltipStyleClass="pronunciationTooltip"
                                                    [escape]="false"
                                                    >
                                                    {{ word.word }}
                                                </span>
                                            </div>
                                        </div>
                                </ng-container>
                                </div>

                                <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Fail">
                                    {{"Key.GptFailStatusMessage" | translate}}
                                </div>


                            </div>
                            <div class="custom-col-6 flex" style="flex-direction: column;padding-right: 0;">
                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" class="col-12" style="padding-left: 0;padding-right: 0;color: var(--gray-700);font-size: 14px;font-weight: 600;">
                                    {{"Key.PronunciationErrors" | translate}}
                                </div>
                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" style="width: 100%;padding-top: 12px;">
                                    <ng-container  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.analysisResultDetail !=null">
                                        <div class="flex col-12" style="padding: 0;align-items: center;">
                                            <div class="col-1" style="padding: 0;">
                                                <div style="background: var(--red-500);text-align: center;width: 24px;border-radius: 21px;height: 23px;display: flex;align-items: center;justify-content: center;color: #fff;">
                                                    {{mispronunciationCount}}
                                                </div>
                                            </div>
                                            <div class="col-10" style="padding:0;color: var(--gray-600);font-size: 14px;font-weight: 500;">
                                                {{"Key.Mispronunciation" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.MispronunciationInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                                <p-inputSwitch [(ngModel)]="showMispronunciation" [style]="{'margin-left':'1rem'}"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" style="width: 100%;padding-top: 12px;">
                                    <ng-container  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.analysisResultDetail !=null">
                                        <div class="flex col-12" style="padding: 0;align-items: center;">
                                            <div class="col-1" style="padding: 0;">
                                                <div style="background: var(--blue-200);text-align: center;width: 24px;border-radius: 21px;height: 23px;display: flex;align-items: center;justify-content: center;color: #fff;">
                                                    {{omissionCount}}
                                                </div>
                                            </div>
                                            <div class="col-10" style="padding:0;color: var(--gray-600);font-size: 14px;font-weight: 500;">
                                                {{"Key.Omission" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.OmissionInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                                <p-inputSwitch [(ngModel)]="showOmission" [style]="{'margin-left':'1rem'}"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" style="width: 100%;padding-top: 12px;">
                                    <ng-container  *ngIf="this.answerContent.answerContentPronunciationAnalysis!=null && this.answerContent.answerContentPronunciationAnalysis.analysisResultDetail !=null">
                                        <div class="flex col-12" style="padding: 0;align-items: center;">
                                            <div class="col-1" style="padding: 0;">
                                                <div style="background: var(--yellow-500);text-align: center;width: 24px;border-radius: 21px;height: 23px;display: flex;align-items: center;justify-content: center;color: #fff;">
                                                    {{insertionCount}}
                                                </div>
                                            </div>
                                            <div class="col-10" style="padding:0;color: var(--gray-600);font-size: 14px;font-weight: 500;">
                                                {{"Key.Insertion" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.InsertionInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-1 emotion-value" style="color:var(--blue-500);padding:0">
                                                <p-inputSwitch [(ngModel)]="showInsertion" [style]="{'margin-left':'1rem'}"></p-inputSwitch>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngSwitchDefault class="answer-container">
                        <div class="grid flex flex-wrap p-4">
                            <div class="custom-col-6 flex height-fit">
                                <div>
                                    <ng-container *ngIf="answerContent ?? false">
                                        <vg-player *ngIf="answerContent.contentURL ?? false" style="border-radius: 15px;">
                                            <video #targetVideo [vgMedia]="$any(media)" #media id="singleVideo" preload="auto"
                                                controls (timeupdate)="highlightSubtitle($event.target)">
                                                <source [src]="answerContent.contentURL" type="video/webm">
                                                <source [src]="answerContent.contentURL" type="video/mp4">
                                            </video>
                                        </vg-player>

                                    </ng-container>
                                </div>
                            </div>

                            <div class="custom-col-6 flex" [ngClass]="{ 'client-fail-panel': (gptEvalStatus === gptEvalStatusEnum.Retry || gptEvalStatus === gptEvalStatusEnum.ClientFail)}">
                                <p-tabView class="w-full" [(activeIndex)]="activeIndex">
                                    <p-tabPanel header='{{"Key.Transcript" | translate}}'>
                                        <ng-container *ngIf="answerContent ?? false">
                                            <div #transcriptDiv *ngIf="transcriptStatus === 2">
                                                <div *ngIf="subtitles.length > 0" class="transcript-text">
                                                    <span *ngFor="let subtitle of subtitles" [id]="'s' + subtitle.id"
                                                        (mouseover)="subtitle.hover=true" (mouseleave)="subtitle.hover=false"
                                                        (click)="jumpToSecond(subtitle.start);" class="subtitleText"
                                                        pTooltip="{{ subtitle.emotion_score !== -1 ? ('%' + (subtitle.emotion_score | number:'2.1-2') + ' ' + ('Key.'+subtitle.emotion | translate)) : ('Key.'+subtitle.emotion | translate) }}"
                                                        tooltipPosition="left"
                                                        [ngStyle]="(subtitle.highlighted || subtitle.hover) ? { 'background-color': getColor(subtitle.emotion, subtitle.emotion_score)}: { 'background-color': 'transparent' }">
                                                        {{ subtitle.text }}
                                                    </span>
                                                </div>
                                                <div *ngIf="subtitles.length === 0" class="transcript-text">
                                                    <span>
                                                        {{"Key.NoTranscriptMessage" | translate}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="(transcriptStatus === 1 || transcriptStatus === 3) && (gptEvalStatus === gptEvalStatusEnum.Waiting)">
                                                {{"Key.WaitForTranscript" | translate}}
                                            </div>

                                            <div *ngIf="gptEvalStatus === gptEvalStatusEnum.ClientFail">
                                                <div class="client-fail-container">
                                                    <div class="grid">
                                                        <div class="col-12 pb-0 flex justify-content-center">
                                                            <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg" class="w-2rem pt-3" >
                                                        </div>

                                                        <div class="col-12 flex justify-content-center answer-text-label text-center text-base font-medium">
                                                            {{ answerContent.aiFinalLog }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-container *ngIf="gptEvalStatus === gptEvalStatusEnum.Retry">
                                                <div class="retry-container">
                                                    <div class="grid">
                                                        <div class="col-12 flex justify-content-center">
                                                            <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                                        </div>
                                                        <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                                                            {{"Key.GptRetryMessage" | translate}}
                                                        </div>
                                                        <div class="col-12 pt-4 flex justify-content-center">
                                                            <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                                            <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </ng-container>

                                            <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Fail">
                                                {{"Key.GptFailStatusMessage" | translate}}
                                            </div>
                                        </ng-container>
                                        <div *ngIf="answerContent === null" class="no-questions-message">
                                            {{"Key.NoAnswer" | translate}}
                                        </div>
                                    </p-tabPanel>
                                    <p-tabPanel header='{{"Key.VinterComment" | translate}}'>
                                        <ng-container *ngIf="answerContent ?? false || gptEvalStatus !== gptEvalStatusEnum.Retry">
                                            <div class="ai-answer-container vinter-comment-text" *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">
                                                {{ answerContent.gptEvalText }}
                                            </div>
                                            <div class="ai-answer-container vinter-comment-text" *ngIf="gptEvalStatus === gptEvalStatusEnum.Waiting || gptEvalStatus === 99">
                                                {{"Key.WaitForAIComment" | translate}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="gptEvalStatus === gptEvalStatusEnum.Retry">
                                            <div class="retry-container">
                                                <div class="grid">
                                                    <div class="col-12 flex justify-content-center">
                                                        <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                                    </div>
                                                    <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                                                        {{"Key.GptRetryMessage" | translate}}
                                                    </div>
                                                    <div class="col-12 pt-4 flex justify-content-center">
                                                        <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                                        <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </ng-container>

                                        <div *ngIf="gptEvalStatus === gptEvalStatusEnum.ClientFail">
                                            <div class="client-fail-container">
                                                <div class="grid">
                                                    <div class="col-12 pb-0 flex justify-content-center">
                                                        <img src="../../../../../assets/vinter/icons/text-grammar-error-regular.svg" class="w-2rem pt-3" >
                                                    </div>

                                                    <div class="col-12 flex justify-content-center answer-text-label text-center text-base font-medium">
                                                        {{ answerContent.aiFinalLog }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Fail">
                                            {{"Key.GptFailStatusMessage" | translate}}
                                        </div>

                                    </p-tabPanel>
                                </p-tabView>

                            </div>

                        </div>



                        <div class="mt-3 flex" *ngIf="questionPageHiddenLog >0">
                            <img src="assets/vinter/icons/cheating-icon.svg">
                            <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
                        </div>
                        <div *ngIf="answerContent.isFaceDetected" class="mt-3 flex-nowrap flex">
                            <img src="assets/vinter/icons/face-detection-icon.svg" width="18px" height="18px">
                            <p class="ml-2 -mt-1 font-medium" style="color: var(--danger-main, #F20505);">{{"Key.FaceDetected" | translate }}</p>
                        </div>
                        <div *ngIf="answerContent.isMultiFaceDetected" class="mt-3 -mb-2 flex-nowrap flex">
                            <img src="assets/vinter/icons/multiface-detected-icon.svg" width="18px" height="18px">
                            <p class="ml-2 -mt-1 font-medium" style="color: #F28505;">{{"Key.MultiFaceDetected" | translate }}</p>
                        </div>
                        <div *ngIf="answerContent.improprietyAnalysis?.isImproprietySpeech" class="mt-3 -mb-2 flex-nowrap flex">
                            <img src="assets/vinter/icons/impropriety-speech-warning.svg" width="18px" height="18px">
                            <p class="ml-2 -mt-1 font-medium" style="color: #F25A05;">{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
                        </div>

                        <hr *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">

                        <div>
                            <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" class="col-12 pl-0 pr-0 flex">
                                <div *ngIf="this.answerContent.emotionAnalysis ?? false" class="col-6 pl-0">
                                    <div>
                                        <h5>
                                            {{"Key.FacialExpressions" | translate}}
                                        </h5>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.happy!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Happy' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/mutlu.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="happy emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.happy"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#FFA372;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.happy.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.sad!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Sad' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/uzgun.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="sad emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.sad"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#1597E5;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.sad.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.fear!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Fear' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/korku.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="fear emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.fear"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#7ECA9C;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.fear.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.angry!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Angry' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/kizgin.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="angry emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.angry"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color: #FE5F55;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.angry.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.surprise!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Surprise' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/saskin.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="surprise emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.surprise"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#9E67BF;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.surprise.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.disgust!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Disgust' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/tiksinmi.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="disgust emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.disgust"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#3AB1C8;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.disgust.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 emotionIconRow"
                                        *ngIf="this.answerContent.emotionAnalysis!=null && this.answerContent.emotionAnalysis.neutral!=null">
                                        <div class="grid">
                                            <div class="col-2 emotionIcon w-1 mr-3">
                                                <img pTooltip="{{'Key.Neutral' | translate}}" tooltipPosition="left"
                                                    class="emotionIcon"
                                                    src="../../../../../../assets/vinter/icons/emotions/ntr.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="neutral emotion" [showValue]="false"
                                                    [value]="this.answerContent.emotionAnalysis.neutral"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#BEBBAD;margin-top: 0.2rem;">
                                                % {{this.answerContent.emotionAnalysis.neutral.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="this.answerContent.impressionAnalysis ?? false" class="col-6 pr-0">
                                    <div>
                                        <h5>
                                            {{"Key.ImpressionAnalysis" | translate}}
                                        </h5>
                                    </div>

                                    <div class="col-12 big5IconRow"
                                        *ngIf="this.answerContent.impressionAnalysis!=null && this.answerContent.impressionAnalysis.openness!=null">
                                        <div class="grid">
                                            <div class="col-2 big5Icon w-1 mr-3">
                                                <img pTooltip="{{'Key.Opennes' | translate}}" tooltipPosition="left"
                                                    class="big5Icon" src="../../../../../../assets/vinter/icons/big5/o.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="impression openness" [showValue]="false"
                                                    [value]="this.answerContent.impressionAnalysis.openness"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#1549D6;margin-top: 0.2rem;">
                                                % {{this.answerContent.impressionAnalysis.openness.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 big5IconRow"
                                        *ngIf="this.answerContent.impressionAnalysis!=null && this.answerContent.impressionAnalysis.conscientiousness!=null">
                                        <div class="grid">
                                            <div class="col-2 big5Icon w-1 mr-3">
                                                <img pTooltip="{{'Key.Conscientiousness' | translate}}" tooltipPosition="left"
                                                    class="big5Icon" src="../../../../../../assets/vinter/icons/big5/c.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="impression conscientiousness" [showValue]="false"
                                                    [value]="this.answerContent.impressionAnalysis.conscientiousness"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#2962FF;margin-top: 0.2rem;">
                                                % {{this.answerContent.impressionAnalysis.conscientiousness.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 big5IconRow"
                                        *ngIf="this.answerContent.impressionAnalysis!=null && this.answerContent.impressionAnalysis.extroversion!=null">
                                        <div class="grid">
                                            <div class="col-2 big5Icon w-1 mr-3">
                                                <img pTooltip="{{'Key.Extroversion' | translate}}" tooltipPosition="left"
                                                    class="big5Icon" src="../../../../../../assets/vinter/icons/big5/e.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="impression extroversion" [showValue]="false"
                                                    [value]="this.answerContent.impressionAnalysis.extroversion"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#0735B5;margin-top: 0.2rem;">
                                                % {{this.answerContent.impressionAnalysis.extroversion.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 big5IconRow"
                                        *ngIf="this.answerContent.impressionAnalysis!=null && this.answerContent.impressionAnalysis.agreeableness!=null">
                                        <div class="grid">
                                            <div class="col-2 big5Icon w-1 mr-3">
                                                <img pTooltip="{{'Key.Agreeableness' | translate}}" tooltipPosition="left"
                                                    class="big5Icon" src="../../../../../../assets/vinter/icons/big5/a.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="impression agreeableness" [showValue]="false"
                                                    [value]="this.answerContent.impressionAnalysis.agreeableness"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#5985FF;margin-top: 0.2rem;">
                                                % {{this.answerContent.impressionAnalysis.agreeableness.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 big5IconRow"
                                        *ngIf="this.answerContent.impressionAnalysis!=null && this.answerContent.impressionAnalysis.neuroticism!=null">
                                        <div class="grid">
                                            <div class="col-2 big5Icon w-1 mr-3">
                                                <img pTooltip="{{'Key.Neuroticism' | translate}}" tooltipPosition="left"
                                                    class="big5Icon" src="../../../../../../assets/vinter/icons/big5/n.svg">
                                            </div>
                                            <div class="col-8 justify-content-center mt-2 w-auto">
                                                <p-progressBar class="impression neuroticism" [showValue]="false"
                                                    [value]="this.answerContent.impressionAnalysis.neuroticism"></p-progressBar>
                                            </div>
                                            <div class="col-2 pl-0 w-auto emotion-value" style="color:#82A3FF;margin-top: 0.2rem;">
                                                % {{this.answerContent.impressionAnalysis.neuroticism.toFixed(2)}}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <hr *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">

                            <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful" class="col-12 pl-0 flex">
                                <div *ngIf="this.answerContent.voiceAnalysis ?? false" class="col-6 pl-0" style="width: auto;">
                                    <div>
                                        <h5>
                                            {{"Key.PRAATVoiceAnalysis" | translate}}
                                        </h5>
                                    </div>

                                    <div *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.speechSpeed !=null">
                                        <div class="flex">
                                            <div class="col-4 p-0 mr-3 praat-text" style="width: 46%">
                                                {{"Key.SpeechSpeed" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.SpeechSpeedInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-6 p-0 justify-content-center">
                                                <p-rating class="voice"
                                                    [(ngModel)]="this.answerContent.voiceAnalysis.speechSpeed" [readonly]="true"
                                                    [cancel]="false">
                                                    <ng-template pTemplate="onicon">
                                                        <img src="assets/vinter/icons/rating-onIcon.svg" class="praat-rating-img"/>
                                                    </ng-template>
                                                    <ng-template pTemplate="officon">
                                                        <img src="assets/vinter/icons/rating-offIcon.svg" class="praat-rating-img"/>
                                                    </ng-template>
                                                </p-rating>
                                            </div>
                                            <div class="col-2 p-0 praat-value" style="width: auto;">
                                                {{this.answerContent.voiceAnalysis.speechSpeed |
                                                voiceSpeechSpeedTypes:voiceSpeechSpeedTypes }}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.originalDuration !=null">
                                        <div class="flex">
                                            <div class="custom-voice-col-6 p-0 pt-3 mr-3 praat-text">
                                                {{"Key.TapeDuration" | translate}}
                                            </div>
                                            <div class="col-4 p-0 pt-3" style="width: 39%">

                                            </div>
                                            <div class="col-2 p-0 pt-3 praat-value">
                                                {{this.answerContent.voiceAnalysis.originalDuration+" s"}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.speechDuration !=null">
                                        <div class="flex">
                                            <div class="custom-voice-col-6 p-0 pt-3 mr-3 praat-text">
                                                {{"Key.SpeechDuration" | translate}}
                                            </div>
                                            <div class="col-4 p-0 pt-3" style="width: 39%">

                                            </div>
                                            <div class="col-2 p-0 pt-3 praat-value">
                                                {{this.answerContent.voiceAnalysis.speechDuration+" s"}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.numOfPauses !=null">
                                        <div class="flex">
                                            <div class="custom-voice-col-6 p-0 pt-3 mr-3 praat-text">
                                                {{"Key.NumberofPauses" | translate}}
                                            </div>
                                            <div class="col-4 p-0 pt-3" style="width: 39%">

                                            </div>
                                            <div class="col-2 p-0 pt-3 praat-value">
                                                {{this.answerContent.voiceAnalysis.numOfPauses}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.speechDuration !=null && this.answerContent.voiceAnalysis.originalDuration !=null">
                                        <div class="flex">
                                            <div class="custom-voice-col-6 p-0 pt-3 mr-3 praat-text">
                                                {{"Key.SpeechRatio" | translate}}
                                                <!-- <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.SpeechRatioInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" /> -->
                                            </div>
                                            <div class="col-4 p-0 pt-3" style="width: 39%">

                                            </div>
                                            <div class="col-2 p-0 pt-3 praat-value">
                                                {{"Key.SpeechRatioValue" | translate: {value: (this.answerContent.voiceAnalysis.originalDuration !== 0 ? ((this.answerContent.voiceAnalysis.speechDuration /
                                                        this.answerContent.voiceAnalysis.originalDuration * 100).toFixed(2)) : 0)} }}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.speechRate !=null">
                                        <div class="flex">
                                            <div class="custom-voice-col-6 p-0 pt-3 mr-3 praat-text">
                                                {{"Key.SpeechRate" | translate}}
                                            </div>
                                            <div class="col-4 p-0 pt-3" style="width: 39%">

                                            </div>
                                            <div class="col-2 p-0 pt-3 praat-value">
                                                {{this.answerContent.voiceAnalysis.speechRate}}
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.answerContent.voiceAnalysis!=null && this.answerContent.voiceAnalysis.articulationRate !=null">
                                        <div class="flex">
                                            <div class="custom-voice-col-6 p-0 pt-3 mr-3 praat-text">
                                                {{"Key.ArticulationRate" | translate}}
                                                <img class="rating-info" src="assets/vinter/icons/info-icon.svg"
                                                    pTooltip="{{'Key.ArticulationRateInfo' | translate}}" tooltipPosition="top"
                                                    width="15px" height="15px" />
                                            </div>
                                            <div class="col-4 p-0 pt-3" style="width: 39%">

                                            </div>
                                            <div class="col-2 p-0 pt-3 praat-value">
                                                {{this.answerContent.voiceAnalysis.articulationRate}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="gptEvalStatus === gptEvalStatusEnum.Waiting || gptEvalStatus === 99" class="col-12">
                                {{"Key.WaitForAIComment" | translate}}
                            </div>
                        </div>



                    </div>

                </ng-container>
            </ng-container>

            <div class="ml-5 flex" *ngIf="answerContent === null">
                <img src="assets/vinter/icons/not-answered.svg">
                <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
            </div>

        </ng-container>

        <ng-container *ngIf="answerType ===QuestionExpectedAnswerType.Coding">
            <div class="col-12 flex pt-0 pb-0 pl-0">
                <div class="col-6 pt-0 pl-0 pb-0 flex">
                    <div class="col-2 pt-0 pr-0 pl-0 question-icon-width">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/codingQuestion.svg" />
                    </div>
                    <div class="col-7 pt-0 pl-0">
                        <h5 style="margin-top: 0.6rem">
                            {{"Key.QuestionOrder" | translate: { value1: questionOrder } }}
                        </h5>

                    </div>
                </div>
            </div>
            <div *ngIf="choiceAnswers !== null" [ngSwitch]="questionContent?.contentURLType">
                <div *ngSwitchCase="!QuestionContentType.Image || !QuestionContentType.Audio || !QuestionContentType.Video">
                    <div class="question-container-sideBar-text">
                        <div class="flex flex-nowrap">
                            <span
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="font-medium mr-2">
                                {{ selectedQuestion?.langShort}}
                            </span>
                            <div
                            *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight ml-2 mr-2 pb-3"></div>
                            <div class="concept-text mt-2">
                                {{ questionConcept}}
                            </div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                            <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                                class="flex flex-nowrap mt-2">
                                <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                                <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                            </div>
                        </div>
                        <div class="flex question-text-label mb-0" [innerHTML]="questionText" style="display:block !important">
                            <!-- {{questionText}} -->
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="QuestionContentType.Image">
                    <div class="flex flex-nowrap">
                        <span
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="font-medium mr-2">
                            {{ selectedQuestion?.langShort}}
                        </span>
                        <div
                        *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight ml-2 mr-2 pb-3"></div>
                        <div class="concept-text mt-2">
                            {{ questionConcept}}
                        </div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="vertical-line-weight-sidebar ml-2 mr-2 mt-2"></div>
                        <div *ngIf="item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.TechnicalTest || item.positionStep.questionCollection.questionCollectionCategory == questionCollectionCategories.CompetenceTest"
                            class="flex flex-nowrap mt-2">
                            <img alt="" [src]="questionWeightIconPath" class="w-1rem pb-3">
                            <span class="text-blue-800 font-medium text-sm ml-2">{{questionWeight}}</span>
                        </div>
                    </div>
                    <div class="question-container-sideBar-text">
                        <div class="col-12 p-0 flex">
                            <div class="col-6 p-0 pr-3">
                                <p-image src={{questionContent.contentURL}} [imageStyle]="{'width': '100%'}"
                                    [preview]="true"></p-image>
                            </div>
                            <div class="col-6 p-0 pl-1">
                                <div class="question-text-label mb-0"  [innerHTML]="questionText"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="answer-text">
                <ng-container>
                    <div class="flex">
                        <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
                        <h5 class="text-justify answer-title text-lg ml-2 mb-2 m-3 h5-font">
                            {{'Key.Answer' | translate}}
                        </h5>
                    </div>

                    <div class="answer-coding-container">
                        <div class="answer-text-label">
                            <app-vinter-code-editor
                                [isSelectionEnabled]="true"
                                [dropdownReadonly]="true"
                                [template]="answerContent?.answerText"
                                [codeType]="answerContent?.answerCodeLanguage"
                                [height]="500"
                                [disabled]="true">
                            </app-vinter-code-editor>
                        </div>
                    </div>

                    <div class="ml-5 flex" *ngIf="questionPageHiddenLog >0">
                        <img src="assets/vinter/icons/cheating-icon.svg">
                        <p class="ml-2" style="color: #F2A105;">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
                    </div>
                    <div class="flex ml-5 mt-2" *ngIf="(answerContent ?? false) && (answerContent?.codingAnalysis ?? false) && answerContent?.codingAnalysis?.isAnswerValid">
                        <img src="assets/vinter/icons/correct-answer.svg">
                        <p class="ml-2" style="color: #3A9B18;">{{"Key.CorrectAnswer" | translate}}</p>
                    </div>
                    <div class="flex ml-5 mt-2" *ngIf="(answerContent ?? false) && (answerContent?.codingAnalysis ?? false) && !answerContent?.codingAnalysis?.isAnswerValid">
                        <img src="assets/vinter/icons/wrong-answer.svg">
                        <p class="ml-2" style="color: #F20505;">{{"Key.WrongAnswer" | translate}}</p>
                    </div>
                    <div class="ml-5 flex" *ngIf="!(answerContent ?? false)">
                        <img src="assets/vinter/icons/not-answered.svg">
                        <p class="ml-2" style="color: #686E75;">{{"Key.QuestionNotAnswered" | translate}}</p>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="answerContent !== null" class="answer-text pt-0">
                <ng-container>
                    <div class="flex">
                        <h5 class="text-justify answer-title text-lg ml-2 mb-2 pl-4 m-3 h5-font">
                            {{'Key.VinterComment' | translate}}
                        </h5>
                    </div>
                    <div class="flex answer-container" [ngClass]="{'justify-content-center': gptEvalStatus === gptEvalStatusEnum.Retry}">
                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Succesful">
                            {{ answerContent.gptEvalText }}
                        </div>
                        <ng-container *ngIf="gptEvalStatus === gptEvalStatusEnum.Retry">
                            <div class="retry-container">
                                <div class="grid">
                                    <div class="col-12 flex justify-content-center">
                                        <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                    </div>
                                    <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium pt-0 text-center">
                                        {{"Key.GptRetryMessage" | translate}}
                                    </div>
                                    <div class="col-12 flex justify-content-center">
                                        <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                        <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                    </div>

                                </div>
                            </div>

                        </ng-container>

                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.ClientFail">
                            {{ answerContent.aiFinalLog }}
                        </div>

                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Fail">
                            {{"Key.GptFailStatusMessage" | translate}}
                        </div>

                        <div class="answer-text-label" *ngIf="gptEvalStatus === gptEvalStatusEnum.Waiting || gptEvalStatus === 99">
                            {{"Key.WaitForAIComment" | translate}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </p-sidebar>

    <p-dialog (onHide)="closePopup()" [(visible)]="popupOpen" [modal]="true" [baseZIndex]="10000"
        [style]="{ width: '35rem'}">
        <div  *ngIf="popupOpen && selectedStar !== null && selectedStar !== undefined" class="row flex justify-content-center mb-3">
            <app-vinter-rating [starNumber]="5" (onRate)="rate($event)" [disabled]="false" size="lg" [value]="selectedStar"></app-vinter-rating>
        </div>
        <div class="rating-comment">
            <span class="p-float-label">
                <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="ratingComment"></textarea>
                <label for="float-input">{{"Key.AddComment" | translate}}</label>
            </span>
        </div>
        <div class="grid mt-4">
            <div class="col-6">
                <p-button label="{{'Key.CancelRate' | translate}}" styleClass="p-button-secondary"
                    (click)="closePopup()"></p-button>
            </div>
            <div class="col-6 text-right">
                <p-button label="{{'Key.Rate' | translate}}" styleClass="p-button-outlined"
                    (click)="onFormSubmit(false, isRatingType)"></p-button>
            </div>
        </div>
    </p-dialog>
</div>

<!-- <app-answer-detail-sidebar *ngIf="answerSidebarVisible" [applicationStepId]="item.id"  [selectedQuestion]="selectedQuestion"
 [questionCollectionCategoryId]="item.positionStep.questionCollection.questionCollectionCategory"
  [sidebarVisible]="answerSidebarVisible" (onHideAnswerSidebar)="onHideAnswerSidebar()"></app-answer-detail-sidebar> -->