<p-tabView [(activeIndex)]="activeTabIndex">
    <p-tabPanel header="{{'Key.HiringProcessTab' | translate}}">
        <app-hiring-process-tab *ngIf="activeTabIndex == ApplicantDetailMenu.HiringProcess"
            [applicationId]="applicationId"></app-hiring-process-tab>
    </p-tabPanel>
    <p-tabPanel header="{{'Key.ApplicantProfileTab' | translate}}">
        <app-applicant-profile-detail-tab *ngIf="activeTabIndex == ApplicantDetailMenu.ApplicantProfile"
            [applicationId]="applicationId"></app-applicant-profile-detail-tab>
    </p-tabPanel>
    <p-tabPanel header="{{'Key.ResumeTab' | translate}}">
        <app-resume-tab *ngIf="activeTabIndex == ApplicantDetailMenu.Resume"
            [applicationId]="applicationId"></app-resume-tab>
    </p-tabPanel>
</p-tabView>