import { Injectable } from '@angular/core';
import { HttpBaseService } from '../http/http-base.service';
import { HttpClient } from '@angular/common/http';
import { CredentialsService } from './credentials.service';
import { NotifyService } from '../notify.service';
import { LocalizationService } from '../localization.service';
import { ConfigurationService } from '../configuration.service';
import { Observable, of , lastValueFrom} from 'rxjs';
import { LoginContext, Credentials, InvitationContext, InvitationResponse, RegisterContext, ResetPasswordContext, ResetPasswordMailContext, RegisterAndLoginContext } from '@app/shared/common/interfaces';
import { UserManagementListItem } from '@app/vinter/models/user-profile-list-item';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends HttpBaseService {

    constructor(
        private http: HttpClient
        , private credentialsService: CredentialsService
        , private notifySer: NotifyService
        , private localizationService : LocalizationService
        , private configService: ConfigurationService
    ) {
        super(http, "auth", notifySer, localizationService, configService)
    }
    /**
     * Authenticates the user.
     * @param context The login parameters.
     * @return The user credentials.
     */
    login(context: LoginContext): Observable<Credentials> {
        return this.post<LoginContext>("login", context, true);
    }

    loginWithApiKey(apiKey: any): Observable<Credentials> {
        return this.post<LoginContext>("LoginWithApiKey", apiKey, true);
    }

    register(context: RegisterContext): Observable<any> {
        return this.post<RegisterContext>("register", context, true);
    }

    registerAndLogin(context: RegisterAndLoginContext): Observable<Credentials> {
        return this.post<RegisterAndLoginContext>("registerAndLogin", context, true);
    }


    checkInvitation(context: InvitationContext): Observable<InvitationResponse> {
        return this.post<InvitationContext>("checkInvitation", context, true);
    }

    resetPassword(context: ResetPasswordContext): Observable<any> {
        return this.post<ResetPasswordContext>("ResetPassword", context, true);
    }

    sendResetPasswordMail(context: string): Observable<any> {
        return this.post<string>("SendResetPasswordMail?mail=" + context, "", true);
    }

    refreshToken(refreshFromDB: boolean) {
        let obj = new Object();
        obj["token"] = encodeURIComponent(this.credentialsService.getJwtToken());
        obj["refreshToken"] = this.credentialsService.getRefreshToken();
        obj["refreshFromDB"] = refreshFromDB;

        return lastValueFrom(this.post<any>("refresh", obj, true)).then(data=>{
            this.setCredentials(data);
        });
    }

    setCredentials(body: Credentials): any {
        this.credentialsService.setCredentials(body, true);
    }


    setEncryptedCredentials(body: Credentials): any {
        this.credentialsService.setCryptedCredentials(body, true);
    }
    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        this.credentialsService.setCredentials();
        return of(true);
    }
}
