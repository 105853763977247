import { HttpClient } from '@angular/common/http';
import { ConfigurationService, LocalizationService } from '@app/core';

export function configurationFactory(
    http: HttpClient,
    config: ConfigurationService
): () => Promise<any> {
    return (): Promise<any> => {
        return config.loadConfigurations(http)
    };
}

export function localizationFactory(
    http: HttpClient,
    config: LocalizationService
): () => Promise<any> {
    return (): Promise<any> => {
        return config.initTranslate(http)
    };
}

export function boolToNumber(value: boolean): number {
    if (value == null) return null;
    else if (value) return 1;
    else if (!value) return 0;
}

export function getFileExtension(fileName): string {
    let re = /(?:\.([^.]+))?$/;
    return re.exec(fileName)[1];
}