import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { AnswerType, GPTEvalStatus, QuestionCollectionCategoryEnum } from '@app/shared/common/enums';
import { AnswerContentHRScoreResponse } from '@app/vinter/models/answer-content-form-item';
import { CodingAnswerItem } from '@app/vinter/models/answer/coding-answer-item';
import { ForeignLanguageOpenEndedAnswerItem } from '@app/vinter/models/answer/foreign-language-open-ended-asnwer-item';
import { ForeignLanguageVideoAnswerItem } from '@app/vinter/models/answer/foreign-language-video-answer-item';
import { MultipleChoiceAnswerItem } from '@app/vinter/models/answer/multiple-choice-answer-item';
import { OpenEndedAnswerItem } from '@app/vinter/models/answer/open-ended-answer-item';
import { PronunciationVideoAnswerItem } from '@app/vinter/models/answer/pronunciation-video-answer-item';
import { VideoAnswerItem } from '@app/vinter/models/answer/video-answer-item';
import { AnswerContentHRScoreService } from '@app/vinter/services/answer-content-hr-score.service';
import { AnswerService } from '@app/vinter/services/answer.service';
import { QuestionService } from '@app/vinter/services/question.service';

@Component({
  selector: 'app-answer-detail-sidebar',
  templateUrl: './answer-detail-sidebar.component.html',
  styleUrls: ['./answer-detail-sidebar.component.scss']
})
export class AnswerDetailSidebarComponent implements OnInit,OnChanges {

  @Input() selectedQuestion: any;
  @Input() sidebarVisible: boolean;
  @Input() questionCollectionCategoryId: string;
  @Input() applicationStepId: string;
  @Output() onHideAnswerSidebar: EventEmitter<any> = new EventEmitter<any>();
  @Output() getPreviousOrNextQuestion: EventEmitter<any> = new EventEmitter<any>();
  changeScoreSidebarVisible: boolean = false;
  isScoreChanged: boolean = false;
  codingAnswer: CodingAnswerItem = null;
  changeVinterScoreSidebarVisible: boolean = false;
  multipleChoiceAnswer: MultipleChoiceAnswerItem = null;
  hrScoreDetails:  AnswerContentHRScoreResponse[];
  openEndedAnswer: OpenEndedAnswerItem = null;
  fullValue: number;
  valueToAdded: number;
  fractionValue: number = 0;
  videoAnswer: VideoAnswerItem = null;
  speakingAnswer: ForeignLanguageVideoAnswerItem = null;
  pronunciationVideoAnswer: PronunciationVideoAnswerItem = null;
  writingAnswer: ForeignLanguageOpenEndedAnswerItem = null;
  isResponse : boolean = false;
  answerType: number;
  vinterScore: any;
  scoreChange: number = 0;
  answerContentID: string;
  applicantID: string;
  isWaitingForAI = false;
  constructor(private answerService: AnswerService,
    private answerContentHRScoreService : AnswerContentHRScoreService

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if((this.selectedQuestion ?? false)){
      if(this.selectedQuestion?.answerId){
        this.getAnswerContentIdByFilter();
         this.getAnswerByFilter();
      }
     else{
      this.codingAnswer = null;
      this.multipleChoiceAnswer = null;
      this.openEndedAnswer = null;
      this.videoAnswer = null;
      this.speakingAnswer = null;
      this.pronunciationVideoAnswer = null;
      this.writingAnswer = null;
      this.vinterScore = 0;
      this.isResponse = true;
     }
    }
  }

  ngOnInit(): void {
    if((this.selectedQuestion ?? false)){
      if(this.selectedQuestion?.answerId){
        this.getAnswerContentIdByFilter();
         this.getAnswerByFilter();
      }
      else{
        this.codingAnswer = null;
        this.multipleChoiceAnswer = null;
        this.openEndedAnswer = null;
        this.videoAnswer = null;
        this.speakingAnswer = null;
        this.pronunciationVideoAnswer = null;
        this.writingAnswer = null;
        this.vinterScore = 0;
        this.isResponse = true;
       }
    }
  }
  getChangedScoreData(answerContentID, applicantID){
    const filter = { answerContentID: answerContentID, applicantID: applicantID };
    this.answerContentHRScoreService.getChangedScoreData(filter).subscribe((response: AnswerContentHRScoreResponse[]) => {
      this.hrScoreDetails = response;
      this.fullValue = Math.floor(this.vinterScore);
      this.fractionValue = this.vinterScore - this.fullValue;
      this.valueToAdded = 1 - this.fractionValue;
      if (this.hrScoreDetails && this.hrScoreDetails.length > 0) {
        if((this.vinterScore < this.vinterScore + this.hrScoreDetails[0].scoreChange) && this.fractionValue != 0){
          this.scoreChange = this.hrScoreDetails[0].scoreChange + this.valueToAdded;
        }
        else{
          this.scoreChange = this.hrScoreDetails[0].scoreChange - this.fractionValue;
        }
      }
    });
  }
  goToPreviousQuestion(){
    this.getPreviousOrNextQuestion.emit(this.selectedQuestion.order - 1)
  }
  goToNextQuestion(){
    this.getPreviousOrNextQuestion.emit(this.selectedQuestion.order + 1)
  }
  onHideSidebar(){
    this.onHideAnswerSidebar.emit(this.isScoreChanged)
  }
  openChangeVinterScoreSidebar() {
    if((this.vinterScore !== null && this.vinterScore !== undefined) && this.answerType != AnswerType.MultipleChoice && (this.selectedQuestion.answerId !== null && this.selectedQuestion.answerId !== undefined)){
      this.changeScoreSidebarVisible = true;
    }
  }

  onSidebarClosed() {
    this.changeScoreSidebarVisible = false;
  }


  getAnswerContentIdByFilter(){
    this.answerService.getAnswerContentIdByFilter({id: this.selectedQuestion?.answerId}).subscribe((resp)=>{
      this.answerContentID = resp?.answerContentID;
      this.applicantID = resp?.userId;
      this.getChangedScoreData(this.answerContentID, this.applicantID);
    });
  }
  getAnswerByFilter(){
    this.answerService.getAnswerByFilter({id: this.selectedQuestion?.answerId}).subscribe((resp)=>{
      if(resp.answerType == AnswerType.Coding){
        this.codingAnswer = resp;
      }
      else if(resp?.answerType == AnswerType.MultipleChoice){
        this.multipleChoiceAnswer = resp;
        if(this.multipleChoiceAnswer?.choiceAnswer?.isCorrect){
          this.vinterScore = 100;     
        }
        else{
          this.vinterScore = 0;   
        }    
      }
      else if(resp.answerType == AnswerType.OpenEnded && resp.questionCollectionCategory != QuestionCollectionCategoryEnum.ForeignLanguage){
        this.openEndedAnswer = resp;
      }
      else if(resp.answerType == AnswerType.OpenEnded && resp.questionCollectionCategory == QuestionCollectionCategoryEnum.ForeignLanguage){
        this.writingAnswer = resp;
      }
      else if(resp.answerType == AnswerType.Video && resp.questionCollectionCategory != QuestionCollectionCategoryEnum.ForeignLanguage && resp.questionCollectionCategory != QuestionCollectionCategoryEnum.Pronunciation){
        this.videoAnswer = resp;
      }
      else if(resp.answerType == AnswerType.Video && resp.questionCollectionCategory == QuestionCollectionCategoryEnum.ForeignLanguage){
        this.speakingAnswer = resp;
      }
      else if(resp.answerType == AnswerType.Video && resp.questionCollectionCategory == QuestionCollectionCategoryEnum.Pronunciation){
        this.pronunciationVideoAnswer = resp;
      }

      if(resp.answerType !== AnswerType.MultipleChoice){
        this.vinterScore = resp?.answerContent?.gptEvalScore;
      }
      this.answerType = resp?.answerType;
      this.isResponse = true;
      if(resp?.answerContent?.gptEvalStatus == GPTEvalStatus.Waiting){
        this.isWaitingForAI = true;
      }
    });
  }
}
