import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationService } from '@app/core';
import { MailService } from '@app/vinter/services/mail.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  showGetContactForm=false;
  showMembershipAgreement=false;
  showConfidentialityAgreement=false;
  showSpinner=false;
  showSuccess=false;
  getInTouhcForm: FormGroup;

  stepIndex=1;
  showApplicantScreenImages=false;
  hideApplicantScreenImages=true;
  @ViewChild('slider') slider: ElementRef;
  startX: any;
  scrollLeft: any;
  mouseDown = false;
  scrollYPreviousValue=0;
  stopSlider=false;
  overlayVisible: boolean = false;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private mailService:MailService,
    private renderer: Renderer2,
    private configurationService: ConfigurationService,
    @Inject(DOCUMENT) private document: Document,
  ){
    
  }

  ngOnInit() {
    this.getInTouhcForm = new FormGroup({
      name: new FormControl('',[
        Validators.required
      ]),
      surname: new FormControl('',[
        Validators.required
      ]),
      company:new FormControl(),
      email:new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
      ]),
      phone:new FormControl(),
      isAgreementAccepted:new FormControl(false,[Validators.requiredTrue])
    })
  }

  goToUrl(param: string): void {
    const applicantDomain = this.configurationService.config != undefined ? this.configurationService.config.applicantDomain : 'https://applicant.vinter.me/';
    const hrDomain = this.configurationService.config != undefined ? this.configurationService.config.hrDomain : 'https://app.vinter.me/';

    if(param === "applicant"){
      this.onLoginClicked(applicantDomain);
    }
    else{
      this.onLoginClicked(hrDomain);
    }
  }

  onLoginClicked(domain){
    this.document.location.href = domain + 'login';
  }

  toggle() {
    this.overlayVisible = !this.overlayVisible;
  }

  onBeforeShowHandler(event) {
    let parentElement = event.element.parentElement;
    const computedStyle = window.getComputedStyle(parentElement);
    const currentLeft = parseInt(computedStyle.left, 10);
    this.renderer.setStyle(parentElement, 'left', `${currentLeft - 57}px`);
  }

  onSidebarCloseClicked(){
    this.showGetContactForm=false;
    this.showMembershipAgreement=false;
    this.showConfidentialityAgreement=false;
  }

  onSidebarBackClicked(){
    this.showMembershipAgreement=false;
    this.showConfidentialityAgreement=false;
  }

  onMembershipAgreementClicked(){
    this.showMembershipAgreement=true;
    this.showConfidentialityAgreement=false;
  }

  onConfidentialityAgreementClicked(){
    this.showMembershipAgreement=false;
    this.showConfidentialityAgreement=true;
  }

  onSendGetContactForm(){

    if (this.getInTouhcForm.invalid) return;
    if (this.getInTouhcForm.controls["isAgreementAccepted"].value==false) return;

    let formObj=this.getInTouhcForm.value;

    this.showSpinner=true;
    this.mailService.sendContactUsMail(formObj).subscribe(
      (data)=>{
      this.showSpinner=false;
      this.showSuccess=true;
    },
    (error)=>{
      this.showSpinner=false;
    }
    )

  }

  onStepClicked(index){
    this.stepIndex=index;
  }

  onApplicantScreenImageClicked(){
    this.showApplicantScreenImages=!this.showApplicantScreenImages;
    if(!this.showApplicantScreenImages){
      setTimeout(() => {
        this.hideApplicantScreenImages=true;
      }, 800);
    }
    else{
      this.hideApplicantScreenImages=false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const numb = window.scrollY;
    if (numb >= 2700){
      this.showApplicantScreenImages=true;
      this.hideApplicantScreenImages=false;
    }
    else {
      this.showApplicantScreenImages=false;
      if(this.scrollYPreviousValue>numb){
        setTimeout(() => {
          this.hideApplicantScreenImages=true;
        }, 500);
      }
    }
    this.scrollYPreviousValue=numb;
  }

  startSliderDragging(e) {
    this.stopSlider=true;
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }

  stopSliderDragging(e) {
    this.stopSlider=false;
    this.mouseDown = false;
  }

  moveSliderEvent(e){
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

  startTouchSliderDragging(e) {
    this.stopSlider=true;
    if(e.targetTouches!=null && e.targetTouches.length>0)
    {
      this.mouseDown = true;
      this.startX = e.targetTouches[0].pageX - this.slider.nativeElement.offsetLeft;
      this.scrollLeft = this.slider.nativeElement.scrollLeft;
    }
  }

  stopTouchSliderDragging(e) {
    this.stopSlider=false;
    this.mouseDown = false;
  }

  moveTouchSliderEvent(e){
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    if(e.targetTouches!=null && e.targetTouches.length>0)
    {
      const x = e.targetTouches[0].pageX - this.slider.nativeElement.offsetLeft;
      const scroll = x - this.startX;
      this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
    }
    
  }
}
