import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NotifyService } from '../notify.service';
import { ConfigurationService } from '../configuration.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalizationService } from '../localization.service';

export class HttpBaseService {
    constructor(
        private httpClient: HttpClient,
        private controllerName: string,
        private notifyService: NotifyService,
        private localizationServ: LocalizationService, // !!! localizationService gerekli
        private configurationService: ConfigurationService

    ) { }

    get url() {
        return (this.configurationService.config != undefined) ? this.configurationService.config.serviceUrl : undefined;
    }

    get apiPath() {
        return `${this.url}/${this.controllerName}`;
    }

    public postAll<T>(methodName: string, item: T[], includeData: boolean = false): Observable<any> {
        let that = this;
        return this.httpClient
            .post(`${this.apiPath}/${methodName}`, item)
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res, includeData)));
    }

    public post<T>(methodName: string, item: T, includeData: boolean = false,showNotify:boolean=true): Observable<any> {
        let that = this;
        return this.httpClient
            .post(`${this.apiPath}/${methodName}`, item)
            .pipe(catchError(err => (this.handleError(err, that,showNotify))))
            .pipe(map(res => this.extractData(res, includeData)));
    }

    public postWithoutSpinner<T>(methodName: string, item: T, includeData: boolean = false): Observable<any> {
        let that = this;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('hideSpinner', 'true');
        const requestOptions = {
            headers: headers,
          };
        return this.httpClient
            .post(`${this.apiPath}/${methodName}`, item,requestOptions)
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res, includeData)));
    }

    getWithoutSpinner<T>(query: string): Observable<T> {
        let that = this;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('hideSpinner', 'true');
        const requestOptions = {
            headers: headers,
          };
        return this.httpClient
            .get<T>(`${this.apiPath}/` + query,requestOptions)
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res)));
    }


    getWithParamsWithoutSpinner<T>(query: string, params: HttpParams): Observable<T> {
        let that = this;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('hideSpinner', 'true');
        const requestOptions = {
            headers: headers,
            params:params
          };
        return this.httpClient
            .get<T>(`${this.apiPath}/` + query, requestOptions)
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res)));
    }

    getAllWithParams<T>(query: string, params: HttpParams): Observable<T[]> {
        let that = this;
        return this.httpClient
            .get<T>(`${this.apiPath}/` + query, { params })
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res)));
    }

    getAll<T>(query: string): Observable<T[]> {
        let that = this;
        return this.httpClient
            .get<T>(`${this.apiPath}/` + query)
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res)));
    }

    get<T>(query: string): Observable<T> {
        let that = this;
        return this.httpClient
            .get<T>(`${this.apiPath}/` + query)
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res)));
    }

    getWithParams<T>(query: string, params: HttpParams): Observable<T> {
        let that = this;
        return this.httpClient
            .get<T>(`${this.apiPath}/` + query, { params })
            .pipe(catchError(err => (this.handleError(err, that))))
            .pipe(map(res => this.extractData(res)));
    }


    extractData(res: any, includeData: boolean = false): any {
        let that = this;
        if (res.message) {
            that.notifyService.notifySuccess(res.isResourceKey ? this.localizationServ.translateText(res.message) : res.message); // !!! localizationService dil desteği gerekli

            if (!includeData) return res.success;
        }
        return res["data"];
    }

    getApiUrl(methodName: string): string { return this.apiPath + "/" + methodName; }
    handleError(error, that,showNotify:boolean=true) {
        if(showNotify)
            that.notifyService.notifyError(error);
        return throwError(() => error);
    }

    notifyError(message) {
        this.notifyService.notifyError(message);
    }
}
