import { LocalizationService } from '@app/core';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AppMenuService } from './app.menu.service';
import { LayoutService } from './service/app.layout.service';
import { sideNavMenuKey } from '@app/shared/common/constants';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    constructor(
        public layoutService: LayoutService,
        public appMenuService: AppMenuService,
        public localizationService: LocalizationService) { }
    ngOnInit() {
        const menuList$ = this.appMenuService.getMenuListByAuth();
        const savedMenu = localStorage.getItem(sideNavMenuKey);
        if (savedMenu) {
        this.model = JSON.parse(savedMenu);
        }
        else {
        menuList$.subscribe((data) => {
            this.model = this.appMenuService.mapSourceMenuListToDestinationMenu(data);
            localStorage.setItem(sideNavMenuKey, JSON.stringify(this.model));
        })
        }
    }

}
