import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core';
import { ApplicantPdfReportTypeEnum, ESuggestedPackageType } from '@app/shared/common/enums';
import { ApplicationService } from '@app/vinter/services/application.service';
import { PositionService } from '@app/vinter/services/position.service';

@Component({
  selector: 'app-applicant-header',
  templateUrl: './applicant-header.component.html',
  styleUrls: ['./applicant-header.component.scss']
})
export class ApplicantHeaderComponent implements OnInit {

  @Input() applicationId: string;
  suggestSidebarVisible = false;
  pdfDownloadOverlayVisible = false;
  downloadPdfReport = false;
  isLocked: boolean;
  pdfReportType: number = 0;

  positionTitle="";
  positionId=""
  responsibleUserFirstName: string;
  responsibleUserLastName: string;
  responsibleUserImageUrl: string;
  organizationImageUrl: string;
  organizationName: string;

  ApplicantPdfReportTypeEnum = ApplicantPdfReportTypeEnum;
  suggestedPackageType = ESuggestedPackageType.AIReport;

  showPendingAIReportBtn:boolean=false;
  isShowLicensePlanPopup: boolean
  showRequestAIReportBtn:boolean=false;
  showDownloadAIReportBtn:boolean=false;
  showAiReportAddedNewStepInfo:boolean=false;
  showAiReportPendingInfo:boolean=false;
  isAutomationLicenseValid: boolean;
  isAIReportLicenseValid = false;
  isAIReportLicensePending = false;

  aiReportData:any=null;
  applicationStepsData: any[] = [];

  constructor(private router: Router,
    private positionService: PositionService,
    private renderer: Renderer2,
    private credentialsService: CredentialsService,
    public applicationService:ApplicationService) {
  }

  ngOnInit(): void {
    this.isAIReportLicenseValid = this.credentialsService.getIsExistAIReportLicense();
    this.isAIReportLicensePending = this.credentialsService.getIsAIReportLicensePending();
    this.getPositionIdNameInfoByApplication(this.applicationId);
    this.isLocked = this.credentialsService.getIsLocked();
    this.getAIBasedStepReportButtonStatus();
  }

  getPositionIdNameInfoByApplication(applicationId){
    this.positionService.getPositionIdNameInfoByApplication({applicationId}).subscribe(data=>{
      this.positionTitle = data.positionTitle;
      this.positionId = data.positionId;
      this.responsibleUserFirstName = data.responsibleUserFirstName;
      this.responsibleUserLastName = data.responsibleUserLastName;
      this.responsibleUserImageUrl = data.responsibleUserImageUrl;
      this.organizationImageUrl = data.organizationImageURL;
      this.organizationName = data.organizationName;
    })
  }

  onButtonClick() {
    this.router.navigate(['/position/detail', this.positionId]);
  }

  suggestApplicant(){
    this.suggestSidebarVisible = !this.suggestSidebarVisible;
  }

  openLicensePlanPopup(){
    if(this.isAIReportLicensePending){
      this.router.navigate(['organization/settings'], {
        queryParams: {
          activeTab: 3,
        }
      });
    }
    else{
      this.isShowLicensePlanPopup = !this.isShowLicensePlanPopup;
    }
  }
  
  pdfDownload(){
    this.pdfDownloadOverlayVisible = !this.pdfDownloadOverlayVisible;
  }

  onBeforeShowHandler(event) {
    let parentElement = event.element.parentElement;
    const computedStyle = window.getComputedStyle(parentElement);
    const currentLeft = parseInt(computedStyle.left, 10);
    this.renderer.setStyle(parentElement, 'left', `${currentLeft - 50}px`);
  }

  checkLicenseLockAndGenerateDetailPdf(){
      this.pdfReportType = this.ApplicantPdfReportTypeEnum.DetailPdf;
      this.downloadPdfReport = true;
  }

  checkLicenseLockAndGenerateSummaryPdf(){
      this.pdfReportType = this.ApplicantPdfReportTypeEnum.SummaryPdf;
      this.downloadPdfReport = true;
  }

  getApplicationStepData(event : any[]){
    this.applicationStepsData = event;
    if(this.applicationStepsData.length > 0){
      this.applicationService.GetAIBasedStepReport({ id: this.applicationId }).subscribe(reportData=>{

        if(!reportData.isNewStepReportAdded){
          this.aiReportData=reportData;

          for (let index = 0; index < this.applicationStepsData.length; index++) {
            let r=reportData?.steps?.find(x=>x.applicationStepId==this.applicationStepsData[index].id)
            if(r){
              this.applicationStepsData[index].reportText=r.reportText;
              this.applicationStepsData[index].currentOrder=r.currentOrder;
              this.applicationStepsData[index].currentApplicantCount=r.currentApplicantCount;
            }
            else{
              this.applicationStepsData[index].reportText=null;
              this.applicationStepsData[index].currentOrder=null;
              this.applicationStepsData[index].currentApplicantCount=null;
            }
          }

        }
        else{
          this.aiReportData=null;
          this.toggleShowAiReportAddedNewStepInfo();
        }


        this.getAIBasedStepReportButtonStatus();
      });
    }
  }

  checkLicenseLockAndDownloadAIBasedPdf(){
    this.pdfReportType = this.ApplicantPdfReportTypeEnum.AIPdf;
    this.downloadPdfReport = true;
  }

  checkLicenseLockAndRequestAIBasedPdf(){
    this.applicationService.AIBasedReportRequest({ id: this.applicationId }).subscribe(x=>{
      this.toggleShowAiReportPendingInfo();
      this.getAIBasedStepReportButtonStatus();
    })
  }

  toggleShowAiReportAddedNewStepInfo(){
    this.showAiReportAddedNewStepInfo=!this.showAiReportAddedNewStepInfo;
  }

  toggleShowAiReportPendingInfo(){
    this.showAiReportPendingInfo=!this.showAiReportPendingInfo;
  }

  getAIBasedStepReportButtonStatus(){
    this.applicationService.GetAIBasedStepReportButtonStatus({ id: this.applicationId }).subscribe(x=>{
      this.showPendingAIReportBtn=x.showPending;
      this.showRequestAIReportBtn=x.showRequest;
      this.showDownloadAIReportBtn=x.showDownload;
    })
  }


}
