<div *ngIf="(!isGeneralEnglishTest) && (filteredStepInfo?.questionCollectionResult?.subCategory != this.questionCollectionSubCategory.QuickEnglish)" class="grid flex mx-0 languageStepAvgScoreDiv pt-2">
  <div *ngFor="let score of scoreList"
    class="md:col sm:col-4 col-6 languageStepAvgScoreElementDiv border {{score.key + 'BorderColor'}}">
    <img [src]="'assets/vinter/icons/pdfReport/' + score.icon + 'CategoryColor.svg'" width="28px" height="28px">
    <div class="progress">
      <div class="progress-bar" [style.width]="scores[score.key] + '%'" [style.backgroundColor]="score.color"></div>
    </div>
    <div [class]="score.key + 'TextColor w-5rem font-semibold'">
      % {{scores[score.key]?.toFixed(0)}}
    </div>
  </div>
</div>

<div *ngIf="isGeneralEnglishTest" class="grid flex mx-0 languageStepAvgScoreDiv">
  <div *ngFor="let score of scoreList"
    class="lg:col md:col-6 languageStepAvgScoreElementDiv border {{score.iconKey + 'BorderColor'}}">
    <div *ngIf="score.key !== 'generalEnglishGrammerVocabulary'" class="flex" style="margin-left: -0.1rem;">
      <img [src]="'assets/vinter/icons/pdfReport/' + score.icon" width="28px" height="28px">
    </div>
    <div *ngIf="score.key === 'generalEnglishGrammerVocabulary'" class="flex" style="margin-left: -0.1rem;">
      <img src="assets/vinter/icons/pdfReport/grammerCategoryColor.svg" width="28px" height="28px">
      <img src="assets/vinter/icons/pdfReport/vocabularyCategoryColor.svg" width="28px" height="28px"
        style="margin-left: -0.4rem;">
    </div>
    <div class="progress mx-0">
      <div class="progress-bar" [style.width]="scores[score.key] + '%'" [style.backgroundColor]="score.color"></div>
    </div>
    <div class="min-w-max flex justify-content-between align-items-center mr-2">
      <div *ngIf="filteredStepInfo.generalEnglishTestResult?.[score.levelKey] ?? false"
        class="font-semibold {{score.iconKey + 'TextColor'}}">
        {{filteredStepInfo.generalEnglishTestResult?.[score.levelKey]}} /
      </div>
      <div class="font-semibold scoreTextColor">
        {{scores[score.key]?.toFixed(0)}}
      </div>
    </div>
  </div>
</div>

 
<div class="w-full flex p-0 mx-2 py-2">
  <div *ngIf="stepInfo?.questionCollectionResult?.subCategory !== questionCollectionSubCategory.GeneralEnglish"
    class="xl:col-6 lg:col-5 md:col-8 p-0 flex flex-column">
    <span class="custom-letter-spacing text-gray-800 font-semibold text-xs pb-2 ">{{"Key.Questions" |
      translate}}</span>
    <div class="flex flex-row">

      <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Writing]">
        <app-custom-chip 
          class="w-3rem cursor-pointer custom-chip-custom-width" 
          [svgUrl]="selectedFilters.includes(languageQuestionCategories.Writing) 
                      ? 'assets/vinter/icons/writing-icon-circled-selected.svg' 
                      : 'assets/vinter/icons/writing-icon-circled.svg'"
          [alt]="'Writing'" 
          [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Writing) 
                            ? 'selected-bg-class' 
                            : 'bg-transparent'" 
          [imgSize]="'23px'" 
          (click)="filterListByAnswerType(languageQuestionCategories.Writing)">
        </app-custom-chip>
      </div>
    
      <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Listening]">
        <app-custom-chip 
          class="w-3rem cursor-pointer custom-chip-custom-width" 
          [svgUrl]="selectedFilters.includes(languageQuestionCategories.Listening) 
                      ? 'assets/vinter/icons/listening-icon-circled-selected.svg' 
                      : 'assets/vinter/icons/listening-icon-circled.svg'"
          [alt]="'Listening'" 
          [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Listening) 
                            ? 'selected-bg-class' 
                            : 'bg-transparent'" 
          [imgSize]="'23px'" 
          (click)="filterListByAnswerType(languageQuestionCategories.Listening)">
        </app-custom-chip>
      </div>
    
      <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Reading]">
        <app-custom-chip 
          class="w-3rem cursor-pointer custom-chip-custom-width" 
          [svgUrl]="selectedFilters.includes(languageQuestionCategories.Reading) 
                      ? 'assets/vinter/icons/reading-icon-circled-selected.svg' 
                      : 'assets/vinter/icons/reading-icon-circled.svg'"
          [alt]="'Reading'" 
          [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Reading) 
                            ? 'selected-bg-class' 
                            : 'bg-transparent'" 
          [imgSize]="'23px'" 
          (click)="filterListByAnswerType(languageQuestionCategories.Reading)">
        </app-custom-chip>
      </div>
    
      
      <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Speaking]">
        <app-custom-chip 
          class="w-3rem cursor-pointer custom-chip-custom-width" 
          [svgUrl]="selectedFilters.includes(languageQuestionCategories.Speaking) 
                      ? 'assets/vinter/icons/speaking-icon-circled-selected.svg' 
                      : 'assets/vinter/icons/speaking-icon-circled.svg'"
          [alt]="'Speaking'" 
          [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Speaking) 
                            ? 'selected-bg-class' 
                            : 'bg-transparent'" 
          [imgSize]="'23px'" 
          (click)="filterListByAnswerType(languageQuestionCategories.Speaking)">
        </app-custom-chip>
      </div>

      <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Vocabulary]">
        <app-custom-chip 
          class="w-3rem cursor-pointer custom-chip-custom-width" 
          [svgUrl]="selectedFilters.includes(languageQuestionCategories.Vocabulary) 
                      ? 'assets/vinter/icons/vocabulary-icon-circled-selected.svg' 
                      : 'assets/vinter/icons/vocabulary-icon-circled.svg'"
          [alt]="'Vocabulary'" 
          [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Vocabulary) 
                            ? 'selected-bg-class' 
                            : 'bg-transparent'" 
          [imgSize]="'23px'" 
          (click)="filterListByAnswerType(languageQuestionCategories.Vocabulary)">
        </app-custom-chip>
      </div>
    
      <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Grammer]">
        <app-custom-chip 
          class="w-3rem cursor-pointer custom-chip-custom-width" 
          [svgUrl]="selectedFilters.includes(languageQuestionCategories.Grammer) 
                      ? 'assets/vinter/icons/grammer-icon-circled-selected.svg' 
                      : 'assets/vinter/icons/grammer-icon-circled.svg'"
          [alt]="'Grammer'" 
          [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Grammer) 
                            ? 'selected-bg-class' 
                            : 'bg-transparent'" 
          [imgSize]="'23px'" 
          (click)="filterListByAnswerType(languageQuestionCategories.Grammer)">
        </app-custom-chip>
      </div>
    
    </div>
    
  </div>

  <div *ngIf="stepInfo?.questionCollectionResult?.subCategory !== questionCollectionSubCategory.GeneralEnglish"
    class="xl:col-6 lg:col-7 md:col-4 p-0 justify-content-end flex align-items-center">
    <div *ngIf="stepInfo?.questionCollectionResult?.subCategory !== questionCollectionSubCategory.QuickEnglish">
      <app-vinter-score-card [isWaitingForAI]="isWaitingForAI" [score]="stepInfo?.averageVinterScore" [labelKey]="'StepVinterScore'" class="justify-content-center flex"></app-vinter-score-card>
    </div>
    <div *ngIf="stepInfo?.questionCollectionResult?.subCategory === questionCollectionSubCategory.QuickEnglish">
      <app-vinter-score-card [isWaitingForAI]="isWaitingForAI" [score]="stepInfo?.averageVinterScore" [englishLevel]="getQuickEnglishLevel(stepInfo?.averageVinterScore)"  [labelKey]="'StepVinterScore'" class="justify-content-center flex"></app-vinter-score-card>
    </div>
  </div>

  <div *ngIf="stepInfo?.questionCollectionResult?.subCategory === questionCollectionSubCategory.GeneralEnglish"
  class="xl:col-6 lg:col-5 md:col-8 p-0 flex flex-column">
  <span class="custom-letter-spacing text-gray-800 font-semibold text-xs pb-2 ">{{"Key.Questions" |
    translate}}</span>
  <div class="flex flex-row">

    <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Writing]">
      <app-custom-chip 
        class="w-3rem cursor-pointer custom-chip-custom-width" 
        [svgUrl]="selectedFilters.includes(languageQuestionCategories.Writing) 
                    ? 'assets/vinter/icons/writing-icon-circled-selected.svg' 
                    : 'assets/vinter/icons/writing-icon-circled.svg'"
        [alt]="'Writing'" 
        [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Writing) 
                          ? 'selected-bg-class' 
                          : 'bg-transparent'" 
        [imgSize]="'23px'" 
        (click)="filterListByAnswerType(languageQuestionCategories.Writing)">
      </app-custom-chip>
    </div>

    <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Listening]">
      <app-custom-chip 
        class="w-3rem cursor-pointer custom-chip-custom-width" 
        [svgUrl]="selectedFilters.includes(languageQuestionCategories.Listening) 
                    ? 'assets/vinter/icons/listening-icon-circled-selected.svg' 
                    : 'assets/vinter/icons/listening-icon-circled.svg'"
        [alt]="'Listening'" 
        [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Listening) 
                          ? 'selected-bg-class' 
                          : 'bg-transparent'" 
        [imgSize]="'23px'" 
        (click)="filterListByAnswerType(languageQuestionCategories.Listening)">
      </app-custom-chip>
    </div>

    <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Reading]">
      <app-custom-chip 
        class="w-3rem cursor-pointer custom-chip-custom-width" 
        [svgUrl]="selectedFilters.includes(languageQuestionCategories.Reading) 
                    ? 'assets/vinter/icons/reading-icon-circled-selected.svg' 
                    : 'assets/vinter/icons/reading-icon-circled.svg'"
        [alt]="'Reading'" 
        [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Reading) 
                          ? 'selected-bg-class' 
                          : 'bg-transparent'" 
        [imgSize]="'23px'" 
        (click)="filterListByAnswerType(languageQuestionCategories.Reading)">
      </app-custom-chip>
    </div>

    <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Speaking]">
      <app-custom-chip 
        class="w-3rem cursor-pointer custom-chip-custom-width" 
        [svgUrl]="selectedFilters.includes(languageQuestionCategories.Speaking) 
                    ? 'assets/vinter/icons/speaking-icon-circled-selected.svg' 
                    : 'assets/vinter/icons/speaking-icon-circled.svg'"
        [alt]="'Speaking'" 
        [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Speaking) 
                          ? 'selected-bg-class' 
                          : 'bg-transparent'" 
        [imgSize]="'23px'" 
        (click)="filterListByAnswerType(languageQuestionCategories.Speaking)">
      </app-custom-chip>
    </div>

    <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Vocabulary]">
      <app-custom-chip 
        class="w-3rem cursor-pointer custom-chip-custom-width" 
        [svgUrl]="selectedFilters.includes(languageQuestionCategories.Vocabulary) 
                    ? 'assets/vinter/icons/vocabulary-icon-circled-selected.svg' 
                    : 'assets/vinter/icons/vocabulary-icon-circled.svg'"
        [alt]="'Vocabulary'" 
        [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Vocabulary) 
                          ? 'selected-bg-class' 
                          : 'bg-transparent'" 
        [imgSize]="'23px'" 
        (click)="filterListByAnswerType(languageQuestionCategories.Vocabulary)">
      </app-custom-chip>
    </div>

    <div *ngIf="categoryValidation !=null && categoryValidation[languageQuestionCategories.Grammer]">
      <app-custom-chip 
        class="w-3rem cursor-pointer custom-chip-custom-width" 
        [svgUrl]="selectedFilters.includes(languageQuestionCategories.Grammer) 
                    ? 'assets/vinter/icons/grammer-icon-circled-selected.svg' 
                    : 'assets/vinter/icons/grammer-icon-circled.svg'"
        [alt]="'Grammer'" 
        [bgColorClass]="selectedFilters.includes(languageQuestionCategories.Grammer) 
                          ? 'selected-bg-class' 
                          : 'bg-transparent'" 
        [imgSize]="'23px'" 
        (click)="filterListByAnswerType(languageQuestionCategories.Grammer)">
      </app-custom-chip>
    </div>

  </div>
</div>

  <div *ngIf="stepInfo?.questionCollectionResult?.subCategory === questionCollectionSubCategory.GeneralEnglish"
    class="xl:col-6 lg:col-7 md:col-4 p-0 justify-content-end flex align-items-center">
    <app-vinter-score-card [isWaitingForAI]="isWaitingForAI" [score]="stepInfo?.averageVinterScore"
      [englishLevel]="stepInfo?.generalEnglishTestResult?.generalLevel" [labelKey]="'StepVinterScore'"
      class="justify-content-center flex"></app-vinter-score-card>
  </div>

</div>

<div *ngIf="filteredStepInfo ?? false" class="w-full p-0 height-fit">
  <app-step-question-result-list
    [questionList]="filteredStepInfo?.questionCollectionResult"></app-step-question-result-list>
</div>
