<img *ngIf="!(isMultipleFaceDetected === null && isFaceDetected === null && isImproprietySpeech === null)" class="ml-2" src="assets/vinter/icons/question-behaiviour-warning.svg" width="18px" height="18px" (mouseover)="op.show($event)" (mouseout)="op.hide()">

<p-overlayPanel #op styleClass="overlay-beh-applicant-detail" >

    <div class="min-w-10rem">

        <div *ngIf="isSwitchedTab" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/cheating-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold switch-text-color">{{"Key.SwitchedTabDetect" | translate }}</p>
            </div>
        </div>
        <div *ngIf="isMultipleFaceDetected" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/multiface-detected-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold multipleFace-text-color">{{"Key.MultipleFaceDetect" | translate }}</p>
            </div>
        </div>
        <div *ngIf="!faceDetectionStatus" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/face-detection-status-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold multipleFace-text-color">{{"Key.FaceDetectionStatus" | translate }}</p>
            </div>
        </div>
        <div *ngIf="isFaceDetected" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/face-detection-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold faceDetecht-text-color">{{"Key.DifferentPersonDetect" | translate }}</p>
            </div>
        </div>
        <div *ngIf="isImproprietySpeech" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/impropriety-speech-warning.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold impropriety-text-color">{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
            </div>
        </div>
    </div>

</p-overlayPanel>
