<div *ngIf="isAIPdfOpen" class="hidden" id="parentAIReportDivId" >
    <div id="applicantAIBasedPdf" class="pdf-width h-auto">
        <div id="info">
            <div #pdfCoverPage class="report relative overflow-hidden">
                <div class="absolute top-0 left-0 w-full h-full">
                    <img src="../../../../../assets/vinter/icons/pdfAiReport/background.svg" alt="Gradient Background" class="w-full">
                    <div class="blue-section absolute bg-blue-600 pdf-width">
                        <div class="position justify-content-center">
                            <h3 class="text-blue-400 font-semibold mt-0" >{{'Key.PositionNameTitle' | translate}} </h3>
                            <h1 class="font-bold mt-0 text-2xl text-white">{{positionTitle}}</h1>
                        </div>
                    </div>
                    <div class="report-name-section absolute bg-gray-900 bottom-0 pdf-width">
                        <div class="report-footer flex align-items-center justify-content-center">
                            <div class="line1 bg-blue-600"></div>
                            <h3 class="mx-2 my-0 text-blue-300 text-lg">{{'Key.AIReportName' | translate}} </h3>
                            <div class="line2 bg-blue-600"></div>
                        </div>
                    </div>
                </div>

                <div class="content absolute bg-white text-gray-900 inline-flex flex-column">
                    <div class="header flex justify-content-center">
                        <img class="align-items-center" [src]="organizationLogo" alt="Vinter Logo" class="logo">
                    </div>
                    <div class="candidate-info inline-flex flex-column align-items-start h-auto sticky pt-6 pl-0">
                        <h2 class="m-0 text-gray-800 font-bold applicant-name">{{applicant.firstName + " " + applicant.lastName}}</h2>
                        <div *ngIf="isCompletedPosition" class="flex">     
                            <div class="flex align-items-center completed-position-container completed-text-color" >
                                <p class="completed-text completed-text-color text-center font-semibold flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                            </div>
                            <p  class="completed-date-text text-gray-700 text-center font-medium -mb-2 mt-2 ml-2">{{stepCompletedDate | date:'dd/MM/yyyy'}}</p>
                        </div>

                        <hr class="custom-hr m-0 my-3 -mb-2">

                        <span class="text-blue-700 font-medium -mt-3">{{'Key.Score' | translate}}</span>
                        <div class="flex align-items-center">
                            <div class="progress-container border-round-lg mr-2">
                                <div class="progress-bar border-round-md"
                                    [style.width.%]="totalScore !== 0 ? totalScore : 2"
                                    [style.background-color]="getProgressBarColor(totalScore)"></div>
                            </div>
                            <span class="flex font-bold text-gray-900 align-items-baseline" style="width: 45px;">
                                <span class="big">{{getIntegerPart(totalScore) }}</span>
                                <span class="text-sm">.{{ getDecimalPart(totalScore) }}</span>
                            </span>
                        </div>

                        <div class="contact-info">
                            <div class="label text-gray-700 flex align-items-center">
                                <img src="assets/vinter/icons/pdf-mail.svg" class="my-2 w-1rem h-1rem">
                                <div class="text-justify my-2 ml-2">
                                    {{applicant.email}}
                                </div>
                            </div>
                            <div  class="label text-gray-700 flex align-items-center">
                                <img src="assets/vinter/icons/pdf-phone.svg" class="my-2 w-1rem h-1rem">
                                <div class="text-justify my-2">
                                    {{applicant.phone}}
                                </div>
                            </div>
                            <div *ngIf="applicant?.userProfile?.linkedinProfileURL ?? false"  class="label text-gray-700 flex align-items-center">
                                <img src="assets/vinter/icons/pdf-linkedin.svg" class="my-2 w-1rem h-1rem">
                                <div class="text-justify my-2">
                                    {{applicant?.userProfile?.linkedinProfileURL}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div #area class="avoid page-height">
                <header class="report-header">
                    <div class="header-left">
                        <span class="ml-3">{{organizationName}}</span>
                        <span class="product-manager">{{positionTitle}}</span>
                        <span class="al-raporu">{{'Key.AIReport' | translate}}</span>
                        <span class="name"> - {{applicant.firstName + " " + applicant.lastName}}</span>
                    </div>
                    <div class="header-line-1">

                    </div>
                    <div class="header-logo mx-2">
                        <img src="../../../../../assets/vinter/icons/pdfAiReport/vinter-header-logo.svg" alt="Vinter Logo" />
                    </div>
                    <div class="header-line-2">

                    </div>
                </header>

                <div class="mt-5 mx-6 text-justify pdf-text-size page-content-height">
                    <div class="flex">
                        <h5 class="font-bold text-blue-500">»</h5> 
                        <h5 class="font-bold text-gray-900 m-0 ml-2">{{'Key.Contents' | translate}}</h5>
                    </div>

                    <div class="">
                        <div class="flex justify-content-between mb-2">
                            <span class="text-gray-900 text-justify font-medium text-xs">{{'Key.ApplicantRate' | translate}}</span>
                            <span class="dots"></span>
                            <span class="text-gray-900 text-justify font-medium text-xs">1</span>
                        </div>

                        <div *ngFor="let step of applicationStepsData; let i = index">
                            <div class="flex justify-content-between mb-2">
                                <span class="text-gray-900 text-justify font-medium text-xs">{{step.positionStep.title}}</span>
                                <span class="dots"></span>
                                <span class="text-gray-900 text-justify font-medium text-xs">{{i+2}}</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div #area class="avoid page-height">
                <header class="report-header">
                    <div class="header-left">
                        <span class="ml-3">{{organizationName}}</span>
                        <span class="product-manager">{{positionTitle}}</span>
                        <span class="al-raporu">{{'Key.AIReport' | translate}}</span>
                        <span class="name"> - {{applicant.firstName + " " + applicant.lastName}}</span>
                    </div>
                    <div class="header-line-1">

                    </div>
                    <div class="header-logo mx-2">
                        <img src="../../../../../assets/vinter/icons/pdfAiReport/vinter-header-logo.svg" alt="Vinter Logo" />
                    </div>
                    <div class="header-line-2">

                    </div>
                </header>

                <div class="mt-5 mx-6 text-justify pdf-text-size page-content-height">
                    <div class="flex">
                        <h5 class="font-bold text-blue-500">»</h5> 
                        <h5 class="font-bold text-gray-900 m-0 ml-2">{{'Key.ApplicantRate' | translate}}</h5>
                    </div>

                    <div class="flex align-items-center mb-3">
                        <div class="flex align-items-center">
                            <div class="progress-container border-round-lg mr-2">
                                <div class="progress-bar border-round-md"
                                    [style.width.%]="totalScore !== 0 ? totalScore : 2"
                                    [style.background-color]="getProgressBarColor(totalScore)"></div>
                            </div>
                        </div>
                        <span class="flex font-bold text-gray-900 align-items-baseline" style="width: 45px;">
                            <span class="big">{{getIntegerPart(totalScore) }}</span>
                            <span class="text-sm">.{{ getDecimalPart(totalScore) }}</span>
                        </span>
                    </div>

                    <p class="mb-3 text-gray-800 text-justify text-xs ">
                        {{aiReportData.applicationReportText}}
                    </p>
                </div>

                <footer class="report-footer-2">
                    <div class="footer-middle">
                        <img src='{{organizationLogo}}' alt="Koç Logo" class="organization-logo">
                        <div class="footer-line"> </div>
                        <div class="footer-right">
                            <span class="page-number">1</span>
                        </div>
                    </div>
                </footer>
            </div>

            <div class="applicant-pdf-container bg-white">
                <div class="flex justify-content-between flex-wrap">
                    <div class="report-container">                   
                        <main class="">
                            <div *ngFor="let step of applicationStepsData; let i = index" class="step-card-container avoid page-break" #area >
                                <div class="page-min-height">
                                    <header class="report-header">
                                        <div class="header-left">
                                            <span class="ml-3">{{organizationName}}</span>
                                            <span class="product-manager">{{positionTitle}}</span>
                                            <span class="al-raporu">{{'Key.AIReport' | translate}}</span>
                                            <span class="name"> - {{applicant.firstName + " " + applicant.lastName}}</span>
                                        </div>
                                        <div class="header-line-1">
            
                                        </div>
                                        <div class="header-logo mx-2">
                                            <img src="../../../../../assets/vinter/icons/pdfAiReport/vinter-header-logo.svg" alt="Vinter Logo" />
                                        </div>
                                        <div class="header-line-2">
            
                                        </div>
                                    </header>                          
    
                                    <div class="mt-5 mx-6">
                                        <div class="flex">
                                            <h5 class="font-bold text-blue-500">»</h5>
                                            <h5 class="font-bold text-gray-900 m-0 ml-2">{{step.positionStep.title}}</h5>
                                        </div>
    
                                        <div *ngIf="step.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest && step.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage">
                                            <div class="flex align-items-start">
                                                <div  class="chart-container">
                                                    <div *ngIf="step.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage">
                                                        <app-circle-chart [calculatedProgressValue]="step.calculatedProgressValue" [strokeDasharray]="step.strokeDasharray" [progressValue]="step.progressValue" [strokeColor]="step.strokeColor"></app-circle-chart>
                                                    </div>
                                                    
                                                </div>
                                                
                                                <div class="flex-1">
                                                    <p class="candidate-percentage">
                                                        {{'Key.ApplicantPercentage' | translate}}<span class="font-bold text-blue-500"> %{{applicantPercentage.toFixed(0)}}</span>
                                                    </p>
                                                    <div class="flex flex-column">
                                                        <p class="description-text" [innerHTML]="firstPartReportText"></p>
                                                            
                                                    </div>
                                                </div>
                                            </div>
    
                                            <p class="description-text" [innerHTML]="secondPartReportText"></p>
                                            
                                        </div>                  
                                    </div>  
                                    
                                    <div #area class="my-3 mx-6 page-Flex" [ngClass]="step.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage ? '':'avoid'" >
                                        <app-step-card [step]="step" [report]="getReportText(step.id)" [pageNumber]="i+1" [chartData]="chartData"
                                            [languageAnsweredQuestions]="languageAnsweredQuestions" [answeredQuestionsWithId]="answeredQuestionsWithId"></app-step-card>
                                    </div>
    
                                    <footer class="report-footer-2">
                                        <div class="footer-middle">
                                            <img src='{{organizationLogo}}' alt="Koç Logo" class="organization-logo">
                                            <div class="footer-line"> </div>
                                            <div class="footer-right">
                                                <span class="page-number">{{ i+2 }}</span>
                                            </div>
                                        </div>
                                    </footer>

                                </div>
                                
                            </div>
                    
                        </main>
                    
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
