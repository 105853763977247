import { Component, Input, OnInit } from '@angular/core';
import { CredentialsService } from '@app/core';
import { EQuestionCategories, EQuestionCollectionSubCategory, LanguageEnum, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { UserManagementListItem } from '@app/vinter/models/user-profile-list-item';
import * as html2pdf from 'html2pdf.js';


@Component({
  selector: 'app-summary-pdf-report',
  templateUrl: './summary-pdf-report.component.html',
  styleUrls: ['./summary-pdf-report.component.scss']
})
export class SummaryPdfReportComponent implements OnInit{

  @Input() positionTitle: string;
  @Input() applicant: UserManagementListItem;
  @Input() applicationStepsData;
  @Input() answeredQuestionsWithId;
  @Input() languageAnsweredQuestions;
  @Input() totalScore: number;

  isPdfOpen: boolean = false;
  isCompletedPosition: boolean = false;
  stepCompletedDate: Date;
  currentLanguageId:string;
  deviceTypes: any[];

  isSwitchedTab: boolean = false;
  isMultipleMonitor: boolean = false;
  isFaceDetectedLog: boolean = false;
  isMultipleFaceLog: boolean = false;
  isAllowedFaceDetectedLog: boolean = false;
  isAllowedImproprietySpeechLog: boolean = false;
  isAllowedMultipleFaceLog: boolean = false;
  isImproprietySpeechLog: boolean = false;


  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  QuestionCollectionSubCategory = EQuestionCollectionSubCategory;
  QuestionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  LanguageEnum = LanguageEnum;
  EQuestionCategories = EQuestionCategories;

  constructor(
    private credentialsService: CredentialsService,
    private appInfoService: AppInfoService,
  ) { }
  
  ngOnInit(): void {
    this.currentLanguageId = this.credentialsService.getLanguage();
    this.deviceTypes = this.appInfoService.getDeviceTypes();

    this.getCheatingAnalysis();
    this.getCompletedDatePosition();
    this.generateSummaryPDF();
  }

  generateSummaryPDF() {
    this.isPdfOpen = true;
    setTimeout(() => {
        const element = document.getElementById('applicantInfoSummaryPdf');
        const opt = {
        margin: [10, 10, 10, 10],
        filename: 'Summary_Report_' + this.applicant.firstName + " "+ this.applicant.lastName + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        pagebreak: { avoid: "#area", mode: "css" },
        html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true, allowTaint: true, useCORS: true },
        jsPDF: { unit: 'px', format: [595, 842], orientation: 'portrait' },
        };

        html2pdf().from(element).set(opt).save();
        this.isPdfOpen = false;
        
    }, 0);

  }

  getCompletedDatePosition(){
    const stepsWithId = this.applicationStepsData.filter(step => step.id);
    this.isCompletedPosition =  stepsWithId.every(step => step.completedDate !== null);

    if(this.isCompletedPosition){
      this.stepCompletedDate = stepsWithId[stepsWithId.length - 1].completedDate;
    }
  }

  getCurrentStepQuestions(id, stepId) {
    return this.languageAnsweredQuestions.find(x => x.id === id && x.positionStepId === stepId);
  }

  getCheatingAnalysis() {
    this.isSwitchedTab = this.applicationStepsData.some(x => x.isSwitchedTab === true);
    this.isAllowedMultipleFaceLog = this.applicationStepsData.some(x => x.isAllowedMultipleFaceLog);
    this.isAllowedFaceDetectedLog = this.applicationStepsData.some(x => x.isAllowedFaceDetectedLog); 
    this.isAllowedImproprietySpeechLog = this.applicationStepsData.some(x => x.isAllowedImproprietySpeechLog);
    this.isMultipleMonitor = this.applicationStepsData.some(x => x.isMultipleMonitor === true);
    this.isFaceDetectedLog = this.applicationStepsData.some(x => x.isFaceDetectedLog === true);
    this.isMultipleFaceLog = this.applicationStepsData.some(x => x.isMultipleFaceLog === true);
    this.isImproprietySpeechLog = this.applicationStepsData.some(x => x.isImproprietySpeechLog === true);
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

  getIntegerPart(score: number): string {
    let value = "00";
    if(score > 0)
      value = Math.floor(score).toString();
    return value;
  }

  getDecimalPart(score: number): string {
    const decimalPart = score.toFixed(2).split('.')[1];
    return decimalPart || '00'; 
  }

}
