import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileVisibilityService {

  private profileVisibility = new BehaviorSubject(1);
  profileVisibility$ = this.profileVisibility.asObservable();

  constructor() { }

  changeProfileVisibility(visibility: number) {
    this.profileVisibility.next(visibility)
  }
}
