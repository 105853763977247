import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
    AuthenticationService,
    ConfigurationService,
    CredentialsService,
    LocalizationService,
    NotifyService,
} from '@app/core';
import { LanguageEnum } from '@app/shared/common/enums';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {
    APPLICANT,
    ORGADMIN,
    VINTERADMIN,
    HR,
    flagENPath,
    flagTRPath,
    languageKey,
    vinterLogoWithoutTextPath,
    vinterPureLogoRotatedPath,
    mailKey,
    rememberKey,
} from '@app/shared/common/constants';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { LanguageService } from '@app/shared/services/language.service';
import { lastValueFrom } from 'rxjs';
import { Language, RememberContext } from '@app/shared/common/interfaces';
import { SpinnerHandlerService } from '../loader/spinner-handler.service';
import { MessageService } from 'primeng/api';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MessageService],
})
export class LoginComponent implements OnInit {
    vinterLogoWithoutTextPath: string = vinterLogoWithoutTextPath;
    vinterPureLogoRotatedPath: string = vinterPureLogoRotatedPath;
    flagTRPath: string = flagTRPath;
    flagENPath: string = flagENPath;
    password: string;
    mail: string;
    encryptedInvitationID: string;
    remember: boolean;
    language: string;
    loadingVisible: boolean = false;
    languageDS: Language[] = [];
    selectedLanguage: Language = {};
    fromPageLoad: boolean;
    formatMessage: any;
    isSmallScreen: boolean = false;
    stateOptions: any[];
    isTurkishSelected: boolean = true;
    form: FormGroup;
    rememberValues: RememberContext;
    isRecaptchaResolved: boolean = false;
    resolvedRecaptcha: any;
    recaptchaKey: string = '';
    isRecaptchaActive = true;
    @ViewChild('invisible') invisibleCaptcha: any;
    @ViewChild('captchaRef') captchaElem: RecaptchaComponent;
    isLoginAction = false;
    isFirstLogin = false;
    constructor(
        public appInfo: AppInfoService,
        private router: Router,
        private route: ActivatedRoute,
        private configService: ConfigurationService,
        private authenticationService: AuthenticationService,
        private languageService: LanguageService,
        private localizationService: LocalizationService,
        private notifyService: NotifyService,
        private spinner: SpinnerHandlerService,
        private messageService: MessageService,
        private credentialsService: CredentialsService
    ) {
        this.stateOptions = [
            { label: 'Türkçe', value: 'Türkçe' },
            { label: 'English', value: 'English' },
        ];
    }

    ngOnInit() {
        this.recaptchaKey = this.configService.config.reCaptchaKey;
        this.isRecaptchaActive = this.configService.config.isRecaptchaActive;

        this.isRecaptchaResolved = false;
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            this.mail = this.route.snapshot.queryParamMap.get('mail');
            this.encryptedInvitationID =
                this.route.snapshot.queryParamMap.get('invitationID');
            this.isFirstLogin =
                this.route.snapshot.queryParamMap.get('firstLogin') === 'true';
        });
        if (this.mail === null) {
            this.rememberValues = this.credentialsService.getRememberValues();
            if (this.rememberValues?.remember) {
                this.remember = this.rememberValues.remember;
                this.mail = this.rememberValues.mail;
            }
        }
        this.setFormGroup();

        lastValueFrom(this.languageService.getLanguagesFromApi()).then(
            (data) => {
                this.languageDS = data;
                this.fromPageLoad = true;
                let lastSelectedLanguage: string =
                    this.localizationService.langId;
                if (!lastSelectedLanguage || lastSelectedLanguage == '') {
                    this.selectedLanguage = this.languageDS.find(
                        (x) => x.id === LanguageEnum.TurkishTurkey
                    );
                } else {
                    this.selectedLanguage = this.languageDS.find(
                        (x) => x.id === lastSelectedLanguage
                    );
                    if (lastSelectedLanguage != LanguageEnum.TurkishTurkey) {
                        this.isTurkishSelected = false;
                    }
                }
                this.localizationService.initTranslate(null);
            }
        );
    }

    resolved(event) {
        if (this.isLoginAction === true) {
            if (this.form.valid) {
                this.resolvedRecaptcha = event;
                this.isRecaptchaResolved = true;
                this.doLogin();
            } else {
                this.isLoginAction = false;
                this.captchaElem.reset();
                this.isRecaptchaResolved = false;
                this.resolvedRecaptcha = null;
            }
        }
    }

    onLanguageToggle() {
        const langCode = this.isTurkishSelected ? 'tr-TR' : 'en-US';

        const newLanguage = this.languageDS.find(
            (x) => x.languageCode === langCode
        );
        this.languageOnSelectionChanged(newLanguage);
    }

    languageOnSelectionChanged(e) {
        localStorage.setItem(
            languageKey,
            JSON.stringify({ id: e.id, code: e.languageCode })
        );
        if (!this.fromPageLoad) {
            this.fromPageLoad = false;
        }
        this.localizationService.initTranslate(null);

        this.fromPageLoad = true;
    }

    setFormGroup() {
        this.form = new FormGroup({
            email: new FormControl(this.mail, {
                validators: [
                    Validators.required,
                    this.trimValidator,
                    Validators.pattern(
                        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
                    ),
                ],
                updateOn: 'change',
            }),
            password: new FormControl('', [
                Validators.required,
                this.trimValidator,
            ]),
            remember: new FormControl(this.remember),
        });
    }

    trimValidator: ValidatorFn = (control: FormControl) => {
        if (control.value !== null && control.value.startsWith(' ')) {
            return {
                error: { value: 'Key.StartsWithErr' },
            };
        }
        if (control.value !== null && control.value.endsWith(' ')) {
            return {
                error: { value: 'Key.EndsWithErr' },
            };
        }
        return null;
    };

    checkFormValidity(formControlName: string) {
        if (
            this.form.get(formControlName).touched &&
            this.form.get(formControlName).errors
        ) {
            return true;
        }
        return false;
    }

    getFormValidityMessage(formControlName: string) {
        if (
            this.form.get(formControlName).touched &&
            this.form.get(formControlName).errors?.['error']
        ) {
            return this.localizationService.translateTextWithoutKey(
                this.form.get(formControlName).errors?.['error'].value
            );
        } else if (
            this.form.get(formControlName).touched &&
            this.form.get(formControlName).errors?.['pattern']
        ) {
            return this.localizationService.translateText('InvalidEmailFormat');
        }
    }

    handleRememberChange(event: any) {
        if (event.checked && this.rememberValues?.remember)
            this.credentialsService.setRememberValues(
                this.form.controls['email'].value,
                this.form.controls['remember'].value
            );
        else this.credentialsService.setRememberValues();
    }

    checkRecaptchaAndLogin(captchaRef) {
        if (this.isRecaptchaActive) {
            this.isLoginAction = true;
            captchaRef.execute();
        } else {
            this.doLogin();
        }
    }

    doLogin() {
        if (this.form.invalid) return;
        if (!this.isRecaptchaResolved && this.isRecaptchaActive) return;
        if (this.isTurkishSelected) {
            this.selectedLanguage = this.languageDS.find(
                (x) => x.id === LanguageEnum.TurkishTurkey
            );
        } else {
            this.selectedLanguage = this.languageDS.find(
                (x) => x.id === LanguageEnum.EnglishUnitedStates
            );
        }
        this.onLanguageToggle();

        if (this.form.controls['remember'].value) {
            this.credentialsService.setRememberValues(
                this.form.controls['email'].value,
                this.form.controls['remember'].value
            );
        }

        const login$ = this.authenticationService.login({
            password: this.form.controls['password'].value,
            email: this.form.controls['email']?.value?.trim(),
            remember: this.form.controls['remember'].value,
            language: this.selectedLanguage.id,
            languageCode: this.selectedLanguage.languageCode,
            encryptedInvitationID: this.encryptedInvitationID,
            recaptchaKey: this.resolvedRecaptcha,
        });

        lastValueFrom(login$)
            .then((data) => {
                this.localizationService.langId = this.selectedLanguage.id;
                this.authenticationService.setCredentials(data);
                var userGroups = this.credentialsService
                    .getUserGroups()
                    .split(',');
                if (userGroups.some((s) => s.includes(APPLICANT))) {
                    if (this.mail == null)
                        this.router.navigate(
                            [
                                this.route.snapshot.queryParams.redirect ||
                                    'my-positions/list',
                            ],
                            { replaceUrl: true }
                        );
                    else
                        this.router.navigate(
                            [
                                this.route.snapshot.queryParams.redirect ||
                                    'my-positions/list',
                            ],
                            { replaceUrl: true, queryParams: { showDialog: 1 } }
                        );
                } else if (
                    userGroups.some((s) => s.includes(ORGADMIN)) ||
                    userGroups.some((s) => s.includes(HR))
                ) {
                    this.router.navigate(
                        [
                            this.route.snapshot.queryParams.redirect ||
                                'dashboard/hr-dashboard',
                        ],
                        {
                            replaceUrl: true,
                            queryParams: { firstLogin: this.isFirstLogin },
                        }
                    );
                } else if (userGroups.some((s) => s.includes(VINTERADMIN))) {
                    this.router.navigate(
                        [
                            this.route.snapshot.queryParams.redirect ||
                                'organization/spend',
                        ],
                        { replaceUrl: true }
                    );
                }
            })
            .catch((error) => {
                if (this.isRecaptchaActive) {
                    this.captchaElem.reset();
                    this.isRecaptchaResolved = false;
                    this.resolvedRecaptcha = null;
                    this.isLoginAction = false;
                }
                this.spinner.hide();
            });
    }
}
