import { Injectable } from '@angular/core';
import { HttpBaseService, NotifyService, LocalizationService, ConfigurationService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { ApiControllerEnum } from '../common/enums';
import { Observable } from 'rxjs';
import { AppCustomMenu, Menu } from '../common/interfaces';

@Injectable({ providedIn: 'root' })
export class MenuService extends HttpBaseService {
    private menuData : Menu[];

    constructor(
        private http: HttpClient
        , private notifyServ: NotifyService
        , private localizationService: LocalizationService
        , private configService: ConfigurationService
    ) {
        super(http, ApiControllerEnum.Menu, notifyServ, localizationService, configService);
    }

    //gets menu list from api for authorized user
    getMenuListByAuth(): Observable<any[]> {
        return this.getAll<any>("GetMenuListByAuth");
    }

    //#region Customize Menu For PrimeNg Standard Menu Model
    mapSourceMenuListToDestinationMenu(menuData : Menu[]){
        this.menuData = menuData;
        let menuItems=[];
        for (let index = 0; index < this.menuData.length; index++) {
            const m = this.menuData[index];
            if(m.topMenuID==null){
                const menu : AppCustomMenu = {};
                menu.menuID = m.menuID;
                menu.icon = m.icon;
                menu.label = m.menuName;
                if(m.route)
                {
                    menu.routerLink = [];
                    menu.routerLink.push(m.route);
                }
                let subMenu=this.setSubMenu(menu);
                if(subMenu!=null && subMenu.length>0){
                    menu.items=subMenu;
                }
                menuItems.push(menu);
            }
        }
        return menuItems;
    }

    private setSubMenu(parent:AppCustomMenu):AppCustomMenu[] {
        let menuItems=[];
        let filteredMenus=this.menuData.filter(m => m.topMenuID == parent.menuID);
        if(filteredMenus!=null && filteredMenus.length>0){
            for (let index = 0; index < filteredMenus.length; index++) {
                const m = filteredMenus[index];
                const menu : AppCustomMenu = {};
                menu.menuID = m.menuID;
                menu.icon = m.icon;
                menu.label = m.menuName;
                if(m.route)
                {
                    menu.routerLink = [];
                    menu.routerLink.push(m.route);
                }
                let subMenu=this.setSubMenu(menu);
                if(subMenu!=null && subMenu.length>0){
                    menu.items=subMenu;
                }
                menuItems.push(menu);
            }
        }
        return menuItems;
    } 
    //#endregion
}