import {Component, Input} from '@angular/core';
import { EQuestionCategories } from '@app/shared/common/enums';

@Component({
    selector: 'app-proficiency-test-card',
    templateUrl: './proficiency-test-card.component.html',
    styleUrls: ['./proficiency-test-card.component.scss']
})
export class ProficiencyTestCardComponent {
  @Input() step: any;
  @Input() report: any;
  @Input() pageNumber: number = 0;
  
  firstPartReportText: string = '';
  secondPartReportText: string = '';

  applicantPercentage: number = 0;

  spiderChartData: number[] = [];
  spiderChartLabels: string[] = ['Overall Score', 'L','R','V','G','W'];

  @Input() chartData: any;
  @Input() languageAnsweredQuestions: any;

  questionCategories = EQuestionCategories;
  skillLevel: any[] = [];


  ngOnInit() {

  }
  ngAfterViewInit(): void {
    this.splitReportText();
    this.calculatePercentage();
    let stepChartData = this.chartData.find(x => x.positionStepId === this.step.positionStepId);
    this.spiderChartData = [this.step.averageVinterScore, stepChartData.chartData[0], stepChartData.chartData[1], stepChartData.chartData[3], stepChartData.chartData[2], stepChartData.chartData[4]];
    this.setKnobChartValue();
  }

  calculatePercentage(){
    this.applicantPercentage = (this.report.currentOrder / this.report.currentApplicantCount ) * 100;
  }
    
  splitReportText() {
    const maxChars = 300;
    if (this.report.reportText.length > maxChars) {
        let splitIndex = this.report.reportText.lastIndexOf(' ', maxChars);
        if (splitIndex === -1) {
            splitIndex = maxChars;
        }
        this.firstPartReportText = this.report.reportText.slice(0, maxChars);
        this.secondPartReportText = this.report.reportText.slice(maxChars).trim();
    } 
    else {
        this.firstPartReportText = this.report.reportText;
        this.secondPartReportText = '';
    }
  }

  setKnobChartValue(){
    let writingLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Writing).averageScores;
    let readingLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Reading).averageScores;
    let listeningLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Listening).averageScores;
    let grammerLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Grammer).averageScores;
    let vocabLevel = this.languageAnsweredQuestions.find(x => x.id === this.questionCategories.Vocabulary).averageScores;

    this.skillLevel = [
        { id: this.questionCategories.Writing, color: 'pink', name: 'Writing', percentage: writingLevel },
        { id: this.questionCategories.Listening, color: 'purple', name: 'Listening', percentage: listeningLevel*100  },
        { id: this.questionCategories.Reading, color: 'dark-blue', name: 'Reading', percentage: readingLevel*100 },
        { id: this.questionCategories.Grammer, color: 'pink', name: 'Grammar', percentage: grammerLevel*100  },
        { id: this.questionCategories.Vocabulary, color: 'purple', name: 'Vocabulary', percentage: vocabLevel*100  }
    ];
}
}