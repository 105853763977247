<div class="logo"></div>
<div
    class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden background"
>
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
        <div class="col-12 xl:col-5">
            <div class="h-full w-full m-0 py-5 px-5 loginCard">
                <div class="w-full md:w-10 mx-auto">
                    <div class="header-container mb-4">
                        <div class="logo-container">
                            <div>
                                <img
                                    data-cy="image-logo"
                                    src="{{ vinterLogoWithoutTextPath }}"
                                    alt="Vinter logo"
                                    class="w-full"
                                />
                            </div>
                        </div>
                        <p-toggleButton
                            data-cy="button-language-toggle"
                            [(ngModel)]="isTurkishSelected"
                            onLabel="EN"
                            offLabel="TR"
                            (onChange)="onLanguageToggle()"
                        ></p-toggleButton>
                    </div>

                    <div class="mb-5">
                        <div
                            data-cy="label-page-title-login"
                            class="pageTitleLogin"
                        >
                            {{ "Key.Login" | translate }}
                        </div>
                        <p
                            data-cy="label-subtitle-text-login"
                            class="subTitleTextLogin"
                        >
                            {{ "Key.LoginText" | translate }}
                        </p>
                    </div>

                    <div class="flex flex-column">
                        <form [formGroup]="form" class="form">
                            <div class="mb-2">
                                <div class="flex mb-2">
                                    <label
                                        data-cy="label-email"
                                        class="text-title"
                                        >{{ "Key.Mail" | translate }}</label
                                    >
                                    <div class="error-message flex">
                                        <p
                                            data-cy="indicator-input-email-invalid"
                                            *ngIf="
                                                form.controls.email.touched &&
                                                form.controls.email.errors
                                                    ?.required
                                            "
                                        >
                                            *
                                        </p>
                                    </div>
                                </div>
                                <input
                                    data-cy="input-email"
                                    id="email1"
                                    formControlName="email"
                                    type="mail"
                                    pInputText
                                    class="w-full"
                                    (onchange)="checkFormValidity('email')"
                                    (keyup.enter)="
                                        isLoginAction = true;
                                        checkRecaptchaAndLogin(captchaRef)
                                    "
                                    style="padding: 1rem"
                                    placeholder="{{
                                        'Key.EnterMail' | translate
                                    }}"
                                    required
                                />
                                <div class="alert mt-2 min-height">
                                    <p
                                        data-cy="message-email-invalid"
                                        *ngIf="this.checkFormValidity('email')"
                                    >
                                        {{
                                            this.getFormValidityMessage("email")
                                                | translate
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="flex mb-2">
                                    <label
                                        data-cy="label-password"
                                        class="text-title"
                                        >{{ "Key.Password" | translate }}</label
                                    >
                                    <div class="error-message flex">
                                        <p
                                            data-cy="indicatorinput-password-invalid"
                                            *ngIf="
                                                form.controls.password
                                                    .touched &&
                                                form.controls.password.errors
                                                    ?.required
                                            "
                                        >
                                            *
                                        </p>
                                    </div>
                                </div>
                                <p-password
                                    data-cy="input-password"
                                    id="password"
                                    formControlName="password"
                                    [toggleMask]="true"
                                    [feedback]="false"
                                    (keyup.enter)="
                                        isLoginAction = true;
                                        checkRecaptchaAndLogin(captchaRef)
                                    "
                                    styleClass="w-full "
                                    class="passwordStyle"
                                    placeholder="{{
                                        'Key.EnterPassword' | translate
                                    }}"
                                    required
                                ></p-password>
                            </div>
                            <div
                                class="w-full grid justify-content-between mt-3"
                            >
                                <div class="col6 ml-3 flex">
                                    <p-checkbox
                                        data-cy="checkbox-remember-me"
                                        formControlName="remember"
                                        value="remember"
                                        inputId="remember"
                                        [binary]="true"
                                        (onChange)="
                                            handleRememberChange($event)
                                        "
                                    ></p-checkbox>
                                    <label
                                        data-cy="label-remember-me"
                                        class="rememberMeText"
                                        for="remember"
                                        >{{ "Key.Remember" | translate }}</label
                                    >
                                </div>
                                <!-- <div class="col4"></div> -->
                                <div class="col6">
                                    <a
                                        data-cy="link-forgot-password"
                                        href="reset-password"
                                        class="forgotPasswordText"
                                        >{{
                                            "Key.ForgotPassword" | translate
                                        }}</a
                                    >
                                </div>
                            </div>
                        </form>
                        <div class="flex flex-wrap">
                            <re-captcha
                                [ngClass]="{ hidden: !isRecaptchaActive }"
                                #captchaRef="reCaptcha"
                                size="invisible"
                                class="mt-5"
                                (resolved)="resolved($event)"
                                [siteKey]="recaptchaKey"
                            ></re-captcha>
                            <button
                                data-cy="button-signin"
                                [disabled]="form.invalid"
                                pButton
                                pRipple
                                label="{{ 'Key.SignIn' | translate }}"
                                class="buttonFontLogin bg-indigo-500 w-full p-3 login-Button"
                                style="margin-top: 30px"
                                (click)="checkRecaptchaAndLogin(captchaRef)"
                                icon="pi pi-arrow-right"
                                iconPos="right"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-center"></p-toast>
