<img class="ml-2" src="assets/vinter/icons/question-behaiviour-warning.svg" width="18px" height="18px" (mouseover)="op.show($event)" (mouseout)="op.hide()">

<p-overlayPanel #op styleClass="overlay-beh-applicant-detail" >

    <div style="min-width: 10rem;">

        <div *ngIf="checkIfQuestionLogExists(EApplicantBehaviourEventType.PageHidden)" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/cheating-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold mail-text" style="color:#F2A105" >{{"Key.SwitchedTabDetect" | translate }}</p>
            </div>
        </div>
        <div *ngIf="question.isMultipleFaceDetected" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/multiface-detected-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold mail-text" style="color:#F20505" >{{"Key.MultipleFaceDetect" | translate }}</p>
            </div>
        </div>
        <div *ngIf="question.isFaceDetected" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/face-detection-icon.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold mail-text" style="color:#F28505" >{{"Key.DifferentPersonDetect" | translate }}</p>
            </div>
        </div>
        <div *ngIf="question.question.isImproprietySpeech" class="flex my-2">
            <div class="flex align-items-center justify-content-center">
                <img src="assets/vinter/icons/impropriety-speech-warning.svg" width="18px" height="18px">
                <p class="ml-2 font-semibold mail-text" style="color:#F25A05" >{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
                <!-- <p class="ml-2 font-semibold mail-text" style="color:#37AC00" >{{'Key.Yes' | translate}}</p> -->
            </div>
        </div>
    </div>

</p-overlayPanel>

