import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthenticationService, LocalizationService } from '@app/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { SpinnerHandlerService } from '../loader/spinner-handler.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Language } from '@app/shared/common/interfaces';
import { flagENPath, flagTRPath, languageKey, vinterLogoWithoutTextPath, vinterPureLogoRotatedPath, passwordMinCharcount, UserConsentTypeStrings } from '@app/shared/common/constants';
import { LanguageService } from '@app/shared/services/language.service';
import { ELanguages, EUserConsentType, LanguageEnum } from '@app/shared/common/enums';
import * as intlTelInput from 'intl-tel-input';
import { CountryService } from '@app/vinter/services/country.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  vinterLogoWithoutTextPath: string = vinterLogoWithoutTextPath;
  vinterPureLogoRotatedPath: string = vinterPureLogoRotatedPath;
  flagTRPath: string = flagTRPath;
  flagENPath: string = flagENPath;
  encryptedInvitationID: string;
  validationID: string;
  mail: string;
  passwordMinCount = passwordMinCharcount;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  form: FormGroup;
  showFormValidation: boolean;
  language: string;
  languageDS: Language[] = [];
  selectedLanguage: Language = {};
  defaultLanguageID?: string;
  fromPageLoad: boolean;
  isSignUpSuccess: boolean;
  isTurkishSelected: boolean = true;
  countryCode: number;
  phoneNumber: number;
  iti: any;
  userAgreementVisible: boolean = false;
  disclosureAgreement: boolean;
  TEIOMAgreement: boolean;
  GDPRAgreement: boolean;
  mailAgreement: boolean;
  company: string;
  country: string;
  EUserConsentType = EUserConsentType;
  selectedConsentType: EUserConsentType;
  selectedConsentName: string;
  countries: any[];
  filteredCountries: any[];
  LanguageEnum = ELanguages;

  constructor(
    private service: AuthenticationService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerHandlerService,
    private languageService: LanguageService,
    private countryService: CountryService
  ) {
  }

  ngOnInit(): void {
    this.getCountryDatas(this.LanguageEnum.English);

    const inputElement = document.querySelector('#phone');
    if(inputElement){
      this.iti =  intlTelInput(inputElement,{
        preferredCountries: ["tr", "us"],
        showSelectedDialCode: true,
        initialCountry: "",
        utilsScript: 'assets/vinter/js/regexUtils.js',
        separateDialCode:true
      });
    }
    const countryData = this.iti?.getSelectedCountryData();
    this.countryCode = countryData?.dialCode;
    const number = this.iti.getNumber();
    this.iti.setCountry(countryData?.iso2);

    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.encryptedInvitationID = this.route.snapshot.queryParamMap.get('invitationID');
        this.validationID = this.route.snapshot.queryParamMap.get('validationID');
        this.mail = this.route.snapshot.queryParamMap.get('mail');
        this.company = this.route.snapshot.queryParamMap.get('company');
      }
    );
    if (this.mail === null || (this.encryptedInvitationID === null && this.validationID === null)) {
      this.router.navigate([this.route.snapshot.queryParams.redirect || 'not-found'], { replaceUrl: true });
    }
    this.isSignUpSuccess = false;
    lastValueFrom(this.languageService.getLanguagesFromApi()).then(data => {
      this.languageDS = data;
      this.fromPageLoad = true;
      this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.EnglishUnitedStates);
      this.isTurkishSelected = false;
      this.languageOnSelectionChanged(this.selectedLanguage);

      this.localizationService.initTranslate(null);
      if(this.isTurkishSelected){
        this.setCheckboxValidatorsToTurkish();
      }
      else{
        this.setCheckboxValidatorsToEnglish();
      }
    })
    this.setFormGroup();
    if(this.validationID == null){
      this.form.controls['company'].clearValidators();
      this.form.controls['country'].clearValidators();
    }
  }

  getCountryDatas(selectedLang: string){
    this.countryService.getCountries(selectedLang).then((countries) => {
      this.countries = countries;
  });
  }

  filterCountry(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.countries.length; i++) {
        let country = this.countries[i];
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
    this.filteredCountries = filtered;
  }

  toggleUserAgreementDialog(consentType: EUserConsentType) {
    this.selectedConsentName = UserConsentTypeStrings[consentType];
    this.selectedConsentType = consentType;
    this.userAgreementVisible = true;
  }

  setFormGroup() {
    this.form = new FormGroup(
      {
        email: new FormControl(this.mail, [
          Validators.required,
          this.trimValidator,
        ]),
        company: new FormControl(this.company, [
          Validators.required,
          this.trimValidator,
        ]),
        country: new FormControl('', [
          Validators.required,
        ]),
        phone: new FormControl('', [
          Validators.required,
          Validators.pattern('[- +()0-9]{5,15}')
        ]),
        firstName: new FormControl('', [
          Validators.required,
        ]),
        lastName: new FormControl('', [
          Validators.required,
        ]),
        password: new FormControl('', [
          Validators.required,
          this.trimValidator,
          this.passwordValidator()
        ]),
        passwordAgain: new FormControl('', [
          Validators.required,
          this.trimValidator,
          this.passwordValidator()
        ]),
        acceptDisclosure: new FormControl(this.disclosureAgreement),
        acceptTEIOM: new FormControl(this.TEIOMAgreement),
        acceptGDPR: new FormControl(this.GDPRAgreement),
        acceptMail: new FormControl(this.mailAgreement),
      },
      { validators: this.matchValidator('password', 'passwordAgain') }
    );
  }

  matchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }

  trimValidator: ValidatorFn = (control: FormControl) => {
    if (control.value !== null && control.value.startsWith(' ')) {
      return {
        error: { value: 'Key.StartsWithErr' },
      };
    }
    if (control.value !== null && control.value.endsWith(' ')) {
      return {
        error: { value: 'Key.EndsWithErr' },
      };
    }
    return null;
  }

  validateInput(event: any) {
    if (!event.target.value.trim()) {
      this.showFormValidation = true;
    } else if (this.iti.isValidNumberPrecise()) {
      this.showFormValidation = false;
    } else {
      this.showFormValidation = true;
    }
}


  validateKeydown(event: any) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (!allowedKeys.includes(event.key) && isNaN(parseInt(event.key, 10))) {
      event.preventDefault();
    }
  }

  checkFormValidity(formControlName: string) {
    if (
      (this.form.get(formControlName).touched &&
        this.form.get(formControlName).errors) ||
      this.form.errors?.['mismatch']
    ) {
      return true;
    }
    return false;
  }

  getFormValidityMessage(formControlName: string) {
    if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.['error']
    ) {
      return this.localizationService.translateTextWithoutKey(
        this.form.get(formControlName).errors?.['error'].value
      );
    } else if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.['required']
    ) {
      //   return this.localizationService.translateText('RequiredMassage');
    }
    else if (
      (this.form.get(formControlName).touched &&
        this.form.errors?.['mismatch']) &&
      (formControlName == 'password' || formControlName == 'passwordAgain') &&
      this.form.get('password').value &&
      this.form.get('passwordAgain').value
    ) {
      return this.localizationService.translateText('MismatchMessage');
    }
    else if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.['invalidPassword']
    ) {
      return 'Key.InvalidRegistirationPassword';
    }
    else if (
      this.form.get(formControlName).touched &&
      this.form.get(formControlName).errors?.pattern
    ) {
      return 'Key.InvalidPhonePattern';
    }
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumeric = /\d/.test(value);
      const isLengthValid = value?.length >= passwordMinCharcount;

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && isLengthValid;

      return !passwordValid ? { invalidPassword: true } : null;
    };
  }


  onLanguageToggle(isClicked: boolean = false) {
    if(isClicked){
      this.form.controls['acceptDisclosure'].setValue(false);
      this.form.controls['acceptTEIOM'].setValue(false);
      this.form.controls['acceptGDPR'].setValue(false);
      this.form.controls['acceptMail'].setValue(false);
    }
    const langCode = this.isTurkishSelected ? 'tr-TR' : 'en-US';
    const newLanguage = this.languageDS.find(x => x.languageCode === langCode);
    this.getCountryDatas(newLanguage.id);
    this.languageOnSelectionChanged(newLanguage);
    if(this.isTurkishSelected){
      this.setCheckboxValidatorsToTurkish();
    }
    else{
      this.setCheckboxValidatorsToEnglish();
    }
  }

  languageOnSelectionChanged(e) {
    localStorage.setItem(languageKey, JSON.stringify({ id: e.id, code: e.languageCode }));
    if (!this.fromPageLoad) {
      this.fromPageLoad = false;
    }
    this.localizationService.initTranslate(null);
    this.fromPageLoad = true;
  }

  setCheckboxValidatorsToEnglish(){
    this.form.controls['acceptDisclosure'].clearValidators();
    this.form.controls['acceptTEIOM'].clearValidators();
    this.form.controls['acceptGDPR'].setValidators([Validators.requiredTrue]);
    this.form.controls['acceptDisclosure'].updateValueAndValidity();
    this.form.controls['acceptTEIOM'].updateValueAndValidity();
    this.form.controls['acceptGDPR'].updateValueAndValidity();
  }

  setCheckboxValidatorsToTurkish(){
    this.form.controls['acceptDisclosure'].setValidators([Validators.requiredTrue]);
    this.form.controls['acceptTEIOM'].setValidators([Validators.requiredTrue]);
    this.form.controls['acceptGDPR'].clearValidators();
    this.form.controls['acceptDisclosure'].updateValueAndValidity();
    this.form.controls['acceptTEIOM'].updateValueAndValidity();
    this.form.controls['acceptGDPR'].updateValueAndValidity();

  }

  register() {
    if (this.form.invalid) return;
    if (this.isTurkishSelected) {
      this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.TurkishTurkey);
    }
    else {
      this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.EnglishUnitedStates);
    }
    this.onLanguageToggle();
    const response$ = this.service.register({
      email: this.mail,
      phone: this.iti.getNumber(),
      firstName: this.form.controls['firstName'].value,
      lastName: this.form.controls['lastName'].value,
      password: this.form.controls['password'].value,
      defaultLanguageID: this.selectedLanguage.id,
      encryptedInvitationID: this.encryptedInvitationID,
      validationID: this.validationID,
      company: this.form.controls['company'].value,
      country: this.form.controls['country'].value.code,
      mailPermission: this.isTurkishSelected ? true : this.form.controls['acceptMail'].value
    });
    lastValueFrom(response$).then(data => {
      if(this.validationID == null){
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], {
          replaceUrl: true, queryParams: { mail: this.mail}
        });
      }
      else{
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], {
          replaceUrl: true, queryParams: { mail: this.mail, firstLogin: true }
        });
      }
    }).catch(error => {
      this.spinner.hide();
    });
  }

}
