<div class="-mt-4">
    <div class="row flex mb-1 ">
        <div class="flex col-6 align-content-center backToButton pb-0 pl-4 pt-0">
            <div class="flex col-2 align-item-center align-self-center w-min pr-0 pl-0 textFont clickable-card"
                (click)="backToQuestionList()"><i class="pi pi-arrow-left"></i>
            </div>
            <span class="flex col-10 align-item-center pl-0 textFont ">{{'Key.BackToLibrary' | translate}}</span>

        </div>
    </div>
    <div class="row flex ">
        <div class="col-12 grid flex align-content-center pt-0 pb-0">
            <div class="base-title mr-3 pl-3 pt-3 pb-3 align-item-center align-self-center w-full">{{pageHeader |
                translate}}</div>

        </div>
    </div>
    <div class="row flex mb-3">
        <div
            class="w-full mr-3 ml-3 justify-content-between flex-wrap flex align-content-center pt-0 detailCard h-5rem">
            <div class="flex justify-content-start">
                <div class="mr-3 align-item-center align-self-center detailFont">{{questionCollectionInfo?.title ??
                    "Key.Untitled" | translate}}</div>
                <div class="vl"></div>
                <img class="mr-2 ml-2" src="../../../../../assets/vinter/icons/position-step.svg" width="18px">
                <div class="mr-2 align-item-center align-self-center detailSubFont">{{questionsForQuestionList?.length}}
                </div>
                <div class="mr-2 align-item-center align-self-center detailSubFont">{{"Key.QuestionCount" | translate}}
                </div>
                <div class="vl"></div>
                <img class="mr-2 ml-2" src="../../../../../assets/vinter/icons/timer-white.svg" width="18px">
                <div class="mr-2 align-item-center align-self-center detailSubFont">{{questionCollectionInfo?.duration
                    ?? 0}}</div>
                <div class="mr-2 align-item-center align-self-center detailSubFont">{{"Key.Minutes" | translate}}</div>
            </div>
            <div class="flex justify-content-end">
                <p-button styleClass="p-button-position mr-2" icon="pi pi-arrow-left" iconPos="left"
                    *ngIf="activeIndex > 0" (onClick)="goBackOneStep()"></p-button>
                <p-button *ngIf="activeIndex < 2" [label]="'Key.Next' | translate"
                    [disabled]="disableButton || ((this.questionCollectionInfo.duration === 0 || this.questionCollectionInfo.duration === null || this.questionCollectionInfo.duration === undefined) && activeIndex === 1) "
                    styleClass="p-button-position" icon="pi pi-arrow-right" iconPos="right"
                    (onClick)="next()"></p-button>

                <p-button *ngIf="activeIndex == 2" [label]="'Key.Save' | translate" styleClass="p-button-position"
                    icon="pi pi-save" iconPos="right" (onClick)="saveQuestionList()"></p-button>
            </div>
        </div>
    </div>
    <p-steps [model]="createQuestionListSteps" [(activeIndex)]="activeIndex" [readonly]="true"
        ngClass="stepComponent"></p-steps>

    <app-question-collection-form *ngIf="activeIndex === 0" (onCustomStepSave)="saveCustomStep($event)"
        [hidden]="activeIndex !== 0" [questionCollectionInfo]="questionCollectionInfo"
        (onQuestionCollectionInfoSave)="next()" (resetQuestionList)="resetQuestionList()"
        (selectedLanguageId)="getLanguage($event)" (enableNextButton)="enableNextButton($event)"
        (infoChange)="changeInfo($event)"></app-question-collection-form>

    <app-question-collection-list *ngIf="activeIndex === 1" [hidden]="activeIndex !== 1"
        [formType]="formTypeEnum.QestionCollection" [positionEditFormMode]="positionEditFormMode"
        [isAddPersonalQuestionBtnVisible]="isAddPersonalQuestionBtnVisible" [languageId]="languageId"
        [positionName]="positionName" (onSaveStepQuestions)="saveStepInfo($event)"
        (onViewQuestions)="goViewQuestions($event)" (next)="next()" (changeSubStep)="changeSubStep()"
        [questionCollectionFormInfo]="questionCollectionInfo" (onCreateNewQuestion)="goCreateNewQuestion($event)"
        (onPreviewQuestions)="openPreviewQuestions($event)" [selectedQuestionList]="questionsForQuestionList"
        [createdQuestionList]="yourQuestions" (goBackOneStep)="goBackOneStep()"
        [saveQuestionListCategory]="saveQuestionListCategoryId"
        (onAddedAllListQuestions)="addAllQuestionsOfCollection($event)"
        (saveQuestionListInfo)="saveQuestionListInfo($event,false)"
        (questionCollectionDuration)="questionCollectionDuration($event)">
    </app-question-collection-list>

    <app-your-question-list-preview *ngIf="activeIndex===2" [hidden]="activeIndex !== 2"
        (goBackOneStep)="goBackOneStep()" (closeDialog)="closeDialog($event)"
        [selectedQuestionsFromQuestionCollection]="questionsForQuestionList"
        [createdQuestionCollection]="questionCollectionInfo" [createdQuestions]="yourQuestions"
        (onSaveDialog)="onSaveDialog($event)" (callCloseDialog)="closeDialogOnSave()"
        (saveQuestionCollectionId)="saveQuestionCollectionId($event)"
        [saveQuestionListCategory]="saveQuestionListCategoryId"
        (onSaveQuestionCollectionQuestions)="saveQuestionCollection($event)">
    </app-your-question-list-preview>

    <p-sidebar [(visible)]="subStep" position="right">
        <app-select-questions [formType]="formTypeEnum.QestionCollection" [selectedQuestionList]="selectedQuestionList"
            [selectedLanguage]="selectedQuestionList.languageID"
            [yourQuestionListDescription]="selectedQuestionList.description"
            [yourQuestionListDuration]="selectedQuestionList.duration"
            [yourQuestionListTitle]="selectedQuestionList.title" [selectedQuestions]="questionsForQuestionList"
            [isFromEditCurrent]="false" [selectedQuestionCategory]="questionCollectionInfo.questionCollectionCategory"
            [stepDurationType]="stepDurationTypeEnum.QuestionBased" *ngIf="subStep===true"
            (saveQuestionListInfo)="saveQuestionListInfo($event,false)"
            (saveQuestionsForQuestionList)="saveQuestionsForQuestionList($event)"
            (saveQuestionListSubCategory)="saveQuestionListSubCategory($event)" (closeSubStep)="closeSubStep()"
            (editCustomQuestion)="editCustomQuestion($event)">
        </app-select-questions>
    </p-sidebar>

    <p-sidebar [showCloseIcon]="false" *ngIf="isPreviewCurrentCollectionOpen===true"
        [(visible)]="isPreviewCurrentCollectionOpen" position="right">
        <app-select-questions [formType]="formTypeEnum.QestionCollection" [selectedQuestionList]="selectedQuestionList"
            [selectedLanguage]="questionCollectionInfo.languageId" [addQuestionShownType]="PreviewCurrentType"
            [isFromEditCurrent]="true" (saveQuestionListInfo)="saveQuestionListInfo($event,true)"
            [yourQuestionListDescription]="questionCollectionInfo.description"
            [yourQuestionListDuration]="questionCollectionInfo.duration" [createdQuestionList]="yourQuestions"
            [yourQuestionListTitle]="questionCollectionInfo.title" [selectedQuestions]="questionsForQuestionList"
            [selectedQuestionCategory]="questionCollectionInfo.questionCollectionCategory"
            [stepDurationType]="stepDurationTypeEnum.QuestionBased"
            (saveQuestionsForQuestionList)="saveQuestionsForQuestionList($event)"
            (saveCreatedQuestionList)="saveCreatedQuestionList($event)"
            (saveQuestionListSubCategory)="saveQuestionListSubCategory($event)" (closeSubStep)="closeSubStep()"
            (editCustomQuestion)="editCustomQuestion($event)">
        </app-select-questions>
    </p-sidebar>

    <p-sidebar [showCloseIcon]="false" [(visible)]="subQuestionStep" position="right"
        (visibleChange)="onSubQuestionStepVisibleChange($event)">
        <app-add-your-question *ngIf="subQuestionStep===true" (goBackOneStep)="closeSubQuestionStep()"
            (saveYourQuestions)="saveYourQuestions($event)" [yourQuestionListTitle]="questionCollectionInfo.title"
            [positionLanguageId]="languageId" [questionToDisplayArray]="yourQuestions"
            [questionCollectionCategory]="questionCollectionInfo.questionCollectionCategory"
            [questionListMode]="questionListMode" [questionToEdit]="questionToEdit"
            [stepDurationType]="stepDurationTypeEnum.QuestionBased">
        </app-add-your-question>
    </p-sidebar>
</div>