import { Component, Input, OnInit } from '@angular/core';
import { EApplicantBehaviourEventType } from '@app/shared/common/enums';

@Component({
  selector: 'app-step-result-question-behaviour-info',
  templateUrl: './step-result-question-behaviour-info.component.html',
  styleUrls: ['./step-result-question-behaviour-info.component.scss']
})
export class StepResultQuestionBehaviourInfoComponent implements OnInit{

  @Input() isFaceDetected;
  @Input() isMultipleFaceDetected;
  @Input() isImproprietySpeech;
  @Input() isSwitchedTab;
  @Input() faceDetectionStatus;
  
  ngOnInit(): void {
  }

}
