<div class="flex gap-3 flex-wrap mt-3">
    <div class="flex-nowrap flex gap-2 align-items-center" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isFaceDetected && answer?.isAllowedDifferentFaceAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-icon.svg" class="detect-img">
        <p class="font-medium face-detected">{{"Key.FaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.isMultiFaceDetected && answer?.isAllowedMultipleFaceAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/multiface-detected-icon.svg" class="detect-img">
        <p class="font-medium multiface-detected">{{"Key.MultiFaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.answerContent?.improprietyAnalysis?.isImproprietySpeech && answer?.isAllowedImproprietySpeechAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/impropriety-speech-warning.svg" class="detect-img">
        <p class="font-medium impropriety-speech">{{"Key.ImproprietySpeechAnalysis" | translate }}</p>
    </div>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="ml-5 flex" *ngIf="answer === null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.ThisTypeQuestionNotAnswered" | translate}}</p>
</div>

<div *ngIf="answer?.answerContent" class="grid lg:ml-4 lg:-mr-2">
    <div class="col-12 md:col-12 lg:col-6 lg:px-2 w-full flex flex-wrap">
        <div class="flex flex-wrap flex-row align-items-start answer-container border-1 border-solid border-gray-50 border-round-md p-3">
            <div class="custom-col-6 flex-1 p-2 height-fit">
                <vg-player *ngIf="answer?.answerContent.contentURL ?? false" style="border-radius: 15px; background-color:white">
                    <video #speakingVideo [vgMedia]="$any(speakingMedia)" #speakingMedia id="speakingVideo" preload="auto"
                        controls (timeupdate)="highlightSpeaking($event.target)">
                        <source [src]="answer?.answerContent.contentURL" type="video/webm">
                        <source [src]="answer?.answerContent.contentURL" type="video/mp4">
                    </video>
                </vg-player>
            </div>
            <div class="p-2 custom-col-6 flex flex-column">
                <span class="text-gray-900 font-semibold mb-3 text-lg block">{{"Key.Text" | translate}}</span>
                <div *ngIf="answer?.answerContent?.gptEvalStatus !== GPTEvalStatusEnum.Succesful" 
                    class="w-full p-3 bg-white flex align-items-center justify-content-center flex-wrap">
                    <img [src]="AICommentPathIcon" width="25px" height="25px" class="mr-2">
                    <span class="text-gray-700 font-bold text-center">
                        <div [ngSwitch]="answer?.answerContent.gptEvalStatus">
                            <div *ngSwitchCase="GPTEvalStatusEnum.ClientFail">
                                {{answer?.answerContent.aiFinalLog}}
                            </div>
                            <div *ngSwitchCase="GPTEvalStatusEnum.Fail">
                                {{"Key.AIFail" | translate}}
                            </div>
                            <div *ngSwitchCase="GPTEvalStatusEnum.Waiting">
                                {{"Key.WaitForAIComment" | translate}}
                            </div>
                            <div *ngSwitchDefault>
                                {{'Key.ErrorNotFound' | translate}}
                            </div>
                        </div>
                    </span>
                </div>
                <!-- <div *ngIf="answer?.answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="text-gray-700 line-height-4">
                    {{answer?.answerContent.transcriptText}}
                </div>  -->
                <div #transcriptDiv *ngIf="answer?.answerContent?.transcriptStatus === GPTEvalStatusEnum.Succesful">
                    <div *ngIf="subtitles.length > 0" class="text-gray-700 text-justify">
                        <span *ngFor="let subtitle of subtitles" [id]="'s' + subtitle.id"
                            (mouseover)="subtitle.hover=true" (mouseleave)="subtitle.hover=false"
                            (click)="jumpToSecond(subtitle.start);" class="subtitleText"
                            pTooltip="{{ 
                                (subtitle.emotion_score !== -1 && answer?.emotionAnalysis && answer?.isAllowedEmotionAnalysis) ? 
                                  ('%' + (subtitle.emotion_score | number:'2.1-2') + ' ' + ('Key.'+subtitle.emotion | translate)) : 
                                  (answer?.emotionAnalysis ? ('Key.'+subtitle.emotion | translate) : '')
                              }}"                            
                            tooltipPosition="left"
                            [ngStyle]="(subtitle.highlighted || subtitle.hover) ? { 'background-color': getColor(subtitle.emotion, subtitle.emotion_score),'color':'var(--gray-900)'}: { 'background-color': 'transparent' }">
                            {{ subtitle.text }}
                        </span>
                    </div>
                    <div *ngIf="subtitles.length === 0" class="text-gray-400 text-justify">
                        <span>
                            {{"Key.NoTranscriptMessage" | translate}}
                        </span>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</div>
<div class="col-12 md:col-12 lg:col-6 grid lg:ml-3 w-full pt-4" *ngIf="answer?.answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful && answer?.speechAnalysis != null && answer?.speechAnalysis.finalScore != null"> 
    <div class="w-full flex flex-column align-items-center justify-content-center w-full custom-display border-round-lg border-1 border-solid border-blue-50 px-3 py-2">
        <div class="w-full flex">
                <div class="col-6 pl-0 pr-0 text-lg font-semibold text-gray-900">
                    {{'Key.TotalScore' | translate}}
                </div>
                <div class="col-6 flex align-items-center justify-content-end gap-2 pr-0 white-space-nowrap">
                    <p-progressBar class="w-full total-score"
                        [value]="answer?.speechAnalysis ?? false ? answer?.speechAnalysis.finalScore : 0"
                        [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                    <span class="font-bold text-blue-700 text-xs">% {{(answer?.speechAnalysis.finalScore ?? false) ? answer?.speechAnalysis.finalScore?.toFixed(0):0}}
                    </span>
                </div>
        </div>

        <div *ngIf="answer?.answerContent.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="col-12 p-0">
            <p-accordion [multiple]="true" iconPos="start">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.VocabularyAccuracyScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.VocabAccInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="this.answer?.speechAnalysis.vocabAccRangeScore"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{answer?.speechAnalysis.vocabAccRangeScore.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{vocabAccEval}}
                        </p>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.GrammarScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.GrammarAccInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="this.answer?.speechAnalysis.grammarAccRangeScore"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{answer?.speechAnalysis.grammarAccRangeScore.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{grammarEval}}
                        </p>
                    </ng-template>
                </p-accordionTab>                       
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.DiscourseOrganizationScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.DiscourseOrganizationInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="this.answer?.speechAnalysis.discourseOrganizationScore"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{answer?.speechAnalysis.discourseOrganizationScore.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{discourseOrgEval}}
                        </p>
                    </ng-template>
                </p-accordionTab>        
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.FluencyScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.FluencyInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="this.answer?.speechAnalysis.fluencyScore"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{answer?.speechAnalysis.fluencyScore.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{fluencyEval}}
                        </p>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.PronunciationScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.PronunciationInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="this.answer?.speechAnalysis.pronunciationScore"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{answer?.speechAnalysis.pronunciationScore.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{pronunciationEval}}
                        </p>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>

<div *ngIf="answer?.answerContent?.gptEvalStatus === GPTEvalStatusEnum.Succesful" class="grid w-full m-0 ml-3 -mt-2">
    <!-- emotion analysis -->
    <div *ngIf="answer?.emotionAnalysis && answer?.isAllowedEmotionAnalysis" class="col-12 md:col-6 lg:col-6 pr-0 custom-style emotionAnalysis-container">
        <div class="p-4 border-gray-50 border-round-lg border-1 border-solid card-container">
            <div>
                <h5>{{ "Key.FacialExpressions" | translate }}</h5>
            </div>
            <div class="w-full flex gap-3 align-items-center" *ngFor="let emotionEntry of emotionArray">
                <div class="flex w-10 gap-3 align-items-center">
                    <img [pTooltip]="'Key.' + emotionEntry.name | translate" tooltipPosition="left"
                        class="py-2 h-3rem"
                        [src]="'../../../../../../assets/vinter/icons/emotion-icons/' + emotionEntry.name + '.svg'">
                    <p-progressBar [class]="emotionEntry.name" class="w-full" [showValue]="false"
                        [value]="emotionEntry.value" [style]="{ height: '8px' }"></p-progressBar>
                </div>
                <div class="w-2">
                    <span class="font-semibold white-space-nowrap" [class]="'emotion-' + emotionEntry.name">%
                        {{emotionEntry.value.toFixed(2)}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- impression analysis -->
    <div *ngIf="answer?.impressionAnalysis && answer?.isAllowedImpressionAnalysis" class="col-12 md:col-6 lg:col-6 custom-impression-style impressionAnalysis-container">
        <div class="p-4 h-full border-gray-50 border-round-lg border-1 border-solid card-container flex flex-column gap-3">
            <div>
                <h5>{{ "Key.ImpressionAnalysis" | translate }}</h5>
            </div>
            <div class="w-full flex gap-3 align-items-center" *ngFor="let impressionEntry of impressionArray">
                <div class="flex w-10 gap-3 align-items-center">
                    <span [class]="impressionEntry.name + '-icon'"
                        [pTooltip]="'Key.' + impressionEntry.name | translate" tooltipPosition="left"
                        class="border-circle p-2 font-medium ocean-icon flex justify-content-center align-items-center text-white h-2rem">{{impressionEntry.name?.slice(0,1).toUpperCase()}}</span>
                    <p-progressBar [class]="impressionEntry.name" class="w-full" [showValue]="false"
                        [value]="impressionEntry.value" [style]="{ height: '8px' }"></p-progressBar>
                </div>
                <div class="w-2">
                    <span class="font-semibold white-space-nowrap" [class]="'impression-' + impressionEntry.name">%
                        {{impressionEntry.value.toFixed(2)}}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- voice analysis -->
    <div *ngIf="answer?.voiceAnalysis && answer?.isAllowedVoiceAnalysis" class="col-12 md:col-6 lg:col-6 pr-3 custom-style voiceAnalysis-container" 
         [ngClass]="{'custom-voice-analysis-style': answer?.voiceAnalysis && answer?.emotionAnalysis && answer?.impressionAnalysis}"
    >
        <div class="flex flex-column w-full p-4 border-gray-50 border-round-lg border-1 border-solid card-container">
            <div>
                <h5>
                    {{"Key.PRAATVoiceAnalysis" | translate}}
                </h5>
            </div>
            <div *ngIf="answer.voiceAnalysis?.speechSpeed != null">
                <div class="flex w-full align-items-center justify-content-between">
                    <div class="praat-text font-medium">
                        {{"Key.speechSpeed" | translate}}
                        <img class="rating-info ml-2" src="assets/vinter/icons/info-icon.svg"
                            pTooltip="{{'Key.SpeechSpeedInfo' | translate}}" tooltipPosition="top" />
                    </div>
                    <div class="flex gap-3 align-items-center justify-content-center">
                        <p-rating class="voice" [(ngModel)]="answer.voiceAnalysis.speechSpeed"
                            [readonly]="true" [cancel]="false">
                            <ng-template pTemplate="onicon">
                                <img src="assets/vinter/icons/rating-onIcon.svg" class="praat-rating-img" />
                            </ng-template>
                            <ng-template pTemplate="officon">
                                <img src="assets/vinter/icons/rating-offIcon.svg" class="praat-rating-img" />
                            </ng-template>
                        </p-rating>
                        <span class="praat-value font-medium text-lg">{{answer.voiceAnalysis.speechSpeed |
                            voiceSpeechSpeedTypes:voiceSpeechSpeedTypes }}</span>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let voiceEntry of voiceArray">
                <div *ngIf="voiceEntry.name !== 'speechSpeed'"
                    class="flex w-full justify-content-between align-items-center mt-3">
                    <div class="praat-text font-medium">
                        {{"Key." + voiceEntry.name | translate}}
                    </div>
                    <div class="praat-value font-semibold">
                        {{voiceEntry.value}}
                        <span
                            *ngIf="voiceEntry.name === 'speechDuration' || voiceEntry.name === 'originalDuration'">
                            s</span>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="answer.voiceAnalysis?.speechDuration != null && answer.voiceAnalysis?.originalDuration != null"
                class="flex w-full justify-content-between align-items-center mt-3">
                <div class="praat-text font-medium">
                    {{"Key.SpeechRatio" | translate}}
                </div>
                <div class="praat-value font-semibold">
                    {{"Key.SpeechRatioValue" | translate: {value: (answer.voiceAnalysis?.originalDuration !== 0
                    ? ((answer.voiceAnalysis?.speechDuration / answer.voiceAnalysis?.originalDuration * 100).toFixed(2)) : 0)} }}
                </div>
            </div>
        </div>
    </div>
</div>
