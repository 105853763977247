import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CredentialsService, LocalizationService } from '@app/core';
import { eyeIconOutline, timeIconOutline } from '@app/shared/common/constants';
import { EGeneralApplicationFormAccountLinkType, EGeneralApplicationFormAccountLinkTypeURL, LanguageEnum, PositionStepKanbanStaticTypes } from '@app/shared/common/enums';
import { ApplicationService } from '@app/vinter/services/application.service';
import { PositionService } from '@app/vinter/services/position.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-applicant-profile',
    templateUrl: './applicant-profile.component.html',
    styleUrls: ['./applicant-profile.component.scss']
})
export class ApplicantProfileComponent implements OnInit {

    @Input() applicationId: string;
    applicant: any = {};
    loading: boolean = true;

    iconPath = 'assets/vinter/icons/';
    iconPathEnding = '_input.svg';
    currentStepIdTemp = '-3';
    isEmailCopied = false;
    applicationStepId: string;
    changedTab: string;
    socialPlatformsDialogVisible = false;
    eGeneralApplicationFormAccountLinkType = EGeneralApplicationFormAccountLinkType;
    timeIcon = timeIconOutline;
    eyeIconOutline = eyeIconOutline;
    positionId: string;
    kanbanSteps: any[];
    data: any;
    isStepChangeSelectionDisabled = true;
    selectableStepList = [];
    constructor(public applicationService: ApplicationService,
        private positionService: PositionService,
        public route: ActivatedRoute,
        public translate: LocalizationService,
    ) {
    }


    updateStepChangeDisabled() {
        return this.applicant?.applicationStatus == PositionStepKanbanStaticTypes.Archived ||
            this.applicant?.applicationStatus == PositionStepKanbanStaticTypes.Rejected ||
            this.applicant?.applicationStatus == PositionStepKanbanStaticTypes.Invitation ||
            this.applicant?.applicationStatus == PositionStepKanbanStaticTypes.Hired;
    }

    applicantStepChanged(event) {
        var applicantStepChangeData = {
            previousApplicationStep: {
                Id: this.applicant.currentStepId,
                applicationId: this.applicationId,
            },
            nextPositionStepID: event.value,
            isArchived: false,
        }
        this.positionService.changeApplicationStepByKanban(applicantStepChangeData).subscribe({
            next: (response) => {
                this.data = this.applicant.currentStepId;
                this.applicant.currentStepId = event.value;
                this.currentStepIdTemp = JSON.parse(JSON.stringify(this.applicant.currentStepId));
            }
        });
    }


    async ngOnInit() {
        this.loading = true;
        if (this.applicationId) {
            await this.getApplicationProfileData();
            this.setApplicantUrlList();
            this.updateStepChangeDisabled();
        } else {
            this.loading = false;
        }
    }

    lastActivityDiff() {
        let currentDate = new Date();
        let loginDate = new Date(this.applicant.lastLoginDate);
        let timeDiff = Math.abs(currentDate.getTime() - loginDate.getTime());
        this.applicant.daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
    }


    async getApplicationProfileData() {
        try {
            this.loading = true;
            this.applicant = await firstValueFrom(this.applicationService.GetApplicationUserInfoData({ id: this.applicationId }));
            this.currentStepIdTemp = JSON.parse(JSON.stringify(this.applicant.currentStepId));
            this.lastActivityDiff();
        } catch (error) {
            console.error('Error loading application profile data', error);
        } finally {
            this.loading = false;
        }
    }

    openSocialPlatformDialog() {
        this.socialPlatformsDialogVisible = !this.socialPlatformsDialogVisible;
    }

    setApplicantUrlList() {
        this.applicant.socialPlatformListForProfile = this.applicant.socialPlatformListForProfile ?? false ? this.applicant.socialPlatformListForProfile.map(x => {
            return {
                ...x,
                fullUrl: this.getAccountFullURL(x.socialPlatformLinkType, x.socialPlatformURL),
                svgIconPath: this.iconPath + this.getSocialSvgIconRef(x.socialPlatformLinkType) + this.iconPathEnding
            }
        }) : [];
    }

    getSocialSvgIconRef(type) {
        return Object.keys(EGeneralApplicationFormAccountLinkType)[Object.values(EGeneralApplicationFormAccountLinkType).indexOf(type)].toLowerCase();
    }

    getAccountFullURL(type, url) {
        let urlKey = Object.keys(EGeneralApplicationFormAccountLinkType)[Object.values(EGeneralApplicationFormAccountLinkType).indexOf(type)];
        return EGeneralApplicationFormAccountLinkTypeURL[urlKey] + url;
    }

    copyText(email: string) {
        this.isEmailCopied = true;
        const textArea = document.createElement('textarea');
        textArea.value = email;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        setTimeout(() => {
            this.isEmailCopied = false;
        }, 1000);

    }

    getStepColor(stepId: string): string {
        switch (stepId) {
            case '-1':
                return '#2962FF';
            case '-2':
                return '#7173AC';
            case '-3':
                return '#F20505';
            case '-4':
                return '#E9B500';
            case '-5':
                return '#5ED44A';
            case '-6':
                return '#df739c';
            default:
                return '#2962FF';
        }
    }
}
