<div *ngIf="isPdfOpen" class="hidden" id="parentDivId">
    <div #applicantInfoPdf id="applicantInfoPdf" class="h-auto pdf-width p-0">
        <div #pdfCoverPage class="report relative overflow-hidden p-0">
            <div class="absolute top-0 left-0 w-full h-full">
                <img src="../../../../../assets/vinter/icons/pdfAiReport/background.svg" alt="Gradient Background" class="w-full">
                <div class="blue-section absolute bg-blue-600 pdf-width">
                    <div class="position justify-content-center">
                        <h3 class="text-blue-400 font-semibold mt-0" >{{'Key.PositionNameTitle' | translate}} </h3>
                        <h1 class="font-bold mt-0 text-2xl text-white">{{positionTitle}}</h1>
                    </div>
                </div>
                <div class="report-name-section absolute bg-gray-900 bottom-0 pdf-width">
                    <div class="report-footer flex align-items-center justify-content-center">
                        <div class="line1 bg-blue-600"></div>
                        <h3 class="mx-2 my-0 text-blue-300 text-lg">{{'Key.ReportName' | translate}} </h3>
                        <div class="line2 bg-blue-600"></div>
                    </div>
                </div>
            </div>
            <div class="content absolute bg-white text-gray-900 inline-flex flex-column">
                <div class="header flex justify-content-center">
                    <img class="align-items-center" [src]="organizationLogo" alt="Vinter Logo" class="logo">
                </div>
                <div class="candidate-info inline-flex flex-column align-items-start h-auto sticky pt-4 pl-0">
                    <h2 class="m-0 text-gray-800 font-bold applicant-name">{{applicant.firstName + " " + applicant.lastName}}</h2>
                    <div *ngIf="isCompletedPosition" class="flex">     
                        <div class="flex align-items-center completed-position-container completed-text-color" >
                            <p class="completed-text completed-text-color text-center font-semibold flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                        </div>
                        <p  class="completed-date-text text-gray-700 text-center font-medium -mb-2 mt-2 ml-2">{{stepCompletedDate | date:'dd/MM/yyyy'}}</p>
                    </div>

                    <hr class="custom-hr m-0 my-3 -mb-2">

                    <span class="text-blue-700 font-medium -mt-3">{{'Key.Score' | translate}}</span>
                    <div class="flex align-items-center">
                        <div class="progress-container border-round-lg mr-2">
                            <div class="coverPage-progress-bar border-round-md"
                                [style.width.%]="totalScore !== 0 ? totalScore : 2"
                                [style.background-color]="getProgressBarColor(totalScore)"></div>
                        </div>
                        <span class="flex font-bold text-gray-900 align-items-baseline" style="width: 45px;">
                            <span class="big">{{getIntegerPart(totalScore) }}</span>
                            <span class="text-sm">.{{ getDecimalPart(totalScore) }}</span>
                        </span>
                    </div>

                    <div class="contact-info">
                        <div class="label text-gray-700 flex align-items-center">
                            <img src="assets/vinter/icons/pdf-mail.svg" class="my-2 w-1rem h-1rem">
                            <div class="text-justify my-2 ml-2">
                                {{applicant.email}}
                            </div>
                        </div>
                        <div  class="label text-gray-700 flex align-items-center">
                            <img src="assets/vinter/icons/pdf-phone.svg" class="my-2 w-1rem h-1rem">
                            <div class="text-justify my-2">
                                {{applicant.phone}}
                            </div>
                        </div>
                        <div *ngIf="applicant?.userProfile?.linkedinProfileURL ?? false"  class="label text-gray-700 flex align-items-center">
                            <img src="assets/vinter/icons/pdf-linkedin.svg" class="my-2 w-1rem h-1rem">
                            <div class="text-justify my-2">
                                {{applicant?.userProfile?.linkedinProfileURL}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div #area class="avoid page-height">
            <header class="report-header">
                <div class="header-left">
                    <span class="ml-3">{{organizationName}}</span>
                    <span class="product-manager">{{positionTitle}}</span>
                    <span class="al-raporu">{{'Key.ReportName' | translate}}</span>
                    <span class="name"> - {{applicant.firstName + " " + applicant.lastName}}</span>
                </div>
                <div class="header-line-1">

                </div>
                <div class="header-logo mx-2">
                    <img src="../../../../../assets/vinter/icons/pdfAiReport/vinter-header-logo.svg" alt="Vinter Logo" />
                </div>
                <div class="header-line-2">

                </div>
            </header> 

            <div class="mt-5 mx-6 text-justify pdf-text-size page-content-height">
                <div class="flex">
                    <h5 class="font-bold text-blue-500">»</h5> 
                    <h5 class="font-bold text-gray-900 m-0 ml-2">{{'Key.Contents' | translate}}</h5>
                </div>

                <div class="">
                    <div class="flex justify-content-between mb-2">
                        <span class="text-gray-900 text-justify font-medium text-xs">{{'Key.ApplicantRate' | translate}}</span>
                        <span class="dots"></span>
                        <span class="text-gray-900 text-justify font-medium text-xs">1</span>
                    </div>

                    <div *ngFor="let step of applicationStepsData; let i = index">
                        <div class="flex justify-content-between mb-2">
                            <span class="text-gray-900 text-justify font-medium text-xs">{{step.positionStep.title}}</span>
                            <span class="dots"></span>
                            <span class="text-gray-900 text-justify font-medium text-xs">{{i+2}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div #area class="avoid page-height">
            <header class="report-header">
                <div class="header-left">
                    <span class="ml-3">{{organizationName}}</span>
                    <span class="product-manager">{{positionTitle}}</span>
                    <span class="al-raporu">{{'Key.ReportName' | translate}}</span>
                    <span class="name"> - {{applicant.firstName + " " + applicant.lastName}}</span>
                </div>
                <div class="header-line-1">

                </div>
                <div class="header-logo mx-2">
                    <img src="../../../../../assets/vinter/icons/pdfAiReport/vinter-header-logo.svg" alt="Vinter Logo" />
                </div>
                <div class="header-line-2">

                </div>
            </header> 
            <div class="mt-5 mx-6 text-justify pdf-text-size page-content-height">
                <div class="flex">
                    <h5 class="font-bold text-blue-500">»</h5> 
                    <h5 class="font-bold text-gray-900 m-0 ml-2">{{'Key.ApplicantRate' | translate}}</h5>
                </div>
                <div #area class="avoid">
                    <div class="mx-3 mb-5 text-justify pdf-text-size w-33rem">
                        <p class="mb-3">
                            {{'Key.PdfInfoPart1' | translate}}
                        </p>
                        <p class="mb-3">
                            {{'Key.PdfInfoPart2' | translate}}
                        </p>
                        <p class="mb-3">
                        </p>
                    </div>
                </div>
            </div>
            <footer class="report-footer-2">
                <div class="footer-middle">
                    <img src='{{organizationLogo}}' alt="Koç Logo" class="organization-logo">
                    <div class="footer-line"> </div>
                    <div class="footer-right">
                        <span class="page-number">1</span>
                    </div>
                </div>
            </footer>
        </div>
        <ng-container *ngFor="let item of applicationStepsData; let i = index" class="step-card-container avoid page-break">
            <div *ngIf="item?.completedDate" class="page-height">
                <header class="report-header">
                    <div class="header-left">
                        <span class="ml-3">{{organizationName}}</span>
                        <span class="product-manager">{{positionTitle}}</span>
                        <span class="al-raporu">{{'Key.ReportName' | translate}}</span>
                        <span class="name"> - {{applicant.firstName + " " + applicant.lastName}}</span>
                    </div>
                    <div class="header-line-1">

                    </div>
                    <div class="header-logo mx-2">
                        <img src="../../../../../assets/vinter/icons/pdfAiReport/vinter-header-logo.svg" alt="Vinter Logo" />
                    </div>
                    <div class="header-line-2">

                    </div>
                </header> 
                <div class="mt-5 text-justify pdf-text-size page-content-height page-Flex">
                    <div #area *ngIf="(item.completedDate ?? false)" class="mx-7 avoid">
                        <div>
                            <span class="font-semibold text-lg my-5">{{item.positionStep.title}}</span>
                            <div *ngIf="item.completedDate ?? false" class="py-2">
                                <div class="flex">
                                    <i class="pi pi-clock completed-text-color pdf-text-size">
                                        <span class="status-text text-center font-semibold pdf-text-size">
                                            {{'Key.Completed' | translate}}
                                        </span>
                                    </i>

                                    <div *ngIf="item.startedDate ?? false" class="flex">
                                        <span class="completed-text-color pdf-text-size">
                                            {{'Key.CompletedStepTime' | translate: { value: item.completedStepDuration} }}
                                        </span>
                                        <span class="pdf-text-size" style="color: #9B9EA3;">
                                            /{{'Key.CompletedStepTime' | translate: { value: item.positionStep.duration} }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-4 text-justify pdf-text-size w-32rem">
                                <p>
                                    {{item.stepTypeDesc | translate}}
                                </p>

                                <div *ngFor="let category of answeredQuestionsWithId">
                                    <p *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                                    && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                                    && currentLanguageId === LanguageEnum.EnglishUnitedStates
                                    && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                                    Candidate <span>{{applicant.firstName + " " + applicant.lastName}}</span> has completed the Verbal Cognitive Ability Test. This test measures the candidate's ability to comprehend and analyze
                                    complex texts, visual attention/visual interpretation, verbal reasoning, application of grammar rules, verbal reasoning, critical thinking, synthesizing information,
                                    listening comprehension, summarizing and reconstructing, vocabulary and solving verbal patterns, and grammatical knowledge. The test aims to comprehensively assess the candidate's
                                    verbal abilities through a variety of question types. The candidate answered <span>{{category.answeredQuestionsCount}}</span> questions in this test consisting of
                                    <span>{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> questions. The candidate answered <span>{{category.correctAnsweredQuestionsCount}}</span> questions correctly,
                                    <span>{{category.incorrectAnsweredQuestionsCount}}</span> questions incorrectly and <span>{{category.notAnsweredQuestionsCount}}</span> questions blank.
                                    Accordingly, the score of the candidate was evaluated in the category '<span>{{category.totalScores}}</span>'.
                                    </p>
                                </div>

                                <div *ngFor="let category of answeredQuestionsWithId">
                                    <p *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                                        && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                                        && currentLanguageId === LanguageEnum.TurkishTurkey
                                        && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                                        Aday <span class="">{{applicant.firstName + " " + applicant.lastName}}</span> Sözel Bilişsel Yetenek Testini tamamlamıştır.Bu test, adayın karmaşık metinleri anlama ve analiz etme, görsel dikkat/görsel
                                        yorumlama, sözel muhakeme yapma, dil bilgisi kurallarını uygulayabilme, sözel mantık yürütme, eleştirel düşünme, bilgileri sentezleme, dinlediğini anlama, özetleme ve yeniden
                                        yapılandırma, kelime bilgisi ve sözel kalıpları çözme yeteneklerini ve gramer bilgisini ölçmektedir. Test, içerdiği çeşitli soru türleri ile adayın sözel yeteneklerini kapsamlı
                                        bir şekilde değerlendirmeyi hedeflemektedir. Aday <span class="">{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> sorudan oluşan bu testte <span class="">{{category.answeredQuestionsCount}}</span>
                                        soruyu yanıtlamıştır. Aday sorulardan <span class="">{{category.correctAnsweredQuestionsCount}}</span> tane soruyu doğru, <span class="">{{category.incorrectAnsweredQuestionsCount}}</span>
                                        tane soruyu yanlış cevaplamış ve <span class="">{{category.notAnsweredQuestionsCount}}</span> tane soruyu boş bırakmıştır. Bu doğrultuda adayın aldığı puan
                                        '<span class="">{{category.totalScores}}</span>' kategorisinde değerlendirilmiştir.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="my-4 w-32rem">
                                <span class="font-semibold text-sm my-5 text-blue-900">{{'Key.CheatingAnalysis' | translate}}</span>
                                
                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.Device' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs">
                                        <p *ngIf="item.deviceType !== null">{{item.deviceType | deviceTypes:deviceTypes }} </p>
                                        <p *ngIf="item.deviceType === null">{{'Key.DeviceNotFound' | translate}} </p>
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.SamePageUsed' | translate}}
                                    </div>
                                    <div *ngIf="!item.isSwitchedTab"
                                        class="flex align-items-center justify-content-center font-semibold text-xs completed-text-color">
                                        {{'Key.Yes' | translate}}
                                    </div>
                                    <div *ngIf="item.isSwitchedTab"
                                        class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#F20505">
                                        {{'Key.No' | translate}}
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2" *ngIf="item.isAllowedFaceDetectedLog">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.FaceDetectedLog' | translate}}
                                    </div>
                                    <div *ngIf="item.isFaceDetectedLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs"
                                        style="color:#F20505">
                                        {{'Key.Yes' | translate}}
                                    </div>
                                    <div *ngIf="!item.isFaceDetectedLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs completed-text-color">
                                        {{'Key.No' | translate}}
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2" *ngIf="item.isAllowedMultipleFaceLog">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.MultiFaceDetectedLog' | translate}}
                                    </div>
                                    <div *ngIf="item.isMultipleFaceLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs"
                                        style="color:#F20505">
                                        {{'Key.Yes' | translate}}
                                    </div>
                                    <div *ngIf="!item.isMultipleFaceLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs completed-text-color">
                                        {{'Key.No' | translate}}
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2" *ngIf="item.isAllowedImproprietySpeechLog">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.LanguageInappropriateness' | translate}}
                                    </div>
                                    <div *ngIf="item.isImproprietySpeechLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs"
                                        style="color:#F20505">
                                        {{'Key.Yes' | translate}}
                                    </div>
                                    <div *ngIf="!item.isImproprietySpeechLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs completed-text-color">
                                        {{'Key.No' | translate}}
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.FaceDetectionStatusLog' | translate}}
                                    </div>
                                    <div *ngIf="item.faceDetectionStatusLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs"
                                        style="color:#F20505">
                                        {{'Key.No' | translate}}
                                    </div>
                                    <div *ngIf="!item.faceDetectionStatusLog"
                                        class="flex align-items-center justify-content-center font-semibold text-xs completed-text-color">
                                        {{'Key.Yes' | translate}}
                                    </div>
                                </div>

                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center pdf-text-size text-gray-500">
                                        {{'Key.MultipleMonitor' | translate}}
                                    </div>
                                    <div *ngIf="!item.isMultipleMonitor"
                                        class="flex align-items-center justify-content-center font-semibold text-xs completed-text-color">
                                        {{'Key.No' | translate}}
                                    </div>
                                    <div *ngIf="item.isMultipleMonitor"
                                        class="flex align-items-center justify-content-center font-semibold text-xs"
                                        style="color:#F20505">
                                        {{'Key.Yes' | translate}}
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                                <div class="my-4">
                                    <div class="my-4 mr-3 avoid w-32rem">
                                        <div class="flex justify-content-between flex-wrap my-2">
                                            <div class="flex align-items-center justify-content-center font-medium text-xs ">
                                                {{'Key.PdfVinterScore' | translate}}
                                            </div>
                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display w-12rem flex">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                            [style.backgroundColor]="'#3F26DC'"></div>
                                                    </div>
                                                    <div *ngIf="item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish && item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.QuickEnglish">
                                                        <p class="text-xs m-0">{{item.averageVinterScore?.toFixed(2)}}</p>
                                                    </div>
                                                    <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish" class="flex">
                                                        <p class="text-xs font-medium m-0"> {{item.generalEnglishTestResult?.generalLevel}}</p>
                                                        <p class="text-xs font-medium text-gray-500"> /{{item.averageVinterScore?.toFixed(2)}}</p>
                                                    </div>
                                                    <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.QuickEnglish" class="flex">
                                                        <p class="text-xs font-medium m-0"> {{item.quickEnglishLevel}}</p>
                                                        <p class="text-xs font-medium text-gray-500"> /{{item.averageVinterScore?.toFixed(2)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                                (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                                (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">
                                <div class="my-4">
                                    <div class="w-32rem my-4 mr-3 avoid">
                                        <div class="flex justify-content-between flex-wrap my-2">
                                            <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                                {{'Key.PdfVinterScore' | translate}}
                                            </div>
                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display w-12rem flex">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                            [style.backgroundColor]="'#3F26DC'"></div>
                                                    </div>
                                                    <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest)">
                        <div class="mx-7" id="ocean">
                            <div class="my-4">
                                <div #area class="mb-3 avoid w-32rem">
                                    <div class="ocean-grid">
                                        <div class="grid flex pb-3" style="border: none;">
                                            <div class="col-1 ocean-icon-width align-content-center custom-display">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/o-icon.svg"
                                                    width="22px">

                                            </div>
                                            <div
                                                class="col-5 pl-1 pr-0 font-medium text-sm flex flex-wrap align-content-center o-text-color w-auto">
                                                {{"Key.OpennessToExperience" | translate}}
                                            </div>
                                        </div>

                                        <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.o"
                                            [oceanCategory]="'o'" [oceanScore]="item?.stepPerTestResult?.o?.toFixed(0)"
                                            [oceanParent]="true"></app-ocean-progressbar>

                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                            class="flex justify-content-between">
                                            <div
                                                class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size h-full mt-2">
                                                    {{"Key.OpennessLowScoreDescription" | translate}}
                                                </p>

                                            </div>
                                            <div
                                                class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size text-right h-full mt-2">
                                                    {{"Key.OpennessHighScoreDescription" | translate}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid mx-0 pb-4 mt-0">
                                        <p class="text-justify pdf-text-size">
                                            {{item?.stepPerTestResult?.reportTextO}}
                                        </p>
                                    </div>

                                    <div
                                        *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                                {{"Key.Imagination" | translate}}
                                            </div>
                                            <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.imagination"
                                                        [oceanCategory]="'o'"
                                                        [oceanScore]="item?.stepPerTestResult?.imagination?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                                {{"Key.ArtisticInterest" | translate}}
                                            </div>
                                            <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.artisticInterests"
                                                        [oceanCategory]="'o'"
                                                        [oceanScore]="item?.stepPerTestResult?.artisticInterests?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                                {{"Key.Emotionality" | translate}}
                                            </div>
                                            <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.emotionality"
                                                        [oceanCategory]="'o'"
                                                        [oceanScore]="item?.stepPerTestResult?.emotionality?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                                {{"Key.Adventurousness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.adventurousness"
                                                        [oceanCategory]="'o'"
                                                        [oceanScore]="item?.stepPerTestResult?.adventurousness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                                {{"Key.Intellect" | translate}}
                                            </div>
                                            <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.intellect"
                                                        [oceanCategory]="'o'"
                                                        [oceanScore]="item?.stepPerTestResult?.intellect?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center o-text-color">
                                                {{"Key.Liberalism" | translate}}
                                            </div>
                                            <div class="col-9 pb-0  pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.liberalism"
                                                        [oceanCategory]="'o'"
                                                        [oceanScore]="item?.stepPerTestResult?.liberalism?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div #area class="mb-3 avoid w-32rem">
                                    <div class="ocean-grid">
                                        <div class="grid flex pb-3" style="border: none;">
                                            <div class="col-1 ocean-icon-width align-content-center custom-display">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/c-icon.svg"
                                                    width="22px">
                                            </div>
                                            <div
                                                class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center c-text-color w-auto">
                                                {{"Key.Conscientiousness" | translate}}
                                            </div>
                                        </div>

                                        <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.c"
                                            [oceanCategory]="'c'" [oceanScore]="item?.stepPerTestResult?.c?.toFixed(0)"
                                            [oceanParent]="true"></app-ocean-progressbar>

                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                            class="flex justify-content-between">

                                            <div
                                                class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size h-full mt-2">
                                                    {{"Key.ConscientiousnessLowScoreDescription" | translate}}
                                                </p>

                                            </div>
                                            <div
                                                class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size text-right h-full mt-2">
                                                    {{"Key.ConscientiousnessHighScoreDescription" | translate}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid mx-0 pb-4 mt-0">
                                        <p class="text-justify pdf-text-size">
                                            {{item?.stepPerTestResult?.reportTextC}}
                                        </p>
                                    </div>

                                    <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                                {{"Key.SelfEfficacy" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.selfEfficacy"
                                                        [oceanCategory]="'c'"
                                                        [oceanScore]="item?.stepPerTestResult?.selfEfficacy?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                                {{"Key.Orderliness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.orderliness"
                                                        [oceanCategory]="'c'"
                                                        [oceanScore]="item?.stepPerTestResult?.orderliness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                                {{"Key.Dutifulness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.dutifulness"
                                                        [oceanCategory]="'c'"
                                                        [oceanScore]="item?.stepPerTestResult?.dutifulness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                                {{"Key.AchievementStriving" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.achievementStriving"
                                                        [oceanCategory]="'c'"
                                                        [oceanScore]="item?.stepPerTestResult?.achievementStriving?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                                {{"Key.SelfDiscipline" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.selfDiscipline"
                                                        [oceanCategory]="'c'"
                                                        [oceanScore]="item?.stepPerTestResult?.selfDiscipline?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center c-text-color">
                                                {{"Key.Cautiousness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.cautiousness"
                                                        [oceanCategory]="'c'"
                                                        [oceanScore]="item?.stepPerTestResult?.cautiousness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div #area class="mb-3 avoid w-32rem">
                                    <div class="ocean-grid">
                                        <div class="grid flex pb-3" style="border: none;">
                                            <div class="col-1 ocean-icon-width align-content-center custom-display">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/e-icon.svg"
                                                    width="22px">
                                            </div>
                                            <div
                                                class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center blue-600 w-auto">
                                                {{"Key.Extraversion" | translate}}
                                            </div>
                                        </div>

                                        <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.e"
                                            [oceanCategory]="'e'" [oceanScore]="item?.stepPerTestResult?.e?.toFixed(0)"
                                            [oceanParent]="true"></app-ocean-progressbar>

                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                            class="flex justify-content-between">
                                            <div
                                                class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size h-full mt-2">
                                                    {{"Key.ExtraversionLowScoreDescription" | translate}}
                                                </p>

                                            </div>
                                            <div
                                                class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size text-right h-full mt-2">
                                                    {{"Key.ExtraversionHighScoreDescription" | translate}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid mx-0 pb-4 mt-0">
                                        <p class="text-justify pdf-text-size">
                                            {{item?.stepPerTestResult?.reportTextE}}
                                        </p>
                                    </div>

                                    <div
                                        *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                                {{"Key.Friendliness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.friendliness"
                                                        [oceanCategory]="'e'"
                                                        [oceanScore]="item?.stepPerTestResult?.friendliness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                                {{"Key.Assertiveness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.assertiveness"
                                                        [oceanCategory]="'e'"
                                                        [oceanScore]="item?.stepPerTestResult?.assertiveness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                                {{"Key.Gregariousness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.gregariousness"
                                                        [oceanCategory]="'e'"
                                                        [oceanScore]="item?.stepPerTestResult?.gregariousness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                                {{"Key.ActivityLevel" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.activityLevel"
                                                        [oceanCategory]="'e'"
                                                        [oceanScore]="item?.stepPerTestResult?.activityLevel?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                                {{"Key.ExcitementSeeking" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.excitementSeeking"
                                                        [oceanCategory]="'e'"
                                                        [oceanScore]="item?.stepPerTestResult?.excitementSeeking?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center blue-600">
                                                {{"Key.Cheerfulness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.cheerfulness"
                                                        [oceanCategory]="'e'"
                                                        [oceanScore]="item?.stepPerTestResult?.cheerfulness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div #area class="mb-3 avoid w-32rem">
                                    <div class="ocean-grid">
                                        <div class="grid flex pb-3" style="border: none;">
                                            <div class="col-1 ocean-icon-width align-content-center custom-display">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/a-icon.svg"
                                                    width="22px">
                                            </div>
                                            <div
                                                class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center a-text-color w-auto">
                                                {{"Key.Agreeableness" | translate}}
                                            </div>
                                        </div>

                                        <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.a"
                                            [oceanCategory]="'a'" [oceanScore]="item?.stepPerTestResult?.a?.toFixed(0)"
                                            [oceanParent]="true"></app-ocean-progressbar>

                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                            class="flex justify-content-between">
                                            <div
                                                class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size h-full mt-2">
                                                    {{"Key.AgreeablenessLowScoreDescription" | translate}}
                                                </p>

                                            </div>
                                            <div
                                                class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size text-right h-full mt-2">
                                                    {{"Key.AgreeablenessHighScoreDescription" | translate}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid mx-0 pb-4 mt-0">
                                        <p class="text-justify pdf-text-size">
                                            {{item?.stepPerTestResult?.reportTextA}}
                                        </p>
                                    </div>

                                    <div
                                        *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                                {{"Key.Trust" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.trust"
                                                        [oceanCategory]="'a'"
                                                        [oceanScore]="item?.stepPerTestResult?.trust?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                                {{"Key.Morality" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.morality"
                                                        [oceanCategory]="'a'"
                                                        [oceanScore]="item?.stepPerTestResult?.morality?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                                {{"Key.Altruism" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.altruism"
                                                        [oceanCategory]="'a'"
                                                        [oceanScore]="item?.stepPerTestResult?.altruism?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                                {{"Key.Cooperation" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.cooperation"
                                                        [oceanCategory]="'a'"
                                                        [oceanScore]="item?.stepPerTestResult?.cooperation?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                                {{"Key.Modesty" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.modesty"
                                                        [oceanCategory]="'a'"
                                                        [oceanScore]="item?.stepPerTestResult?.modesty?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center a-text-color">
                                                {{"Key.Sympathy" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.sympathy"
                                                        [oceanCategory]="'a'"
                                                        [oceanScore]="item?.stepPerTestResult?.sympathy?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div #area class="mb-3 avoid w-32rem">
                                    <div class="ocean-grid">
                                        <div class="grid flex pb-3" style="border: none;">
                                            <div class="col-1 ocean-icon-width align-content-center custom-display">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/n-icon.svg"
                                                    width="22px">
                                            </div>
                                            <div
                                                class="col-4 pl-1 pr-0 font-medium text-sm  flex flex-wrap align-content-center n-text-color w-auto">
                                                {{"Key.Neuroticism" | translate}}
                                            </div>
                                        </div>

                                        <app-ocean-progressbar [progressBarOceanScore]="item?.stepPerTestResult?.n"
                                            [oceanCategory]="'n'" [oceanScore]="item?.stepPerTestResult?.n?.toFixed(0)"
                                            [oceanParent]="true"></app-ocean-progressbar>

                                        <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120"
                                            class="flex justify-content-between">
                                            <div
                                                class="ocean-report-grid grid mt-3 ml-1 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size h-full mt-2">
                                                    {{"Key.NeuroticismLowScoreDescription" | translate}}
                                                </p>

                                            </div>
                                            <div
                                                class="ocean-report-grid grid mt-3 flex align-items-center justify-content-center">
                                                <p class="pdf-text-size text-right h-full mt-2">
                                                    {{"Key.NeuroticismHighScoreDescription" | translate}}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid mx-0 pb-4 mt-0">
                                        <p class="text-justify pdf-text-size">
                                            {{item?.stepPerTestResult?.reportTextN}}
                                        </p>
                                    </div>

                                    <div
                                        *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.OCEAN120">
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                                {{"Key.Anxiety" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.anxiety"
                                                        [oceanCategory]="'n'"
                                                        [oceanScore]="item?.stepPerTestResult?.anxiety?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                                {{"Key.Anger" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.anger"
                                                        [oceanCategory]="'n'"
                                                        [oceanScore]="item?.stepPerTestResult?.anger?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                                {{"Key.Depression" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.depression"
                                                        [oceanCategory]="'n'"
                                                        [oceanScore]="item?.stepPerTestResult?.depression?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                                {{"Key.SelfConsciousness" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.selfConsciousness"
                                                        [oceanCategory]="'n'"
                                                        [oceanScore]="item?.stepPerTestResult?.selfConsciousness?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                                {{"Key.Immoderation" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.immoderation"
                                                        [oceanCategory]="'n'"
                                                        [oceanScore]="item?.stepPerTestResult?.immoderation?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="grid flex flex-nowrap p-0" style="border: none;">
                                            <div
                                                class="col-3 pt-2 font-medium text-xs flex flex-wrap align-content-center n-text-color">
                                                {{"Key.Vulnerability" | translate}}
                                            </div>
                                            <div class="col-9 pb-0 pt-2 align-content-center custom-display">
                                                <div class="w-18rem ml-7">
                                                    <app-ocean-progressbar
                                                        [progressBarOceanScore]="item?.stepPerTestResult?.vulnerability"
                                                        [oceanCategory]="'n'"
                                                        [oceanScore]="item?.stepPerTestResult?.vulnerability?.toFixed(0)"
                                                        [oceanParent]="false"></app-ocean-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                                (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                                (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">
                            
                        <div class="mx-7">
                            <div class="my-4">
                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0" class="my-5">
                                    <div class="avoid w-32rem">
                                        <div class="flex">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/openEndedPdf.svg" width="24px">
                                            <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                style="color:#2885F1; ">
                                                {{item.positionStep.questionCollection.expectedAnswerOpenEndedCount}}
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                {{'Key.OpenEndedQuestions' | translate}}
                                            </div>

                                        </div>
                                        <div class="my-3 text-justify pdf-text-size w-32rem" style="width: 32rem;">
                                            <p>
                                                {{'Key.PdfOpenEndedInfo' | translate}}
                                            </p>
                                        </div>
                                        <div class="mr-3">
                                            <div class="flex justify-content-between flex-wrap my-2">
                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-xs ">
                                                    {{'Key.PdfVinterScore' | translate}}
                                                </div>
                                                <div class="flex align-items-center justify-content-center font-semibold">
                                                    <div class="custom-display w-12rem flex">
                                                        <div class="progress-bar-container mt-2 mr-2">
                                                            <div class="progress-bar"
                                                                [style.width]="item.positionStep.questionCollection.openEndedQuestionsAverageGptScore+ '%'"
                                                                [style.backgroundColor]="'#3F26DC'"></div>
                                                        </div>
                                                        <p class="text-xs ">
                                                            {{item?.positionStep?.questionCollection?.openEndedQuestionsAverageGptScore?.toFixed(2)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerCodingCount !== 0" class="my-5">
                                    <div class="avoid" style=" width: 32rem; ">
                                        <div class="flex">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/codingQuestionIcon.svg" width="24px">
                                            <div class="mr-2 align-item-center text-blue-800 align-self-center detailSubFont font-semibold font-size-12px">
                                                {{item.positionStep.questionCollection.expectedAnswerCodingCount}}
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                {{'Key.PdfCodingQuestions' | translate}}
                                            </div>

                                        </div>
                                        <div class="my-3 text-justify pdf-text-size w-32rem" style="width: 32rem;">
                                            <p>
                                                {{'Key.PdfCodingInfo' | translate}}
                                            </p>
                                        </div>
                                        <div class="mr-3">
                                            <div class="flex justify-content-between flex-wrap my-2">
                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-xs ">
                                                    {{'Key.PdfVinterScore' | translate}}
                                                </div>
                                                <div class="flex align-items-center justify-content-center font-semibold">
                                                    <div class="custom-display w-12rem flex">
                                                        <div class="progress-bar-container mt-2 mr-2">
                                                            <div class="progress-bar"
                                                                [style.width]="item.positionStep.questionCollection.codingQuestionsAverageGptScore+ '%'"
                                                                [style.backgroundColor]="'#3F26DC'"></div>
                                                        </div>
                                                        <p class="text-xs ">
                                                            {{item?.positionStep?.questionCollection?.codingQuestionsAverageGptScore?.toFixed(2)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0"
                                    class="my-5">
                                    <div class="avoid w-32rem">
                                        <div class="flex">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/multiChoicePdf.svg" width="24px">
                                            <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px" style="color:#722DDE;">
                                                {{item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount}}
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                {{'Key.MultiChoiceQuestions' | translate}}
                                            </div>
                                        </div>
                                        <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                            <p>
                                                {{'Key.PdfMultiChoiceInfo' | translate}}
                                            </p>

                                        </div>

                                        <div>
                                            <app-custom-progressbar [answeredQuestionsWithId]="answeredQuestionsWithId"
                                                [itemId]="item.positionStep.questionCollection.questionCollectionCategory">
                                            </app-custom-progressbar>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerVideoCount !== 0" class="my-5">
                                    <div class="avoid w-32rem">
                                        <div class="flex">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/videoPdf.svg" width="24px">
                                            <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px" style="color:#6255F3;">
                                                {{item.positionStep.questionCollection.expectedAnswerVideoCount}}
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                {{'Key.VideoQuestions' | translate}}
                                            </div>

                                        </div>

                                        <div class="my-3 text-justify pdf-text-size" style="width: 32rem;">
                                            <p>
                                                {{'Key.PdfVideoInfo' | translate}}
                                            </p>

                                        </div>

                                        <div class="mr-3">
                                            <div class="flex justify-content-between flex-wrap my-2">
                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-xs ">
                                                    {{'Key.PdfVinterScore' | translate}}
                                                </div>
                                                <div class="flex align-items-center justify-content-center font-semibold">
                                                    <div class="custom-display w-12rem flex">
                                                        <div class="progress-bar-container mt-2 mr-2">
                                                            <div class="progress-bar"
                                                                [style.width]="item.positionStep.questionCollection.videoQuestionsAverageGptScore + '%'"
                                                                [style.backgroundColor]="'#3F26DC'"></div>
                                                        </div>
                                                        <p class="text-xs ">
                                                            {{item?.positionStep?.questionCollection?.videoQuestionsAverageGptScore?.toFixed(2)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                        <div class="mx-7">
                            <div class="my-4">
                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0" class="my-7">
                                    <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.questionCount > 0">
                                        <div #area class="avoid w-32rem">
                                            <div class="flex">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-writing-pdf-badge.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                    style="color:#2D4ADE; ">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Writing' | translate}}
                                                </div>
                                            </div>

                                            <div class="my-3 text-justify pdf-text-size w-32rem">
                                                <p>
                                                    {{'Key.WritingPdfDesc' | translate}}
                                                </p>
                                            </div>

                                            <div class="my-5">
                                                <div class="flex justify-content-between flex-wrap my-2">
                                                    <div class="flex align-items-center justify-content-center pdf-text-size"
                                                        style="color: #686E75;">
                                                        {{'Key.WritingScore' | translate}}
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center font-semibold">
                                                        <div class="custom-display flex mr-3 progress-width">
                                                            <div class="progress-bar-container mt-2 mr-2">
                                                                <div class="progress-bar"
                                                                    [style.width]="(getCurrentStepQuestions(EQuestionCategories.Writing,item.positionStep.id)?.totalScores )+ '%'"
                                                                    [style.backgroundColor]="'#2D4ADE'"></div>
                                                            </div>
                                                            <div class="flex">
                                                                <p class="text-xs font-normal mr-1" style="color: #2D4ADE;">
                                                                    {{item.generalEnglishTestResult?.writingLevel}}/ </p>
                                                                <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                                    %{{(getCurrentStepQuestions(EQuestionCategories.Writing,
                                                                    item.positionStep.id)?.totalScores)?.toFixed(0)}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0" class="my-7">

                                    <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.questionCount > 0" #area class="avoid">
                                        <div #area class="avoid w-32rem">
                                            <div class="flex">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-listening-pdf-badge.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                    style="color:#9B2DDE; ">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Listening' | translate}}
                                                </div>
                                            </div>

                                            <div class="my-3 text-justify pdf-text-size w-32rem">
                                                <p>
                                                    {{'Key.ListeningPdfDesc' | translate}}
                                                </p>
                                            </div>

                                            <div class="my-5">
                                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                    class="flex justify-content-between flex-wrap">
                                                    <div class="flex mt-1 justify-content-center pdf-text-size"
                                                        style="color: #686E75;">
                                                        {{'Key.ListeningScore' | translate}}
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center font-semibold">
                                                        <div class="custom-display flex progress-width mr-3">
                                                            <div class="progress-bar-container mt-2 mr-2">
                                                                <div class="progress-bar"
                                                                    [style.width]="(getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                                    [style.backgroundColor]="'#CE4FBA'"></div>
                                                            </div>
                                                            <div class="flex">
                                                                <p class="text-xs font-normal mr-1" style="color: #CE4FBA;">
                                                                    {{item.generalEnglishTestResult?.listeningLevel}}/ </p>
                                                                <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                                    %{{(getCurrentStepQuestions(EQuestionCategories.Listening,
                                                                    item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <app-custom-progressbar
                                                    [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)]"
                                                    [itemId]="EQuestionCategories.Listening"></app-custom-progressbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.questionCount > 0" class="avoid w-32rem">
                                        <div class="flex">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-reading-pdf-badge.svg"
                                                width="24px">
                                            <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                style="color:#6931DF; ">
                                                {{getCurrentStepQuestions(EQuestionCategories.Reading,
                                                item.positionStep.id)?.questionCount}}
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                {{'Key.Reading' | translate}}
                                            </div>
                                        </div>

                                        <div class="my-3 text-justify pdf-text-size w-33rem">
                                            <p>
                                                {{'Key.ReadingPdfDesc' | translate}}
                                            </p>
                                        </div>

                                        <div class="my-5">
                                            <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                class="flex justify-content-between flex-wrap">
                                                <div class="flex mt-1 justify-content-center pdf-text-size"
                                                    style="color: #686E75;">
                                                    {{'Key.ReadingScore' | translate}}
                                                </div>
                                                <div class="flex align-items-center justify-content-center font-semibold">
                                                    <div class="custom-display flex progress-width mr-3">
                                                        <div class="progress-bar-container mt-2 mr-2">
                                                            <div class="progress-bar"
                                                                [style.width]="(getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.averageScores*100) + '%'"
                                                                [style.backgroundColor]="'#2DC9DE'"></div>
                                                        </div>
                                                        <div class="flex">
                                                            <p class="text-xs font-normal mr-1" style="color: #2DC9DE;">
                                                                {{item.generalEnglishTestResult?.readingLevel}}/ </p>
                                                            <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                                %{{(getCurrentStepQuestions(EQuestionCategories.Reading,
                                                                item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <app-custom-progressbar
                                                [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)]"
                                                [itemId]="EQuestionCategories.Reading"></app-custom-progressbar>
                                        </div>

                                    </div>

                                    <div *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount > 0">
                                        <div class="avoid w-32rem">
                                            <div class="flex">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                    style="color:#2E75FF;">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                    item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}}
                                                </div>
                                            </div>

                                            <div class="my-3 text-justify pdf-text-size w-32rem">
                                                <p>
                                                    {{'Key.GrammerPdfDesc' | translate}}
                                                </p>
                                            </div>

                                            <div class="my-5">
                                                <app-custom-progressbar
                                                    [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)]"
                                                    [itemId]="EQuestionCategories.Grammer"></app-custom-progressbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount > 0">
                                        <div class="avoid w-32rem">
                                            <div class="flex">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                    style="color:#7752FE;">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                    item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="my-3 text-justify pdf-text-size w-32rem">
                                                <p>
                                                    {{'Key.VocabularyPdfDesc' | translate}}
                                                </p>
                                            </div>

                                            <div class="my-5">
                                                <app-custom-progressbar
                                                    [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)]"
                                                    [itemId]="EQuestionCategories.Vocabulary"></app-custom-progressbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="(item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish)">
                                        <div #area class="avoid w-32rem">
                                            <div class="flex">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg"
                                                    width="24px">
                                                <img class="mr-1"
                                                    src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg"
                                                    width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                                    style="color:#7752FE; ">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                    item.positionStep.id)?.questionCount +
                                                    getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                    item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}} / {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="my-3 text-justify pdf-text-size w-32rem">
                                                <p>
                                                    {{'Key.VocabularyPdfDesc' | translate}}
                                                </p>
                                            </div>

                                            <div class="my-5">
                                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                    class="flex justify-content-between flex-wrap">
                                                    <div class="flex mt-1 justify-content-center pdf-text-size"
                                                        style="color: #686E75;">
                                                        {{'Key.GrammerVocabularyScore' | translate}}
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center font-semibold">
                                                        <div class="custom-display flex progress-width mr-3">
                                                            <div class="progress-bar-container mt-2 mr-2">
                                                                <div class="progress-bar"
                                                                    [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.totalScores + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.totalScores)
                                                                                                        /(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount) + '%'"
                                                                    [style.backgroundColor]="'#0E9776'"></div>
                                                            </div>
                                                            <div class="flex">
                                                                <p class="text-xs font-normal mr-1" style="color: #0E9776;">
                                                                    {{item.generalEnglishTestResult?.grammarVocabularyLevel}}/
                                                                </p>
                                                                <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                                    %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                                    item.positionStep.id)?.totalScores +
                                                                    getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                                    item.positionStep.id)?.totalScores)
                                                                    /(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                                    item.positionStep.id)?.questionCount +
                                                                    getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                                    item.positionStep.id)?.questionCount).toFixed(0)}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <app-custom-progressbar [answeredQuestionsWithId]="languageAnsweredQuestions"
                                                    [isGrammarVocabulary]="true" [positionStepId]="item.positionStep.id"
                                                    [itemId]="1"></app-custom-progressbar>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.questionCount > 0"
                            class="avoid ml-7 my-1 w-32rem">
                            <div class="flex">
                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-speaking-pdf-badge.svg"
                                    width="24px">
                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold font-size-12px"
                                    style="color:#D11ABF; ">
                                    {{getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.questionCount}}
                                </div>

                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                    {{'Key.Speaking' | translate}}
                                </div>
                            </div>

                            <div class="my-3 text-justify pdf-text-size w-33rem">
                                <p>
                                    {{'Key.SpeakingPdfDesc' | translate}}
                                </p>
                            </div>

                            <div class="my-5">
                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                    class="flex justify-content-between flex-wrap">
                                    <div class="flex mt-1 justify-content-center pdf-text-size"
                                        style="color: #686E75;">
                                        {{'Key.SpeakingScore' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display flex progress-width mr-3">
                                            <div class="progress-bar-container mt-2 mr-2">
                                                <div class="progress-bar"
                                                    [style.width]="(getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.totalScores) + '%'"
                                                    [style.backgroundColor]="'#2DC9DE'"></div>
                                            </div>
                                            <div class="flex">
                                                <p class="text-xs font-normal mr-1" style="color: #2DC9DE;">
                                                    {{item.generalEnglishTestResult?.speakingLevel}}/ </p>
                                                <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                    %{{(getCurrentStepQuestions(EQuestionCategories.Speaking,
                                                    item.positionStep.id)?.totalScores)?.toFixed(0)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <app-custom-progressbar
                                    [answeredQuestionsWithId]="[getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)]"
                                    [itemId]="EQuestionCategories.Speaking"></app-custom-progressbar>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation)">
                        <div class="mx-7">
                            <div class="my-4">
                                <div class="flex pdf-text-size" style="color: #686E75;">
                                    <p>
                                        {{"Key.Question" | translate}}
                                        {{item.positionStep.questionCollection.expectedAnswerVideoCount}}
                                    </p>
                                </div>

                                <div class="my-4 mr-3">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div class="flex align-items-center justify-content-center font-medium text-xs ">
                                            {{"Key.AveragePronunciationScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">{{item.averageVinterScore?.toFixed(2)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div #area class="my-4 mr-3 ml-3 avoid w-32rem">
                                    <div class="flex font-medium text-xs mb-3">
                                        {{"Key.ScoreBreakdown" | translate}}
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size "
                                            style="color: #686E75;">
                                            {{"Key.completeness" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size "
                                            style="color: #686E75;">
                                            {{"Key.accuracy" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.FluencyScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.fluencyTimeScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size"
                                            style="color: #686E75;">
                                            {{"Key.fluencyDisruptorScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="report-footer-2">
                    <div class="footer-middle">
                        <img src='{{organizationLogo}}' alt="Koç Logo" class="organization-logo">
                        <div class="footer-line"> </div>
                        <div class="footer-right">
                            <span class="page-number">{{ i+2 }}</span>
                        </div>
                    </div>
                </footer>
            </div>
    </ng-container>
    </div>
</div>