import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { VinterAICommentIcon } from '@app/shared/common/constants';
import { EApplicantBehaviourEventType, EFaceDetectionStatus, EntityStateEnum, GPTEvalStatus, ProcessStatus } from '@app/shared/common/enums';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';
import { CodingAnswerItem } from '@app/vinter/models/answer/coding-answer-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';

@Component({
  selector: 'app-coding-answer',
  templateUrl: './coding-answer.component.html',
  styleUrls: ['./coding-answer.component.scss']
})
export class CodingAnswerComponent implements OnInit {
  @Input() answer: CodingAnswerItem;
  gptEvalStatusEnum = GPTEvalStatus;
  processStatusEnum = ProcessStatus;
  parsedCodingAnalysisResultDetail: any;
  vinterAICommentIcon = VinterAICommentIcon;
  questionPageHiddenLog: number;
  EFaceDetectionStatus = EFaceDetectionStatus;

  constructor(private answerContentService : AnswerContentService,
    private eventLogService: ApplicantBehaviourEventLogService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.answer){
      if (this.answer?.codingAnalysis ?? false) {
        this.parsedCodingAnalysisResultDetail = (JSON.parse(this.answer.codingAnalysis.analysisResultDetail.toString()));
      }
      this.getPageHiddenLog();
    }
  }
  
  ngOnInit(): void {
    if(this.answer){
      if (this.answer?.codingAnalysis ?? false) {
        this.parsedCodingAnalysisResultDetail = (JSON.parse(this.answer.codingAnalysis.analysisResultDetail.toString()));
      }
      this.getPageHiddenLog();
    }
    
  }

  setGptStatus(){
    let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
    answerContentForm = this.answer?.answerContent;
    answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
    answerContentForm.processStatus = this.processStatusEnum.AIContainerWaiting;
    answerContentForm.entityState = EntityStateEnum.Modified;
    answerContentForm.xmin = this.answer?.answerContent?.xmin;
  
    this.answerContentService.SaveAsync(answerContentForm).subscribe();
  }
  getPageHiddenLog(){
    let eventLogFilter: any = {};
    eventLogFilter.applicationStepId = this.answer.applicationStepId;
    eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
    eventLogFilter.questionId = this.answer.questionId;
    this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
        this.questionPageHiddenLog = eventLog.length;
    });
}

}
