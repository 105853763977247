<div>
    <div class="flex align-items-start">
        <div class="chart-container">
            <app-circle-chart [calculatedProgressValue]="step.calculatedProgressValue" [strokeDasharray]="step.strokeDasharray" [progressValue]="step.progressValue" [strokeColor]="step.strokeColor"></app-circle-chart>
        </div>
        
        <div class="flex-1">
            <p class="candidate-percentage">
                {{'Key.ApplicantPercentage' | translate}}<span class="font-bold text-blue-500"> %{{applicantPercentage.toFixed(0)}}</span>
            </p>
            <p class="candidate-percentage">
                {{'Key.ApplicantScoreWithCefr' | translate}}<span class="font-bold ml-2">{{step.averageVinterScore.toFixed(2)}} - {{step.quickEnglishLevel}}</span>
            </p>
            <div class="flex flex-column">
                <p class="description-text" [innerHTML]="firstPartReportText"></p>
                    
            </div>
        </div>
    </div>
    
    <p class="description-text" [innerHTML]="secondPartReportText"></p>

</div>



