<div *ngIf="isPdfOpen" class="hidden" id="parentSummaryDivId" >
    <div id="applicantInfoSummaryPdf" class="h-auto pdf-width">
        <div id="info">

            <div #pdfCoverPage class="report relative overflow-hidden">
                <div class="absolute top-0 left-0 w-full h-full">
                    <img src="../../../../../assets/vinter/icons/pdfReport/pdf-background-gradient.png" alt="Gradient Background" class="w-full h-full">
                    <div class="blue-section absolute bg-blue-600 bottom-0 pdf-width">
                        <div class="report-footer flex align-item-center justify-content-center align-items-center">
                            <div class="line1 bg-blue-500"></div>
                            <h3 class="mx-3 text-white my-0">{{'Key.ReportName' | translate}} </h3>
                            <div class="line2 bg-blue-500"></div>
                        </div>
                    </div>
                </div>

                <div class="content absolute top-0 right-0 bg-gray-900">
                    <div class="header">
                        <img class="align-items-center" src="../../../../../assets/vinter/icons/pdfReport/vinter-logo-pdf.svg" alt="Vinter Logo" class="logo">
                    </div>
                    <div class="position">
                        <h3 class="text-blue-400 font-semibold mt-0" >{{'Key.PositionNameTitle' | translate}} </h3>
                        <h1 class="font-bold mt-0">{{positionTitle}}</h1>
                    </div>
                    <div class="candidate-info inline-flex flex-column align-items-start h-auto sticky">
                        <h2 class="m-0 text-gray-800 font-bold">{{applicant.firstName + " " + applicant.lastName}}</h2>
                        <p *ngIf="isCompletedPosition" class="completed-date-text text-blue-600 text-center font-medium -mb-2 mt-3">{{stepCompletedDate | date:'dd/MM/yy'}}</p>
                        <div *ngIf="isCompletedPosition" class="flex align-items-center completed-position-container completed-text-color" >
                            <i class="pi pi-circle-fill completed-text-color"></i>
                            <p class="completed-text completed-text-color text-center font-medium flex align-items-center justify-content-center mb-0">{{'Key.Completed' | translate}} </p>
                        </div>

                        <hr class="custom-hr m-0 -mb-2">

                        <div class="flex align-items-center mt-3">
                            <div class="flex align-items-center">
                                <div class="progress-container border-round-lg mr-2">
                                    <div class="progress-bar-coverPage border-round-md"
                                        [style.width.%]="totalScore !== 0 ? totalScore : 2"
                                        [style.background-color]="getProgressBarColor(totalScore)"></div>
                                </div>
                            </div>
                            <span class="flex font-bold text-gray-900 align-items-baseline" style="width: 45px;">
                                <span class="big">{{getIntegerPart(totalScore) }}</span>
                                <span class="text-sm">.{{ getDecimalPart(totalScore) }}</span>
                            </span>
                        </div>

                        <div class="contact-info">
                            <div class="label text-gray-700 flex">
                                <img src="assets/vinter/icons/pdf-mail.svg" class="mt-2 w-1rem h-1rem">
                                <div class="text-justify ml-2 mt-2">
                                    {{applicant.email}}
                                </div>
                            </div>
                            <div  class="label text-gray-700 flex">
                                <img src="assets/vinter/icons/pdf-phone.svg" class="mt-2 w-1rem h-1rem">
                                <div class="text-justify ml-2 mt-2">
                                    {{applicant.phone}}
                                </div>
                            </div>
                            <div *ngIf="applicant?.userProfile?.linkedinProfileURL ?? false"  class="label text-gray-700 flex">
                                <img src="assets/vinter/icons/pdf-linkedin.svg" class="mt-2 w-1rem h-1rem">
                                <div class="text-justify ml-2 mt-2">
                                    {{applicant?.userProfile?.linkedinProfileURL}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="applicant-pdf-container flex flex-column m-7 mb-0 mt-4">
                <div class="flex justify-content-between flex-wrap">
                    <div *ngFor="let item of applicationStepsData; let i = index">

                        <div *ngIf="(item.completedDate ?? false)" class="mr-7">
                            <div #area class="avoid">
                                <span class="font-semibold text-lg my-5">{{item.positionStep.title}}</span>
                            </div>

                            <div #area class="my-4 text-justify pdf-text-size avoid w-31rem">
                                <p>
                                    {{item.stepTypeDesc | translate}}
                                </p>

                                <div *ngFor="let category of answeredQuestionsWithId">
                                    <p *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                                    && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                                    && currentLanguageId === LanguageEnum.EnglishUnitedStates
                                    && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                                    Candidate <span>{{applicant.firstName + " " + applicant.lastName}}</span> has completed the Verbal Cognitive Ability Test. This test measures the candidate's ability to comprehend and analyze
                                    complex texts, visual attention/visual interpretation, verbal reasoning, application of grammar rules, verbal reasoning, critical thinking, synthesizing information,
                                    listening comprehension, summarizing and reconstructing, vocabulary and solving verbal patterns, and grammatical knowledge. The test aims to comprehensively assess the candidate's
                                    verbal abilities through a variety of question types. The candidate answered <span>{{category.answeredQuestionsCount}}</span> questions in this test consisting of
                                    <span>{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> questions. The candidate answered <span>{{category.correctAnsweredQuestionsCount}}</span> questions correctly,
                                    <span>{{category.incorrectAnsweredQuestionsCount}}</span> questions incorrectly and <span>{{category.notAnsweredQuestionsCount}}</span> questions blank.
                                    Accordingly, the score of the candidate was evaluated in the category '<span>{{category.totalScores}}</span>'.
                                    </p>
                                </div>
        
                                <div *ngFor="let category of answeredQuestionsWithId">
                                    <p *ngIf="item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility
                                        && item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.VerbalCognitiveAbility
                                        && currentLanguageId === LanguageEnum.TurkishTurkey
                                        && (category.answeredQuestionsCount + category.notAnsweredQuestionsCount > 0)">
                                        Aday <span class="">{{applicant.firstName + " " + applicant.lastName}}</span> Sözel Bilişsel Yetenek Testini tamamlamıştır.Bu test, adayın karmaşık metinleri anlama ve analiz etme, görsel dikkat/görsel
                                        yorumlama, sözel muhakeme yapma, dil bilgisi kurallarını uygulayabilme, sözel mantık yürütme, eleştirel düşünme, bilgileri sentezleme, dinlediğini anlama, özetleme ve yeniden
                                        yapılandırma, kelime bilgisi ve sözel kalıpları çözme yeteneklerini ve gramer bilgisini ölçmektedir. Test, içerdiği çeşitli soru türleri ile adayın sözel yeteneklerini kapsamlı
                                        bir şekilde değerlendirmeyi hedeflemektedir. Aday <span class="">{{category.answeredQuestionsCount + category.notAnsweredQuestionsCount}}</span> sorudan oluşan bu testte <span class="">{{category.answeredQuestionsCount}}</span>
                                        soruyu yanıtlamıştır. Aday sorulardan <span class="">{{category.correctAnsweredQuestionsCount}}</span> tane soruyu doğru, <span class="">{{category.incorrectAnsweredQuestionsCount}}</span>
                                        tane soruyu yanlış cevaplamış ve <span class="">{{category.notAnsweredQuestionsCount}}</span> tane soruyu boş bırakmıştır. Bu doğrultuda adayın aldığı puan
                                        '<span class="">{{category.totalScores}}</span>' kategorisinde değerlendirilmiştir.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest)">                      
                            <div #area class="mr-7 avoid" id="ocean">
                                <div class="ocean-grid">
                                    <div id="oceanReportO" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/o-icon.svg" width="22px">
                                            <div class="flex align-items-center justify-content-center font-medium text-sm o-text-color">
                                                {{"Key.OpennessToExperience" | translate}}
                                            </div>

                                        </div>
                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div class="w-18rem">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.o"
                                                    [oceanCategory]="'o'"
                                                    [oceanScore]="item?.stepPerTestResult?.o?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="oceanReportC" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/c-icon.svg" width="22px">
                                            <div
                                                class="flex align-items-center justify-content-center font-medium text-sm c-text-color">
                                                {{"Key.Conscientiousness" | translate}}
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div class="w-18rem">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.c"
                                                    [oceanCategory]="'c'"
                                                    [oceanScore]="item?.stepPerTestResult?.c?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="oceanReportE" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/e-icon.svg" width="22px">
                                            <div class="flex align-items-center justify-content-center font-medium text-sm blue-600">
                                                {{"Key.Extraversion" | translate}}
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div class="w-18rem">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.e"
                                                    [oceanCategory]="'e'"
                                                    [oceanScore]="item?.stepPerTestResult?.e?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="oceanReportA" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/a-icon.svg" width="22px">
                                            <div class="flex align-items-center justify-content-center font-medium text-sm a-text-color">
                                                {{"Key.Agreeableness" | translate}}
                                            </div>
                                        </div>
                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div class="w-18rem">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.a"
                                                    [oceanCategory]="'a'"
                                                    [oceanScore]="item?.stepPerTestResult?.a?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="oceanReportN" class="flex justify-content-between flex-wrap pb-3">
                                        <div class="flex justify-content-center">
                                            <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/n-icon.svg" width="22px">
                                            <div class="flex align-items-center justify-content-center font-medium text-sm n-text-color">
                                                {{"Key.Neuroticism" | translate}}
                                            </div>
                                        </div>

                                        <div class="flex align-items-center justify-content-center pt-3 pr-3">
                                            <div class="w-18rem">
                                                <app-ocean-progressbar
                                                    [progressBarOceanScore]="item?.stepPerTestResult?.n"
                                                    [oceanCategory]="'n'"
                                                    [oceanScore]="item?.stepPerTestResult?.n?.toFixed(0)"
                                                    [oceanParent]="false"></app-ocean-progressbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.PersonalityTest) &&
                                    (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.ForeignLanguage) &&
                                    (item.positionStep.questionCollection.questionCollectionCategory !== QuestionCollectionCategoryEnum.Pronunciation)">

                            <div #area class="my-4 mr-7 avoid">
                                <div class="flex justify-content-between flex-wrap my-2">
                                    <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                        {{'Key.PdfVinterScore' | translate}}
                                    </div>
                                    <div class="flex align-items-center justify-content-center font-semibold">
                                        <div class="custom-display w-12rem flex">
                                            <div class="progress-bar-container mt-2 mr-2">
                                                <div class="progress-bar" [style.width]="item.averageVinterScore + '%'"
                                                    [style.backgroundColor]="'#3F26DC'"></div>
                                            </div>
                                            <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage)">
                            <div class="mr-7">
                                <div #area class="my-4 avoid">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div class="flex align-items-center justify-content-center font-semibold text-xs ">
                                            {{'Key.PdfVinterScore' | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <div *ngIf="item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish && item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.QuickEnglish">
                                                    <p class="text-xs ">{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish" class="flex">
                                                    <p class="text-xs font-medium mb-0">
                                                        {{item.generalEnglishTestResult?.generalLevel}}</p>
                                                    <p class="text-xs font-medium" style="color: #686E75;">
                                                        /{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                                <div *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.QuickEnglish" class="flex">
                                                    <p class="text-xs font-medium mb-0">
                                                        {{item.quickEnglishLevel}}</p>
                                                    <p class="text-xs font-medium" style="color: #686E75;">
                                                        /{{item.averageVinterScore?.toFixed(2)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerOpenEndedCount !== 0">
                                    <div #area *ngIf="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.questionCount > 0"
                                        class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-writing-pdf-badge.svg"  width="24px">
                                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Writing' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="getCurrentStepQuestions(EQuestionCategories.Writing, item.positionStep.id)?.totalScores + '%'"
                                                            [style.backgroundColor]="'#2D4ADE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #2D4ADE;">
                                                            {{item.generalEnglishTestResult?.writingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            {{(getCurrentStepQuestions(EQuestionCategories.Writing,
                                                            item.positionStep.id)?.totalScores)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="item.positionStep.questionCollection.expectedAnswerMultipleChoiceCount !== 0">
                                    <div #area *ngIf="getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.questionCount > 0" class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-listening-pdf-badge.svg" width="24px">
                                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Listening' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Listening, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#CE4FBA'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #CE4FBA;">
                                                            {{item.generalEnglishTestResult?.listeningLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Listening,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area *ngIf="getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.questionCount > 0" class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-reading-pdf-badge.svg" width="24px">
                                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Reading' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Reading, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#2DC9DE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #6931DF;">
                                                            {{item.generalEnglishTestResult?.readingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Reading,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount > 0" class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg" width="24px">
                                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div #area *ngIf="(item.positionStep.questionCollection.subCategory !== QuestionCollectionSubCategory.GeneralEnglish) && getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount > 0" class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg" width="24px">
                                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.averageScores*100 )+ '%'"
                                                            [style.backgroundColor]="'#D71773'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.averageScores*100)?.toFixed(0)}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div #area *ngIf="(item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish)" class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-grammer-pdf-badge.svg" width="24px">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-vocabulary-pdf-badge.svg" width="24px">
                                                <div class="mr-2 align-item-center align-self-center detailSubFont font-semibold"
                                                    style="color:#7752FE; font-size: 12px;">
                                                    {{getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                    item.positionStep.id)?.questionCount +
                                                    getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                    item.positionStep.id)?.questionCount}}
                                                </div>

                                                <div
                                                    class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Grammer' | translate}} / {{'Key.Vocabulary' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.totalScores + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.totalScores)
                                                                                    /(getCurrentStepQuestions(EQuestionCategories.Vocabulary, item.positionStep.id)?.questionCount + getCurrentStepQuestions(EQuestionCategories.Grammer, item.positionStep.id)?.questionCount) + '%'"
                                                            [style.backgroundColor]="'#0E9776'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #0E9776;">
                                                            {{item.generalEnglishTestResult?.grammarVocabularyLevel}}/
                                                        </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.totalScores +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.totalScores)
                                                            /(getCurrentStepQuestions(EQuestionCategories.Vocabulary,
                                                            item.positionStep.id)?.questionCount +
                                                            getCurrentStepQuestions(EQuestionCategories.Grammer,
                                                            item.positionStep.id)?.questionCount).toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div #area *ngIf="getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.questionCount > 0" class="my-3 avoid">
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex justify-content-center">
                                                <img class="mr-1" src="../../../../../assets/vinter/icons/pdfReport/general-english-speaking-pdf-badge.svg" width="24px">
                                                <div class="flex align-items-center justify-content-center font-semibold text-sm">
                                                    {{'Key.Speaking' | translate}}
                                                </div>
                                            </div>

                                            <div class="flex align-items-center justify-content-center font-semibold">
                                                <div class="custom-display flex" style="width:14.3rem;">
                                                    <div class="progress-bar-container mt-2 mr-2">
                                                        <div class="progress-bar"
                                                            [style.width]="(getCurrentStepQuestions(EQuestionCategories.Speaking, item.positionStep.id)?.totalScores )+ '%'"
                                                            [style.backgroundColor]="'#2DC9DE'"></div>
                                                    </div>
                                                    <div class="flex">
                                                        <p *ngIf="item.positionStep.questionCollection.subCategory === QuestionCollectionSubCategory.GeneralEnglish"
                                                            class="text-xs font-normal mb-0 mr-1"
                                                            style="color: #CE4FBA;">
                                                            {{item.generalEnglishTestResult?.speakingLevel}}/ </p>
                                                        <p class="text-xs font-normal w-max" style="color: #686E75;">
                                                            %{{(getCurrentStepQuestions(EQuestionCategories.Speaking,
                                                            item.positionStep.id)?.totalScores)?.toFixed(0)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div  #area class="avoid" *ngIf="(item.completedDate ?? false) && (item.positionStep.questionCollection.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation)">
                            <div #area class="mr-7 avoid">
                                <div class="my-4">
                                    <div class="flex justify-content-between flex-wrap my-2">
                                        <div class="flex align-items-center justify-content-center font-medium text-xs ">
                                            {{"Key.AveragePronunciationScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.averageVinterScore + '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">{{item.averageVinterScore?.toFixed(2)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div  class="my-4 ml-3">
                                    <div class="flex font-medium text-xs mb-3">
                                        {{"Key.ScoreBreakdown" | translate}}
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                            {{"Key.completeness" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageCompletenessScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size " style="color: #686E75;">
                                            {{"Key.accuracy" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageAccuracyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                            {{"Key.FluencyScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                            {{"Key.fluencyTimeScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyTimeScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex justify-content-between flex-wrap my-1">
                                        <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                            {{"Key.fluencyDisruptorScore" | translate}}
                                        </div>
                                        <div class="flex align-items-center justify-content-center font-semibold">
                                            <div class="custom-display w-12rem flex">
                                                <div class="progress-bar-container mt-2 mr-2">
                                                    <div class="progress-bar"
                                                        [style.width]="item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore+ '%'"
                                                        [style.backgroundColor]="'#3F26DC'"></div>
                                                </div>
                                                <p class="text-xs blue-600">
                                                    {{item.positionStep.questionCollection.averagePronunciationScores.averageFluencyDisruptorScore?.toFixed(2)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr *ngIf="(item.completedDate ?? false)" class="custom-hr-summary my-4 mr-7">
                    </div>
                    <div #area class="my-4 mr-3 avoid w-33rem">
                        <span class="font-semibold text-sm my-5" style="color:#002075;">
                            {{'Key.CheatingAnalysis' |translate}}
                        </span>
                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                {{'Key.SamePageUsed' | translate}}
                            </div>
                            <div *ngIf="!isSwitchedTab" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#37AC00">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="isSwitchedTab" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#F20505">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2" *ngIf="isAllowedFaceDetectedLog">
                            <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                {{'Key.FaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="isFaceDetectedLog" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isFaceDetectedLog" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2" *ngIf="isAllowedMultipleFaceLog">
                            <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                {{'Key.MultiFaceDetectedLog' | translate}}
                            </div>
                            <div *ngIf="isMultipleFaceLog" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isMultipleFaceLog" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2" *ngIf="isAllowedImproprietySpeechLog">
                            <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                {{'Key.LanguageInappropriateness' | translate}}
                            </div>
                            <div *ngIf="isImproprietySpeechLog" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                            <div *ngIf="!isImproprietySpeechLog" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                        </div>

                        <div class="flex justify-content-between flex-wrap my-2">
                            <div class="flex align-items-center justify-content-center pdf-text-size" style="color: #686E75;">
                                {{'Key.MultipleMonitor' | translate}}
                            </div>
                            <div *ngIf="!isMultipleMonitor" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#37AC00">
                                {{'Key.No' | translate}}
                            </div>
                            <div *ngIf="isMultipleMonitor" class="flex align-items-center justify-content-center font-semibold text-xs" style="color:#F20505">
                                {{'Key.Yes' | translate}}
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </div>

    </div>

</div>
