import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { CredentialsService } from './core';
import { AppInfoService } from './shared/services/app-info.services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig,
        private credentialService: CredentialsService,
        public appInfo: AppInfoService,
        public translate: TranslateService,) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
    }


  isAutorized() {
    return this.credentialService.isAuthenticated();
  }

}
