<div *ngIf="reportType == ApplicantPdfReportTypeEnum.DetailPdf && isPdfDownload">
    <app-detail-pdf-report 
        [positionTitle]="positionTitle"
        [organizationImageUrl]="organizationImageUrl"
        [applicant]="applicantProfile"
        [organizationName]="organizationName"
        [applicationStepsData]="applicationStepsInfo"
        [answeredQuestionsWithId]="answeredQuestionsWithId"
        [languageAnsweredQuestions]="languageAnsweredQuestions"
        [totalScore]="totalScore"
        ></app-detail-pdf-report>
</div>

<div *ngIf="reportType == ApplicantPdfReportTypeEnum.SummaryPdf && isPdfDownload">
    <app-summary-pdf-report
        [positionTitle]="positionTitle"
        [applicant]="applicantProfile"
        [organizationImageUrl]="organizationImageUrl"
        [organizationName]="organizationName"
        [applicationStepsData]="applicationStepsInfo"
        [answeredQuestionsWithId]="answeredQuestionsWithId"
        [languageAnsweredQuestions]="languageAnsweredQuestions"
        [totalScore]="totalScore"
    ></app-summary-pdf-report>
</div>

<div *ngIf="(aiReportData ?? false) && reportType == ApplicantPdfReportTypeEnum.AIPdf && isPdfDownload">
    <app-ai-pdf-report
        [positionTitle]="positionTitle"
        [organizationImageUrl]="organizationImageUrl"
        [totalScore]="totalScore"
        [applicant]="applicantProfile"
        [applicationStepsData]="applicationStepsInfo"
        [answeredQuestionsWithId]="answeredQuestionsWithId"
        [languageAnsweredQuestions]="languageAnsweredQuestions"
        [aiReportData]="aiReportData"
        [organizationName]="organizationName"
    ></app-ai-pdf-report>
</div>
