import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { CredentialsService } from '@app/core';
import { QuestionCollectionCategoryEnum, EQuestionCollectionSubCategory, QuestionExpectedAnswerType, LanguageEnum, EQuestionCategories } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { UserManagementListItem } from '@app/vinter/models/user-profile-list-item';
import * as html2pdf from 'html2pdf.js';
import { vinterLogoWithoutTextPath } from '@app/shared/common/constants';

@Component({
  selector: 'app-detail-pdf-report',
  templateUrl: './detail-pdf-report.component.html',
  styleUrls: ['./detail-pdf-report.component.scss']
})
export class DetailPdfReportComponent implements OnInit{
  
  isPdfOpen: boolean = false;
  isCompletedPosition: boolean = false;
  stepCompletedDate: Date;
  organizationLogo: string;
  currentLanguageId:string;
  deviceTypes: any[];

  @Input() positionTitle: string;
  @Input() applicant: UserManagementListItem;
  @Input() applicationStepsData;
  @Input() organizationImageUrl: string;
  @Input() organizationName: string;
  @Input() answeredQuestionsWithId;
  @Input() languageAnsweredQuestions;
  @Input() totalScore: number;

  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  QuestionCollectionSubCategory = EQuestionCollectionSubCategory;
  QuestionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  LanguageEnum = LanguageEnum;
  EQuestionCategories = EQuestionCategories;
  vinterLogoPath: string = vinterLogoWithoutTextPath;

  constructor(
    private credentialsService: CredentialsService,
    private appInfoService: AppInfoService,
  ) { }

  ngOnInit(): void {
    this.organizationLogo = this.organizationImageUrl ?? false ? this.organizationImageUrl : this.vinterLogoPath;
    this.currentLanguageId = this.credentialsService.getLanguage();
    this.deviceTypes = this.appInfoService.getDeviceTypes();


    this.getCompletedDatePosition();
    this.generatePDF();
  }
  

  generatePDF() {
    this.isPdfOpen = true;
    setTimeout(() => {
        const element = document.getElementById('applicantInfoPdf');
        const childHeight = this.getHiddenElementHeight('parentDivId', 'applicantInfoPdf');

        const opt = {
          margin: [0, 0, 0, 0],
          filename: 'Detailed_Report_' + this.applicant.firstName + " "+ this.applicant.lastName + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          pagebreak: { avoid: "#area", mode: "css" },
          html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true, allowTaint: true, useCORS: true },
          jsPDF: { unit: 'px', format: [575, 822], orientation: 'portrait' },
        };

        html2pdf().from(element).set(opt).save();
        this.isPdfOpen = false;
    }, 0);
  }

  getHiddenElementHeight(parentId, elementId) {
    const parentElement = document.getElementById(parentId);
    const element = document.getElementById(elementId);

    const originalDisplay = parentElement.style.display;

    parentElement.style.display = 'block';
    parentElement.style.visibility = 'hidden';
    parentElement.style.position = 'absolute';

    const height = element.offsetHeight;

    parentElement.style.display = originalDisplay;
    parentElement.style.visibility = '';
    parentElement.style.position = '';

    return height;
  }

  getCompletedDatePosition(){
    const stepsWithId = this.applicationStepsData.filter(step => step.id);
    this.isCompletedPosition =  stepsWithId.every(step => step.completedDate !== null);

    if(this.isCompletedPosition){
      this.stepCompletedDate = stepsWithId[stepsWithId.length - 1].completedDate;
    }
  }

  getCurrentStepQuestions(id, stepId) {
    return this.languageAnsweredQuestions.find(x => x.id === id && x.positionStepId === stepId);
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

  getIntegerPart(score: number): string {
    let value = "00";
    if(score > 0)
      value = Math.floor(score).toString();
    return value;
  }

  getDecimalPart(score: number): string {
    const decimalPart = score.toFixed(2).split('.')[1];
    return decimalPart || '00'; 
  }

}

@Pipe({
  name: 'deviceTypes',
})
export class DeviceTypePipe implements PipeTransform {
  transform(row: any, list: any[]): any {
    return list.find(x => x.id === row).name;
  }
}
