<div class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <app-topbar></app-topbar>
<div class="layout-content-wrapper"  [ngStyle]="{'margin-left': (marginLeft) ? '' : '0rem'}">
        <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>