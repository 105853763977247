import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vinter-score-card',
  templateUrl: './vinter-score-card.component.html',
  styleUrls: ['./vinter-score-card.component.scss']
})
export class VinterScoreCardComponent {

  @Input() score: number | null | undefined = null;
  @Input() scoreChange: number | null | undefined = null;
  @Input() labelKey: string = 'TotalVinterScore';
  @Input() showTooltip: boolean = false;
  @Input() englishLevel: string | null = null;
  @Input() isWaitingForAI: boolean = false;
  
  Math = Math;

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

  getIntegerPart(score: number): string {
    let value = "00";
    if(score > 0)
      value = Math.floor(score).toString();
    return value;
  }

  getDecimalPart(score: number): string {
    const decimalPart = score.toFixed(2).split('.')[1];
    return decimalPart || '00'; 
  }
}
