import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mt-competence-test-card',
  templateUrl: './mt-competence-test-card.component.html',
  styleUrls: ['./mt-competence-test-card.component.scss']
})
export class MTCompetenceTestCardComponent {
  @Input() step: any;

  conceptList: any[] = [];

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.conceptList = this.analyzeQuestionConcepts();
  }

  analyzeQuestionConcepts() {
    const questions = this.step.positionStep.questionCollection.questionCollectionQuestionRelations;
    const conceptMap = new Map<string, any>();
    const result : any[] = [];
    questions.forEach((relation: any) => {
      const question = relation.question;
      const concept = question.questionConcept;
      if (!concept) return; 

      const conceptName = concept.name || concept.description;

      if (!conceptMap.has(conceptName)) {
        conceptMap.set(conceptName, {
          conceptName: conceptName,
          totalQuestions: 0,
          answeredQuestions: 0,
          correctAnswers: 0,
          incorrectAnswers: 0,
          notAnsweredQuestions: 0,
          totalWeight: 0,
          totalScore: 0
        });
      }

      const currentConcept = conceptMap.get(conceptName);
      currentConcept.totalQuestions++;

      if (question.isAnswered) {
        currentConcept.answeredQuestions++;
        currentConcept.totalWeight = currentConcept.totalWeight + relation.weight; 
        if(question.answerId && question.gptScore>0){
          currentConcept.correctAnswers++;
          currentConcept.totalScore = currentConcept.totalScore + (question.gptScore*relation.weight);
        }
        if(question.answerId && question.gptScore === 0){
          currentConcept.incorrectAnswers++;
        }
      }
      else{
        currentConcept.notAnsweredQuestions++;
      }
    });
    conceptMap.forEach((value, key) => {
      const percentage = value.totalQuestions > 0 ? (value.totalScore / value.totalWeight )  : 0;
      result.push({
        name:key,
        percentage: percentage,
        color: this.getProgressBarColor(percentage)
      })
    });
    return result;
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#DC2626';
    if (score <= 20) return '#DC2667';
    if (score <= 30) return '#DC2688';
    if (score <= 40) return '#DC269E';
    if (score <= 50) return '#AD26DC';
    if (score <= 60) return '#8126DC';
    if (score <= 70) return '#6026DC';
    if (score <= 80) return '#3F26DC';
    if (score <= 90) return '#1549D6';
    return '#2962FF';
  }

}
