import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum} from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class MailService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.Mail, notifyServ, localizationService, configService);
  }

  sendContactUsMail(form: any): Observable<any[]> {
    return this.postWithoutSpinner("saveContactUsMail", form, true);
  }

  SendUpgradeLicenseMail(body: any): Observable<any[]> {
    return this.postWithoutSpinner("SendUpgradeLicenseMail", body, true);
  }

}
