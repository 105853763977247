<div class="w-full flex p-0 mx-2 py-2">
    <div class="xl:col-6 lg:col-5 md:col-8 p-0 flex flex-column">
        <span class="custom-letter-spacing text-gray-800 font-semibold text-xs pb-2 ">{{"Key.Questions" |
            translate}}</span>
        <div class="flex flex-row">

            <div *ngIf="stepInfo?.questionCollectionResult?.expectedAnswerOpenEndedCount > 0">
                <app-custom-chip [label]="stepInfo?.questionCollectionResult?.expectedAnswerOpenEndedCount"
                    (click)="filterList(questionExpectedAnswerType.OpenEnded)" class="cursor-pointer" [svgUrl]="selectedFilters.includes(questionExpectedAnswerType.OpenEnded) 
                        ? 'assets/vinter/icons/icon-park_text-style-open-ended.svg' 
                        : 'assets/vinter/icons/icon-park_text-style-open-ended-outlined-lightblue.svg'"
                    [alt]="'OpenEnded'"
                    [bgColorClass]="selectedFilters.includes(questionExpectedAnswerType.OpenEnded) ? 'light-blue' : 'outlined-open-ended-class'"
                    [imgSize]="'16px'">
                </app-custom-chip>
            </div>

            <div *ngIf="stepInfo?.questionCollectionResult?.expectedAnswerMultipleChoiceCount > 0">
                <app-custom-chip [label]="stepInfo?.questionCollectionResult?.expectedAnswerMultipleChoiceCount"
                    (click)="filterList(questionExpectedAnswerType.MultipleChoice)" class="cursor-pointer" [svgUrl]="selectedFilters.includes(questionExpectedAnswerType.MultipleChoice) 
                        ? 'assets/vinter/icons/icon-park-outline_choice.svg' 
                        : 'assets/vinter/icons/icon-park-outline_choice-outlined-purple.svg'" [alt]="'MultipleChoice'"
                    [bgColorClass]="selectedFilters.includes(questionExpectedAnswerType.MultipleChoice) ? 'purple' : 'outlined-multiple-choice-class'"
                    [imgSize]="'16px'">
                </app-custom-chip>
            </div>

            <div *ngIf="stepInfo?.questionCollectionResult?.expectedAnswerVideoCount > 0">
                <app-custom-chip [label]="stepInfo?.questionCollectionResult?.expectedAnswerVideoCount"
                    (click)="filterList(questionExpectedAnswerType.Video)" class="cursor-pointer" [svgUrl]="selectedFilters.includes(questionExpectedAnswerType.Video) 
                        ? 'assets/vinter/icons/icon-park_text-tabler_video.svg' 
                        : 'assets/vinter/icons/icon-park_text-tabler_video-outlined-dark-blue.svg'"
                    [alt]="'VideoQuestion'"
                    [bgColorClass]="selectedFilters.includes(questionExpectedAnswerType.Video) ? 'dark-blue' : 'outlined-video-class'"
                    [imgSize]="'16px'">
                </app-custom-chip>
            </div>

            <div *ngIf="stepInfo?.questionCollectionResult?.expectedAnswerCodingCount > 0">
                <app-custom-chip [label]="stepInfo?.questionCollectionResult?.expectedAnswerCodingCount"
                    (click)="filterList(questionExpectedAnswerType.Coding)" class="cursor-pointer" [svgUrl]="selectedFilters.includes(questionExpectedAnswerType.Coding) 
                        ? 'assets/vinter/icons/pozisyon-detay-tabler-coding-question.svg' 
                        : 'assets/vinter/icons/pozisyon-detay-tabler-coding-question-outlined-blue.svg'"
                    [alt]="'CodingQuestion'"
                    [bgColorClass]="selectedFilters.includes(questionExpectedAnswerType.Coding) ? 'blue' : 'outlined-coding-class'"
                    [imgSize]="'16px'">
                </app-custom-chip>
            </div>

        </div>
    </div>
    <div class="xl:col-6 lg:col-7 md:col-4 p-0 justify-content-end flex align-items-center">
        <app-vinter-score-card [isWaitingForAI]="isWaitingForAI" [score]="stepInfo?.averageVinterScore" [labelKey]="'StepVinterScore'"
            class="justify-content-center flex"></app-vinter-score-card>
    </div>
</div>

<div *ngIf="filteredStepInfo ?? false" class="w-full p-0 height-fit">
    <app-step-question-result-list [questionList]="filteredStepInfo?.questionCollectionResult"
        [applicationStepId]="applicationStepId"></app-step-question-result-list>
</div>