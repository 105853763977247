import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { QuestionCollectionListItem, QuestionCollectionPostItemForDeprecate } from '../models/question-collection-list';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';
import { QuestionCollectionFormItem } from '../models/question-collection-form';
import { QuestionCollectionWithQuestionsListItem } from '../models/question-collection-with-questions-list';
import { QuestionListItem } from '../models/question-list-item';
import { QuestionCollectionQuestionCustomItem } from '../models/shared/question-collection-question-custom-item';
import { QuestionCollectionWithQuestions } from '../models/question-collection-with-questions';

@Injectable({
  providedIn: 'root'
})
export class QuestionCollectionService extends HttpBaseService{

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.QuestionCollection, notifyServ, localizationService, configService);
  }

  getListByFilter(filter: any): Observable<QuestionCollectionListItem[]> {
    return this.post("GetListByFilter", filter, true);
  }

  getListByFilterWithQuestionCount(filter: any): Observable<QuestionCollectionListItem[]> {
    return this.post("GetListByFilterWithQuestionCount", filter, true);
  }

  getListByFilterWithQuestionCountForCreate(filter: any): Observable<QuestionCollectionListItem[]> {
    return this.post("GetListByFilterWithQuestionCountForCreate", filter, true);
  }

  getListByFilterBelongsUserOrganizationID(filter: any): Observable<QuestionCollectionListItem[]> {
    return this.post("GetListByFilterBelongsUserOrganizationID", filter, true);
  }

  getListByFilterFromVinterOrganization(filter: any): Observable<QuestionCollectionListItem[]> {
    return this.post("GetListByFilterFromVinterOrganization", filter, true);
  }

  getDataByFilter(filter: any): Observable<QuestionCollectionListItem> {
    return this.post("GetDataByFilter", filter, true);
  }

  GetQuestionCollectionWithQuestions(questionCollectionID: string): Observable<QuestionCollectionWithQuestionsListItem>{
    return this.get("GetQuestionCollectionWihtQuestions?questionCollectionID=" + questionCollectionID);
  }

  GetQuestionCollectionWihtQuestionsChoice(questionCollectionID: string): Observable<QuestionCollectionWithQuestionsListItem>{
    return this.get("GetQuestionCollectionWihtQuestionsChoice?questionCollectionID=" + questionCollectionID);
  }

  GenerateRandomQuestions(questionCollectionID: string): Observable<QuestionCollectionWithQuestionsListItem>{
    return this.get("GenerateRandomQuestions?questionCollectionID=" + questionCollectionID);
  }

  saveQuestionCollectionAndQuestion(obj: QuestionCollectionQuestionCustomItem): Observable<any> {
    return this.post("SaveQuestionCollectionAndQuestion", obj, true);
  }

  changeDeprecation(obj: QuestionCollectionPostItemForDeprecate): Observable<any> {
    obj.entityState = EntityStateEnum.Modified;
    return this.post("SaveAsync", obj, true);
  }

  changeDeprecationTemplate(obj: QuestionCollectionPostItemForDeprecate): Observable<any> {
    obj.entityState = EntityStateEnum.Modified;
    return this.post("DeleteCollectionTemplate", obj, true);
  }

  getQuestionsCollectionWithQuestionList(filter: string[]): Observable<QuestionCollectionWithQuestions[]> {
    return this.post("GetQuestionsCollectionWithQuestionList", filter, true);
  }

}
