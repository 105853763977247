import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { CredentialsService } from '@app/core';
import { APPLICANT, HR, ORGADMIN, VINTERADMIN } from '@app/shared/common/constants';
import { MenuVisibilityService } from '@app/shared/services/menu-visibility.service';
import { EMenuVisibility } from '@app/shared/common/enums';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent implements OnInit {
    timeout: any = null;
    route: string;
    display="flex"

    @ViewChild('menuContainer') menuContainer!: ElementRef;
    constructor(public layoutService: LayoutService, 
        private menuVisibilityService:MenuVisibilityService,
        public el: ElementRef,
         private credentialService: CredentialsService) {}

    ngOnInit(): void {
        var userGroups= this.credentialService.getUserGroups().split(',');
        if (userGroups.some(s => s.includes(APPLICANT))) {
            this.route ='my-positions/list';
          }
          else if (userGroups.some(s => s.includes(ORGADMIN)) || userGroups.some(s => s.includes(HR))) {
            this.route ='dashboard/hr-dashboard';
          }
          else if (userGroups.some(s => s.includes(VINTERADMIN))) {
            this.route ='organization/spend';
          }

        this.menuVisibilityService.menuVisibility$.subscribe(visibility=>{
            if(visibility == EMenuVisibility.ShowTopBarSideBar)
                this.display="flex";
            else
                this.display="none";
        })
    }
    


    onMouseEnter() {
        if (!this.layoutService.state.anchored) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.layoutService.state.sidebarActive = true;
           
    
        }
    }

    onMouseLeave() {
        if (!this.layoutService.state.anchored) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => this.layoutService.state.sidebarActive = false, 300);
            }
        }
    }

    anchor() {
        this.layoutService.state.anchored = !this.layoutService.state.anchored;
    }

}
