import { Injectable } from '@angular/core';
import { ConfigurationService, CredentialsService } from '@app/core';
import { BehaviorSubject } from 'rxjs';
import * as signalR from "@microsoft/signalr"
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationCheckerService {

    private hubConnection: signalR.HubConnection

    private changeNotification = new BehaviorSubject<boolean>(false);
    changeNotification$ = this.changeNotification.asObservable();

    constructor(
      private credentialsService: CredentialsService,
      private configurationService:ConfigurationService,
      private messageService: MessageService,
      private credentialService: CredentialsService
    ) { }

    changeNewNotificationStatus(changeStatus: boolean) {
        this.changeNotification.next(changeStatus);
    }

    get notificationUrl() {
      return (this.configurationService.config != undefined) ? this.configurationService.config.notificationDomainUrl : undefined;
    }

    public startConnection = () => {
      const options: IHttpConnectionOptions = {
        accessTokenFactory: () => {
          return this.credentialsService.credentials.tokenWithoutAuth;
        }
      };

      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.notificationUrl,options)
      .build();

      this.hubConnection
        .start()
        .then(() => {})
        .catch(err => {})
    }

    public notificationListen = () => {
      this.hubConnection.on('newNotification', (data) => {
            let language = this.credentialService.getLanguage();
            let notificationData = data.find(x => x.languageId == language);
            this.messageService.add({key: 'notificationToast', severity:'success', summary: notificationData.iconPath, detail: notificationData.text});
            this.changeNewNotificationStatus(true);
        //
      });
    }

}
