import { Component, Input } from '@angular/core';
import { EUserConsentType, LanguageEnum } from '@app/shared/common/enums';

@Component({
  selector: 'app-user-consent',
  templateUrl: './user-consent.component.html',
  styleUrls: ['./user-consent.component.scss']
})

export class UserConsentComponent {
  @Input() userAgreementVisible: boolean;
  @Input() consentType: EUserConsentType;
  EUserConsentType = EUserConsentType;

  @Input() languageID: string;
  LanguageEnum = LanguageEnum;
}
