<div class="logo"></div>
<div class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden background">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
        <div *ngIf="!showMailPendingPage" class="col-12 xl:col-4">
            <div class="h-full w-full m-0 py-5 px-5 loginCard">
                <div class="w-full md:w-10 mx-auto">

                    <div class="header-container mb-4">
                        <div>
                            <div>
                                <img src='{{vinterLogoPath}}' alt="Vinter logo" class="w-full">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="pageTitleLogin">{{"Key.Welcome" | translate}}</div>
                        <p class="subTitleTextLogin">{{"Key.ResetPasswordDescription" | translate}}</p>
                    </div>
                        <div class="p-fluid w-full pt-6">
                            <div class="mb-4">
                                <div class="flex mb-2 align-items-center">
                                    <i class="pi pi-envelope"></i>
                                    <label class="text-title">{{ "Key.Mail" | translate }}</label>
                                    <div class="error-message flex">
                                        <p *ngIf="mailInput.touched && mailInput.errors?.required">*</p>
                                    </div>
                                </div>
                                <input id="email1" [(ngModel)]="mail" type="text" pInputText (keyup.enter)="checkInvitation()" #mailInput="ngModel" required />
                            </div>
                        </div>
                        <button pButton pRipple
                            label="{{'Key.Next' | translate}}"
                            class="buttonFontLogin bg-indigo-500 w-full p-3 login-Button" style="margin-top: 30px;"
                            (click)="checkInvitation()" icon="pi pi-arrow-right" iconPos="right"></button>
                </div>
            </div>
        </div>
        <div *ngIf="showMailPendingPage" class="col-12 xl:col-4">
            <div class="h-full w-full m-0 py-5 px-5 loginCard">
                <div class="w-full md:w-10 mx-auto">
                    <div class="header-container mb-4">
                        <div>
                            <div>
                                <img src='../../../../assets/vinter/icons/mail-big.svg' alt="check_mail" class="w-full">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="pageTitleLogin mb-4">{{"Key.CheckInboxHeader" | translate}}</div>
                        <p class="subTitleTextLogin">{{"Key.CheckInboxMessage" | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-center"></p-toast>
