import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EApplicantDetailMenu } from '@app/shared/common/enums';

@Component({
    selector: 'app-applicant-detail-tab-list',
    templateUrl: './applicant-detail-tab-list.component.html',
    styleUrls: ['./applicant-detail-tab-list.component.scss']
})
export class ApplicantDetailTabListComponent implements OnInit {

    @Input() applicationId: string;
    activeTabIndex = 0;
    ApplicantDetailMenu = EApplicantDetailMenu
    constructor(public translate: LocalizationService) {
    }

    ngOnInit(): void {

    }
}
