import { Injectable } from '@angular/core';
import { LanguageEnum } from '../shared/common/enums';
import { languageKey } from '../shared/common/constants';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    formatDate: any;
    langCode: string;
    langId: string;
    translatedTextList: any;
    translatePath = "Key";
    langList = ['en-US', 'tr-TR'];

    constructor(private translateService: TranslateService, private primeConfig: PrimeNGConfig) {
        this.getLangCode();
    }

    getLangCode(){
      if (JSON.parse(localStorage.getItem(languageKey)) != null) {
        this.langCode = JSON.parse(localStorage.getItem(languageKey)).code;
        this.langId = JSON.parse(localStorage.getItem(languageKey)).id;
      }else{
        this.langCode = "tr-TR";
        this.langId = LanguageEnum.TurkishTurkey;
      }
    }

    public initTranslate(http: HttpClient): Promise<void> {
      this.getLangCode();
      this.langId = this.langCode === 'tr-TR' ? LanguageEnum.TurkishTurkey : LanguageEnum.EnglishUnitedStates;
      this.translateService.addLangs(this.langList);
      this.translateService.setDefaultLang(this.langCode);
      this.translateService.get('primeng').subscribe(res => this.primeConfig.setTranslation(res));
      this.translateService.get(this.translatePath).subscribe(x => {
        this.translatedTextList = x;
      });
        return;
    }

    translateText(textPath: string){
      let text = '';
      this.translateService.get( this.translatePath +'.'+ textPath ).subscribe(x =>{
        text = x;
      })
      return text;
    }

    translateTextWithoutKey(textPath: string){
      let text = '';
      this.translateService.get( textPath ).subscribe(x =>{
        text = x;
      })
      return text;
    }
}
