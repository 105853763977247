import { Component, Input, OnInit } from '@angular/core';
import { UserCvListItem } from '@app/vinter/models/user-cv-list-item';
import { UserManagementService } from '@app/vinter/services/user-management.service';
import * as pdfjsLib from 'pdfjs-dist';
import { jsPDF } from 'jspdf';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-resume-tab',
    templateUrl: './resume-tab.component.html',
    styleUrls: ['./resume-tab.component.scss']
})

export class ResumeTabComponent implements OnInit {
    @Input() applicationId: string;
    userCVAndNameInfo: UserCvListItem = null;
    loading: boolean = true;

    constructor(private userManagementService: UserManagementService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.userManagementService.getUserCvFile(this.applicationId)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe({
                next: (response) => {
                    this.userCVAndNameInfo = response;
                },
                error: (error) => {
                    console.error('Error fetching CV file:', error);
                }
            });
    }

    get formattedCvFileName(): string {
        return `${this.userCVAndNameInfo.firstName}_${this.userCVAndNameInfo.lastName}_Cv.pdf`;
    }


    pdf2Image2Pdf(userCvFileUrl: string) {
        if (userCvFileUrl != null) {
            const loadingTask = pdfjsLib.getDocument(userCvFileUrl);

            loadingTask.promise.then((pdf) => {
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;

                const doc = new jsPDF();

                const totalPages = pdf.numPages;

                const processPage = (pageNum) => {
                    pdf.getPage(pageNum).then((page) => {
                        const viewport = page.getViewport({ scale: 1 });
                        const scale = Math.min(screenWidth / viewport.width, screenHeight / viewport.height);
                        const scaledViewport = page.getViewport({ scale });

                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        const higherResolutionScale = 2;
                        canvas.width = scaledViewport.width * higherResolutionScale;
                        canvas.height = scaledViewport.height * higherResolutionScale;

                        context.scale(higherResolutionScale, higherResolutionScale);

                        page.render({
                            canvasContext: context,
                            viewport: scaledViewport
                        }).promise.then(() => {
                            const imageData = canvas.toDataURL('image/png');

                            const pageWidth = doc.internal.pageSize.width;
                            const pageHeight = doc.internal.pageSize.height;
                            const imageWidth = canvas.width / 4;
                            const imageHeight = canvas.height / 4;

                            const scaleFactor = Math.min(pageWidth / imageWidth, pageHeight / imageHeight);
                            const finalImageWidth = imageWidth * scaleFactor;
                            const finalImageHeight = imageHeight * scaleFactor;

                            if (pageNum === 1) {
                                doc.addImage(imageData, 'PNG', 0, 0, finalImageWidth, finalImageHeight);
                            } else {
                                doc.addPage();
                                doc.addImage(imageData, 'PNG', 0, 0, finalImageWidth, finalImageHeight);
                            }

                            if (pageNum === totalPages) {
                                doc.save(this.formattedCvFileName);
                            }
                        });
                    });
                };

                for (let i = 1; i <= totalPages; i++) {
                    processPage(i);
                }

            }).catch((error) => {
                console.error('Error processing PDF:', error);
            });
        }
    }


}
