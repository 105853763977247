<div *ngIf="consentType == EUserConsentType.HrDisclosure && languageID == LanguageEnum.TurkishTurkey" [ngStyle]="{
    'max-height':'144',
    'overflow-y':'auto',
    'text-left':'left',
    'font-size':'xs',
    'font-family':'sans-serif',
    'color':'gray'
}" class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
text-gray-700">

    <p>Vinter AI Recruitment Ltd. (“<b>Şirket”</b>) olarak süreçlerimizi yürütürken başta temel hak ve özgürlüklerin korunması olmak üzere özel hayata ilişkin gizliliğin korunması, bilgi güvenliğinin sağlanması ve korunması, etik değerlere saygı öncelikli prensiplerimiz arasında yer almaktadır. Bu kapsamda, kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu (“<b>KVKK</b>”) hükümleri kapsamında ve işbu Abone / Alıcı Aydınlatma Metni (“<b>Aydınlatma Metni</b>”) çerçevesinde işlenmektedir.</p>
    <p>Şirketimiz, gizliliğinizin ve kişisel verilerinizin korunmasına ilişkin haklarınıza saygı duymakta ve sizinle güvene dayalı bir ilişki kurmak ve sürdürmek için çalışmaktadır. Bu kapsamda Şirketimiz, kişisel verilerinizi KVKK başta olmak üzere kişisel verilerin korunmasına ilişkin tüm mevzuat hükümlerine uygun şekilde işlemekte, verilerinizin güvenli bir şekilde barındırılmasını sağlamakta ve olası hukuka aykırı erişimlere karşı gerekli tüm teknik ve idari tedbirleri almaktadır. Bu Aydınlatma Metni, Şirketimizin <a class="text-xs" href="https://www.vinter.me/" target="_blank" rel="noreferrer">https://www.vinter.me</a> adresinde bulunan internet sitesi ("<b>Web Sitesi</b>") ve Web Sitesi üzerinden sunduğumuz hizmetlerden ("<b>Hizmetler"</b>) yararlanabilmesi amacıyla müşteri (“<b>Müşteri</b>”) ve müşteri yetkilisi (“<b>Müşteri Yetkilisi”</b>) ile kurduğumuz sözleşmesel ilişki çerçevesinde, veri sorumlusu sıfatıyla, topladığımız kişisel verilerinizin nasıl işlendiğini açıklamaktadır.</p>
    <p><b>Kişisel Verilerinizin Toplanma Yöntemleri</b></p>
    <p>Kişisel verileriniz, Şirketimiz tarafından fiziki ve/veya elektronik ortamlardan, Şirketimizin internet siteleri, matbu formlar, çağrı/ iletişim merkezi, e-posta, SMS, telefon, faks, kargo / posta, kanunen yetkili kurum ve kuruluşlar ve diğer gerçek kişi ve özel hukuk tüzel kişileri ile bunlara ileride eklenebilecek sair yöntemlerle elde edilmektedir.</p>
    <p><b>İşlenen Kişisel Verileriniz, İşlenme Amaçları</b></p>

    <table border="1">
    <thead>
      <tr>
        <th>Kişisel Veri Kategorileri</th>
        <th>Kişisel Verilerinizin İşlenme Amaçları</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>Kimlik Verisi</b></td>
        <td>
          <ul>
            <li>Sözleşme süreçlerinin yürütülmesi,</li>
            <li>Hukuki işlerin takibi ve yürütülmesi,</li>
            <li>Hizmet satış ve satış sonrası destek hizmeti süreçlerinin yürütülmesi,</li>
            <li>Hizmet satın alma konusunda talepte bulunan Müşteri Yetkilisi ile Müşteri’nin eşleştirilebilmesi,</li>
            <li>Talebiniz üzerine, Müşteri adına demo hesabı oluşturulması ve Website ve Hizmetler’e kuruluşunuz adına erişiminizin sağlanması,</li>
            <li>Web Sitemiz için üyelik, kayıt, akvitasyon ve kullanıcı doğrulama işlemlerinin yürütülmesi,</li>
            <li>Müşteri Yetkilisi’nin ve/veya Müşteri’nin Web Sitemize ve Hizmetlerimize tanımlama işlemlerinin tamamlanması,</li>
            <li>Müşteri’ye temin edilecek abonelik paketi ve bu kapsamdaki Hizmetler’e ilişkin eğitim süreçlerin yürütülmesi, bu kapsamda Hizmetler’imizden etkili bir şekilde yararlanmanızın sağlanması,</li>
            <li>Hizmetler ve Web Sitesine ilişkin destek hizmetlerinin yürütülmesi,</li>
            <li>İletişim faaliyetlerinin yürütülmesi,</li>
            <li>Finans ve muhasebe işlerinin yürütülmesi (örn. ücret ödemelerinin alınması ve faturalandırma işlemlerinin gerçekleştirilmesi),</li>
            <li>İş operasyonlarımızın yürütülmesi / denetimi (örn. Web Sitesi üzerinden yayımladığınız iş ilanları ve mülakat soru ve içeriklerine ilişkin idari operasyonların yürütülmesi),</li>
            <li>Faaliyetlerin mevzuata uygun yerine getirilmesi.</li>
            <li>Şirketimiz tarafından sunulan ürün ve Hizmetler’den sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması,</li>
            <li>Müşteri ilişkileri süreçlerinin yönetimi,</li>
            <li>Müşteri memnuniyeti faaliyetlerinin yürütülmesi,</li>
            <li>Taleplerin / şikayetlerin takibi.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>İletişim Verisi</b><br>
            (E-Posta Adresi, Telefon Numarası, Adres)
        </td>
        <td>
          <ul>
            <li>Sözleşme süreçlerinin yürütülmesi,</li>
            <li>Hizmet satış ve satış sonrası destek hizmeti süreçlerinin yürütülmesi,</li>
            <li>Hizmet satın alma konusunda talepte bulunan Müşteri Yetkilisi ile Müşteri’nin eşleştirilebilmesi,</li>
            <li>Talebiniz üzerine, Müşteri adına demo hesabı oluşturulması ve Website ve Hizmetler’e kuruluşunuz adına erişiminizin sağlanması,</li>
            <li>Web Sitemiz için üyelik, kayıt, akvitasyon ve kullanıcı doğrulama işlemlerinin yürütülmesi,</li>
            <li>Müşteri Yetkilisi’nin ve/veya Müşteri’nin Web Sitemize ve Hizmetlerimize tanımlama işlemlerinin tamamlanması,</li>
            <li>Müşteri’ye temin edilecek abonelik paketi ve bu kapsamdaki Hizmetler’e ilişkin eğitim süreçlerin yürütülmesi, bu kapsamda Hizmetler’imizden etkili bir şekilde yararlanmanızın sağlanması,</li>
            <li>Hizmetler ve Web Sitesine ilişkin destek hizmetlerinin yürütülmesi,</li>
            <li>İletişim faaliyetlerinin yürütülmesi,</li>
            <li>Şirketimiz tarafından iletişime yönelik idari operasyonların yürütümesi,</li>
            <li>İş operasyonlarımızın yürütülmesi / denetimi (örn. Web Sitesi üzerinden yayımladığınız iş ilanları ve mülakat soru ve içeriklerine ilişkin idari operasyonların yürütülmesi),</li>
            <li>Müşteri memnuniyeti faaliyetlerinin yürütülmesi,</li>
            <li>Müşteri memnuniyeti faaliyetlerinin yürütülmesi,</li>
            <li>Müşteri ilişkileri süreçlerinin yönetimi,</li>
            <li>Taleplerin / şikayetlerin takibi.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>Finans Verisi</b></td>
        <td>
          <ul>
            <li>Sözleşme süreçlerinin yürütülmesi,</li>
            <li>Finans ve/veya muhasebe işlerinin takibi ve denetimi (örn. ücret ödemelerinin alınması ve faturalandırma işlemlerinin gerçekleştirilmesi),</li>
            <li>Müşterilerin finansal risklerinin tespitine yönelik faaliyetlerin yürütülmesi,</li>
            <li>Web Sitemiz için üyelik, kayıt, akvitasyon ve kullanıcı doğrulama işlemlerinin yürütülmesi,</li>
            <li>Müşteri Yetkilisi’nin ve/veya Müşteri’nin Web Sitemize ve Hizmetlerimize tanımlama işlemlerinin tamamlanması,</li>
            <li>Müşteri’ye temin edilecek abonelik paketi ve bu kapsamdaki Hizmetler’e ilişkin eğitim süreçlerin yürütülmesi, bu kapsamda Hizmetler’imizden etkili bir şekilde yararlanmanızın sağlanması,</li>
            <li>Finans ve muhasebe işlerinin yürütülmesi,</li>
            <li>İş operasyonlarımızın yürütülmesi / denetimi.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>Mesleki Deneyim Verisi</b><br>(Yetkilisi Bulunduğunuz Şirket, Görev ve Unvan Bilgileri)</td>
        <td>
          <ul>
            <li>Sözleşme süreçlerinin yürütülmesi,</li>
            <li>Hizmet satış ve satış sonrası destek hizmeti süreçlerinin yürütülmesi,</li>
            <li>Hizmet satın alma konusunda talepte bulunan Müşteri Yetkilisi ile Müşteri’nin eşleştirilebilmesi,</li>
            <li>Talebiniz üzerine, Müşteri adına demo hesabı oluşturulması ve Website ve Hizmetler’e kuruluşunuz adına erişiminizin sağlanması,</li>
            <li>Web Sitemiz için üyelik, kayıt, akvitasyon ve kullanıcı doğrulama işlemlerinin yürütülmesi,</li>
            <li>Müşteri Yetkilisi’nin ve/veya Müşteri’nin Web Sitemize ve Hizmetlerimize tanımlama işlemlerinin tamamlanması,</li>
            <li>Müşteri’ye temin edilecek abonelik paketi ve bu kapsamdaki Hizmetler’e ilişkin eğitim süreçlerin yürütülmesi, bu kapsamda Hizmetler’imizden etkili bir şekilde yararlanmanızın sağlanması,</li>
            <li>Hizmetler ve Web Sitesine ilişkin destek hizmetlerinin yürütülmesi,</li>
            <li>İletişim faaliyetlerinin yürütülmesi,</li>
            <li>İş operasyonlarımızın yürütülmesi / denetimi,</li>
            <li>Taleplerin / şikayetlerin takibi.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>Müşteri İşlem Verisi</b><br>(Satın alınan abonelik ve hizmet detayları, müşteri tarafından oluşturulan iş ilanları, oluşturulan mülakat soruları, Müşteri ve Müşteri Yetkilisi’nin Web site ve Hizmetler’i kullanım detayları)</td>
        <td>
          <ul>
            <li>Sözleşme süreçlerinin yürütülmesi,</li>
            <li>Şirketimizin ticari faaliyetlerin gerçekleştirilmesi,</li>
            <li>Talebiniz üzerine, Müşteri adına demo hesabı oluşturulması ve Website ve Hizmetler’e kuruluşunuz adına erişiminizin sağlanması,</li>
            <li>Web Sitemiz için üyelik, kayıt, akvitasyon ve kullanıcı doğrulama işlemlerinin yürütülmesi,</li>
            <li>Müşteri Yetkilisi’nin ve/veya Müşteri’nin Web Sitemize ve Hizmetlerimize tanımlama işlemlerinin tamamlanması,</li>
            <li>Müşteri’ye temin edilecek abonelik paketi ve bu kapsamdaki Hizmetler’e ilişkin eğitim süreçlerin yürütülmesi, bu kapsamda Hizmetler’imizden etkili bir şekilde yararlanmanızın sağlanması,</li>
            <li>Hizmetler ve Web Sitesine ilişkin destek hizmetlerinin yürütülmesi,</li>
            <li>İletişim faaliyetlerinin yürütülmesi,</li>
            <li>İş operasyonlarımızın yürütülmesi / denetimi,</li>
            <li>Etik değerlere ve hukuka uyum ve hukuki iş ve prosedürlerin yürütülmesi,</li>
            <li>Şirketimiz tarafından sunulan ürün ve Hizmetler’den sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması,</li>
            <li>Müşteri ilişkileri süreçlerinin yönetimi,</li>
            <li>Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması,</li>
            <li>Taleplerin / şikayetlerin takibi.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>Talep / Şikayet Verisi</b></td>
        <td>
          <ul>
            <li>İş süreçlerinin iyileştirilmesine yönelik önerilerin alınması ve değerlendirilmesi,</li>
            <li>Müşteri ilişkileri süreçlerinin yönetimi,</li>
            <li>Taleplerin / şikayetlerin takibi,</li>
            <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,</li>
            <li>İş operasyonlarımızın yürütülmesi / denetimi,</li>
            <li>İletişim faaliyetlerinin yürütülmesi,</li>
            <li>Teknik / müşteri hizmetleri desteği sağlanması.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>İşlem Güvenliği Verisi</b><br>(Kullanıcı hesabı bilgileri, şifre ve parola bilgileri, cihaz bilgileri, IP adresi bilgileri, çerez bilgileri, erişim kayıtları)</td>
        <td>
          <ul>
            <li>Bilgi güvenliği yönetimi hizmetlerinin yürütülmesi,</li>
            <li>Şirketimizin Web Sitesi’nin teknik olarak yönetimi,</li>
            <li>Web Sitemiz için üyelik, kayıt, akvitasyon ve kullanıcı doğrulama işlemlerinin yürütülmesi,</li>
            <li>Müşteri Yetkilisi’nin ve/veya Müşteri’nin Web Sitemize ve Hizmetlerimize tanımlama işlemlerinin tamamlanması,</li>
            <li>Hizmetleri kullanımınız sırasında sizlere teknik destek sağlanması,</li>
            <li>Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan kişilerle hukuki ve ticari ilişkilerin yürütülmesi ve bu ilişkilerin güvenliğinin temini,</li>
            <li>İş operasyonlarının yürütülmesi / denetimi,</li>
            <li>Şüpheli işlemlere yönelik inceleme yapılması,</li>
            <li>Etik değerlere ve hukuka uyum ve hukuki iş ve prosedürlerin yürütülmesi.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><b>Pazarlama Verisi</b></td>
        <td>
          <ul>
            <li>Firma / ürün / hizmetlere bağlılık süreçlerinin yürütülmesi,</li>
            <li>Müşteri ilişkileri süreçlerinin yürütülmesi,</li>
            <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,</li>
            <li>Kurumsal iletişim ve kurumsal yönetim faaliyetlerinin planlanması ve yürütülmesi,</li>
            <li>Pazarlama analiz çalışmalarının yürütülmesi,</li>
            <li>Reklam / kampanya / promosyon süreçlerinin yürütülmesi,</li>
            <li>Ürün ve/veya hizmetlerin satış, pazarlama, satış sonrası süreçlerinin planlanması ve yürütülmesi,</li>
            <li>Ürün ve hizmetlerin içerikleri konusunda bilgilendirme yapılması.</li>
          </ul>
        </td>
      </tr>      
    </tbody>
    </table>
    <p><b>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</b></p>
    <p>Yukarıda sayılan kişisel verileriniz, ancak KVKK’nın 5. maddesi doğrultusunda aşağıdaki şartlardan biri veya birkaçının varlığı durumunda Şirketimiz tarafından işlenecektir:</p>
    <ul>
        <li>Açık rızanızın bulunması.</li>
        <li>İlgili verilerin işlenmesinin kanunlarda açıkça öngörülmesi</li>
        <li>Fiili imkânsızlık nedeniyle açık rızanızın açıklayamayacak durumda bulunulması veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.</li>
        <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</li>
        <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.</li>
        <li>Verilerin tarafınızca alenileştirilmiş olması.</li>
        <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.</li>
        <li>Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, Şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu olması.</li>
    </ul>
    <p><b>Şirketimiz Tarafından Amaçları Dahilinde İşlenen Verilerin Aktarımı</b></p>
    <p>Şirketimiz bünyesinde kişisel verilerinize, yalnızca yukarıda detaylarına yer verilen amaçlar bakımından sınırlı yetki erişimine sahip çalışanlarımız tarafından kendi görevlerini yerine getirmek amacıyla gerekli ölçüde erişilebilmektedir.</p>
    <p>Yukarıda belirtilen her bir kişisel veri kategorisi kapsamında işlenen kişisel verileriniz, Kişisel Verilerin Korunması Kanunu’nun 8. ve 9. maddelerine uygun şekilde ve ilgili veri kategorisi için yukarıda belirtilen kişisel veri işleme şartlarının (hukuki sebeplerinin) aktarım amaçları bakımından da ayrıca mevcut olması halinde aşağıdaki alıcı gruplarına aktarılabilecektir.</p>
    <ul>
        <li>Şirket faaliyetlerinin yürütülmesi bakımından ürün ve/veya hizmet temin edilmesi amacı başta olmak üzere yukarıda her bir veri kategorisi için ilgili kategoride belirlenen amaçlarla tedarikçi olarak hareket eden topluluk şirketlerimizin de dahil olduğu yurt içinde ve/veya yurt dışında yerleşik <b><i>tedarikçilerimize</i></b> (örn. hukuk danışmanları, denetim şirketleri, müşteri hizmetleri desteği sunan firmalar ile bilişim teknolojileri ve veri barındırma hizmet sağlayıcıları),</li>
        <li>olası şirket birleşme, devralma, ortak girişim, bölünme ve sair yapısal değişikliklerde Şirketimizin hukuki ve finansal inceleme faaliyetlerinin gerçekleştirilmesi amaçlarıyla  <b><i>potansiyel alıcı, satıcı veya işlemin muhatabı sair taraflar ve bunların sır saklama yükümlülüğü altındaki çalışanları, danışmanları ve ilgili sürece dahil olan veri tabanı hizmet sağlayıcılarına,</i></b></li>
        <li>Şirketimizin faaliyetleri kapsamında iş sürekliliğinin sağlanması amaçlarıyla iletişimde olduğunuz yurt içinde ve/veya yurt dışında yerleşik <b><i>iş ve çözüm ortaklarımız ile çalışanlarına,</i></b></li>
        <li>kurumsal iletişim faaliyetlerinin planlanması veya gerçekleştirilmesi amacı başta olmak üzere yukarıda her bir veri kategorisi için ilgili kategoride belirlenen amaçlarla yurt içinde ve/veya yurt dışında yerleşik <b><i>sosyal medya platformlarına, basın / yayın kuruluşlarına ve bu kapsamda içeriğin kamuya açılması suretiyle erişim sağlayan diğer üçüncü kişilere,</i></b> ve</li>
        <li>Şirketimizin hukuki yükümlüklerinin yerine getirilmesi ile hukuk işlerinin takibi amaçları başta olmak üzere yukarıda her bir veri kategorisi için ilgili kategoride belirlenen amaçlarla yurt içinde ve/veya yurt dışında yerleşik <b><i>yetkili kamu kurum ve kuruluşlarına</i></b> (örn. Mahkemeler).</li>
    </ul>
    <p><b>Kişisel Verilerinizin Saklanma Süreleri</b></p>
    <p>Şirket faaliyetlerimiz sırasında elde edilen kişisel verileriniz, başta Anayasa olmak üzere KVKK, Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında Yönetmelik ve diğer ilgili mevzuat hükümlerine uygun şekilde saklamakta ve imha etmektedir.</p>
    <p>Bu kapsamda,  kişisel verileriniz, KVKK’nın 5. maddesinde yer alan kişisel veri işleme şartlarının tamamının ortadan kalkması halinde imha edilecektir. Kişisel verileriniz bu doğrultuda, ilgili yasal mevzuatlarda belirtilen saklama sürelerince, ilgili yasal mevzuatlarda herhangi bir süre belirlenmemişse Şirketimizin uygulamaları ve ticari yaşamının teamülleri uyarınca veya yukarıda anılan işleme amaçlarının gerekli kıldığı süre boyunca verileriniz saklanmakta ve sonrasında Kişisel Verilerin Korunması Kanunu 7. maddesine uygun olarak silinmekte, yok edilmekte ya da anonim hale getirilmektedir. Kişisel verilerinizin imha edilmesine ilişkin talepleriniz bakımından, “Kişisel Verilerinizle İlgili Haklarınız” bölümünü incelemenizi rica ederiz.</p>
    <p><b>Şirketimiz Tarafından İşlenen Verilerinizin Güvenliği</b></p>
    <p>Kişisel verileriniz, işlendikleri ve saklandıkları ortamlarda yetkisiz erişime maruz kalmamaları, kaybolmamaları ve zarar görmemeleri amacıyla yönetimimiz tarafından uluslarası bilgi güvenliği standartlarına ait güvenlik kontrolleri ve ayrıca Kişisel Verileri Koruma Kurulu tarafından yayımlanmış Kişisel Veri Güvenliği Rehberi gereklilikleri sürekli işletilmekte ve sürekli iyileştirme kapsamında geliştirmektedir.</p>
    <p><b>Kişisel Verilerinizle İlgili Haklarınız</b></p>
    <p>Kişisel Verilerin Korunması Kanunu 11. maddesi uyarınca kişisel verilerinizle ilgili olarak aşağıda yer alan haklarınızı veri sorumlusu Şirketimize başvurarak kullanabilirsiniz:</p>
    <ul>
        <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
        <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
        <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
        <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
        <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
        <li>Kişisel Verilerin Korunması Kanunu 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
        <li>İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
        <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.</li>
    </ul>
    <p>İşbu Aydınlatma Metni’nin “İlgili Kişinin Hakları Çerçevesinde Başvuru Yöntemleri” bölümünde belirtilen yollarla iletmiş olduğunuz başvurular Şirketimiz tarafından en geç 30 (otuz) gün içerisinde ücretsiz olarak sonuçlandırılacaktır.  İşlemin ayrıca bir maliyeti gerektirdi durumunda, Kişisel Verileri Koruma Kurulunca belirlenen tarife doğrultusunda ücret alınabilir.</p>
    <p>Şirketimiz belirtilen süre içerisinde talebi kabul eder veya gerekçesini açıklayarak reddeder ve cevabını yazılı olarak veya elektronik ortamda bildirir. Başvuruda yer alan talebin kabul edilmesi hâlinde Şirketimiz tarafından başvurunun gereği derhal yerine getirilir.</p>
    <p><b>İlgi Kişinin Hakları Çerçevesinde Başvuru Yöntemleri</b></p>
    <p>Kişisel Verilerin Korunması Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi, 10 Mart 2018 tarihli ve 30356 sayılı “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ” istinaden aşağıdaki yöntemler ve bilgiler ile gerçekleştirebilirsiniz.</p>
    <p>Başvuru içeriğinde gerekli bilgiler;</p>
    <ul>
        <li>Başvuru sahibinin ad soyad bilgisi,</li>
        <li>Başvuru sahibi Türkiye Cumhuriyeti vatandaşı ise TC kimlik numarası, eğer değil ise uyruğu ile birlikte pasaport numarası veya var ise kimlik numarası,</li>
        <li>Başvuru sahibinin tebligata esas yerleşim yeri veya iş yeri adresi,</li>
        <li>Başvuru sahibinin bildirime esas elektronik posta adresi, telefon veya faks,</li> 
        <li>Başvuru sahibinin talep konusu,</li>
        <li>Başvuru sahibinin talep konusuna istinaden bilgi ve belgeler.</li>
    </ul>
    <p>Şirketimizin Veri Sorumlusu sıfatıyla kişisel verileri korumakla ve güvenliği sağlamakla yükümlü olması nedeniyle başvuruda eksik bilgi bulunması, yanlış bilgi tespit edilmesi, başvuranın kimliğinin ve/veya hak sahipliğinin tespit edilmemesi durumlarında Şirketimizin başvuruyu reddetmek zorunda kalacağını hatırlatırız.</p>
    <p>Başvuru Yöntemleri;</p>
    <ul>
        <li>Başvurunuzu, ıslak imzalı ve şahsen “VINTER AI RECRUITMENT LTD - 74a High Stret Wanstead, London, Greater London, Birleşik Krallık, E11 2RJ” adresine «Başvuru Formu» doldurarak ve kapalı zarfın üzerine «Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi» notu ile;</li>
        <li>Güvenli elektronik imza veya mobil imza ile;</li>
        <li>Noter vasıtasıyla “VINTER AI RECRUITMENT LTD - 74a High Stret Wanstead, London, Greater London, Birleşik Krallık, E11 2RJ" adresine ve tebligatın üzerine «Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi» notu ile; veya </li>
        <li>Sistemimizde kayıtlı bulunan elektronik posta adresinizi kullanarak  dpo@vinter.me adresine ve e-posta konu kısmına «Kişisel Verilerin Korunması Kanunu K Bilgi Talebi» notu ile tarafımıza iletebilirsiniz.</li>
    </ul>
    <br>
    <p style="text-align: right;"><b>VINTER AI RECRUITMENT LTD</b></p>
</div>

<div *ngIf="consentType == EUserConsentType.TermsOfUse && languageID == LanguageEnum.TurkishTurkey" [ngStyle]="{
  'max-height':'144',
  'overflow-y':'auto',
  'text-left':'left',
  'font-size':'xs',
  'font-family':'sans-serif',
  'color':'gray'
  }" class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
  text-gray-700">
  <p><b>Gizlilik ve Kişisel Verilerin Korunması Politikamız</b></p>
  <p><b>1- Giriş</b></p>
  <p>VINTER AI RECRUITMENT LTD (bundan böyle "Vinter" olarak anılacaktır) olarak kişisel verilerin korunması ve müşterilerimizin / çalışan adaylarımızın vb. gizliliğine büyük önem vermekteyiz.</p>
  <p>Bu kapsamda kişisel veriler 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca "Veri Sorumlusu" sıfatıyla, aşağıda belirtilen amaçlar ve sınırlar kapsamında işlenmekte olup gerekli idari ve teknik tedbirlerin alınmasına özen göstermekteyiz.</p>
  <p>Bu sebeple iş bu "Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Bildirimi” ("Bildirim") vasıtasıyla kişisel verileri işleme süreçlerimiz ve veri sahibinin KVKK kapsamındaki hakları konusunda gerekli bilgiyi sağlamayı amaçlamaktayız. Kişisel verilerin Vinter tarafından hangi amaçlarla, ne şekilde işlenebileceği ve kimlerle paylaşılabileceği konularında gerekli bilgiler bu bildirimde yer almaktadır.</p>
  <p><b>2- Kişisel Verilerinin Toplanmasının Yasal Dayanağı</b></p>
  <p>İş bu bilgilendirme 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 10. maddesi gereği yapılmaktadır. Vinter ve iştirakleri, müşteri / çalışan adayı bilgilerinin gizli tutulması ve bu bilgilerin üçüncü kişilerle paylaşılmaması için gerekli tedbirleri almayı taahhüt eder. Vinter ve çalışanları, görevlerini yerine getirirken elde ettikleri bilgilerin gizliliğini korumayı ve söz konusu bilgileri sadece mevzuat kapsamında kullanmayı taahhüt etmektedirler.</p>
  <p><b>3- İşlenen Kişisel Veriler</b></p>
  <p>Vinter, veri sahibinin açık rızasına istinaden ve ilgili yasal düzenlemelerde izin verilen hâllerde, kişisel verileri işlemektedir. Vinter müşteri / çalışan adayı olan veri sahipleri hakkında aşağıdaki gibi bilgiler istenebilmektedir;</p>
  <ul>
    <li>Kimlik Bilgileri ( İsim, Soy isim , Kimlik numarası, Hesap ve Üyelik Bilgileri gibi)</li>
    <li>İletişim Bilgileri (Adres, Telefon numarası, Eposta adresi, IP adresi, )</li>
    <li>Özlük Bilgileri (Özgeçmiş bilgileri,)</li>
    <li>Mesleki Deneyim Bilgileri(Diploma bilgileri, Gidilen kurslar,Meslek içi eğitim bilgileri,Sertifikalar,Transkript bilgileri,</li>
    <li>Biyometrik verileri  (yüz tanıma bilgileri)</li>
    <li>Görsel Ve İşitsel Kayıtlar ( Fotoğraf, Video, Ses Kayıt)</li>
  </ul>
  <p>(Metin içerisinde yukarıda sayılı verilerin hepsi birlikte "Kişisel Veri" olarak anılacaktır.)</p>
  <p><b>4- Kişisel Verilerin Elde Edilme Zamanı ve Yöntemi</b></p>
  <p>Vinter web sitesinden işlem yapan veri sahiplerinin verdikleri veriler ve açık rızaları mevzuat hükümleri uyarınca Vinter tarafından işlenmektedir. Vinter, 6698 Sayılı Kişisel Verilerin Korunması Kanunu gereğince müşteri / çalışan adayı bilgilerinin gizli tutulması ve bu bilgilerin üçüncü kişilerle paylaşılmaması için gerekli tedbirleri almayı taahhüt etmektedir. Ayrıca, Şirket veya grup Şirketlerimizin hizmetlerini kullanmak amacıyla çağrı merkezlerimizi veya internet sayfamızı kullandığınızda, Şirketimizi veya internet sitemizi ziyaret ettiğinizde de kişisel verileriniz işlenebilecektir.</p>
  <p>KVKK uyarınca Vinter tarafından müşterilerimizin / çalışan adaylarımızın verileri Veri Sorumlusu sıfatıyla işlenmektedir.</p>
  <p>Kişisel veriler, aşağıdaki sayılan durumlarda toplanabilir:</p>
  <ul>
    <li>Hizmet hakkında bilgi vermek ve şikâyetler için; kimlik ve iletişim bilgileri</li>
    <li>İş başvurusu ya da firma ziyareti için gelen kişilerin güvenlik amaçlı; kimlik ve iletişim bilgileri</li>
    <li>İşletmeyi ziyaretinde bulunan kişilerin yasal şartlar doğrultusunda güvenliliklerinin sağlanması için; fiziksel mekan güvenliği bilgileri</li>
    <li>Bir hizmet için kaydolma veya hizmeti kullanma</li>
    <li>Bir soru veya talep için bizimle iletişime geçme</li>
  </ul>
  <b>5- Özel Nitelikli Kişisel Verilerin İşlenmesi</b>
  <p>6698 Sayılı Kişisel Verilerin Korunması Kanunu'nun 6. maddesinde, hukuka aykırı olarak işlendiğinde kişilerin mağduriyetine veya ayrımcılığa sebep olma riski taşıyan bir takım kişisel veri "özel nitelikli" olarak belirlenmiştir. Bu veriler; ırk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık ve kıyafet, dernek - vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik verilerdir. Vinter, özel nitelikli kişisel verilerin işlenmesi bakımından Kişisel Verilerin Korunması Kanunu'nun 6. maddesinde öngörülen düzenlemelere uygun hareket etmekte ve bu verilerin korunmasında hassasiyetle davranmaktadır.</p>
  <p><b>6- Kişisel Verilerin İşlenme Amaçları</b></p>
  <p>Müşterilimize / çalışan adaylarımıza ait ad, soyad, e-posta adresi, telefon numarası, T.C. kimlik numarası vb. veriler sözlü, yazılı ya da elektronik olarak talep, şikayet ve önerilerinizi almak, hizmetlerimizi sunmak, hizmetlerimizi geliştirmek, sistemsel sorunların çözümü, önceden onay vermeniz halinde ürünler ve hizmetler hakkında pazarlama amacıyla, bilgilerinizi güncellemek ve üyeliğinizi yönetmek ve sürdürmek, ilginizi çekebilecek hizmetleri önermek, burs ve benzeri taleplerinizi karşılamak amacıyla ve bu faaliyetleri yönetmek, Veri analizi, denetim, yeni hizmetler geliştirmek, hizmetlerimizi geliştirmek, iyileştirmek veya değiştirmek, hizmetlerimizin kullanımını ve işlevselliğini denetlemek, Kullanım Şartlarımıza uygunluğu sağlamaya yardımcı olmak, hizmetlerimizi korumaya yardımcı olmak, ticari faaliyetlerimizi yürütmek ve genişletmek ve 3. kişilerin teknik, lojistik ve benzeri diğer işlevlerini Vinter adına yerine getirmelerini sağlamak yazılı/manyetik arşivlerde muhafaza edilebilir, kullanılabilir, güncellenebilir, paylaşılabilir, transfer olunabilir ve sair suretler ile işlenebilir. Ancak firmamız üyelik formlarından topladığı bazı bilgileri, söz konusu üye kullanıcının haberi ya da aksi bir talimatı olmaksızın, üçüncü şahıslarla kesinlikle paylaşmamakta (<i>çalışan adayı için başvuru yapılan şirkete yapılan paylaşım bu kapsamdan istisnadır</i>), faaliyet dışı hiçbir nedenle ticari amaçla kullanmamakta ve kesinlikle satmamaktadır.</p>
  <p>Vinter, mevzuatın izin verdiği durumlarda ve ölçüde kişisel bilgilerinizi kaydedebilecek, saklayabilecek, güncelleyebilecek, üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek ve işleyebilecektir.</p>
  <p><u>Kişisel verileriniz şu amaçlarla kullanılmaktadır:</u></p>
  <p>Erişim Yetkilerinin Yürütülmesi, Faaliyetlerin Mevzuata uygun Yürütülmesi, Finans ve Muhasebe İşlerinin Yürütülmesi, Mal/ Hizmet Satın alım Sözleşmelerinin Yürütülmesi, Mal ve Hizmet Satış Süreçlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi, Talep /Şikayetlerin Takibi, Tedarik Zinciri Yönetimi Süreçlerinin Yürütülmesi, Yetkili Kişi-Kurum ve Kuruluşlara Bilgi Verilmesi, Diğer İç-Dış Güvenliğin Yürütülmesi</p>
  <p><b>7- Kişisel Verilerin Paylaşımı / Aktarımı</b></p>
  <p>Müşterilerimize / çalışan adaylarına ait kişisel verilerin üçüncü kişiler ile paylaşımı, müşterilerin / çalışan adaylarının izni çerçevesinde gerçekleşmekte ve kural olarak müşterilerimizin/ çalışan adaylarımızın onayı olmaksızın kişisel verileri üçüncü kişilere aktarılmamaktadır.</p>
  <p>Bununla birlikte, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve diğer kamu kurumları ile kişisel veriler paylaşılmaktadır. Ayrıca, taahhüt ettiğimiz hizmetleri sağlayabilmek, verilen hizmetlerin kalite kontrolünü yapabilmek, Haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizi ya da Bağlı Kuruluşlarımızın, sizin veya diğer üçüncü tarafların haklarını, gizliliğini, güvenliğini ya da mülkiyetini korumak için anlaşmalı üçüncü kişilere kişisel veri aktarımı yapılmaktadır.</p>
  <p>Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve üçüncü kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden Vinter sorumlu değildir.</p>
  <p>Kişisel verileriniz açık rızanız olması kaydıyla Vinter’in grup şirketleriyle, doğrudan/dolaylı yurtiçi iştiraklerimize faaliyetlerimizi yürütebilmek için işbirliği yaptığımız program ortağı kurum, kuruluşlarla, müşterilerimize / çalışan adaylarına ticari elektronik iletilerin gönderilmesi konusunda anlaşmalı olduğumuz yurtiçi kuruluşlarla, Bankalar arası Kart Merkeziyle, anlaşmalı olduğumuz bankalarla paylaşılabilmektedir. Yasal yükümlülükler çerçevesinde ve gerekmesi halinde Kişisel Verileriniz devlet kurumlarına, yargı organlarına, uluslararası sözleşmelerle kurulmuş yabancı misyonlara (büyükelçilikler, konsolosluklar vs.) aktarılabilmektedir.</p>
  <p><b>8- Kişisel Verilerin İşlenme Süresi</b></p>
  <p>Kişisel veriler, ilgili mevzuatta bu verilerin saklanması için öngörülen bir süre var ise bu sürenin sonuna kadar, böyle bir süre belirlenmemiş ise işlendikleri amaç için gerekli olan süre (en az 10 Yıl) sonuna kadar saklanacaktır.</p>
  <p>Bu sürelerin dolması halinde kişisel verileriniz re'sen veya talebiniz üzerine derhal mevzuat tahtındaki kurallara uygun şekilde silinecek, yok edilecek veya anonim hale getirilecektir. Bu süre içerisinde kişisel verilerinizde bir değişiklik olması durumunda kayıtlarınızın güncel ve doğru tutulabilmesi adına Vinter’e bilgi verme yükümlülüğünüz bulunmaktadır.</p>
  <p><b>9- Kişisel Verilerin Korunma Yöntemleri</b></p>
  <p>Vinter ile paylaşılan kişisel veriler, Vinter gözetimi ve kontrolü altındadır. Vinter, yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.</p>
  <p>Kişisel verilerini işlediğimiz ilgili kişi grupları Web Sitesi üzerinden paylaştıkları ve/veya bizzat kendilerinin verdikleri, sözleşme ilişkisi dolayısıyla alınan kişisel verilerin doğru ve güncel olmasının, KVKK anlamında kişisel verileri üzerinde sahip oldukları hakları kullanabilmeleri ve ilgili diğer mevzuat açısından önemli olduğunu bildiklerini ve yanlış bilgi verilmesinden doğacak sorumluluğun tamamen kendilerine ait olacağını kabul ve beyan etmişlerdir.</p>
  <p><b>12- Veri Sahibinin Hakları ve Veri Sorumlusuna Başvuru Yolları</b></p>
  <p>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, işbu Aydınlatma Metninde aşağıda düzenlenen yöntemlerle Şirketimize iletmeniz durumunda Şirketimiz talebin niteliğine göre talebi en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma Kurulunca bir ücret öngörülmesi halinde, Şirketimiz tarafından belirlenen tarifedeki ücret alınacaktır. Bu kapsamda kişisel veri sahipleri;</p>
  <p>a. Kişisel veri işlenip işlenmediğini öğrenme,</p>
  <p>b. Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</p>
  <p>c. Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</p>
  <p>d. Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</p>
  <p>e. Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
  <p>f. KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
  <p>g. İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</p>
  <p>h. Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</p>
  <p>haklarına sahiptir.</p>
  <p>Kişisel verilerle ilgili her türlü sorunuz için Vinter’in veri güvenliği adına sorumlu yetkili birimiyle (Şirket Veri Güvenliği Sorumlu Yetkili Birimi:  (dpo@vinter.me.e-posta)  iletişime geçebilirsiniz. </p>
  <p>Yukarıda belirtilen haklarınızı kullanmak için kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi; dpo@vinter.me adresine e-posta göndererek, noter kanalıyla veya ilgili kişi tarafından daha önce bildirilen ve şirketimizin sisteminde kayıtlı bulunan e-posta adresini kullanmak suretiyle göndererek başvurabilirsiniz. Kişisel veri sahibinin başvurusunun daha hızlı cevaplanabilmesi için başvuru zarfının üzerine “Kişisel Veri Bilgi Talebi” yazılması ricamızdır.</p>
  <p><b>Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonimleştirilmesi</b></p>
  <p>İşbu bildirimde belirtilen amaçlarla işlenmiş olan kişisel verileriniz; 6698 Sayılı Kanun md. 7/f.1’e göre işlenmesi gerektiren amaç ortadan kalktığında ve md. 17 ve Türk Ceza Kanunu md. 138’e göre ise Kanunların belirlediği süreler geçtiğinde tarafımızca anonimleştirilerek kullanılmaya devam edilecektir.</p>
  <p>Vinter ilgili mevzuatta öngörülen veya işleme amacının gerekli kıldığı saklama süreleri sona erdiğinde, periyodik imha için öngörülen 6 aylık süre zarfında; işlediği kişisel verileri Kişisel Verileri Koruma Kurulu tarafından yayımlanan Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesine İlişkin Rehber’de belirtilen anonim hale getirme yöntemlerinden, iş süreçleri ve faaliyetlerine en uygun olan bir veya birkaç tekniği kullanarak anonimleştirir ve bu şekilde verileri kullanmaya devam eder.</p>
  <p><b>12- VINTER AI RECRUITMENT LTD.</b></p>
  <p><b>Ticari Bilgileri:</b></p>
  <p><b>Adres:</b>LONDRA GREATER LONDON BİRLEŞİK KRALLIK E11 2RJ</p>
  <p><b>Vergi no: </b></p>
  <p>Vinter iş bu bildirim metninde yasal düzenlemeler ve Şirket Politikası doğrultusunda değişiklik veya güncellemeler yapabilir. Tüm bu değişiklik ve güncellemeleri yansıtan yeni bildirim metni hakkında ilgili kişilere web sitesi üzerinden gerekli bilgilendirmeler yapılır.</p>
</div>

<div *ngIf="consentType == EUserConsentType.TEIOM && languageID == LanguageEnum.TurkishTurkey" [ngStyle]="{
    'max-height':'144',
    'overflow-y':'auto',
    'text-left':'left',
    'font-size':'xs',
    'font-family':'sans-serif',
    'color':'gray'
}" class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
text-gray-700">
  <p>VINTER AI RECRUITMENT LTD olarak, Mesafeli Satış Sözleşmesi kapsamında 6698 sayılı Kişisel Verilerin Korunması Hakkında Kanuna uygun olarak e-posta bilgilerinizi toplamakta ve işlemekteyiz. (<i>Kişisel verilerinizin işlenmesi hakkında detaylı bilgiye Müşteri / Müşteri Yetkilisi Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni’nden ulaşabilirsiniz.</i>)</p>
  <p>Bu metne onay vermeniz halinde, 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve 29417 sayılı Resmi Gazete’de yayınlanan Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik kapsamında, sunduğumuz ürün ve hizmetlere ilişkin olarak e-posta mesajı ile ticari amaçlı reklam, kampanya ve sunulan hizmetler ile içeriklere ilişkin bilgilendirmesi yapılmasını ve bu amaçla kişisel verilerinizin işlenmesine açık rıza verdiğinizi kabul etmiş sayılırsınız.</p>
  <p>Ticari elektronik ileti gönderilmesine ilişkin onayınızı İleti Yönetim Sistemi üzerinden (vatandas.iys.org.tr) veya tarafımızla info@vinter.me e-posta adresi üzerinden   iletişime geçerek her zaman geri alabilirsiniz.</p>
  <p>Ret bildiriminin tarafımıza ulaştığı tarihten itibaren 3 iş günü içinde ticari elektronik ileti gönderimi durdurulacaktır.</p>
</div>

<div *ngIf="consentType == EUserConsentType.TermsOfUse && languageID == LanguageEnum.EnglishUnitedStates" [ngStyle]="{
  'max-height':'144',
  'overflow-y':'auto',
  'text-left':'left',
  'font-size':'xs',
  'font-family':'sans-serif',
  'color':'gray'
}" class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
text-gray-700">
<h4>1. Scope</h4>
<p>1.1. We are Vinter AI Recruitment Ltd (“<b>Vinter AI”</b>, <b>“we”</b>) and we own and operate this website (the <b>“Site”</b>) and provide related and ancillary services (collectively <b>“Services”</b>)</p>
<p>1.2. These Terms of Use set forth the terms and conditions governing access to and use of the Site by visitors, our potential customers and Users (<b>“you”</b>).  By accessing or using the Site, including any of our Services, you hereby agree to comply with these Terms of Use and the conditions outlined in our Privacy Notice (hereafter collectively referred to as the “Terms”). Please review these Terms of Use before accessing, using or submitting any information to our Site. If you disagree with these Terms, you must not access or use the Site.</p>
<p>1.3. We may make changes to the Terms from time to time. We may notify you of such changes by any reasonable means, including by posting the revised version of these Terms on the Site. You can determine when we last changed these Terms of Use by referring to the ‘Last Updated’ statement above. Your use of the Site following changes to these Terms of Use will constitute your acceptance of those changes.</p>
<p>1.4. For the purposes of these Terms, a ‘User’ is any individual or entity that visits or engages with our Site for information or to use any of the Services offered by Vinter AI, including information submission. A ‘Customer’, conversely, refers to any entity who enters into a specific agreement with Vinter AI to use our Services under terms that are mutually agreed upon and distinct from these general Terms of Use. Such agreements might include, but are not limited to, service contracts, subscription agreements. It is important to note that these Terms of Use do not apply to Customers with whom we have established specific agreements that govern the use of our Services.</p>

<h4>2. Acceptable Use and Your Obligations</h4>
<p>2.1. You may use the Site to learn about Vinter AI, our products and Services, and to request information about our Services and offerings.</p>
<p>2.2. Your use of the Site is conditional on your compliance with the conduct rules set out in these Terms of Use, and you agree that you will not:</p>
<ul>
<li>2.2.1. Use the Site in any way that is unlawful, illegal, fraudulent, or harmful purpose.</li>
<li>2.2.2. Defame, abuse, harass, threaten, or otherwise violate the rights of others, including, without limitation, others’ privacy rights or rights of publicity.</li>
<li>2.2.3. Transmit or otherwise make available in connection with the Site any virus, worm, Trojan horse, keystroke logger, or other malicious computer software.</li>
<li>2.2.4. Attempt to gain unauthorized access to the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site.</li>
<li>2.2.5. Interfere with or disrupt the operation of the Site or the servers or networks used to make the Site available, or violate any requirements, procedures, policies, or regulations of such networks.</li>
<li>2.2.6. Use the Site to send unsolicited commercial email to any person.</li>
<li>2.2.7. Scrape or collect any information available on the Site or in the Services.</li>
<li>2.2.8. Create a database by systematically downloading and storing Site content.</li>
<li>2.2.9. Reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Site.</li>
<li>2.2.10. Modify, create derivative works of the Site, or any part thereof.</li>
<li>2.2.11. Decompile, disassemble, reverse engineer, or attempt to reconstruct or derive any source code, algorithms, structure, or organization from the Site or any other information obtained through the Site.</li>
<li>2.2.12. Distribute, disclose, or allow use of any of the Site in any format through any timesharing device, service bureau, network, or by any other means, to or by any third party.</li>
<li>2.2.13. Remove any copyright, trademark, or other proprietary rights notice from the Site or materials originating from the Site.</li>
<li>2.2.14. Use the Site in any way that causes, or may cause, damage to, or impairment of the availability or accessibility of the Site.</li>
</ul>
<p>2.3. We reserve the right to revoke these exceptions either generally or in specific instances.</p>

<h4>3. License to Use the Site</h4>
<p>3.1. All rights, title, and interest in and to the Site and its content, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are and remain the exclusive property of Vinter AI and its licensors. Except as expressly permitted in these Terms, all intellectual property rights in the Site and Services are reserved to Vinter AI. The Terms permit you to use the Site for your personal, non-commercial use only. Subject to these Terms, we grant you a non-exclusive, non-transferable, limited right to access and use the Site for your own personal purposes. No other right, title, or interest in the Site is granted to you.</p>
<p>3.2. We reserve the right to restrict your access to the Site or part of it. Access to restricted areas of the Site may be subject to registration and other conditions. If we grant you permission to access a restricted area, we may withdraw that permission at any time (including where you breach any of these Terms of Use).</p>
<p>3.3. You are responsible for all access to the Site using your Internet connection, even if the access is by another person.</p>

<h4>4. Privacy</h4>
<p>Vinter AI is committed to protecting your privacy. The use of the Site is subject to our Privacy Notice, which outlines our practices concerning the collection, use, and disclosure of personal information. For more information about how we process your personal data, please review our Privacy Notice available [insert the location or hyperlink to the Privacy Notice here]. By using this Site, you acknowledge and agree that you have read and understand our Privacy Notice.</p>

<h4>5. Minors</h4>
<p>The Site is not intended for individuals under the age of 18. We do not knowingly collect personally identifiable information from minors. We reserve the right to request anyone accessing the Site or our Services to provide written proof of age in any form. If we become aware that we have collected personal information from a minor, we take steps to remove that information from our servers.</p>

<h4>6. Warranty</h4>
<p>6.1. This Site and all content, materials, information, software, products, and services are provided "as is" and on an "as available" basis without any warranty, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
<p>6.2. We will use reasonable efforts to ensure that the Site is available at all times. However, we do not guarantee the accuracy, completeness, or usefulness of any information on the Site. Neither Vinter AI nor any of our clients, service providers, or suppliers warrant that the functions contained in the Site will be uninterrupted or error-free, that defects will be corrected, or that the site or the server that makes it available is free of viruses or other harmful components.</p>
<p>6.3. You expressly agree that your use of this site, including all content, data, or software distributed by, downloaded, or accessed from or through this site, is at your sole risk. You understand and agree that you will be solely responsible for any damage to your business, your computer system, or loss of data that results from the download of such content, data, and/or software.</p>
<p>6.4. No advice or information, whether obtained by you from our personnel or through this Site or Services, shall create any warranty not expressly provided in these Terms of Use.</p>

<h4>7. Limitation of Liability</h4>
<p>7.1. To the fullest extent permitted by applicable law, in no event will Vinter AI, its affiliates, officers, directors, employees, agents, suppliers, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site; (ii) any conduct or content of any third party on the Site; (iii) any content obtained from the Site; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential purpose.</p>
<p>7.2. You expressly understand and agree that Vinter AI and its affiliates, officers, employees, agents, partners, and licensors shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if Vinter AI has been advised of the possibility of such damages), resulting from the use or the inability to use the Site or Services.</p>

<h4>8. Links</h4>
<p>The Site may provide links to other websites and online resources. We are not responsible for and do not endorse such external sites or resources. Your use of third party websites and resources is at your own risk.</p>

<h4>9. Termination</h4>
<p>These Terms of Use are effective until terminated. We may, at any time and for any reason, terminate your access to or use of the Site. If we terminate your access to the Site you will not have the right to bring claims against us or our affiliates with respect to such termination. We and our affiliates shall not be liable for any termination of your access to the Site.</p>

<h4>10. Breaches of These Terms of Use</h4>
<p>10.1. By using our Site, you agree to comply with the terms and conditions outlined herein. Failure to adhere to these terms constitutes a breach of these Terms of Use, which may result in immediate termination of your access to our Site, without prior notice, and may further lead to legal actions against you.</p>
<p>10.2. Upon breach of these Terms of Use, we reserve the right to:</p>
<ul>
<li>10.2.1. issue a warning to you;</li>
<li>10.2.2. temporarily suspend your access to our Site;</li>
<li>10.2.3. permanently prohibit you from accessing our Site;</li>
<li>10.2.4. block computers using your IP address from accessing our Site;</li>
<li>10.2.5. take legal action against you to cover all costs resulting from the breach, including, but not limited to, reasonable administrative and legal costs.</li>
</ul>
<p>10.3. We exclude liability for actions taken in response to breaches of these Terms of Use. The responses described in this Terms of Use are not limited, and we may take any other action we reasonably deem appropriate.</p>
<p>10.4. It is our policy to investigate any allegations of breaches of these Terms of Use and to cooperate with law enforcement authorities in investigating such breaches, including, but not limited to, providing the identity of anyone posting any materials that breach these terms.</p>

<h4>11. Governing Law and Jurisdiction</h4>
<p>These Terms of Use will be governed by and construed in accordance with the laws of England and Wales, and the courts of England and Wales will have non-exclusive jurisdiction over any claim or dispute arising under or in connection with these Terms of Use.</p>

</div>

<div *ngIf="consentType == EUserConsentType.PrivacyPolicy && languageID == LanguageEnum.EnglishUnitedStates" [ngStyle]="{
  'max-height':'144',
  'overflow-y':'auto',
  'text-left':'left',
  'font-size':'xs',
  'font-family':'sans-serif',
  'color':'gray'
}" class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
text-gray-700">
<p>We, at Vinter AI (“Vinter AI”, “we”, “us” or “our”), respect your privacy and recognize the importance of providing a secure environment for your personal data. Therefore, we are committed to being transparent about how we collect and process your personal data.</p>
<p>The purpose of this privacy notice (“Privacy Notice”) is to inform you about why and how we collect and process your personal data (any information that can be linked or attributed to you, “Personal Data”) when you use our website at <a href="https://www.vinter.me">https://www.vinter.me</a> (“Website”) and Candidate Assessment System and related services (“Services”).</p>
<p>Our Website and Services are intended for (i) employees of our customers and (ii) candidates whose information we process on behalf of our customers (“Customers”).</p>
<p>This Privacy Notice is divided into the following sections:</p>
<ol>
    <li>What Privacy Notice applies to</li>
    <li>What types of Personal Data do we process and how do we collect it?</li>
    <li>For what purpose do we process your data?</li>
    <li>On which legal basis do we process your Personal Data</li>
    <li>Cookies and similar tracking technology</li>
    <li>With whom do we share your Personal Data?</li>
    <li>Transferring your Personal Data out of the UK and EEA</li>
    <li>How do we protect your Personal Data</li>
    <li>Your Rights</li>
    <li>Keeping your Personal Data secure</li>
    <li>Changes to this Privacy Notice</li>
    <li>How to contact us</li>
</ol>
<h4>1. What Privacy Notice applies to</h4>
<p>Vinter AI specializes in assisting companies with talent and interview management, integrating assessment tools powered by artificial intelligence. Throughout this thorough process, Vinter AI handles personal data within the context of the Website and Services it offers. This Privacy Notice clarifies how we collect, use, disclose, and protect your Personal Data.</p>
<h4>2. What types of Personal Data do we process and how do we collect it?</h4>
<h4>2.1. Personal Data provided by you</h4>
<p>The Personal Data we collect about you depends on the particular activities carried out through our Website and Services. When you use our Website, you may provide or be asked to provide us with certain information, including:</p>
<ul>
    <li>Information related to your request: When you reach out to support, we may collect information about your request and your contract details (such as name, email address, telephone number, and company details). We may also collect details of your feedback, purchase and/or demo requests regarding our Services, and customer survey details.</li>
    <li>If you've created a candidate account ("Candidate") or if you've created a company account as an employee of our Customers ("Employee") and are using our Services on their behalf, we may collect and process your Personal Data. In such cases, we act as a data processor and collect your Personal Data on behalf of our Customer and based on their instructions. The types of Personal Data typically collected include:
        <ul>
            <li>Account information: If you are an Employee and create an account on behalf of the Customer to access our Services, we collect your login data, including your name, email address, password, and professional details such as title, job, and division. Additionally, if you are a Candidate and you’ve been invited to Vinter AI by our Customer and wish to participate in the interview process by creating an account, we process your personal data such as your full name, email address, and social media links.</li>
            <li>Interview Details: If you participate in a job interview via our Services, we may collect Personal Data such as employment history, qualifications, experiences, abilities, work style, certifications & courses, and other responses to questions created by our Customers, as well as submissions in various media formats, including video/audio interviews. This may also include recording of audio and video during the interview process.</li>
        </ul>
    </li>
</ul>
<h4>2.2. Personal Data we collect</h4>
<p>When you interact with our Website and Services, we also process device-related information and data regarding your interactions with our Website and Services, such as:</p>
<ul>
    <li>Browser and device information: such as the type and version of the browser or device (device brand and model), internet service information (e.g., internet service provider used by you), certain browser settings like language, time, time zone, and Internet Protocol (IP) address.</li>
    <li>Unique Online Identifiers: if available, identifiers that enable us to recognize a browser or device across different content, such as mobile ad identifiers, device IDs, and cookie IDs, Apple IDFA, and Android Advertising ID.</li>
    <li>General Geographic Location: precise geographic location, if available from the device.</li>
    <li>Usage information: information about activity on the digital content visited, such as visited pages, searches, or links clicked.</li>
</ul>
<p>Some of this Personal Data may be collected through the use of cookies and similar tracking technologies, as detailed in our Cookie Notice.</p>
<h4>2.3. Personal Data we collect from third parties</h4>
<p>In certain scenarios, we may obtain your Personal Data from third parties, ensuring strict compliance with applicable data protection laws and regulations. Your Personal Data may be provided by the following third parties:</p>
<ul>
    <li>Your Consent String: When you visit our Website, our consent management platform (CMP) provider obtains your consent for our processing activities. The CMP provides us with your consent string, indicating your preferences on our respective processing activities.</li>
    <li>Interest Segments: (e.g., interest information reflecting your interests as may be derived from your behaviour, such as your internet activity or purchase history).</li>
</ul>
<h4>3. For what purpose do we process your data?</h4>
<p>When we act as a controller, depending on the Service you are using and the privacy settings you have selected, we process Personal Data for the following purposes:</p>
<h4>3.1. Provision and improvement of our Services</h4>
<ul>
    <li>to provide our Services.</li>
    <li>to allow you to use our Services.</li>
    <li>to contact with you regarding account activation and password management.</li>
    <li>to contact you for administrative purposes regarding the Services, regarding content that you have posted to the Service.</li>
    <li>to provide technical / customer service support and respond to your requests.</li>
    <li>to operate, maintain, enhance, and provide all the features found on the Website and Services.</li>
    <li>to improve the way the Website and Services work.</li>
    <li>to create new features and functionality.</li>
    <li>to monitor and analyse the effectiveness of the application and evaluation activities.</li>
    <li>to remember you for your next visit to our Website, so you will not have to re-enter your information during your visit or the next time.</li>
</ul>
<h4>3.2. Personalization of your experience</h4>
<ul>
    <li>to personalize the Website and Services based on your preferences and interests.</li>
    <li>to send emails related to the Services that we believe may be of interest to you, provided it aligns with your preferences. In this scenario, you will have the option to opt out of receiving such communications or update your preferences through the Services.</li>
    <li>to provide marketing communications to you: Depending on your settings, we will share marketing communications with you. We will collect and store your information and use it to send you marketing communications, such as emails. Our legitimate interest lies in promoting Company Products and sending direct marketing to you.</li>
    <li>to notify you of any upcoming marketing, training or other events that we think may be of interest to you based on your preferences and previous interactions.</li>
</ul>

<h4>3.3. Compliance with our legal or regulatory obligations</h4>
<ul>
    <li>to comply with applicable laws.</li>
    <li>to enforce our terms and conditions.</li>
    <li>to protect our rights, privacy, safety, and/or that of our Website visitors, users and others.</li>
</ul>

<p>If you're a Candidate or Employee and we collect your Personal Data via the Services, it's on behalf of our Customers, as outlined in our Subscription Agreement with them. For details on why Customers process your data (purpose of processing), please reach out to the relevant Customer (Employer) or refer to their privacy notice.</p>

<h4>4. On which legal basis do we process your Personal Data</h4>
<p>Under data protection law, we can only use your personal data if we have proper legal grounds. When we act as a data controller, we process your Personal Data for the purposes described in this Privacy Notice, based on the following legal grounds:</p>

<ul>
    <li><strong>Consent:</strong> We process your Personal Data if you have provided consent via a consent management platform available on our Website for a specific purpose. You have the right to withdraw your consent at any time, for example, by adjusting your privacy settings. Please note that withdrawing consent will not affect any processing carried out before you withdraw your consent.</li>
    <li><strong>Contractual necessity:</strong> We process your Personal Data for the performance of a contract with you or to take steps before entering into a contract to which you are a party. We may also process your Personal Data to fulfill contracts with our Customers as a data processor.</li>
    <li><strong>Legal obligations:</strong> In some cases, we may process your Personal Data to comply with legal or regulatory obligations, such as tax and accounting requirements or legal disputes.</li>
    <li><strong>Legitimate interests:</strong> We process your Personal Data when it is necessary for our legitimate interests or those of third parties, provided that this processing is balanced against your rights and interests, with appropriate safeguards in place to protect your privacy.</li>
</ul>
<h4>5. Cookies and similar tracking technology</h4>
<p>A cookie is a small text file placed onto your device (such as a computer, smartphone, or other electronic device) when you use our Website. We utilize cookies and other tracking technologies, such as web beacons, action tags, and single-pixel gifs, to help us recognize you and your device, and to store certain information about your preferences or past actions.</p>
<p>For more details regarding cookies and similar technologies, our utilization of cookies, instances where we will seek your consent before placing them, and instructions on how to disable them, please refer to our Cookie Notice.</p>

<h4>6. With whom do we share your Personal Data?</h4>
<p>In order to provide you with the best service and to carry out the purposes described in this Privacy Notice, your data may be transferred:</p>
<ul>
    <li>to Customers,</li>
    <li>to law enforcement, body, regulatory, government authorities or courts</li>
    <li>to a cloud based storage providers</li>
</ul>
<p>As stated above, if you are a Candidate or Employee and we are collecting your Personal Data through the Services, we will be collecting it on behalf of our Customers, in accordance with the agreements  that we have with them. For further information about how your Personal Data is shared with third parties, you should contact the relevant Employer or refer to that Employer’s Privacy Policy/Notice.</p>

<h4>7. Transferring your Personal Data out of the UK and EEA</h4>
<p>We want to make sure that your Personal Data are stored and transferred in a way which is secure. We will therefore only transfer data outside of the UK / European Economic Area (EEA) where it is in line with applicable law, and we will require that there is an adequate level of protection for your Personal Data, and that appropriate security measures are in place.</p>
<p>In such cases where your personal data is transferred outside of the UK / EEA we will require that the following safeguards are observed:</p>
<ul>
    <li>The laws of the country to which your personal data is transferred ensure an adequate level of data protection;</li>
    <li>By way of data transfer agreement, incorporating the standard data protection contractual clauses (SCC’s) approved by the European Commission or International Data Transfer Agreement (IDTA) approved by the UK Government; or</li>
    <li>Any other applicable appropriate safeguards under the DPA 2018 / GDPR 2018 </li>
</ul>
<p>To ensure that your Personal Data receives an adequate level of protection, we have put in place appropriate procedures with the third parties we share your personal data with to ensure that your Personal Data is treated by those third parties in a way that is consistent with, and which respects the law on data protection.</p>

<h4>8. How do we protect your Personal Data</h4>
<p>We have implemented appropriate technical and organizational security measures designed to protect your Personal Data against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use. We update and test our security standards on an ongoing basis. In addition, we take reasonable steps to assure that third parties to whom we transfer your Personal Data provide sufficient protection of Personal Data.</p>

<h4>9. Do we collect data from minors?</h4>
<p>Our services are intended to be appropriate for general audiences. Therefore, we do not intentionally collect and/or categorize Personal Data from children. In the event that we learn we have collected personal data from a minor under 16 years of age within the scope of the Services explained in this Privacy Notice, we will delete that data as quickly as possible. If you believe that a minor use our Services, please contact our support team.</p>

<h4>10. How long do we store your Personal Data?</h4>
<p>We will not retain your Personal Data longer than necessary to fulfil the purposes for which the data was collected or to fulfil our legal obligations or necessary for the establishment, exercise of defence of legal claims or resolving disputes. Afterwards, we will delete or anonymize your Personal Data. Additional information on how long your Personal Data is stored by our service partners can be found in the respective third-party.</p>
<p>When collecting personal data on behalf of our Customers, we will store this information in line with our Subscription Agreement with them and any instructions they provide.</p>

<h4>11. Your Rights</h4>
<p>To the extent permitted by applicable data protection laws and regulations, you have the following rights in relation to your Personal Data:</p>
<ul>
    <li><strong>Access:</strong> Access the Personal Data we hold about you, including information such as, for example, the source and categories of data, the processing purposes, the recipients (or categories thereof) and the respective retention period.</li>
    <li><strong>Update or correction:</strong> Request an update or correction of your Personal Data so that it is always accurate.</li>
    <li><strong>Portability:</strong> Receive your Personal Data in a structured, commonly used and machine-readable format, including the right to transfer your Personal Data to another data controller.</li>
    <li><strong>Deletion:</strong> Request the deletion of your Personal Data if you believe that retention is no longer necessary for the purposes indicated above.</li>
    <li><strong>Restriction of processing:</strong> Restrict the processing of your Personal Data, for example, if you have contested the accuracy of your Personal Data, and for a period of time that is sufficient to allow us to verify its accuracy.</li>
    <li><strong>Withdrawal of consent:</strong> Withdraw your consent at any time if your Personal Data is processed with your consent. Please note that this will not affect the lawfulness of data processing based on consent granted prior to its withdrawal.</li>
    <li><strong>Objection to processing:</strong> Object to the processing on specific grounds relating to your particular situation, in the event and to the extent that we process your Personal Data based on our legitimate interests.</li>
</ul>
<p>If you would like to exercise any of those rights, please email, call or write to us—see below: ‘How to contact us’. When contacting us please:</p>
<ul>
    <li>provide enough information to identify yourself (e.g., your full name, address, Customer and job details) and any additional identity information we may reasonably request from you, and</li>
    <li>let us know which right(s) you want to exercise and the information to which your request relates.</li>
</ul>
<p>Please contact us if you have any queries or concerns about our use of your personal data (see below ‘How to contact us’). We hope we will be able to resolve any issues you may have. You also have the right to lodge a complaint with a competent data protection authority, for example, the Information Commissioner’s Officer (ICO) in the United Kingdom.</p>

<p>For a list of EEA data protection supervisory authorities and their contact details see <a href="#">here</a>.</p>

<h4>12. Changes to this Privacy Notice</h4>
<p>We regularly review and update this Privacy Notice. When there is an important change that will have a relevant impact on the processing of your Personal Data, we will inform you by email if we have your email address. If we do not have your email address, for example because you did not create an account, we will inform you with a banner on our Website.</p>

<h4>13. How to contact us</h4>
<p><strong>Individuals in the UK</strong></p>
<p>You can contact us and/or our Data Protection Officer by post, email or telephone if you have any questions about this Privacy Notice or the information we hold about you, to exercise a right under data protection law or to make a complaint.</p>
<table>
    <tr>
        <th>Our Contact Details</th>
        <th>Our Data Protection Officer’s Contact Details</th>
    </tr>
    <tr>
        <td><a href="mailto:privacy@vinter.me">privacy@vinter.me</a><br>74a High Street, Wanstead, London, Greater London, United Kingdom, E11 2RJ</td>
        <td><a href="mailto:dpo@vinter.me">dpo@vinter.me</a><br>Gökçen Günay<br>Phone: +90-532-5992908<br>Address: NEDU BAGLANTI ELEMANLARI SAN TIC AS<br>10002 Sok. No:8 AOSB Çiğli/İzmir Türkiye</td>
    </tr>
</table>

<p><strong>Individuals in the EEA</strong></p>
<p>We have appointed Erdem Can to be our data protection representative within the EEA. Their contact details are as follows: <a href="mailto:erdem.can@vinter.me">erdem.can@vinter.me</a>,  74a High Street, Wanstead, London, Greater London, United Kingdom, E11 2RJ</p>
<p>Individuals within the EEA can contact us direct (see above) or contact our European representative.</p>
</div>

<div *ngIf="consentType == EUserConsentType.CookiePolicy && languageID == LanguageEnum.EnglishUnitedStates" [ngStyle]="{
  'max-height':'144',
  'overflow-y':'auto',
  'text-left':'left',
  'font-size':'xs',
  'font-family':'sans-serif',
  'color':'gray'
}" class="2xl:max-h-144 max-h-96 pr-2 overflow-y-auto space-y-2 text-left text-xs font-sans
text-gray-700">
<!-- COOKIE NOTICE -->
<p>This cookie notice (<b>“Cookie Notice”</b>) applies to Vinter AI Recruitment Ltd. (“<b>Vinter AI”, “we”, “us”</b> or <b>“our”</b>) website (<b>“Website“</b>). It explains what the used technologies are, why we use them and the duration of their use, as well as your rights to control our use of them. For more information about the ways in which Vinter AI handles personal data, please refer to our Privacy Notice.</p>

<h4>1. What are cookies and similar techniques?</h4>
<p>Cookies are small pieces of information (small files) stored within your browser or on your device. There are similar techniques such as log filex, web beacons, small graphic files (pixels). These techniques function in a similar manner as cookies. In this Cookie Notice, we will be referring to them collectively as “Cookies”.</p>
<p>We use Cookies on our Website to verify login information, track traffic flow in connection with a Service, understand the total number of users of a Service on an ongoing basis and the types of devices, monitor the performance of a Service to continually improve it, customize and enhance your online experience, provide customer service to you.</p>
<p>For further information on Cookies generally, including how to control and manage them, visit the 
  <a class="text-xs" href="https://ico.org.uk/for-organisations/direct-marketing-and-privacy-and-electronic-communications/guide-to-pecr/guidance-on-the-use-of-cookies-and-similar-technologies/" target="_blank" rel="noreferrer">guidance on cookies</a>
  published by the UK Information Commissioner’s Office.</p>

<h4>2. Cookie Parties</h4>
<p>As Vinter AI, we place Cookies when you visit our Services that are only readable for us (<b>“First Party Cookies”</b>), as do the third parties (<b>“Third Party Cookies”</b>). These Cookies are only readable for the third party. Third Party Cookies enable third-party features or functionality to be provided on or through the website.</p>

<h4>3. Cookie Types</h4>
<p>The types of Cookies used by us and third parties in connection with a Service can be classified into these categories; (i) necessary cookies, (ii) functionality cookies, (iii) analytics cookies, and (iv) advertising and tracking cookies. We have set out below some further information about each category, purpose and duration of the Cookies we and third parties set.</p>

<h4>Strictly Necessary Cookies</h4>
<p>These Cookies are strictly necessary to provide you with Services and functionality available through our Website and to use its features securely. Since these Cookies are essential, they are always active in our Services and you cannot refuse them.</p>
<p>We respect your preferences, and in order to make your choices valuable and effective, we keep strictly necessary cookies out of the types of Cookies for which we ask for your consent. We use these Cookies for activities such as recognizing you when you sign in to use our Services so that you do not have to log in manually the next time you visit <a href="https://www.vinter.me/">https://www.vinter.me/</a>.</p>
<table border="1">
    <tr>
        <th>Host</th>
        <th>Cookie Name</th>
        <th>Purpose</th>
    </tr>
    <tr>
        <td><a class="text-xs" href="https://www.vinter.me/" target="_blank" rel="noreferrer">https://www.vinter.me/</a></td>
        <td>Cookie Name</td>
        <td>Purpose</td>
    </tr>
</table>

<h4>Functional Cookies</h4>
<p>These Cookies record information about choices you have made and allow us to tailor a Service to you. These Cookies contribute to a better user experience.</p>
<p>For example, we use these Cookies to remember information when you fill in forms or input fields on various pages and to keep track of your preferences such as language settings, location settings, etc.</p>
<table border="1">
    <tr>
        <th>Host</th>
        <th>Cookie Name</th>
        <th>Purpose</th>
    </tr>
    <tr>
        <td><a class="text-xs" href="https://www.vinter.me/" target="_blank" rel="noreferrer">https://www.vinter.me/</a></td>
        <td>cf_clearance</td>
        <td>1 year</td>
    </tr>
</table>

<h4>Analytics Cookies</h4>
<p>These Cookies analyze how our Service is accessed, used, or performing in order to provide you with a better user experience and to maintain, operate, and continually improve a Service. These Cookies allow us to gather information about the way visitors use our Services.</p>
<p>The information we gather from these Cookies is used to generate site statistics. These statistics provide us with insights, such as how often our Services are visited, which parts or features are mostly viewed, and how often our Services are interacted with. We continuously strive to measure which of our Services are the most popular and attract the most visitors and users. This allows us to make the structure, navigation, and content as user-friendly as possible.</p>
<table border="1">
    <tr>
        <th>Host</th>
        <th>Cookie Name</th>
        <th>Purpose</th>
    </tr>
    <tr>
        <td><a class="text-xs" href="https://www.vinter.me/" target="_blank" rel="noreferrer">https://www.vinter.me/</a></td>
        <td>_ga</td>
        <td>400 days</td>
    </tr>
</table>

<h4>Advertising and Targeting Cookies</h4>
<p>These Cookies may be set through our Services by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.</p>
<p>These Cookies ensure that it is possible for us to display personalized ads, to track which ads you have already seen, and to track how many visitors click on an advertisement. These Cookies help us understand how our Website is being used or how effective our marketing campaigns are. Please note that you will still be shown advertisements even if these Cookies are not used, as we also display ads that do not use Cookies.</p>
<table border="1">
    <tr>
        <th>Host</th>
        <th>Cookie Name</th>
        <th>Purpose</th>
    </tr>
    <tr>
        <td><a class="text-xs" href="https://www.vinter.me/" target="_blank" rel="noreferrer">https://www.vinter.me/</a></td>
        <td>_fbp</td>
        <td>3 months</td>
    </tr>
</table>

<h4>4. Consent to use cookies and changing settings</h4>
<p>We will ask for your consent to place Cookies or other similar technologies on your device, except where they are essential for us to provide you with a service that you have requested (e.g., to enable you to put items in your shopping basket and use our check-out process).</p>
<p>You can withdraw any consent to the use of cookies or manage any other cookie preferences by [ using our privacy dashboard at [insert link] / clicking on the [insert details] icon at the bottom of any page on our site.]. It may be necessary to refresh the page for the updated settings to take effect.</p>

<h4>5. How to turn off all cookies and the consequences of doing so</h4>
<p>If you do not want to accept any Cookies, you can adjust the browser settings so that your browser refuses all Cookies or only Third Party Cookies. You can also delete Cookies that have already been placed. Note that you must adjust the settings separately for each browser and computer that you use.</p>
<p>If you do this, please be aware that you may lose some of the functionality of our website. How you can adjust the settings differs per browser. If necessary, consult the help function of your browser, or click on one of the links below to go directly to the manual of that specific browser:</p>
<ul>
    <li><a class="text-xs" href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noreferrer">Microsoft Edge</a></li>
    <li><a class="text-xs" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer#w_cookie-settings" target="_blank" rel="noreferrer">Firefox</a></li>
    <li><a class="text-xs" href="https://support.google.com/chrome/answer/95647?hl=n" target="_blank" rel="noreferrer">Chrome</a></li>
    <li><a class="text-xs" href="https://support.apple.com/tr-tr/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">Safari</a></li>
</ul>

<h4>6. Updates to this Cookie Notice</h4>
<p>We may amend this Cookie Notice when we implement new technologies and functions. If we make significant changes, we will notify you of this. For example, by posting a notification on our Websites, through email, pop-ups (e.g., by requesting your consent again), or similar methods.</p>

<h4>7. Contact us</h4>
<p>If you wish to contact Vinter AI about this Cookie Notice and the use of Cookies in the Services, please contact our data protection officer at <a href="mailto:privacy@vinter.me">privacy@vinter.me</a>, or send a letter to: 74a High Street, Wanstead, London, Greater London, United Kingdom, E11 2RJ.</p>
</div>

