import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { UserConsentComponent } from '../user-consent/user-consent.component';
import { UserConsentModule } from '../user-consent/user-consent.module';
import { BackgroundMaskModule } from '@app/shared/module/shared/background-mask.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CountryService } from '@app/vinter/services/country.service';
import { RegisterComponent } from './register.component';
import { RegisterRoutingModule } from './register-routing.module';
import { RecaptchaModule } from 'ng-recaptcha-2';



@NgModule({
  declarations: [
    RegisterComponent
  ],
  imports: [
      AutoCompleteModule,
      CommonModule,
      RegisterRoutingModule,
      ToastModule,
      TranslateModule,
      InputTextModule,
      PasswordModule,
      FormsModule,
      ButtonModule,
      CheckboxModule,
      ReactiveFormsModule,
      SelectButtonModule,
      ToggleButtonModule,
      DialogModule,
      InputNumberModule,
      UserConsentModule,
      BackgroundMaskModule,
      RecaptchaModule,
    ],
    providers: [ 
      CountryService 
    ]
})
export class RegisterModule { }
