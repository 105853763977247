import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuVisibilityService {

  private menuVisibility = new BehaviorSubject(1);
  menuVisibility$ = this.menuVisibility.asObservable();

  constructor() { }

  changeMenuVisibility(visibility: number) {
    this.menuVisibility.next(visibility)
  }
}
