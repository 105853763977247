import { Component, Input, OnInit } from '@angular/core';
import { vinterLogoWithoutTextPath } from '@app/shared/common/constants';
import { EQuestionCollectionSubCategory, QuestionCollectionCategoryEnum } from '@app/shared/common/enums';
import { UserManagementListItem } from '@app/vinter/models/user-profile-list-item';
import * as html2pdf from 'html2pdf.js';


@Component({
  selector: 'app-ai-pdf-report',
  templateUrl: './ai-pdf-report.component.html',
  styleUrls: ['./ai-pdf-report.component.scss']
})
export class AiPdfReportComponent implements OnInit{
  

  @Input() positionTitle: string;
  @Input() applicant: UserManagementListItem;
  @Input() applicationStepsData;
  @Input() answeredQuestionsWithId;
  @Input() aiReportData;
  @Input() languageAnsweredQuestions;
  @Input() organizationImageUrl: string;
  @Input() totalScore: number;
  @Input() organizationName: string;

  currentPage:number = 2;
  firstPartReportText: string = '';
  secondPartReportText: string = '';
  applicantPercentage: number = 0;

  isAIPdfOpen: boolean = false;
  isCompletedPosition: boolean = true;
  stepCompletedDate: Date = new Date();

  chartData: any[] = [];

  organizationLogo: string;
  vinterLogoPath: string = vinterLogoWithoutTextPath;
  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  QuestionCollectionSubCategory = EQuestionCollectionSubCategory;
  
  ngOnInit(): void {
    this.organizationLogo = this.vinterLogoPath;
    this.chartData = this.prepareSpiderChartData(this.languageAnsweredQuestions);
    this.getCompletedDatePosition();
    this.generateAiBasedPdf();
  }

  generateAiBasedPdf(){
    this.isAIPdfOpen = true;
    setTimeout(() => {
        const element = document.getElementById('applicantAIBasedPdf');
        const opt = {
        margin: [0, 0, 0, 0],
        filename: 'AIBased_Report_' +  this.applicant.firstName + " "+ this.applicant.lastName + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        pagebreak: { avoid: "#area", mode: "css" },
        html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true, allowTaint: true, useCORS: true },
        jsPDF: { unit: 'px', format: [575, 822], orientation: 'portrait' },
    };
        html2pdf().from(element).set(opt).save();
        this.isAIPdfOpen = false;
    }, 0);
  }

  getCompletedDatePosition(){
    const stepsWithId = this.applicationStepsData.filter(step => step.id);
    this.isCompletedPosition =  stepsWithId.every(step => step.completedDate !== null);

    if(this.isCompletedPosition){
      this.stepCompletedDate = stepsWithId[stepsWithId.length - 1].completedDate;
    }
  }

  getIntegerPart(score: number): string {
    let value = "00";
    if(score > 0)
      value = Math.floor(score).toString();
    return value;
  }

  getDecimalPart(score: number): string {
    const decimalPart = score.toFixed(2).split('.')[1];
    return decimalPart || '00'; 
  }

  getReportText(applicationStepId:string) {
    const report = this.aiReportData.steps.find(item => item.applicationStepId === applicationStepId);
    this.calculatePercentage(report);
    this.splitReportText(report);
    return report ? report : {};
  }

  calculatePercentage(report){
    this.applicantPercentage = (report.currentOrder / report.currentApplicantCount ) * 100;
  }

  splitReportText(report) {
    const maxChars = 450;
    if (report.reportText.length > maxChars) {
        let splitIndex = report.reportText.lastIndexOf(' ', maxChars);
        if (splitIndex === -1) {
            splitIndex = maxChars;
        }
        this.firstPartReportText = report.reportText.slice(0, maxChars);
        this.secondPartReportText = report.reportText.slice(maxChars).trim();
    } 
    else {
        this.firstPartReportText = report.reportText;
        this.secondPartReportText = '';
    }
  }

  prepareSpiderChartData(data: any[]): any[] {
    const grouped = data.reduce((acc: any, item: any) => {
      if (!acc[item.positionStepId]) {
        acc[item.positionStepId] = [];
      }
      acc[item.positionStepId].push(item);
      return acc;
    }, {});

    return Object.keys(grouped).map(positionStepId => {
      const group = grouped[positionStepId];
      const labels: string[] = [];
       const chartData: number[] = [];
       group.forEach((item:any) => {
          labels.push(item.category);
          let score = 0;
          if(item.category !== "Writing" && item.category !== "Speaking"){
            score = item.averageScores*100;
          }
          else{
            score = item.averageScores;

          }
          chartData.push(score);
       });
       return {
         positionStepId: positionStepId,
         labels:labels,
         chartData:chartData,
       }
    });
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

}
