import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/core';
import { HR, ORGADMIN } from '@app/shared/common/constants';

@Component({
  selector: 'app-login-with-key',
  templateUrl: './login-with-key.component.html',
  styleUrls: ['./login-with-key.component.scss']
})
export class LoginWithKeyComponent implements OnInit {

  constructor(
          private authenticationService: AuthenticationService,
          private router: Router,
          private route: ActivatedRoute,
          private credentialsService : CredentialsService
      ) {}
  ngOnInit(): void {
    this.doLoginWithApiKey();
  }

    doLoginWithApiKey() {
      const rawApiKey = this.route.snapshot.queryParams['apiKey'];
      const apiKey = decodeURIComponent(rawApiKey)
      .replace(/\u002B/g, '+')  
      .replace(/\u002F/g, '/')  
      .replace(/ /g, '+')       
      .replace(/%2B/g, '+')     
      .replace(/%2F/g, '/')     
      .replace(/%3D/g, '=')     
      .replace(/%2C/g, ',')     
      .replace(/%25/g, '%')     
      .replace(/%3A/g, ':')     
      .replace(/%2E/g, '.')     
      .replace(/%23/g, '#')     
      .replace(/%3F/g, '?')     
      .replace(/%5C/g, '\\')    
      .replace(/%2D/g, '-')     
      .replace(/%20/g, ' ')     
      .replace(/%5B/g, '[')     
      .replace(/%5D/g, ']')     
      .replace(/%7B/g, '{')     
      .replace(/%7D/g, '}');    

      if (!apiKey) {
          console.error('Cannot find API Key !');
          return;
      }
  
      this.authenticationService.loginWithApiKey({ apiKey }).subscribe({
          next: (data) => {
              this.authenticationService.setCredentials(data);
              var userGroups = this.credentialsService.getUserGroups().split(',');
              userGroups.some((s) => s.includes(ORGADMIN)) ||
              userGroups.some((s) => s.includes(HR))
              const redirectUrl = this.route.snapshot.queryParams.redirect || 'dashboard/hr-dashboard';
              this.router.navigate([redirectUrl], { replaceUrl: true });
          },
          error: (error) => {
              console.error('Login Failed: ', error);
          }
      });
  }
      
}
