export enum StatusEnum {
    Active = 0,
    Passive = 1,
}

export enum EGeneralApplicationSection {
    AboutSection = 1,
    FormSection = 2,
}

export enum ActiveEnum {
    Active = 1,
    Passive = 0,
}

export enum EntityStateEnum {
    Detached = 0,
    Unchanged = 1,
    Deleted = 2,
    Modified = 3,
    Added = 4,
}

export enum EmailVerificationStatusEnum {
    NotTouched = 0,
    VerifyButtonClicked = 0,
    TimerWaiting = 1,
    TimerEnd = 2,
}

export enum YesNoEnum {
    Yes = 1,
    No = 0,
}

export enum ConnectionCriteriaMatchEnum {
    Personel = 1,
}

export enum ConnectionCriteriaFormulaTypeEnum {
    FromDetailCriteriaGroupDefinitions = 1,
    FromDetailCriteriaField = 2,
    Manual = 3,
}

export enum LanguageEnum {
    TurkishTurkey = '9fc6d1ee-0b29-4622-8cee-b349d863b1c5',
    EnglishUnitedStates = '00ae8b40-bafb-4f5d-8111-552a53c033bb',
}
export enum ConditionOperatorEnum{
    LessThan = 1,
    GreaterThan = 2,
    Equals = 3
}


export enum FormOperationEnum {
    None = 0,
    NewRecord = 1,
    Delete = 2,
    Save = 3,
}
export enum EUserWorkExperienceType{
    Internship = 1,
    PartTimeWork = 2,
    FullTimeWork = 3
  }
  
export enum WorkModels {
    Remote = 1,
    Hybrid = 2,
    Office = 3
  }
  export enum WorkModelsFilterValue {
    Remote = 1,
    Hybrid = 2,
    Office = 3,
    All = 4
  }

export enum ModuleNameEnum {
    UserManagement = 'userManagement',
    UserGroupManagement = 'userGroupManagement',
    CompanyManagement = 'companyManagement',
    SiteManagement = 'siteManagement',
    SiteParameters = 'siteParameters',
    TeamManagement = 'teamManagement',
    RoleManagement = 'roleManagement',
    PersonelKaizen = 'PersonelKaizen',
    MainCriteria = 'MainCriteria',
    DetailCriteria = 'DetailCriteria',
    criteriaCalculationDefinitions = 'CriteriaCalculationDefinitions',
}

export enum ApiControllerEnum {
    Language = 'Language',
    Menu = 'Menu',
    User = 'User',
    UserGroup = 'UserGroup',
    Team = 'Team',
    Organization = 'Organization',
    OrganizationApiKey = 'DeveloperKey',
    Position = 'Position',
    OrganizationUserRelation = 'OrganizationUserRelation',
    OrganizationType = 'OrganizationType',
    QuestionCollection = 'QuestionCollection',
    Keyword = 'Keyword',
    QuestionContent = 'QuestionContent',
    Question = 'Question',
    PositionStep = 'PositionStep',
    PositionStepReport = 'PositionStepReport',
    ApplicantEducationInfo = 'ApplicantEducationInfo',
    ApplicantExperienceInfo = 'ApplicantExperienceInfo',
    Invitation = 'Invitation',
    Application = 'Application',
    QuestionCollectionQuestionRelation = 'QuestionCollectionQuestionRelation',
    Answer = 'Answer',
    AnswerContent = 'AnswerContent',
    AnswerContentHRScore = 'AnswerContentHRScore',
    ApplicationStep = 'ApplicationStep',
    Dashboard = 'Dashboard',
    Rating = 'Rating',
    Report = 'Report',
    PositionCategory = 'PositionCategory',
    MailTemplate = 'MailTemplate',
    ApplicationLog = 'ApplicationLog',
    ApplicantBehaviourEventLog = 'ApplicantBehaviourEventLog',
    UserProfile = 'UserProfile',
    UploadFile = 'UploadFile',
    StepPerTestResult = 'StepPerTestResult',
    LicensePlan = 'LicensePlan',
    LicenseOrganizationInfo = 'LicenseOrganizationInfo',
    Mail = 'Mail',
    PositionAutomationRule = 'PositionAutomationRule',
    GeneralApplicationAutomationRule = 'GeneralApplicationAutomationRule',
    CVAnalyzeAutomationRule = 'CVAnalyzeAutomationRule',
    ApplicationOffer = 'ApplicationOffer',
    GeneralApplicationQuestion = 'GeneralApplicationQuestion',
    Payment = 'Payment',
    GeneralApplicationInvitation = 'GeneralApplicationInvitation',
    GeneralApplicationForm = 'GeneralApplicationForm',
    OrganizationParameter = 'OrganizationParameter',
    OrganizationAccountInfo = 'OrganizationAccountInfo',
    AccountUserInfo = 'AccountUserInfo',
    QuestionConcept = 'QuestionConcept',
    UserNotification = "UserNotification",
    Monitoring = 'Monitoring',
    Integration = "Integration",
    IntegrationPosition = "IntegrationPosition",
    IntegrationApplicant = "IntegrationApplicant",
    AIModelTracking = "AIModelTracking",
    OrganizationIntegration = "OrganizationIntegration",
    LicenseOrganizationInfoInvoiceLog = "LicenseOrganizationInfoInvoiceLog",
    OrganizationDocument = "OrganizationDocument"
}

export enum UserAttendanceStatusEnum {
    CheckedIn = 1,
    CheckedOut = 2,
    Break = 3,
    Back = 4,
}

export enum PaymentPeriodEnum{
    Monthly=1,
    Annual=2,
    OneShot=3
}

export enum UserTypeEnum{
    DefaultUser="DefaultUser",
    APIKeyUser="APIKeyUser"
}

export enum LicenseTypeEnum{
    MainLicense = 1,
    AdditionalLicense = 2
}

export enum UserGroupAuthEnum {
    True = 1,
    False = 0,
}

export enum TargetDataTypeEnum {
    Int = 1,
    Decimal = 2,
    String = 3,
    DateTime = 4,
    Boolean = 5,
}

export enum QuestionCollectionCategoryEnum {
    TechnicalTest = '69b3dabc-97ec-431b-b58d-750d0252cd61',
    CompetenceTest = '2404b760-6161-45a1-aa4f-8358f5cedca9',
    PersonalityTest = '6e114849-776c-445c-a59b-13d189a0393d',
    ForeignLanguage = 'b3559dc8-57eb-4c9d-99df-3d089adb44b9',
    CognitiveAbility = '9128b06e-0eaa-482a-994c-4f5c0814f138',
    Pronunciation = '05246b39-91fb-4f1a-82c9-fef8c2593e05',
    MTCompetenceTest = '70e63067-0f9a-4ef7-b1c1-7b5fcaf08bdc',
    Custom = '4c87dbc7-0b33-4b4c-b1e7-529a8f32c520',
    Coding = '58aa915f-fe09-45bd-b351-5f76409e8d8d',
    SpecialSelect = 'cff362f0-40bd-4867-bf74-626f79808542',
}

export enum EducationLevelEnum {
    HighSchool = 1,
    AssociateDegree=2,
    BachelorDegree=3,
    MasterDegree=4,
    Doctorate=5
  }

export enum StepNameCategoryNameEnum {
    TechnicalStepName = '69b3dabc-97ec-431b-b58d-750d0252cd61',
    CompetenceStepName = '2404b760-6161-45a1-aa4f-8358f5cedca9',
    PersonalityStepName = '6e114849-776c-445c-a59b-13d189a0393d',
    ForeignLanguageStepName = 'b3559dc8-57eb-4c9d-99df-3d089adb44b9',
    CognitiveAbilityStepName = '9128b06e-0eaa-482a-994c-4f5c0814f138',
    PronunciationStepName = '05246b39-91fb-4f1a-82c9-fef8c2593e05',
    MTCompetenceStepName = '70e63067-0f9a-4ef7-b1c1-7b5fcaf08bdc',
}

export enum LicenseTypeEnum {
    Basic = 1,
    Essential = 2,
    Premium = 3,
    Enterprise = 4,
}
export enum OrganizationSortEnum {
    increasingPrice = 1,
    decreasingPrice = 2,
    invoiceDate = 3,
}
export enum OrganizationPositionSortEnum {
    increasingPrice = 1,
    decreasingPrice = 2,
}
export enum EApplicantEducationDegreeType{
    HighSchool = 1,
    AssociateDegree = 2,
    BachelorDegree = 3,
    MasterDegree = 4,
    Doctorate = 5
  }

export enum OrganizationEnum {
    Vinter = 'ea1bf6a1-af29-46d2-aff7-f120bdb4f553',
}

export enum QuestionExpectedAnswerType {
    MultipleChoice = 1,
    OpenEnded = 2,
    Video = 3,
    Coding = 4,
}

export enum AnswerType {
    MultipleChoice = 1,
    OpenEnded = 2,
    Video = 3,
    Coding = 4,
}

export enum WritingAnalysisMap {
    Lexis = 'lexis',
    GrammarWriting = 'grammar',
    Mechanics = 'mechanics',
    OrganizationWriting = 'organization',
    TaskAchievement = 'task_achievement',
}

export enum EOrganizationApiKeyExpirationRangeType {
    OneMonth = 1,
    ThreeMonths = 2,
    SixMonths = 3,
    TwelveMonths = 4,
}

export enum AddQuestionShownType {
    PreviewCurrent = 1,
    SaveForList = 2,
    SaveForStep = 3,
}

export enum PositionFormEditModeEnum {
    EditMode = 1,
    TemplateMode = 2,
    CreateMode = 3,
    UseFromMode = 4,
    TemplateEditMode = 5
}

export enum PositionFormEditRootEnum {
    PositionList = 1,
    PositionDetail = 2,
    MyLibrary = 3,
    VinterLibrary = 4,
}

export enum QuestionListRootEnum {
    MyLibrary = 1,
    VinterLibrary = 2,
}

export enum PositionStepType {
    QuestionList = 1,
    VideoMeeting = 2,
    EnglishExam = 3,
}
export enum PositionStepTypeEnum {
    QuestionList = 1,
    Custom = 2,
}

export enum OrganizationTypeEnum {
    MainOrganization = 'da37afcf-a386-4046-aa9d-7aed531f2996',
    Department = '507c960d-45a4-4c1b-8ac6-f9e5ad3598b5',
}

export enum UserGroupEnum {
    VINTERADMIN = 'dd72a9fd-e207-414f-a304-848ab311580c',
    ORGADMIN = 'f2428f62-dc8d-4091-8c72-a90eb72d4547',
    HR = 'acdb7286-b1cc-465b-8778-344595d4a11f',
    APPLICANT = 'd9ba2032-76e3-440c-8334-838229fa8abe',
}
export enum UserGroupCodeEnum {
    VINTERADMIN = 'VINTERADMIN',
    ORGADMIN = 'ORGADMIN',
    HR = 'HR',
    APPLICANT = 'APPLICANT',
}

export enum ApplicationStepStatus {
    NotStarted = 1,
    Ready = 2,
    InProgress = 3,
    ApplicantCompleted = 4,
    HRCompleted = 5,
}

export enum MailDeliveryStatus {
    Fail = 0,
    Send = 1,
    PendingAccountMail = 2,
    Unknown = 3,
}

export enum PositionStepKanbanStaticTypes {
    Invitation = '-1',
    Archived = '-2',
    Rejected = '-3',
    Offer = '-4',
    Hired = '-5',
    ShortList = '-6',
    GeneralApplication = '-7',
}

export enum ApplicationStatus {
    InProgress = '1',
    HRRejected = '2',
    HRArchived = '3',
    HROffer = '4',
    Hired = '5',
    ShortList = '6',
}

export enum EApplicantBehaviourEventType {
    PageVisible = 1,
    PageHidden = 2,
    MouseIn = 3,
    MouseOut = 4,
}

export enum EQuestionContentURLType {
    Text = 0,
    Image = 1,
    Audio = 2,
    Video = 3,
}

export enum LicensePlanPriceType {
    Monthly = 1,
    Yearly = 2,
    OneShot = 3,
}

export enum ELanguages {
    Turkish = '9fc6d1ee-0b29-4622-8cee-b349d863b1c5',
    English = '00ae8b40-bafb-4f5d-8111-552a53c033bb',
}

export enum EQuestionCollectionLanguages {
    Turkish = '9fc6d1ee-0b29-4622-8cee-b349d863b1c5',
    English = '00ae8b40-bafb-4f5d-8111-552a53c033bb',
    Multiple = 'dc0ddd00-cbf8-4c41-9d31-3eba411044e6',
}

export enum WringErrorColorEnum {
    Grammar_Error = '#FE0000',
    TON_ACADEMIC = '#FE0000',
    CASING = '#F88',
    COLLOCATIONS = '#FF5959',
    CONFUSED_WORDS = '#C80000',
    COMPOUNDING = '#E9047F',
    CREATIVE_WRITING = '#FF73BE',
    GRAMMAR = '#FF007A',
    Form_Error = '#F88',
    Lexis_Error = '#FF5959',
    Lexico_Grammar_Error = '#C80000',
    Word_Error = '#E9047F',
    Miscellaneous_Error = '#FF73BE',
    Punctuation_Error = '#FF007A',
    MISC = '#B04759',
    NONSTANDARD_PHRASES = '#9A1663',
    PLAIN_ENGLISH = '#E1578A',
    TYPOS = '#D80B0B',
    PUNCTUATION = '#FF5E04',
    REDUNDANCY = '#EA7000',
    STYLE = '#F8945D',
    SEMANTICS = '#A00',
    TEXT_ANALYSIS = '#C20069',
    TYPOGRAPHY = '#F09D22',
    WIKIPEDIA = '#A7224A',
    BRITISH_ENGLISH = '#FD841F',
    AMERICAN_ENGLISH_STYLE = '#FD841F',
}
export enum EVoiceSpeechSpeed {
    TooSlow = 1,
    Slow = 2,
    Normal = 3,
    Fast = 4,
    TooFast = 5,
}
export enum LicensePlanEnum {
    Basic = 'eaee13cc-726a-4526-bafb-36eed18b0c00',
    BasicAnnual = '14c21964-d1d8-4a71-91e5-f771bc88a030',
    Essential = 'e2cf9ec3-7871-4214-a248-942ade96b3ba',
    EssentialAnnual = '8346666c-50ad-43c5-85b8-a012fa402c15',
    Premium = 'd42477bc-2b4c-4205-b49f-5a43f923c988',
    PremiumAnnual = 'cc6a6937-54a6-4c02-b51c-3fac36760d73',
    Enterprise = '0854b47b-6c8d-4ce7-a566-95a27e73cf42',
    EnterpriseAnnual = '92c63080-08e5-47da-9c42-657113e9c214',
    Trial = 'f9383f46-4350-4e79-84e6-36a894e06fcd',
    EnterpriseAnnualPlus = "66c635d9-59de-4b2e-b0c5-f3758001dbe8",
    EnterpriseOneShot = "7558b43e-f615-4ef1-99a5-03149623de83",
    ATSOneShotMonthly = "b0989424-d814-4904-820d-f1081a7199e8",
    ATSOneShotAnnual = "dd5c9493-63a6-435e-9093-e15fe2f6110c"
}
export enum ELicensePlanType {
    StandartPlan = 1, 
    ExtraPackage = 2
}
export enum ELicensePlanName {
    BasicAnnual = "basicannual", 
    BasicMonthly = "basicmonthly", 
    PremiumAnnual = "premiumannual", 
    Premiummonthly = "premiummonthly", 
    EssentialAnnual = "essentialannual", 
    EssentialMonthly = "essentialmonthly"
}
export enum EQuestionCollectionSubCategory {
    LevelEnglish = 0,
    OCEAN50 = 1,
    OCEAN120 = 2,
    GeneralEnglish = 11,
    QuickEnglish = 12,
    VideoEnglish = 13,
    NumericalCognitiveAbility=21,
    VerbalCognitiveAbility=22,
}

export enum EFormType {
    QestionCollection = 0,
    Position = 1
}

export enum EGeneralApplicationQuestionType {
    MultipleChoice = 1,
    OpenEnded = 2,
}

export enum EGeneralApplicationFormStatus {
    Applied = 1,
    Passed = 2,
}

export enum EGeneralApplicationFormAccountLinkType {
    Linkedin = 1,
    Github = 2,
    Behance = 3,
    Dribbble = 4,
    Medium = 5,
}

export enum EGeneralApplicationFormAccountLinkTypeURL {
    Linkedin = 'https://www.linkedin.com/in/',
    Github = 'https://github.com/',
    Behance = 'https://www.behance.net/',
    Dribbble = 'https://dribbble.com/',
    Medium = 'https://medium.com/@',
}

export enum EGeneralApplicationInvitationStatus {
    Pending = 1,
    FormFilled = 2,
}

export enum EInvitationStatus {
    PendingAccountMail = 0,
    Accepted = 1,
}

export enum ELicensePlanTypes {
    ANNUAL = 1,
}

export enum ERatingType {
    VinterTrust = 1,
    HrPoint = 2,
}

export enum EDeviceType {
    Desktop = 1,
    Mobile = 2,
    Tablet = 3,
    AnyDevice = 4,
}

export enum GPTEvalStatus {
    Waiting = 1,
    Succesful = 2,
    Fail = 3,
    Retry = 4,
    ClientFail = 5,
    VideoFail = 99
}

export enum ProcessStatus {
    AIContainerWaiting = 1,
    AIContainerInprogress = 2,
    AIContainerCompleted = 3,
    VideoConversionWaiting = 4,
    VideoConversionInprogress = 5,
}

export enum EMenuVisibility {
    ShowTopBarSideBar = 1,
    ShowTopBar = 2,
    HideTopBarSideBar = 3,
}

export enum EOrganizationSettingsMenu {
    OrganizationInfo = 0,
    Departments = 1,
    Users = 2,
    Plan = 3,
    MailTemplates = 4,
    Preferences = 5,
    DeveloperAPI = 6,
    Invoices = 7
}
export enum EApplicantDetailMenu {
    HiringProcess = 0,
    ApplicantProfile = 1,
    Resume = 2,
    Notes = 3,
}

export enum EProfileVisibility {
    ShowProfile = 1,
    ShowLogout = 2,
}

export enum SelectedChartTimeData {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4,
}

export enum OfferRejectedReasons {
    InsufficientWages = 1,
    SideBenefit = 2,
    FamilyReasons = 3,
    PersonalReasons = 4,
    TaskEnrichmentFromCurrentCompany = 5,
    Location = 6,
    IntenseTravel = 7,
    DifferentCareerGoal = 8,
    DevelopmentOpportunities = 9,
    EvaluatingDifferentJobOffer = 10,
    HowItWorks = 11,
    CompanyCulture = 12,
    JobResponsibilitiesExpectationsDoNotSuitTheCandidate = 13,
    Other = 14,
}

export enum EQuestionCategories {
    Grammer = '9d6b937b-4547-49fb-9ece-94fab9a21823',
    Listening = 'ae37827a-9a21-4e11-8457-106b5eee5acd',
    Reading = '9792496c-b7fe-4ad4-8392-015b1d1be32b',
    Vocabulary = '62d0adcb-e8db-4f07-bf8a-8543ed113e73',
    Writing = 'eafc263c-d052-49e4-83bc-47629b9d959b',
    Pronunciation = '5d30c3ec-9cf5-417a-a8ba-25850348e702',
    Speaking = '7f2f451e-9652-4104-8305-b810a19c74d4'
}

export enum EPositionAutomationRuleWhenSourceType {
    AvgVinterScore = 1,
    // AvgHrRating=2
}

export enum ECVAnalyzeAutomationRuleWhenSourceType {
    Degree = 1,
    WorkExperience = 2,
    GPAMean = 3,
}

export enum EAutomationType {
    Position = 1,
    GeneralApplication = 2,
    CVAnalyze = 3
}

export enum EPositionApproveState {
    No = 'No',
    Yes = 'Yes',
}

export enum EListeningQuestionsParamState {
    No = 'No',
    Yes = 'Yes',
}

export enum ESpeakingQuestionsParamState {
    No = 'No',
    Yes = 'Yes',
}

export enum EWelcomeTextState {
    No = 'No',
    Yes = 'Yes',
}

export enum EOrganizationParameterCode {
    PositionApproveState = 'PositionApproveState',
    OrganizationWelcomeTextActive = 'OrganizationWelcomeTextActive',
    OrganizationWelcomeTextActiveContent = 'OrganizationWelcomeTextActiveContent',
    VoiceAnalysisStatus = "VoiceAnalysisStatus",
    ImproprietyAnalysisStatus = "ImproprietyAnalysisStatus",
    MultipleFaceAnalysisStatus = "MultipleFaceAnalysisStatus",
    DifferentFaceAnalysisStatus = "DifferentFaceAnalysisStatus",
    EmotionAnalysisStatus = "EmotionAnalysisStatus",
    ImpressionAnalysisStatus = "ImpressionAnalysisStatus",
    SpeakingVideoLimitationStatus = "SpeakingVideoLimitationStatus",
    ListeningAudioLimitationStatus = "ListeningAudioLimitationStatus",
    OpenEndedQuestionStatus = "GeneralApplicationOpenEndedQuestionStatus",
}

export enum EApprovalStatus {
    WaitingForApproval = 1,
    NotApproved = 2,
    Approved = 3,
}
export enum EOrganizationAccountType {
    Customer = 1,
    Prospect = 2,
    Suspect = 3,
}
export enum ELicenseType {
    Monthly = 1,
    Annual = 2,
    OneShot = 3
}
export enum ELicensePaymentStatus {
    PendingPayment = 1,
    Successful = 2,
    Failed = 3,
    Trial = 4,
}

export enum EOrganizationParameterType {
    OrganizationAdmin = 1,
    VinterAdmin = 2,
}

export enum EOneShotLicensePaymentStatus {
    PendingPayment = 1,
    Successful = 2,
}
export enum LicensePlanCodeEnum {
    Basic = 'BASIC_MONTHLY',
    BasicAnnual = 'BASIC_ANNUAL',
    Essential = 'ESSENTIAL_MONTHLY',
    EssentialAnnual = 'ESSENTIAL_ANNUAL',
    Premium = 'PREMIUM_MONTHLY',
    PremiumAnnual = 'PREMIUM_ANNUAL',
    Enterprise = 'ENTERPRISE_MONTHLY',
    EnterpriseAnnual = 'ENTERPRISE_ANNUAL',
    EnterpriseAnnualPlus = "ENTERPRISE_ANNUAL_PLUS",
    EnterpriseOneShot = "ENTERPRISE_ONE_SHOT",
    Trial = 'TRIAL',
    Ats ="ATS_ONE_SHOT_MONTHLY",
    AtsAnnual ="ATS_ONE_SHOT_ANNUAL"
}

export enum EMailTemplateSettingType {
    Organization = 1,
    Position = 2,
}
export enum EFaceDetectionStatus {
    VerifiedAndHasFace = 0,
    VerifiedAndNonFace = 1,
    UnverifiedAndHasFace = 2,
    UnverifiedAndNonFace = 3
}

export enum ESuggestedPackageType {
    AIReport = 1,
    Automation = 2,
    Coding = 3,
    AtsIntegration = 4,
}

export enum EMailTemplateTypes {
    Invitation = 0,
    Steps = 1,
    Archived = 2,
    Reject = 3,
    HRInvitation = 4,
    OrgAdminInvitation = 5,
    ForgetPassword = 6,
    PasswordChangedNotification = 7,
    StepCompleted = 8,
    RegistrationSuccessful = 9,
    PreInvitation = 10,
    PositionApproved = 11,
    PositionNotApproved = 12,
    PositionWaitingApprove = 13,
    GeneralAppFormMailValidationCode = 14,
    FreeTrial = 15,
    AIBasedReportNotification = 16,
    InvitedToDepartment = 17,
    StepRedoNotification=18,
    StepProgressionReminder = 19,
    LicensePaymentFailed = 20,
    CvReview = 21,
    InvitationWithAutomation = 22,
}


export enum EAccountUserType {
    ContactUser = 1,
    InvoiceUser = 2,
    OrganizationAdminUser = 3,
}


export enum EApplicationPaymentStatus {
    Pending = 1,
    Successful = 2
}

export enum EDealRange {
    _14Days = 0,
    _1Month = 1,
    _3Months = 3,
    _6Months = 6,
    _12Months = 12,
    _24Months = 24
}

export enum EDealRangeWithoutTrial {
    _1Month = 1,
    _3Months = 3,
    _6Months = 6,
    _12Months = 12,
    _24Months = 24
}

export enum ECVAutomationResultStatus {
    CVMatched = 1,
    CVNotMatched = 2
}


export enum EUserConsentPageType {
    ApplicantRegister = 1,
    ApplicantGeneralApplication = 2,
    HrRegister = 3
}

export enum EUserConsentType {
    TermsOfUse = 1,
    PrivacyPolicy = 2,
    CookiePolicy = 3,
    HrDisclosure = 4,
    TEIOM = 5,
    ApplicantDisclosure = 6
}

export enum EDocumentType {
    File = 1,
    URL = 2
}

export enum EStepDurationType{
    StepBased = 1,
    QuestionBased = 2,
    SectionBased = 3
}
export enum EStepDurationTypeForOptionValue{
    StepBased = 1,
    QuestionBased = 2,
}
export enum EStepScoringType{
    Default = 1,
    WeightedQuestion = 2,
}

export enum EInvoiceStatusType
{
    DraftInvoiceCreateStart = 1,
    DraftInvoiceCreated = 2,
    InvoicePaymentFinished = 3,
    InvoicePDFSaved = 4
}

export enum EUserNotificationStatus{
    Created = 0,
    NotRead = 1,
    Read = 2,
}

export enum TranscriptStatus{
    Waiting = 1,
    Succesful = 2,
    Fail = 3
}

export enum ApplicantPdfReportTypeEnum {
    DetailPdf = 1,
    SummaryPdf = 2,
    AIPdf = 3
}