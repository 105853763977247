<div class="flex gap-3 flex-wrap mt-3">
    <div class="flex-nowrap flex gap-2 align-items-center" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
    </div>
    <div *ngIf="answer?.isFaceDetected && answer?.isAllowedDifferentFaceAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-icon.svg" class="detect-img">
        <p class="font-medium face-detected">{{"Key.FaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.isMultiFaceDetected && answer?.isAllowedMultipleFaceAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/multiface-detected-icon.svg" class="detect-img">
        <p class="font-medium multiface-detected">{{"Key.MultiFaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.faceDetectionStatus === EFaceDetectionStatus.VerifiedAndNonFace ||
     answer?.faceDetectionStatus === EFaceDetectionStatus.UnverifiedAndNonFace" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-status-icon.svg" class="detect-img">
        <p class="font-medium impropriety-speech">{{"Key.FaceDetectionStatus" | translate }}</p>
    </div>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="answer-coding-container ml-5 px-0">
    <div class="answer-text-label text-justify">
        <app-vinter-code-editor
            [isSelectionEnabled]="true"
            [dropdownReadonly]="true"
            [template]="answer?.answerContent?.answerText"
            [codeType]="answer?.answerContent?.answerCodeLanguage"
            [height]="500"
            [disabled]="true">
        </app-vinter-code-editor>
    </div>
</div>

<div class="ml-5 flex" *ngIf="answer === null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.QuestionNotAnswered" | translate}}</p>
</div>
<div *ngIf="answer?.answerContent !== null" class="answer-text -mt-4">
    <div class="flex">
        <h5 class="text-justify font-bold text-blue-800 text-lg ml-2 mb-2 pl-4 m-3">
            {{'Key.VinterComment' | translate}}
        </h5>
    </div>
    <div class="answer-container ml-5 border-1 border-solid border-round-md border-gray-50" [ngClass]="{'justify-content-center': answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry}">
        <img [src]="vinterAICommentIcon">
        <div class="text-blue-800 text-justify mt-2" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Succesful">
            {{ parsedCodingAnalysisResultDetail.general_evaluation }}
        </div>

        <div class="retry-container border-2 border-solid h-13rem w-15rem bg-white" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Retry">
            <div class="grid">
                <div class="col-12 flex justify-content-center">
                    <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                </div>
                <div class="col-12 flex justify-content-center text-gray-700 text-sm font-medium py-0 text-center">
                    {{"Key.GptRetryMessage" | translate}}
                </div>
                <div class="col-12 pt-4 flex justify-content-center">
                    <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                    <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                </div>
            </div>
        </div>

        <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.ClientFail">
            {{ answer?.answerContent?.aiFinalLog }}
        </div>

        <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Fail">
            {{"Key.GptFailStatusMessage" | translate}}
        </div>

        <div class="answer-text-label text-justify" *ngIf="answer?.answerContent?.gptEvalStatus === gptEvalStatusEnum.Waiting || answer?.answerContent?.gptEvalStatus === 99">
            {{"Key.WaitForAIComment" | translate}}
        </div>
    </div>
</div>
<div class="col-12 md:col-12 lg:col-6 grid lg:ml-3 w-full" *ngIf="answer?.answerContent?.gptEvalStatus != gptEvalStatusEnum.ClientFail"> 
    <div class="w-full flex flex-column align-items-center justify-content-center w-full custom-display border-round-lg border-1 border-solid border-blue-50 px-3 py-2">
        <div class="w-full flex">
                <div class="col-6 pl-0 pr-0 text-lg font-semibold text-gray-900">
                    {{'Key.TotalScore' | translate}}
                </div>
                <div class="col-6 flex align-items-center justify-content-end gap-2 pr-0 white-space-nowrap">
                    <p-progressBar class="w-full total-score"
                        [value]="answer?.answerContent?.gptEvalScore"
                        [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                    <span class="font-bold text-blue-700 text-xs">% {{answer?.answerContent?.gptEvalScore.toFixed(0)}}
                    </span>
                </div>
        </div>
        <div class="col-12 p-0">
            <p-accordion [multiple]="true" iconPos="start">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.AccuracyScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.AccuracyScoreInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="parsedCodingAnalysisResultDetail.accuracy.score"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{parsedCodingAnalysisResultDetail.accuracy.score.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{parsedCodingAnalysisResultDetail.accuracy.evaluation}}
                        </p>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.CodeQualityScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.CodeQualityScoreInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="parsedCodingAnalysisResultDetail.code_quality.score"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{parsedCodingAnalysisResultDetail.code_quality.score.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{parsedCodingAnalysisResultDetail.code_quality.evaluation}}
                        </p>
                    </ng-template>
                </p-accordionTab>                       
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="flex align-items-center w-full">
                            <div class="flex align-items-center col-6 gap-2">
                                <span class="text-gray-700 font-medium">{{"Key.EfficiencyScore" | translate}}</span>
                                <img src="assets/vinter/icons/info-fill.svg"  pTooltip="{{'Key.EfficiencyScoreInfo' | translate}}" class="info-fill-icon">
                            </div>
                            <div class="flex align-items-center justify-content-end col-6 gap-2 pr-0 white-space-nowrap">
                                <p-progressBar class="w-full progress-sub"
                                    [value]="parsedCodingAnalysisResultDetail.efficiency.score"
                                    [showValue]="false" [style]="{ height: '8px' }"></p-progressBar>
                                <span class="font-bold text-xs text-blue-500">% {{parsedCodingAnalysisResultDetail.efficiency.score.toFixed(2)}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p class="m-0 text-gray-500 text-sm text-justify">
                            {{parsedCodingAnalysisResultDetail.efficiency.evaluation}}
                        </p>
                    </ng-template>
                </p-accordionTab>        
            </p-accordion>
        </div>
    </div>
</div>