import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { ApplicantPdfReportTypeEnum, EQuestionCategories, EQuestionCollectionSubCategory, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { UserManagementListItem } from '@app/vinter/models/user-profile-list-item';
import { ApplicationStepService } from '@app/vinter/services/application-step.service';
import { ApplicationService } from '@app/vinter/services/application.service';

@Component({
  selector: 'app-pdf-report',
  templateUrl: './pdf-report.component.html',
  styleUrls: ['./pdf-report.component.scss']
})
export class PdfReportComponent implements OnInit{

  @Input() reportType: number;
  @Input() applicationId: string;
  @Input() positionTitle: string;
  @Input() organizationImageUrl: string;
  @Input() organizationName: string;

  @Output() stepDataForAiReport: EventEmitter<any[]> = new EventEmitter<any[]>();


  applicationStepsInfo: any[] = [];
  applicantProfile: UserManagementListItem;
  questionCollectionCategories: any[];
  answeredQuestionsWithId = [];
  languageAnsweredQuestions = [];
  questionCategories: any[];
  aiReportData:any=null;

  isPdfDownload: boolean = false;

  ApplicantPdfReportTypeEnum = ApplicantPdfReportTypeEnum;
  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  QuestionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  EQuestionCategories = EQuestionCategories;

  categoryStats = {
    TechnicalTest: { correct: 0, incorrect: 0, total: 0, positionStepId: '' },
    CognitiveAbility: { correct: 0, incorrect: 0, total: 0, positionStepId: '' },
    CompetenceTest: { correct: 0, incorrect: 0, total: 0, positionStepId: '' }
  };

  validCategories = [
    this.QuestionCollectionCategoryEnum.TechnicalTest,
    this.QuestionCollectionCategoryEnum.CognitiveAbility,
    this.QuestionCollectionCategoryEnum.CompetenceTest
  ];

  strokeDasharray: number = 283;
  totalScore: number = 0;

  constructor(private applicationStepService: ApplicationStepService,
    public translate: LocalizationService,
    private appInfoService: AppInfoService,
    public applicationService:ApplicationService) { }

  ngOnInit(): void {
    this.questionCollectionCategories = this.appInfoService.getQuestionCollectionCategoryArray();
    this.questionCategories = this.appInfoService.getQuestionCategories();

    this.getApplicationSteps();
  }

  getApplicationSteps() {
    if (this.applicationId ?? false) {
      this.applicationStepService.GetListPdfReportInfo({ applicationId: this.applicationId }).subscribe(result => {
        this.applicationStepsInfo = result;
        let score = 0;
        let calcScoreStepCount = 0;


        this.applicationStepsInfo.forEach(element => {
          this.calculateAnsweredQuestions(element);
          element["completedStepDuration"] = this.completedStepTime(element.startedDate, element.completedDate).toFixed(0);
          element["stepTypeDesc"] = this.getTranslationKey(element.positionStep.questionCollection.questionCollectionCategory, element.positionStep.questionCollection.subCategory) ;

          element["strokeDasharray"] = this.strokeDasharray;
          element["calculatedProgressValue"] = this.calculateStrokeOffset(element.averageVinterScore);
          element["progressValue"] = element.averageVinterScore?.toFixed(2);
          element["strokeColor"] = this.getProgressBarColor(element.averageVinterScore);
          element["quickEnglishLevel"] = this.getQuickEnglishLevel(element.averageVinterScore);

          
          if(element.positionStep.questionCollection.questionCollectionCategory !== this.QuestionCollectionCategoryEnum.PersonalityTest && element.positionStep.questionCollection.questionCollectionCategory !== this.QuestionCollectionCategoryEnum.Custom){
            score += element.averageVinterScore;
            calcScoreStepCount++;
          }

        });

        if (this.categoryStats.TechnicalTest.total > 0 || this.categoryStats.CognitiveAbility.total > 0 || this.categoryStats.CompetenceTest.total > 0){
          this.processCategoryStats();
        }

        this.totalScore = score / calcScoreStepCount;
        this.applicantProfile = result[0].applicant;


        if(this.reportType == this.ApplicantPdfReportTypeEnum.AIPdf){
          this.getDownloadadAIBasedPdf();
        }
        else{
          this.isPdfDownload = true;
        }
       
      });
    }
  }

  getProgressBarColor(score: number): string {
    if (score <= 10) return '#F20505';
    if (score <= 20) return '#F55E06';
    if (score <= 30) return '#FF8307';
    if (score <= 40) return '#FDBA04';
    if (score <= 50) return '#FBDF0B';
    if (score <= 60) return '#D7EE06';
    if (score <= 70) return '#AFE705';
    if (score <= 80) return '#6FE819';
    if (score <= 90) return '#01CE2D';
    return '#16BA13';
  }

  getQuickEnglishLevel(score : number){
    if (score >= 0 && score <= 19) {
      return 'A1';
    } else if (score >= 20 && score <= 39) {
      return 'A2';
    } else if (score >= 40 && score <= 65) {
      return 'B1';
    } else if (score >= 66 && score <= 85) {
      return 'B2';
    } else if (score >= 86 && score <= 100) {
      return 'C1';
    } else {
      return '';
    }
  }

  getDownloadadAIBasedPdf(){

    this.applicationService.GetAIBasedStepReport({ id: this.applicationId }).subscribe(reportData=>{
      if(!reportData.isNewStepReportAdded){
        this.aiReportData=reportData;

        for (let index = 0; index < this.applicationStepsInfo.length; index++) {
          let r=reportData?.steps?.find(x=>x.applicationStepId==this.applicationStepsInfo[index].id)
          if(r){
            this.applicationStepsInfo[index].reportText=r.reportText;
            this.applicationStepsInfo[index].currentOrder=r.currentOrder;
            this.applicationStepsInfo[index].currentApplicantCount=r.currentApplicantCount;
          }
          else{
            this.applicationStepsInfo[index].reportText=null;
            this.applicationStepsInfo[index].currentOrder=null;
            this.applicationStepsInfo[index].currentApplicantCount=null;
          }
        }

        this.isPdfDownload = true;

      }
      else{
        this.aiReportData=null;
        //this.toggleShowAiReportAddedNewStepInfo();
      }
      //this.getAIBasedStepReportButtonStatus();
    })

  }

  calculateStrokeOffset(value: number): number {
    const maxOffset = this.strokeDasharray;
    return maxOffset - (value / 100) * maxOffset;
  }

  getSubCategoryEnumKeyFromValue(value: number): string | undefined {
    const keys = Object.keys(EQuestionCollectionSubCategory).filter(key => isNaN(Number(key)));

    for (const key of keys) {
      if (EQuestionCollectionSubCategory[key as keyof typeof EQuestionCollectionSubCategory] === value) {
        return key;
      }
    }
    return undefined;
  }

  getCategoryEnumKeyFromValue(value: string): string | undefined {
    const keys = Object.keys(QuestionCollectionCategoryEnum).filter(key => isNaN(Number(key)));

    for (const key of keys) {
      if (QuestionCollectionCategoryEnum[key as keyof typeof QuestionCollectionCategoryEnum] === value) {
        return key;
      }
    }

    return undefined;
  }

  getTranslationKey(category: string, subCategory?: number): string | null {

    let subCategoryExp =  this.getSubCategoryEnumKeyFromValue(subCategory);
    let categoryExp =  this.getCategoryEnumKeyFromValue(category);

    const categoryMap = {
      PersonalityTest: {
        OCEAN50: 'Key.PdfOceanInfo50',
        OCEAN120: 'Key.PdfOceanInfo120'
      },
      ForeignLanguage: {
        ForeignLanguage: 'Key.PdfLanguageInfo',
        GeneralEnglish: 'Key.PdfLanguageInfo',
        QuickEnglish: 'Key.PdfQuickEnglishInfo'
      },
      Pronunciation: 'Key.PronunciationPdfDesc',
      CompetenceTest: 'Key.PdfCompetenceInfo',
      MTCompetenceTest: 'Key.PdfMTCompetenceInventoryInfo',
      TechnicalTest: 'Key.PdfTechnicalInfo',
      CognitiveAbility: {
        NumericalCognitiveAbility: 'Key.PdfNumericalCognitiveAbilityInfo'
      }
    };

    const categoryEntry = categoryMap[categoryExp];
    if (!categoryEntry) {
      return null;
    }

    if (typeof categoryEntry === 'string') {
      return categoryEntry;
    }

    if (subCategory && categoryEntry[subCategoryExp]) {
      return categoryEntry[subCategoryExp];
    }

    return null;
  }

  completedStepTime(start, complete){
    const startDate = new Date(start);
    const completeDate = new Date(complete);

    let timeDiff = Math.abs(completeDate.getTime() - startDate.getTime());
    const differenceInMinutes = timeDiff / (1000 * 60);
    return differenceInMinutes;
  }

  calculateAnsweredQuestions(data) {
    const questionCollection = data?.positionStep?.questionCollection;
    const category = questionCollection?.questionCollectionCategory;

    if (this.validCategories.includes(category)) {
        questionCollection.questionCollectionQuestionRelations.forEach(element => {
          const categoryKey = Object.keys(QuestionCollectionCategoryEnum).find(key => QuestionCollectionCategoryEnum[key] === data?.positionStep?.questionCollection?.questionCollectionCategory);
          if (categoryKey) {
            if (element.question.isAnswered && element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.MultipleChoice) {
              this.categoryStats[categoryKey].total++;
              this.categoryStats[categoryKey].positionStepId=data.positionStep.id;
              if (element.question.gptScore === 100) {
                this.categoryStats[categoryKey].correct++;
              }
              else {
                this.categoryStats[categoryKey].incorrect++;
              }
            }
          }
        });
    }
    if (category === this.QuestionCollectionCategoryEnum.ForeignLanguage) {
      this.calculateLanguageQuestionsCount(data);
    }
  }

  calculateLanguageQuestionsCount(data: any) {
    let categoryStats = {
      Listening: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Reading: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Grammer: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Vocabulary: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Writing: { correct: 0, incorrect: 0, total: 0, positionStepId:'', score:0 },
      Speaking: { correct: 0, incorrect: 0, total: 0, positionStepId:'', score:0 }
    };

    const questionCollection = data?.positionStep?.questionCollection;

    questionCollection.questionCollectionQuestionRelations.forEach(element => {
      this.updateCategoryStats(categoryStats, element, data.positionStep.id);
    });
  

    if (categoryStats.Grammer.total > 0) {
      this.generateLanguageAnsweredQuestions(categoryStats);
    }
  }

  private updateCategoryStats(categoryStats: any, element: any, positionStepId: string) {
    const categoryKey = Object.keys(EQuestionCategories).find(
      key => EQuestionCategories[key] === element.question.questionCategory
    );
  
    if (categoryKey) {
      const stats = categoryStats[categoryKey];
      stats.total++;
      stats.positionStepId = positionStepId;
  
      if (element.question.isAnswered) {
        if (element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.MultipleChoice) {
          if (element.question.gptScore === 100) {
            stats.correct++;
          } else {
            stats.incorrect++;
          }
        } else if (element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.OpenEnded) {
          if (element.question.gptScore > 0) {
            stats.correct++;
          } else {
            stats.incorrect++;
          }
          stats.score += element.question.gptScore;
        }
        else if (element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.Video ) {
          if (element.question.gptScore > 0) {
            stats.correct++;
          } else {
            stats.incorrect++;
          }
          stats.score += element.question.gptScore;
        }
      }
    }
  }

  private generateLanguageAnsweredQuestions(categoryStats: any) {
    Object.keys(categoryStats).forEach(category => {
      const stats = categoryStats[category];
      const categoryObject = this.questionCategories.find(
        item => item.name === this.translate.translateText(category)
      );
      const categoryId = categoryObject ? categoryObject.id : null;

      let totalScore = 0;
      if(categoryId === EQuestionCategories.Speaking || categoryId === EQuestionCategories.Writing){
        totalScore = stats.score;
      }
      else{
        totalScore= stats.correct * 100
      }
  
      this.languageAnsweredQuestions.push({
        id: categoryId,
        category,
        positionStepId: stats.positionStepId,
        questionCount: stats.total,
        answeredQuestionsCount: stats.correct + stats.incorrect,
        notAnsweredQuestionsCount: stats.total - (stats.correct + stats.incorrect),
        correctAnsweredQuestionsCount: stats.correct,
        incorrectAnsweredQuestionsCount: stats.incorrect,
        averageScores: stats.correct / stats.total,
        totalScores: totalScore
      });
    });
  }

  processCategoryStats() {
    Object.keys(this.categoryStats).forEach(category => {
      const stats = this.categoryStats[category];
      const categoryObject = this.questionCollectionCategories.find(item => item.name === this.translate.translateText(category));
      const categoryId = categoryObject ? categoryObject.id : null;
      this.answeredQuestionsWithId.push({
        id: categoryId,
        category: category,
        questionCount: stats.total,
        positionStepId:stats.positionStepId,
        answeredQuestionsCount: stats.correct + stats.incorrect,
        notAnsweredQuestionsCount: stats.total - (stats.correct + stats.incorrect),
        correctAnsweredQuestionsCount: stats.correct,
        incorrectAnsweredQuestionsCount: stats.incorrect,
        totalScores: stats.correct*5
      });
    });
  }
  
}
