<div class="col-12">

    <h1>Membership Agreement</h1>
    <h2 class="text-gray-800 font-semibold text-base">Definitions</h2>

    <p>In this clarification text;</p>

    <p>
        <span class="font-semibold">Personal Data: </span>

        Concerning all kinds of information of an identifiable or identifiable natural
        person,
    </p>

    <p>
        <span class="font-semibold">
            Personal Data Protection Law (“KVKK”): </span
        >Personal Data Protection Law No. 6698, which was published in the Official Gazette on April 7,
        2016 and became secret,
    </p>

    <p>
        <span class="font-semibold">Data Processor: </span>
        A natural or personal person who produces Personal Data on his or her own behalf,
        based on the authority given by the data controller,
    </p>

    <p>
        <span class="font-semibold">Data Controller: </span>
        Can use the purposes and tools of processing Personal Data,
        and can use the real or personal person responsible for the creation of the data recording system.
    </p>

    <p>
        <span class="font-semibold">Relevant Person: </span>

        Refers to the real person whose personal data is processed.
    </p>

    <p>
        <a
            class="text-xs"
            href="https://www.normdigital.com/"
            target="_blank"
            rel="noreferrer"
        >
            As NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ and VINTER AI RECRUITMENT LTD (“Our Companies”) </a
        >, protecting private lives,
        operating and protecting information security in order to protect fundamental rights and freedoms are among our ethical values and regime.
        Accordingly, our explanations below are presented for your information within the scope of
        fulfilling our duty of clarification arising from Article 10 of the Personal Data Protection Law (“KVKK”).
    </p>

    <h2 class="text-gray-800 font-semibold text-base">
        Data Controller
    </h2>

    <p>
        “Data Controller” in terms of your personal data; It is registered at the Izmir Trade Registry with the registration number 227198,
        can enter Mersis at 0631152363600001, the company headquarters is 'Adatepe Mahallesi Doğuş Caddesi No:207 / AG İç Kapı No:1 Buca'
        NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ.
    </p>

    <h2 class="text-gray-800 font-semibold text-base">
        Parties Whose Data is Collected
    </h2>

    <p>
        We process separately the parties of our custodians who have a business relationship with our
        company within the scope of the Personal Data Protection Law. These;
    </p>

    <ul class="list-disc list-inside">
        <li>Our employees and employee candidates,</li>

        <li>
            Family members and relatives of our employees and employee candidates,
        </li>

        <li>Our customers,</li>

        <li>Our suppliers,</li>

        <li>Our consultants,</li>

        <li>Our business partners,</li>

        <li>Our shareholders,</li>

        <li>Our company members,</li>

        <li>Our company representatives,</li>

        <li>
            Person(s) and employees who separate contractual relations
        </li>

        <li>Person(s) who are the addressees of legal proceedings,</li>

        <li>Survey meetings,</li>

        <li>Our visitors.</li>
    </ul>

    <h2 class="text-gray-800 font-semibold text-base">
        Data Collected and Processed by Our Company from the Parties
    </h2>

    <p>
        We process the following data from the company parties
        within the scope of Articles 5 and 6 of the Personal Data Protection Law. These;
    </p>

    <ul class="list-disc list-inside">
        <li>Family and social life information,</li>

        <li>Education and training information,</li>

        <li>Employment information,</li>

        <li>Information regarding request/complaint management,</li>

        <li>Information regarding legal affairs,</li>

        <li>Information regarding ethical values and legal compliance,</li>

        <li>Financial information,</li>

        <li>Audit information,</li>

        <li>Electronic media usage information,</li>

        <li>
            Information regarding the goods and services provided and supplied,
        </li>

        <li>Business activities information,</li>

        <li>Information regarding trade and other licenses and permits,</li>

        <li>International venue security information,</li>

        <li>
            Visual and business information (photo, camera, audio recording),
        </li>

        <li>Telecommunications noted,</li>

        <li>The use of e-mail and information systems services is registered,</li>

        <li>They logged in,</li>

        <li>Health reports and health information,</li>

        <li>Biometric data,</li>

        <li>Criminal record information..</li>
    </ul>

    <h2 class="text-gray-800 font-semibold text-base">
        Our Company's Data Processing Purposes
    </h2>

    <p>
        Within the scope of Articles 5 and 6 of the Personal Data Protection Law,
        we process data in order to achieve our Company purposes stated below, limited to the relevant purposes. These;
    </p>

    <ul class="list-disc list-inside">
        <li>Carrying out our company's commercial activities,</li>

        <li>
            Ability to carry out business processes related to commercial activities,
        </li>

        <li>
            Management and execution of relationships with business partners and/or suppliers,
        </li>

        <li>Technical management of our company's websites,</li>

        <li>Customer management and tracking of complaints,</li>

        <li>
            Product surveys and follow-up of the questions you send to our Company,
        </li>

        <li>
            Carrying out the necessary work by our business units to benefit you from the products and services offered by our company,
        </li>

        <li>
            Planning and execution of sales, marketing and after-sales processes of products and/or services,
        </li>

        <li>
            Providing information about the contents of products and services,
        </li>

        <li>
            Sending commercial electronic messages with separate approval in accordance with legal regulations,
        </li>

        <li>Conducting competitions, events and other organizations,</li>

        <li>
            Conducting legal and commercial relations with our Company and people who have business relations with our Company and ensuring the security of these relations,
        </li>

        <li>
            Administrative operations regarding communication carried out by our company,
        </li>

        <li>Employee administration and management,</li>

        <li>
            Ensuring physical security and control of company locations,
        </li>

        <li>Planning logistics activities,</li>

        <li>Conducting reputation research processes,</li>

        <li>
            Compliance with ethical values and law and execution of legal affairs and procedures,
        </li>

        <li>Following up contract processes and/or legal requests,</li>

        <li>
            Planning and executing Human Resources and personnel recruitment processes and monitoring and carrying out education and training activities,
        </li>

        <li>
            Planning and/or execution of occupational health and/or safety processes,
        </li>

        <li>
            Ensuring that our Company can benefit from incentives by carrying out research and development activities,
        </li>

        <li>
            Planning and execution of corporate communication and corporate management activities,
        </li>

        <li>Execution of information security management services,</li>

        <li>
            Monitoring and auditing finance and/or accounting affairs and carrying out activities aimed at detecting customers' financial risks,
        </li>

        <li>
            Determining and implementing our company's commercial and business strategies,
        </li>

        <li>Creating and tracking visitor records,</li>

        <li>
            For other purpose or purposes to be notified to the relevant person during the collection of information,
        </li>

        <li>
            To ensure the fulfillment of legal obligations as required or required by the relevant legislation
        </li>
    </ul>

    <h2 class="text-gray-800 font-semibold text-base">
        Transfer of Data Processed by Our Company Within Its Purposes
    </h2>

    <p>
        Within the framework of the personal data processing conditions and purposes specified in Articles 8 and 9 of the Personal Data Protection Law,
        your personal data processed by our Company may be transferred to our subsidiaries, shareholders,
        business partners (only anonymously) within the scope of the purposes detailed above,
        legally authorized public institutions and private persons. It can be shared with other people.
    </p>

    <h2 class="text-gray-800 font-semibold text-base">
        Method and Legal Reason for Processing Data by Our Company
    </h2>

    <p>
        Personal data is collected and processed by our Company in connection with the legitimate purposes clearly stated above,
        in accordance with the law and the rules of honesty, by verbally,
        written and/or electronically,
        by providing verbal, written and/or electronic information to personal data owners in a clear and understandable manner and,
        when necessary, by obtaining their explicit consent.
        It is used, recorded, stored and processed within the framework of the principle of proportionality,
        provided that it is limited. Your personal data is processed automatically from you personally, through our Company's website,
        within the scope of our contractual relationship. With this;
        Audio and visual records of our employees or employee candidates regarding the planning and execution of Human Resources and personnel recruitment processes and the monitoring
        and realization of training and education activities are automatically processed through the mobile application if contacted through our Company's "Vinter"
        mobile application. Your collected personal data is processed by the Company in line with the purpose stated in this disclosure document,
        within the personal data processing conditions specified in Articles 5 and 6 of the KVKK, in order to plan and execute the
        Company's human resources policies and processes; It may be transferred to our suppliers, legally authorized public institutions and legally
        authorized private persons within the scope of the personal data processing conditions and purposes specified in Articles 8 and 9 of the Law.
    </p>

    <p>
        For the purposes mentioned above, interviews are carried out through servers abroad. While the relevant processing and transfer activities are carried out,
        reasonable administrative and technical measures are taken to ensure the security and privacy of your personal data.
    </p>

    <h2 class="text-gray-800 font-semibold text-base">
        Storage Period of Data Processed by Our Company
    </h2>

    <p>
        Your personal data is stored for the retention periods specified in the relevant legal legislation,
        or if no period is specified in the relevant legal legislation, in accordance with our Company's practices and commercial life practices,
        or for the period required by the above-mentioned processing purposes,
        and is subsequently deleted and destroyed in accordance with Article 7 of the Personal Data Protection Law. or is made anonymous.
    </p>

    <h2 class="text-gray-800 font-semibold text-base">
        Security of Your Data Processed by Our Company
    </h2>

    <p>
        In order to prevent your personal data from being exposed to unauthorized access,
        lost or damaged in the environments where they are processed and stored, the technical and
        administrative measures of the Information Security Management System (ISO 27001 Standard
        and good practice booklet 27018) encouraged by our management, the Personal Data Protection
        Management System (Bureau Veritas - Data The requirements of the Protection Technical Standard,
        BS 10012 Data Protection Personal Information Management System Standard) as well as the requirements
        of the Personal Data Security Guide published by the KVKK board are constantly operated and developed within the scope of continuous improvement.
    </p>

    <h2 class="text-gray-800 font-semibold text-base">
        Rights of the Relevant Person whose Data is Processed
    </h2>

    <p>
        In accordance with Article 11 of the Personal Data Protection Law, everyone can contact the data controller and obtain;
    </p>

    <ol class="list-[lower-alpha] list-inside">
        <li>Learning whether personal data is being processed or not,</li>

        <li>Requesting information if personal data has been processed,</li>

        <li>
            Learning the purpose of processing personal data and whether they are used for their intended purpose,
        </li>

        <li>
            Knowing the third parties to whom personal data is transferred at home or abroad,
        </li>

        <li>
            Requesting correction of personal data if it is incomplete or incorrectly processed,
        </li>

        <li>
            Requesting the deletion or destruction of personal data within the framework of the conditions stipulated in Article 7 of the Personal Data Protection Law,
        </li>

        <li>
            Requesting that transactions made in accordance with clauses (d) and (e) be notified to third parties to whom personal data is transferred
        </li>

        <li>
            Objecting to the emergence of a result that is unfavorable to the individual by analyzing the processed data exclusively through automatic systems,
        </li>

        <li>
            In case of damage due to unlawful processing of personal data, they have the right to demand compensation for the damage.
        </li>
    </ol>

    <h2 class="text-gray-800 font-semibold text-base">
        Application Methods Within the Framework of the Rights of the Interested Person
    </h2>

    <p>
        In accordance with the 1st paragraph of Article 13 of the Personal Data Protection Law,
        you can make your request to exercise your above-mentioned rights with the following methods and information,
        in accordance with the "Communiqué on the Procedures and Principles of Application to the Data Controller"
        published on 10 March 2018, No. 30356.
    </p>

    <p>Required information in the application content;</p>

    <ol class="list-decimal list-inside">
        <li>Name and Surname of the applicant</li>

        <li>
            If the applicant is a citizen of the Republic of Turkey, TR ID Number, if not, Passport number and Nationality or ID number, if available.
        </li>

        <li>
            Residence or workplace address of the applicant as basis for notification
        </li>

        <li>
            The applicant's notification e-mail address, telephone or fax number
        </li>

        <li>Subject of the applicant's request</li>

        <li>
            Information and documents regarding the applicant's request
        </li>
    </ol>

    <p class="font-semibold">Application Methods;</p>

    <ol class="list-decimal list-inside">
        <li>
            The applicant can personally fill out the "Application Form" to the addresses of NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ and
            VINTER AI RECRUITMENT LTD and deliver it to the information office with a handwritten report in a sealed envelope and with
            a note of "Information Request in Pursuance of the Personal Data Protection Law" on the envelope.
        </li>

        <li>
            The applicant can send notifications to the addresses of NORM DİJİTAL HİZMETLER TİCARET ANONİM
            ŞİRKETİ and VINTER AI RECRUITMENT LTD through a notary public, but the note
            "Information Request in Pursuance of the Personal Data Protection Law" must be added on the notification envelope.
        </li>

        <li>
            With the "Secure Electronic Signature" defined in the Electronic Signature Law No. 5070,
            the applicant can personally apply to our company's Registered Electronic Mail address

            <a
                class="text-xs text-indigo-600"
                href="mailto:normdigital@hs02.kep.tr"
                target="_blank"
                rel="noreferrer"
            >
                normdigital&#64;hs02.kep.tr </a
            > with the note "Information Request in Pursuance of the Personal Data Protection Law" in the subject line.
        </li>
    </ol>

    <p>
        <span class="font-semibold"> Address: </span>
    </p>

    <p>NORM DİJİTAL HİZMETLER TİCARET ANONİM ŞİRKETİ: Adatepe Mah. Doğuş Cad. No:207 AG İç Kapı No: 1 BUCA/İZMİR</p>
    <p>VINTER AI RECRUITMENT LTD: 74a High Street, Wanstead, London, Greater London, United Kingdom</p>

</div>
