import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthenticationService, ConfigurationService, LocalizationService } from '@app/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { SpinnerHandlerService } from '../loader/spinner-handler.service';
import { languageKey, vinterLogoWithoutTextPath } from '@app/shared/common/constants';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from '@app/shared/services/language.service';
import { Language } from '@app/shared/common/interfaces';
import { LanguageEnum } from '@app/shared/common/enums';

@Component({
  selector: 'app-applicant-welcome',
  templateUrl: './applicant-welcome.component.html',
  styleUrls: ['./applicant-welcome.component.scss'],
  providers: [MessageService]
})
export class ApplicantWelcomeComponent implements OnInit {
  mail: string;
  encryptedInvitationID: string;
  vinterLogoPath: string = vinterLogoWithoutTextPath;
  showMailPendingPage = false;
  languageDS: Language[] = [];
  selectedLanguage: Language = {};

  constructor(private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerHandlerService,
    private languageService: LanguageService,
    private configurationService: ConfigurationService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      (params: ParamMap) => {
        this.encryptedInvitationID = this.route.snapshot.queryParamMap.get('invitationID');
      }
    );

    lastValueFrom(this.languageService.getLanguagesFromApi()).then(data => {
        this.languageDS = data;
        this.selectedLanguage = this.languageDS.find(x => x.id === LanguageEnum.EnglishUnitedStates);
        this.languageOnSelectionChanged(this.selectedLanguage);

        this.localizationService.initTranslate(null);
      })

  }

  languageOnSelectionChanged(e) {
    localStorage.setItem(languageKey, JSON.stringify({ id: e.id, code: e.languageCode }));
    this.localizationService.initTranslate(null);
  }

  checkInvitation() {
    const response$ = this.authenticationService.checkInvitation({
      mail: this.mail,
      encryptedInvitationID: this.encryptedInvitationID,
    });
    lastValueFrom(response$).then(data => {
      if (data.isInvitationExist) {
        if (data.isInvited) {
          if (data.isAccountCreated) {
            if (data.isApplicant) {
              this.goToUrl('login', this.mail, this.encryptedInvitationID);
            }
            else {
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], {
                replaceUrl: true, queryParams: {
                  mail: this.mail,
                  invitationID: this.encryptedInvitationID
                }
              });
            }
          }
          else {
            if (data.isApplicant)
              this.goToUrl('signup', this.mail, this.encryptedInvitationID);
            else {
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/signup'], {
                replaceUrl: true, queryParams: {
                  mail: this.mail,
                  invitationID: this.encryptedInvitationID
                }
              });
            }
          }
        }
        else {
          this.messageService.add({ severity: 'error', summary: this.localizationService.translateText("Error"), detail: this.localizationService.translateText("NotInvited"), life: 2000 });
        }
      }
      else {
        if (data.isAccountCreated) {
          this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], {
            replaceUrl: true, queryParams: {
              mail: this.mail,
              invitationID: this.encryptedInvitationID
            }
          });
        }
        else{
          if(data.isTrialAccount){
            this.showMailPendingPage = true;
          }
          else{
            this.messageService.add({ severity: 'error', summary: this.localizationService.translateText("Error"), detail: this.localizationService.translateText("WrongInvitationID"), life: 2000 });
          }
        }
      }
    }).catch(error => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: this.localizationService.translateText("Error"), detail: this.localizationService.translateText("InvitationMailError"), life: 2000 });
    })
  }

  goToUrl(page: string, mail: string, encryptedInvitationID: string): void {
    const applicantDomain = this.configurationService.config != undefined ? this.configurationService.config.applicantDomain : undefined;
    this.document.location.href = applicantDomain + page + '?mail=' + mail + '&invitationID=' + encryptedInvitationID;
  }
}
