<div *ngIf="(generalAppQuestions === null || generalAppQuestions === undefined || generalAppQuestions?.length === 0)">
    <div *ngIf="isStepLangForPositionActive" class="flex w-min mt-0 question-collection-lang border-round-lg px-2 py-1 bg-white ml-0">
        <span class="flex w-full p-0 align-items-center ">
            <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/positionDetaillanguage.svg" height="25px"
                width="25px" />
            <span class="font-medium text-gray-400 text-sm mr-1">{{"Key.Language" | translate }}</span>
            <span class="text-blue-50 mr-1"> - </span>
            <span class="font-medium text-gray-700 text-sm"> {{positionStep?.langShort}}  </span>
        </span>
    </div>

    <h5  class="font-semibold text-gray-700 mt-5">{{'Key.Questions' | translate}}</h5>
    <div class="overflow-y-auto max-h-50rem">
        <ng-container >
            <div *ngIf="!showQuestions && questionStaticFirst??false">
                <div class="no-blur-question unselectable" [ngClass]="{'question-container': !showQuestions}">
                    <div class="grid ">
                        <div class="question-number col-1 pt-4 mr-0 pr-0 mt-2 font-normal">
                            {{ 1 }}.
                        </div>
                        <div class="col-1 pt-2.5 mt-2 img-position">
                            <img class="custom-svg-question-icon align-self-center"
                                src="/assets/vinter/icons/Quiz.svg" />
                        </div>
                        <div class="question-content flex col unselectable" (click)="toggleDisplay(questionStaticFirst)">
                            <p [ngClass]="{'displayMore': questionStaticFirst.showMoreText,'description2': questionStaticFirst.showMoreText,'description1': !questionStaticFirst.showMoreText}"
                                [innerHTML]="questionStaticFirst.questionContent.questionText"></p>
                            <div class="flex align-items-start  ml-4 cursor-pointer"
                                (click)="toggleDisplay(questionStaticFirst)">
                                <p class="text-blue-400 font-medium mb-0" *ngIf="!questionStaticFirst.showMoreText"></p>
                                <img *ngIf="!questionStaticFirst.showMoreText" class="align-self-start more-icon ml-2"
                                    src="/assets/vinter/icons/more-icon-right.svg"
                                    (click)="toggleDisplay(questionStaticFirst)" />
                                <img *ngIf="questionStaticFirst.showMoreText" class="align-self-start more-icon ml-2"
                                    src="/assets/vinter/icons/more-icon-down.svg"
                                    (click)="toggleDisplay(questionStaticFirst)" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="questionStaticFirst.showMoreText"
                        [ngClass]="{'opacity-60': questionStaticFirst.isDeprecated && !showDeprecatedQuestions}">
                        <div class="flex">
                            <div class="question-number col-1 pt-4 ml-3 pr-2 mt-2 font-normal">
                            </div>
                            <div class="col-1 pt-2.5 mt-2 img-position"></div>
                            <div class=" unselectable">
                                <div *ngIf="questionStaticFirst.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice"
                                    class="answer-container">
                                    <div class="flex">
                                        <div class="flex flex-column gap-2">
                                            <div *ngFor="let choice of sortChoicesByOrder(questionStaticFirst); let i = index"
                                                class="radio-button-card field-checkbox"
                                                [ngClass]="{'correct-choice': choice.isCorrect}">
                                                <div class="flex align-items-center">
                                                    <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                                        [ngModel]="true"></p-radioButton>
                                                    <label [for]="choice.choiceText"
                                                        [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}"
                                                        class="ml-2" [innerHTML]="choice.choiceText"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!showQuestions" class="h-full w-full flex flex-wrap justify-content-center align-item-center">
                    <div class="warning-text-container unselectable mt-7" *ngIf="!showQuestions"
                        [ngStyle]="{'top': showMoreText ? '760px': '600px' }">
                        <div class="col-2 pt-2.5 mt-2 img-position d-flex justify-content-center align-items-center">
                            <img class="warning-custom-svg-question-icon align-self-center"
                                src="/assets/vinter/icons/candidates_only.svg" />
                        </div>
                        <p class="font-semibold flex align-items-center justify-content-center text-800 -mb-1 text-xl">
                            {{'Key.hrWarningTitle' | translate}}</p>
                        <p class="warning-text flex align-items-center justify-content-center line-height-4 text-gray-500">
                            {{'Key.hrWarningText' | translate}}</p>
                    </div>
                    <div class="absolute static-questions mr-7 unselectable">
                        <div *ngFor="let i of [1, 2, 3, 4, 5, 6]"
                            [ngClass]="{'question-container': !showQuestions}">
                            <div class="grid "
                                [ngClass]="{'opacity-60': questionsStaticRemaining.isDeprecated && !showDeprecatedQuestions}">
                                <div class="question-number col-1 pt-4 mr-0 pr-0 mt-2 font-normal">
                                    {{ 1 }}.
                                </div>
                                <div class="col-1 pt-2.5 mt-2 img-position -mr-2"
                                    *ngIf="questionsStaticRemaining.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice">
                                    <img class="custom-svg-question-icon align-self-center"
                                        src="/assets/vinter/icons/Quiz.svg" />
                                </div>
                                <div class="question-content flex col" (click)="toggleDisplay(questionsStaticRemaining)">
                                    <p [ngClass]="{'displayMore': questionsStaticRemaining.showMoreText,'description2': questionsStaticRemaining.showMoreText,'description1': !questionsStaticRemaining.showMoreText}"
                                        [innerHTML]="questionsStaticRemaining.questionContent.questionText"></p>
                                    <div class="flex align-items-start  ml-4 cursor-pointer"
                                        (click)="toggleDisplay(questionsStaticRemaining)">
                                        <p class="text-blue-400  font-medium mb-0" *ngIf="!questionsStaticRemaining.showMoreText"></p>
                                        <img *ngIf="!questionsStaticRemaining.showMoreText" class="align-self-start more-icon ml-2"
                                            src="/assets/vinter/icons/more-icon-right.svg" />
                                        <img *ngIf="questionsStaticRemaining.showMoreText" class="align-self-start more-icon ml-2"
                                            src="/assets/vinter/icons/more-icon-down.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  *ngIf="showQuestions">
                <div *ngFor="let question of questions; let i = index" class="first-question-container">
                <div class="grid " [ngClass]="{'opacity-60': question.isDeprecated && !showDeprecatedQuestions}">
                    <div class="question-number col-1 pt-4 ml-3 pr-2 mt-2 font-normal">
                        {{ i + 1 }}.
                    </div>
                    <div class="col-1 pt-2.5 mt-2 img-position -mr-2 ml-3"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video">
                        <img class="custom-svg-question-icon align-self-center ml-4"
                            src="/assets/vinter/icons/videoQuestion.svg" />
                    </div>
                    <div class="col-1 pt-2.5 mt-2 img-position -mr-2 ml-3"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded">
                        <img class="custom-svg-question-icon align-self-center ml-4"
                            src="/assets/vinter/icons/openEndedQuestion.svg" />
                    </div>
                    <div class="col-1 pt-2.5 mt-2 img-position -mr-2 ml-3"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice">
                        <img class="custom-svg-question-icon align-self-center ml-2" src="/assets/vinter/icons/Quiz.svg" />
                    </div>
                    <div class="col-1 pt-2.5 mt-2 img-position -mr-2 ml-3"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Coding" >
                        <img class="custom-svg-question-icon align-self-center ml-4" src="/assets/vinter/icons/coding.svg" />
                    </div>

                    <div class="pt-3 mt-2 pl-0 img-position  pl-0 -ml-3 english-icons" *ngIf="question.questionCategory === questionCategoryType.Writing">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/writing-icon-circled.svg'" [alt]="'Writing'" [bgColorClass]="'bg-transparent'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="english-icons pt-3 mt-2 pl-0  pl-0 -ml-3 img-position listening-question-style"  *ngIf="question.questionCategory === questionCategoryType.Listening">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/listening-icon-circled.svg'" [alt]="'Listening'" [bgColorClass]="'bg-transparent'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="english-icons pt-3 mt-2 pl-0  pl-0 -ml-3 img-position " *ngIf="question.questionCategory === questionCategoryType.Reading">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/reading-icon-circled.svg'" [alt]="'Reading'" [bgColorClass]="'bg-transparent'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="english-icons pt-3 mt-2 pl-0 pl-0 -ml-3 img-position " *ngIf="question.questionCategory === questionCategoryType.Vocabulary">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/vocabulary-icon-circled.svg'" [alt]="'Vocabulary'" [bgColorClass]="'bg-transparent'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="english-icons pt-3 mt-2 pl-0  pl-0 -ml-3 img-position " *ngIf="question.questionCategory === questionCategoryType.Grammer">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/grammer-icon-circled.svg'" [alt]="'Grammer'" [bgColorClass]="'bg-transparent'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="english-icons pt-3 mt-2 pl-0  pl-0 -ml-3 img-position " *ngIf="question.questionCategory === questionCategoryType.Speaking">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/speaking-icon-circled.svg'" [alt]="'speaking'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                    </div>
                    <div class="question-content col pb-0">
                        <div *ngIf="resultQuestionCollection?.questionCollectionCategory == EQuestionCollectionType.TechnicalTest || resultQuestionCollection?.questionCollectionCategory == EQuestionCollectionType.CompetenceTest || resultQuestionCollection?.questionCollectionCategory == EQuestionCollectionType.MTCompetenceTest"
                        class="flex align-items-center"  [ngClass]="{'no-license-blur-filter': (!isCodingQuestionLicenseValid && question.expectedAnswerType == questionExpectedAnswerTypeEnum.Coding)}">
                            <span *ngIf="question?.langShort ?? false" class="font-medium mr-2">
                                {{question?.langShort}}
                            </span>
                            <div *ngIf="question?.langShort ?? false" class="border-right-1 mr-3 h-1rem border-gray-100"></div>
                            <div *ngIf="positionStep.stepDurationType == stepDurationTypeEnum.QuestionBased" class=" text-blue-400 flex align-items-center">
                                <i class="pi pi-stopwatch static text-xs cursor-auto mr-1"></i>
                                {{question?.duration}}'
                            </div>
                            <div *ngIf="positionStep.stepDurationType == stepDurationTypeEnum.QuestionBased" class="border-right-1 mx-2 h-1rem border-gray-100"></div>
                            <div class=" text-blue-800 flex align-items-center">
                                <img class="mr-1" src="/assets/vinter/icons/question-weight-icon.svg" height="16px" width="16px" />
                                <div>{{question?.weight}}</div>
                            </div>
                        </div>
                        <div *ngIf="(!(resultQuestionCollection ?? false)) && (positionStepCategory === EQuestionCollectionType.TechnicalTest || positionStepCategory === EQuestionCollectionType.CompetenceTest || positionStepCategory === EQuestionCollectionType.MTCompetenceTest)" class="flex align-items-center">
                            <span *ngIf="question?.langShort ?? false" class="font-medium mr-2">
                                {{question?.langShort}}
                            </span>
                            <div *ngIf="question?.langShort ?? false" class="border-right-1 mr-2 h-1rem border-gray-100"></div>
                            <div *ngIf="positionStep.stepDurationType == stepDurationTypeEnum.QuestionBased" class=" text-blue-400 flex align-items-center">
                                <i class="pi pi-stopwatch static text-xs cursor-auto mr-1"></i>
                                {{question?.duration}}'
                            </div> 
                            <div *ngIf="positionStep.stepDurationType == stepDurationTypeEnum.QuestionBased" class="border-right-1 mx-2 h-1rem border-gray-100"></div>
                            <div class=" text-blue-800 flex align-items-center">
                                <img class="mr-1" src="/assets/vinter/icons/question-weight-icon.svg" height="16px" width="16px" />
                                <div>{{question?.weight}}</div>
                            </div>

                        </div>

                        <div class="question-content flex col p-0 mt-0 relative" (click)="toggleDisplay(question)">
                            <div *ngIf="!isCodingQuestionLicenseValid && question.expectedAnswerType == questionExpectedAnswerTypeEnum.Coding" 
                                class="upgrade-button absolute border-round-lg bg-white flex align-items-center h-3rem justify-content-center border-solid border-blue-500 border-1 cursor-pointer">
                                <img src="/assets/vinter/icons/crown.svg" alt="button" class="w-2 h-2rem mr-2">
                                <p class="text-blue-500 font-medium white-space-nowrap cursor-pointer" (click)="openLicensePlanPopup()">{{ 'Key.UpgradePlan' | translate }}</p>
                            </div>
                            <p *ngIf="question.isDeprecated"
                                [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,
                                'description1': !question.showMoreText, 'no-license-blur-filter': (!isCodingQuestionLicenseValid && question.expectedAnswerType == questionExpectedAnswerTypeEnum.Coding)}"
                                [innerHTML]="question.questionContent.questionText"
                                pTooltip="{{'Key.DisabledQuestionFromCollection' | translate}}" tooltipPosition="top"
                                placeholder="Top" class="ml-2">
                            <p *ngIf="!question.isDeprecated"
                                [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,
                                'description1': !question.showMoreText, 'no-license-blur-filter': (!isCodingQuestionLicenseValid && question.expectedAnswerType == questionExpectedAnswerTypeEnum.Coding)}"
                                [innerHTML]="question.questionContent.questionText"
                                class="ml-2">
                            </p>
                            <div *ngIf="isVinterAdminLogged"
                                [ngClass]="{'cursor-pointer': !resultQuestionCollection.isDeprecated,'': resultQuestionCollection.isDeprecated}">
                                <img *ngIf="!question.isDeprecated" class="-mr-5" src="/assets/vinter/icons/eye-icon.svg"
                                    (click)="DeprecateQuestion(question)" />
                                <img *ngIf="question.isDeprecated" class="-mr-5"
                                    src="/assets/vinter/icons/disable-eye-icon.svg"
                                    (click)="UndoDeprecateQuestion(question)" />
                            </div>
                            <div class="flex align-items-start  ml-4 mr-3 cursor-pointer" (click)="toggleDisplay(question)" [ngClass]="{'no-license-blur-filter': (!isCodingQuestionLicenseValid && question.expectedAnswerType == questionExpectedAnswerTypeEnum.Coding)}">
                                <p class="text-blue-400  font-medium mb-0" *ngIf="!question.showMoreText"></p>
                                <img *ngIf="!question.showMoreText" class="align-self-start more-icon ml-2"
                                (click)="toggleDisplay(question)" src="/assets/vinter/icons/more-icon-right.svg" />
                                <img *ngIf="question.showMoreText" class="align-self-start more-icon ml-2"
                                (click)="toggleDisplay(question)" src="/assets/vinter/icons/more-icon-down.svg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="question.showMoreText"
                    [ngClass]="{'opacity-60': question.isDeprecated && !showDeprecatedQuestions, 'no-license-blur-filter': (!isCodingQuestionLicenseValid && question.expectedAnswerType == questionExpectedAnswerTypeEnum.Coding)}">
                    <div class="flex -ml-3 pb-0">
                        <div class="question-number col-1 pt-4 ml-3 pr-2 mt-2 font-normal">
                        </div>
                        <div class="col-1 pt-2.5 mt-2 ml-3 pb-0 img-position">
                            <div class="dump-image"></div>
                        </div>
                        <div class="">
                            <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Image" class="mb-3">
                                <p-image [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"
                                    [imageStyle]="{'width': '14rem','border-radius':'6px'}" [preview]="true"></p-image>
                            </div>
                            <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Video">
                                <video class="mb-3 video-question-style" [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"
                                    controls controlslist="nodownload"
                                    disablepictureinpicture></video>
                            </div>
                            <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Audio">
                                <audio class="mb-3 audio-question-style" [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"  controls
                                    controlslist="nodownload"></audio>
                            </div>
                            <div *ngIf="question.keywords.length > 0 && question.expectedAnswerType !== questionExpectedAnswerTypeEnum.Coding" class="question-container-sideBar-text">
                                <div class="flex mb-3">
                                    <div class="keyword-text font-medium">
                                        {{'Key.Keywords' | translate}}
                                    </div>
                                    <div>
                                        <p-badge class="radius" value={{question.keywords.length}}></p-badge>
                                    </div>
                                </div>
                                <div>
                                    <div class="inline -ml-2" *ngFor="let item of question.keywords">
                                        <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice"
                                class="answer-container">
                                <div class="flex">
                                    <div class="flex flex-column gap-2">
                                        <div *ngFor="let choice of sortChoicesByOrder(question); let i = index"
                                            class="radio-button-card field-checkbox"
                                            [ngClass]="{'correct-choice': choice.isCorrect}">
                                            <div class="flex align-items-center">
                                                <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                                    [ngModel]="true"></p-radioButton>
                                                <label [for]="choice.choiceText"
                                                    [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}"
                                                    class="ml-2" [innerHTML]="choice.choiceText"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video"
                                class="video-answer-container">
                                <p class="text-gray-700 font-medium">{{'Key.VideoQuestionExplanation' | translate}}</p>
                            </div>

                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded"
                                class="video-answer-container">
                                <p class="text-gray-700 font-medium">{{'Key.OpenEndedQuestionExplanation' | translate}}</p>
                            </div>
                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Coding" >
                                <div class="video-answer-container">
                                    <p class="text-gray-700 font-medium">{{'Key.CodingQuestionExplanation' | translate}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </ng-container>
    </div>
</div>
<div *ngIf="generalAppQuestions?.length > 0">
    <h5 class="text-gray-700 font-semibold mt-5">{{'Key.Questions' | translate}}</h5>
    <div class="overflow-y-auto max-h-50rem">
        <ng-container>
            <div *ngFor="let question of generalAppQuestions; let i = index" class="no-blur-conteiner">
                <div class="grid">
                    <div class="question-number col-1 pt-5 mr-0 pr-0 mt-2 font-normal">
                        {{ i + 1 }}.
                    </div>
                    <div class="col-1 pt-4 mt-2 img-position"
                        *ngIf="question.type === questionExpectedAnswerTypeEnum.OpenEnded">
                        <img class="custom-svg-question-icon align-self-center"
                            src="/assets/vinter/icons/openEndedQuestion.svg" />
                    </div>
                    <div class="col-1 pt-4 mt-2 img-position"
                        *ngIf="question.type === questionExpectedAnswerTypeEnum.MultipleChoice">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg" />
                    </div>
                    <div class="question-content flex col">
                        <p [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,'description1': !question.showMoreText}"
                            [innerHTML]="question.questionText">
                        </p>
                        <div *ngIf="question.isMandatory" class="error-message flex">*</div>
                        <div class="flex align-items-start  ml-4 cursor-pointer" (click)="toggleDisplay(question)">
                            <p class="text-blue-400 font-medium mb-0" *ngIf="!question.showMoreText"></p>
                            <img *ngIf="!question.showMoreText" class="align-self-start more-icon ml-2"
                            (click)="toggleDisplay(question)" src="/assets/vinter/icons/more-icon-right.svg" />
                            <img *ngIf="question.showMoreText" class="align-self-start more-icon ml-2"
                            (click)="toggleDisplay(question)"  src="/assets/vinter/icons/more-icon-down.svg" />
                        </div>
                    </div>
                </div>
                <div *ngIf="question.showMoreText">
                    <div class="question-number col-1 pt-4 ml-3 pr-2 mt-2 font-normal">
                    </div>
                    <div class="col-1 pt-2.5 mt-2 img-position">

                    </div>
                    <div class="">
                        <div *ngIf="question.type === questionExpectedAnswerTypeEnum.MultipleChoice"
                            class="answer-container">
                            <div class="flex">
                                <div class="flex flex-column gap-2">
                                    <div *ngFor="let choice of sortChoicesOfGeneralAppQuestionByOrder(question); let i = index"
                                        class="radio-button-card field-checkbox"
                                        [ngClass]="{'correct-choice': choice.isCorrect}">
                                        <div class="flex align-items-center">
                                            <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                                [ngModel]="true"></p-radioButton>
                                            <label [for]="choice.choiceText"
                                                [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}"
                                                class="ml-2">{{ choice.choiceText }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="questions?.length === 0" class="overflow-y-auto max-h-30rem">
    <ng-container>
        <div class="custom-step">
            <div class="custom-step-content">
                {{positionStepDescription}}
            </div>
        </div>
    </ng-container>
</div>

<p-dialog 
    class="license-plan-popup" 
    [blockScroll]="true" 
    showEffect="fade" 
    [draggable]="false" 
    [resizable]="false" 
    [(visible)]="isShowLicensePlanPopup" 
    [modal]="false"
    [style]="{width: '38rem', height: '75rem'}" 
    [contentStyle]="{overflow: 'auto'}">
    <app-license-plan-dialog [suggestedPackageType]="suggestedPackageType"></app-license-plan-dialog>
</p-dialog>