import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LocalizationService } from '@app/core';
import { EApplicantBehaviourEventType, EFaceDetectionStatus, EntityStateEnum, GPTEvalStatus, ProcessStatus, WringErrorColorEnum } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';
import { ForeignLanguageOpenEndedAnswerItem } from '@app/vinter/models/answer/foreign-language-open-ended-asnwer-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';

@Component({
  selector: 'app-foreign-language-open-ended-answer',
  templateUrl: './foreign-language-open-ended-answer.component.html',
  styleUrls: ['./foreign-language-open-ended-answer.component.scss']
})
export class ForeignLanguageOpenEndedAnswerComponent implements OnInit {
  @Input() answer: ForeignLanguageOpenEndedAnswerItem;
  gptEvalStatusEnum = GPTEvalStatus;
  writingAnalysisMap = [];
  jsonData : any;
  isWritingAnalysisEmpty = true;
  writingSubtitles: any[] = [];
  filterErrorArray: any[] = [];
  erroredDataText: any[] = [];
  isErrorNotFound = false;
  errorPopupData: any;
  processStatusEnum = ProcessStatus;
  questionPageHiddenLog: number;
  EFaceDetectionStatus = EFaceDetectionStatus;

  constructor(private appInfoService : AppInfoService,
    private localizationService: LocalizationService,
    private answerContentService: AnswerContentService,
    private eventLogService: ApplicantBehaviourEventLogService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.answer){
        if((this.answer?.writingAnalysis?.analysisResultDetail ?? false)){
            this.jsonData = JSON.parse(this.answer?.writingAnalysis?.analysisResultDetail);
        }
        this.setWritingSubtitles();
        this.getErrorSwitchButtonArray();
        this.getErroredAnswerText();
        this.getPageHiddenLog();
      }
  }

  ngOnInit(): void {
  if(this.answer){
    if((this.answer?.writingAnalysis?.analysisResultDetail ?? false)){
        this.jsonData = JSON.parse(this.answer?.writingAnalysis?.analysisResultDetail);
    }
    this.setWritingSubtitles();
    this.getErrorSwitchButtonArray();
    this.getErroredAnswerText();
    this.getPageHiddenLog();
  }
  }

setWritingSubtitles(){
  this.writingAnalysisMap = this.appInfoService.getWritingAnalysisMap();
  let writingSubtitles = [];
  if(this.jsonData ?? false){
      this.writingAnalysisMap.forEach(element => {
          var obj = {
              score: this.jsonData?.gpt_assessment.parts_of_eval_text[element.id]?.score,
              evalText:this.jsonData?.gpt_assessment?.parts_of_eval_text[element.id]?.eval_text,
              title: element?.name+" Score",
              info:this.localizationService.translateText(element.id+"Info")
          }
          writingSubtitles.push(obj);
      });
      this.isWritingAnalysisEmpty = false;
  }else{
      this.writingAnalysisMap.forEach(element => {
          var obj = {
              score: 0,
              evalText:"Empty Data",
              title: element?.name+" Score"
          }
          writingSubtitles.push(obj);
      });
      this.isWritingAnalysisEmpty = true;
  }
  this.writingSubtitles = writingSubtitles;
}

getErroredAnswerText(){
  let emptyString = [];
  let errorList= [];
  var errorListTemp = [];
  var answerText=this.answer?.answerContent ?? false ? this.answer?.answerContent?.answerText:"";
  if(this.jsonData ?? false){
      answerText = this.jsonData.error_detail.answer_text ?? false ? this.jsonData.error_detail.answer_text: JSON.parse(JSON.stringify(this.answer?.answerContent?.answerText));

      errorList = this.jsonData.error_detail.subtitle.matches;
      errorList=errorList.filter(x=> this.filterErrorArray.some(y=> y.category === x.category && y.isSelected));
      if(errorList.length > 0){
          let lastErrorFinishIndex = 0;
          errorList = errorList.sort((a, b) => a.error_start_index - b.error_start_index);
          errorList.forEach(element => {
              errorListTemp.push({...element, isAdded: false, errorData: []});
          });
          for (let index = 0; index < errorListTemp.length; index++) {
              if(!errorListTemp[index].isAdded){
                  if( lastErrorFinishIndex < errorListTemp[index].error_start_index){
                      emptyString.push(
                          {word:answerText.substring(lastErrorFinishIndex,errorListTemp[index].error_start_index)}
                      );
                  }
                  let resolvedError = this.getErrorWithConflictingErrors(errorListTemp[index],errorListTemp);
                  emptyString.push({word:answerText.substring(errorListTemp[index].error_start_index, errorListTemp[index].error_finish_index)
                      ,class:WringErrorColorEnum[errorListTemp[index].category], isError:true, error: resolvedError})

                  lastErrorFinishIndex = errorListTemp[index].error_finish_index;
              }
          }
          emptyString.push(
              {word:answerText.substring(errorListTemp[errorList.length - 1].error_finish_index)}
          );
          this.erroredDataText=emptyString;

      }else{
          this.erroredDataText=[{word: answerText}];
      }
  }else{
      this.erroredDataText=[{word: answerText}];
  }
}

getErrorSwitchButtonArray(){
  let errorList =[];
  let switchArray=[];
  this.isErrorNotFound=false;
  if(this.jsonData ?? false){
      errorList = this.jsonData.error_detail.subtitle.matches;

      if(errorList.length > 0){
          const unique = [...new Set(errorList.map(item => item.category))];
          unique.forEach(x=>{
              switchArray.push({isSelected: true, label: x, color: WringErrorColorEnum[x], category: x, number: errorList.filter(y=> y.category === x).length})
          })
      }else{
          const errorList = this.appInfoService.getWritingErrorListMap();
          errorList.slice(0, 5).forEach(x=>{
              switchArray.push({isSelected: false, label: x.name, color: x.id, category: x.name, number: 0})
          })
          this.isErrorNotFound=true;
      }
  }
  this.filterErrorArray=switchArray;
}

errorPopup(word,op,event){
  if(word.isError){
      this.errorPopupData = word.error.map(x=> { return {
          errorCategory: x.category,
          wrongText:x.error,
          description:x.message,
          ruleID:x.ruleId
      }});
      op.toggle(event);
  }
}

getErrorWithConflictingErrors(error, errorList){
  let resolvedError= [];
  errorList.forEach(otherError => {
      //other error inside the this error
      if(otherError.error_start_index >= error.error_start_index && otherError.error_finish_index <= error.error_finish_index
          && otherError.error_start_index <= error.error_finish_index){
          otherError.isAdded = true;
          resolvedError.push(otherError)
      }else if(otherError.error_start_index >= error.error_start_index &&  otherError.error_finish_index > error.error_finish_index
          && otherError.error_start_index <= error.error_finish_index){
          otherError.isAdded = false;
          otherError.error_start_index = error.error_finish_index;
          resolvedError.push(otherError)
      }
  });
  return resolvedError;
}

setGptStatus(){
  let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
  answerContentForm = this.answer?.answerContent;
  answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
  answerContentForm.processStatus = this.processStatusEnum.AIContainerWaiting;
  answerContentForm.entityState = EntityStateEnum.Modified;
  answerContentForm.xmin = this.answer?.answerContent?.xmin;

  this.answerContentService.SaveAsync(answerContentForm).subscribe();
}

getPageHiddenLog(){
    let eventLogFilter: any = {};
    eventLogFilter.applicationStepId = this.answer.applicationStepId;
    eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
    eventLogFilter.questionId = this.answer.questionId;
    this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
        this.questionPageHiddenLog = eventLog.length;
    });
}

}
