<!-- Skeleton loader for profile -->
<div *ngIf="loading">
    <p-card styleClass="surface-ground border-1 shadow-none border-gray-50" class="px-2">
        <div class="flex">
            <p-skeleton shape="circle" size="5rem" styleClass="mr-3"></p-skeleton>
            <div class="flex-1">
                <p-skeleton height="2rem" width="60%" styleClass="mb-2"></p-skeleton>
                <p-skeleton height="1.5rem" width="40%" styleClass="mb-2"></p-skeleton>
                <p-skeleton height="1.5rem" width="30%"></p-skeleton>
            </div>
            <div class="flex-none" style="width: 15%;">
                <p-skeleton height="3rem" width="100%"></p-skeleton>
            </div>
        </div>
        <div class="mt-2 pt-3 border-top-1 border-gray-50">
            <div class="flex">
                <div class="flex-grow-1">
                    <p-skeleton height="1.5rem" width="30%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton height="1.2rem" width="80%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton height="1.2rem" width="60%"></p-skeleton>
                </div>
                <div class="flex-grow-1">
                    <p-skeleton height="1.5rem" width="30%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton height="1.2rem" width="70%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton height="1.2rem" width="50%"></p-skeleton>
                </div>
            </div>
        </div>
    </p-card>
</div>

<!-- Actual profile content -->
<p-card *ngIf="!loading" styleClass="surface-ground border-1 shadow-none border-gray-50 " class="profile-card">
    <div class="flex flex-wrap">
        <div class="w-full flex">
            <div class="col-1 w-min p-0 ml-3 my-3">
                <p-avatar *ngIf="!(applicant?.profileImageURL ?? false)" class="responsibleAvatar-blue" shape="circle"
                    styleClass="w-6rem h-6rem border-4 border-blue-200 ">

                    <span class="text-5xl text-blue-900 font-semibold">{{(applicant?.firstName &&
                        applicant?.firstName[0]?.toUpperCase() || '') +
                        (applicant?.lastName && applicant?.lastName[0]?.toUpperCase() || '')}}</span>
                </p-avatar>
                <p-avatar *ngIf="applicant?.profileImageURL ?? false" class="responsibleAvatar-blue ml-2"
                    [image]="applicant?.profileImageURL" shape="circle"
                    styleClass="w-6rem h-6rem border-4 border-blue-200">
                </p-avatar>
            </div>
            <div class="col p-0 ml-3 my-3">
                <div class="w-full pt-3">
                    <span class="avatar-name font-bold">{{(applicant?.firstName || '') + " " + (applicant?.lastName ||
                        '')}}</span>
                </div>
                <div class="flex flex-wrap pt-2">
                    <div class="flex align-content-center">
                        <img class="flex align-items-center justify-content-center mr-2" [src]="timeIcon" width="18px"
                            height="18px">
                        <p class="text-sm text-gray-500 flex align-items-center justify-content-center">
                            {{ applicant?.createdAt | date:'dd.MM.yy' }}</p>
                    </div>

                    <div class="border-left-1 border-gray-300 mx-2"></div>

                    <div class="custom-date-display flex align-content-center">
                        <img class="flex align-items-center justify-content-center mr-2" [src]="eyeIconOutline"
                            width="18px" height="18px">
                        <p class="text-sm text-gray-500 flex align-items-center justify-content-center">
                            {{"Key.LastActivityDay" | translate: { value: applicant?.daysDiff } }}</p>
                    </div>
                </div>
            </div>
            <div class="col-4 flex p-0 mx-3 my-5 justify-content-end">
                <app-vinter-score-card *ngIf="applicant?.totalScore !== null && applicant?.totalScore !== undefined"
                    [score]="applicant?.totalScore" [labelKey]="'TotalVinterScore'" [showTooltip]="true"
                    class="justify-content-center flex fit-content"></app-vinter-score-card>
            </div>
        </div>
        <div class="w-full flex border-top-1 border-gray-50">
            <div class="col-4 pb-0 border-right-1 border-gray-50 pb-3">
                <span class="text-gray-900 font-medium"> {{'Key.ContactInfo' | translate}}</span>
                <div class="w-full flex">
                    <img src="assets/vinter/icons/applicant-detail-mail-filled-black.svg" style="width: 1.2rem;">
                    <div class="text-justify text-sm  m-2  overflow-x-hidden text-overflow-ellipsis text-gray-500">
                        {{applicant?.email}}
                    </div>
                    <img *ngIf="!isEmailCopied" class="clickboard-icon cursor-pointer"
                        src="assets/vinter/icons/pozisyon-detay-fluent-copy-24-regular.svg"
                        pTooltip='{{"Key.Copy" | translate}}' tooltipPosition="top" alt="Copy Email"
                        (click)="copyText(applicant?.email)" />
                    <img *ngIf="isEmailCopied" class="clickboard-icon cursor-pointer"
                        src="assets/vinter/icons/pozisyon-detay-fluent-copy-24-regular.svg"
                        pTooltip='{{"Key.Copied" | translate}}' tooltipPosition="top" alt="Copy Email"
                        (click)="copyText(applicant?.email)" />
                </div>
                <div class="w-full flex">
                    <img src="assets/vinter/icons/applicant-detail-phone-filled-black.svg">
                    <div class="text-justify text-sm text-gray-500 ml-3 m-auto">
                        {{applicant?.phone}}
                    </div>
                </div>
            </div>
            <div *ngIf="applicant?.socialPlatformListForProfile?.length"
                class="col-4 pb-0 border-right-1 border-gray-50 pb-3">
                <span class="text-gray-900 font-medium"> {{'Key.SocialInfo' | translate}}</span>
                <ng-container *ngFor="let accountLink of applicant?.socialPlatformListForProfile; let i = index">
                    <div class="flex mb-1 mt-2 gap-2">
                        <span *ngIf="i <= 1" class="flex gap-2">
                            <span class="text-justify  text-sm  mb-1 h5-font">
                                <a [href]="accountLink.fullUrl" target="_blank"
                                    class="no-style flex align-items-center text-gray-400">
                                    <img style="width: 16px;margin-right: 8px;" [src]="accountLink?.svgIconPath">
                                    {{accountLink.url}}
                                </a>
                            </span>
                            <span (click)="openSocialPlatformDialog()"
                                class="text-blue-500 text-center flex align-items-center mb-1 text-sm cursor-pointer"
                                *ngIf="applicant?.socialPlatformListForProfile.length > 2 && i === 1">
                                +{{applicant?.socialPlatformListForProfile.length - 2}}</span>
                        </span>
                    </div>
                </ng-container>
            </div>
            <div class="p-0 col-12">
                <p class="p-0 m-0 flex justify-content-end pt-2 stage-padding"
                    [ngClass]="(applicant?.socialPlatformListForProfile ?? false) ? 'col-8':'col-4'">
                    {{'Key.CurrentStep' | translate}}
                </p>

                <div class="pb-3 flex justify-content-end pt-2"
                    [ngClass]="(applicant?.socialPlatformListForProfile ?? false) ? 'col-8':'col-4'">

                    <p-dropdown *ngIf="applicant ?? false" [disabled]="applicant?.availableStepList?.length <= 1"
                        [options]="applicant.availableStepList" [(ngModel)]="currentStepIdTemp" optionValue="stepId"
                        (onChange)="applicantStepChanged($event)" [style]="{'width':'24rem'}">
                        <ng-template let-option pTemplate="item">
                            <i class="pi pi-circle-fill"
                                [ngStyle]="{'color': getStepColor(option.stepId), 'font-size': '0.8rem'}"></i>
                            {{option.title}}
                        </ng-template>
                        <ng-template let-option pTemplate="selectedItem">
                            <i class="pi pi-circle-fill"
                                [ngStyle]="{'color': getStepColor(option.stepId), 'font-size': '0.8rem'}"></i>
                            {{option.title}}
                        </ng-template>
                    </p-dropdown>
                </div>

            </div>


        </div>
    </div>
</p-card>