import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as ace from "ace-builds";

@Component({
  selector: 'app-background-mask',
  templateUrl: './background-mask-component.html',
  styleUrls: ['./background-mask-component.scss']
})
export class BackgroundMaskComponent {

    @Input() maskActive: boolean =false;



    
}
