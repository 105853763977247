import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CredentialsService, LocalizationService } from '@app/core';
import { AddQuestionShownType, EQuestionCategories, EQuestionContentURLType, EStepDurationType, EntityStateEnum, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType, QuestionListRootEnum } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AudioUploaderSimpleComponent } from '@app/vinter/components/uploader/audio-uploader-simple/audio-uploader-simple.component';
import { PictureUploaderSimpleComponent } from '@app/vinter/components/uploader/picture-uploader-simple/picture-uploader-simple.component';
import { VideoUploaderSimpleComponent } from '@app/vinter/components/uploader/video-uploader-simple/video-uploader-simple.component';
import { ChoiceContentListItem } from '@app/vinter/models/choice-content-list-item';
import { KeywordListItem } from '@app/vinter/models/keyword-list-item';
import { QuestionConceptListItem } from '@app/vinter/models/question-concept-list-item';
import { QuestionConceptPostItem } from '@app/vinter/models/question-concept-post-item';
import { QuestionContentListItem } from '@app/vinter/models/question-content-list-item';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { ChoiceContentCustomItem } from '@app/vinter/models/shared/choice-content-custom-item';
import { QuestionConceptService } from '@app/vinter/services/question-concept.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-your-question',
  templateUrl: './add-your-question.component.html',
  styleUrls: ['./add-your-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService, MessageService]
})
export class AddYourQuestionComponent {
    answerTypes: any[] = [];
    selectedAnswerType: any;
    keywords: string[] = [];
    isKeywordError = false;
    questionText: string;
    choiceContent: ChoiceContentCustomItem = new ChoiceContentCustomItem();
    choiceContentArray: ChoiceContentListItem[] = [];
    keywordList: KeywordListItem[] = [];
    checkCorrectAnswer: boolean;
    checkValid: boolean = false;
    OpenEndedAnswerType: number;
    VideoAnswerType: number;
    MultipleChoiceAnswerType: number;
    uploadedPictureUrl: string;
    openUploadPicture: boolean;
    questionContentInfo = new QuestionContentListItem();
    VideoQuestionContentType: number;
    AudioQuestionContentType: number;
    ImageQuestionContentType: number;
    selectedQuestionContentType: number = 0;
    oldRadioButtonEventValue: number = 0;
    questionInputLength: number = 0;
    selectedLanguageQuestion: any;
    selectedLanguageName = '';
    questionContentTypes: any[] = [];
    questionLanguageList: any[]=[];
    formats:any[];
    @Input() disabled: boolean = false;
    @Input() questionToDisplayArray;
    foreignLanguageCategory: string;
    questionCategories: any[] = [];
    @Output() goBackOneStep: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveYourQuestions: EventEmitter<QuestionListItem[]> = new EventEmitter<QuestionListItem[]>();
    @Input() yourQuestionListTitle: string;
    @Input() positionLanguageId: string;
    @Input() stepDurationType: any;
    @Input() questionCollectionCategory: string;
    @Input() questionListMode: any;
    @Input() questionToEdit: QuestionListItem;
    @ViewChild(VideoUploaderSimpleComponent) childVideo:VideoUploaderSimpleComponent;
    @ViewChild(PictureUploaderSimpleComponent) childPicture:PictureUploaderSimpleComponent;
    @ViewChild(AudioUploaderSimpleComponent) childAudio:AudioUploaderSimpleComponent;
    selectedCategory: string;
    @ViewChild('bottomDiv') bottomDiv: ElementRef;
    minLenght :number=5;
    maxLenght :number=750;
    questionConceptList: QuestionConceptListItem[];
    selectedConcept: any;
    newConceptName: any;
    questionDuration: number;
    questionWeight: number = 3;
    EQuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
    stepDurationTypeEnum = EStepDurationType;
    orgID: string;
    QuestionListRootEnum = QuestionListRootEnum;
    isMediaChanged: boolean = false;
    tempMediaInfo: any;
    readonly MAX_CHOICE_COUNT: number = 5;

    constructor(
        private appInfoService: AppInfoService,
        private localizationService: LocalizationService,
        public messageService: MessageService,
        private questionConceptService: QuestionConceptService,
        private credentialsService: CredentialsService,
        private cdr: ChangeDetectorRef
        ) { }

    ngOnInit(): void {
        this.orgID = this.credentialsService.getMainOrganizationID();
        this.questionLanguageList = this.appInfoService.getLanguages();
        if (this.positionLanguageId != null) {    
            this.selectedLanguageName = this.questionLanguageList.find(x =>x.id === this.positionLanguageId)?.name;
        }
        this.selectedLanguageQuestion = this.positionLanguageId;
        this.VideoAnswerType = QuestionExpectedAnswerType.Video;
        this.MultipleChoiceAnswerType = QuestionExpectedAnswerType.MultipleChoice;
        this.OpenEndedAnswerType = QuestionExpectedAnswerType.OpenEnded;
        this.VideoQuestionContentType = EQuestionContentURLType.Video;
        this.AudioQuestionContentType = EQuestionContentURLType.Audio;
        this.ImageQuestionContentType = EQuestionContentURLType.Image;
        this.foreignLanguageCategory = QuestionCollectionCategoryEnum.ForeignLanguage;
        let answerType = this.appInfoService.getExpectedAnswerTypesArray().filter(x => x.id != QuestionExpectedAnswerType.Coding);
        this.answerTypes = answerType.filter(item => item.id !== QuestionExpectedAnswerType.Coding);
        this.questionContentTypes = this.appInfoService.getQuestionTypesArray().filter(x=> x.id !== EQuestionContentURLType.Text);
        this.questionCategories = this.appInfoService.getQuestionCategories();
        this.formats=[
            //'background',
            'bold',
            //'color',
            //'font',
            //'code',
            'italic',
            //'link',
            //'size',
            //'strike',
            //'script',
            'underline',
            //'blockquote',
            //'header',
            //'indent',
            'list',
            //'align',
            //'direction',
            //'code-block',
            //'formula'
            // 'image'
            // 'video'
          ];

        // Kategori bazlı işlemler
        if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest || 
            this.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest) {
                        
            // Düzenleme modunda mı?
            if (this.isEditMode) {                
                // Kavram listesini yükle ve sonra loadQuestionForEdit metodunu çağır
                this.getQuestionConceptList(() => {
                    this.loadQuestionForEdit();
                });
            } else {
                // Yeni soru ekleme modu
                this.getQuestionConceptList();
            }
        } else if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest) {            
            // Teknik test kategorisi için kavram listesi yüklemeye gerek yok
            if (this.isEditMode) {
                this.loadQuestionForEdit();
            }
        } else {            
            // Diğer kategoriler için
            if (this.isEditMode) {
                this.loadQuestionForEdit();
            }
        }
    }

    // Düzenlenecek soruyu yükle
    loadQuestionForEdit(): void {
        if (!this.questionToEdit) return;
        // Soru tipini ayarla
        const expectedAnswerType = this.questionToEdit.expectedAnswerType;
        
        // answerTypes dizisinden ilgili nesneyi bul
        const answerType = this.answerTypes.find(a => a.id === expectedAnswerType);

        if (answerType) {
            this.selectedAnswerType = answerType.id;
        } else {
            this.selectedAnswerType = this.MultipleChoiceAnswerType;
        }
        
        // Soru metnini ayarla
        this.questionText = this.questionToEdit.questionContent?.questionText || '';
        
        // Anahtar kelimeleri ayarla
        this.keywords = this.questionToEdit.keywords?.map(k => k.name) || [];
        
        // Çoktan seçmeli sorular için seçenekleri ayarla
        if (this.selectedAnswerType === this.MultipleChoiceAnswerType && this.questionToEdit.choiceContents) {
            this.choiceContentArray = [...this.questionToEdit.choiceContents];
            // Doğru cevap var mı kontrol et
            this.checkIsThereCorrectAnswer();
            
            // Soru içerik tipini ayarla - sadece Multiple Choice için medya içeriği olabilir
            if (this.questionToEdit.questionContent?.contentURLType) {
                this.selectedQuestionContentType = this.questionToEdit.questionContent.contentURLType;
                
                // Medya URL'sini ayarla - contentURL veya contentURLSigned kullan
                if (this.questionToEdit.questionContent.contentURLSigned) {
                    this.uploadedPictureUrl = this.questionToEdit.questionContent.contentURLSigned;
                } else if (this.questionToEdit.questionContent.contentURL) {
                    this.uploadedPictureUrl = this.questionToEdit.questionContent.contentURL;
                }
                
                // QuestionContentInfo'yu da güncelle
                this.questionContentInfo.contentURLType = this.questionToEdit.questionContent.contentURLType;
                this.questionContentInfo.contentURL = this.questionToEdit.questionContent.contentURL;
                this.questionContentInfo.contentURLSigned = this.questionToEdit.questionContent.contentURLSigned;
                this.questionContentInfo.questionText = this.questionToEdit.questionContent.questionText;
                
                // Medya değiştirilmedi olarak işaretle
                this.isMediaChanged = false;
                
                // Geçici medya bilgilerini temizle
                this.tempMediaInfo = null;
                
                // Değişikliklerin UI'a yansıması için
                this.cdr.detectChanges();
            }
        } else {
            // Multiple Choice olmayan soru tipleri için medya içeriğini temizle
            this.selectedQuestionContentType = 0;
            this.uploadedPictureUrl = '';
            this.questionContentInfo.contentURL = '';
            this.questionContentInfo.contentURLSigned = '';
            this.questionContentInfo.contentURLType = 0;
            this.isMediaChanged = false;
            this.tempMediaInfo = null;
        }
        
        // Dil seçimini ayarla
        if (this.questionToEdit.languageID) {
            this.selectedLanguageQuestion = this.questionToEdit.languageID;
        }
        
        // Kategori seçimini ayarla
        if (this.questionToEdit.questionCategory) {
            this.selectedCategory = this.questionToEdit.questionCategory;
        }
        
        // Kavram seçimini ayarla - Kategori bazlı
        if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest) {
            
            // Teknik Test kategorisi için newConceptName kullan
            if (this.questionToEdit.questionConcept) {
                this.newConceptName = this.questionToEdit.questionConcept.name;
            } else if (this.questionToEdit.questionConceptID) {
                this.newConceptName = this.questionToEdit.questionConceptID;
            } else {
                this.newConceptName = '';
            }
        } else if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest || 
                   this.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest) {
            
            // Competence veya MT Test kategorisi için selectedConcept kullan
            if (this.questionToEdit.questionConcept) {
                this.selectedConcept = this.questionToEdit.questionConcept;
            } else if (this.questionToEdit.questionConceptID) {
                
                // Kavram listesini kontrol et
                if (this.questionConceptList && this.questionConceptList.length > 0) {
                    this.selectedConcept = this.questionConceptList.find(c => c.id === this.questionToEdit.questionConceptID);
                } else {
                }
            } else {
            }
        } else {
        }
        
        // Süre ve ağırlık ayarla
        this.questionDuration = this.questionToEdit.duration;
        this.questionWeight = this.questionToEdit.weight;
        
        // Soru uzunluğunu güncelle
        this.questionInputLength = this.questionText ? this.questionText.length : 0;
                
        // Değişiklikleri UI'a yansıt
        this.cdr.detectChanges();
    }

    onQuestionLanguageChange() {
        
        this.questionLanguageList = this.appInfoService.getLanguages();
        this.selectedLanguageName = this.selectedLanguageQuestion;
        this.selectedLanguageName = this.questionLanguageList.find(x => x.id === this.selectedLanguageQuestion)?.name;
        
        // Düzenleme modunda mı?
        if (this.isEditMode) {
            
            // Mevcut kavram bilgisini sakla
            const currentConcept = this.selectedConcept;
            const currentConceptID = currentConcept?.id;
            
            // Kavram listesini güncelle
            this.getQuestionConceptList(() => {
                // Eğer daha önce bir kavram seçilmişse ve kavram listesi doluysa
                if (currentConceptID && this.questionConceptList && this.questionConceptList.length > 0) {
                    // Yeni dildeki kavram listesinde aynı ID'ye sahip kavramı bul
                    const newConcept = this.questionConceptList.find(c => c.id === currentConceptID);
                    
                    if (newConcept) {
                        this.selectedConcept = newConcept;
                    } else {
                        this.selectedConcept = null;
                    }
                } else {
                    this.selectedConcept = null;
                }
            });
        } else {
            // Yeni soru ekleme modunda
            this.getQuestionConceptList();
            this.selectedConcept = null;
        }
        
        // Değişiklikleri UI'a yansıt
        this.cdr.detectChanges();
    }

    // Medya tipini değiştir
    changeClickableData(event){
        // Eğer aynı medya tipine tıklandıysa, seçimi kaldır
        if(event.value === this.oldRadioButtonEventValue){
            this.selectedQuestionContentType = 0;
            this.oldRadioButtonEventValue = 0;
            
            // Medya içeriğini temizle
            this.uploadedPictureUrl = '';
            this.questionContentInfo.contentURL = '';
            this.questionContentInfo.contentURLSigned = '';
            this.isMediaChanged = true;
        } else {
            // Medya tipi değiştiğinde, isMediaChanged'i true yap
            this.isMediaChanged = true;
            
            // Medya tipi değiştiğinde, mevcut medya içeriğini temizle
            // Böylece kullanıcı yeni medya yüklemediğinde, eski medya kullanılmaz
            this.uploadedPictureUrl = '';
            this.questionContentInfo.contentURL = '';
            this.questionContentInfo.contentURLSigned = '';
            
            // Geçici medya bilgilerini temizle
            this.tempMediaInfo = null;
            
            this.oldRadioButtonEventValue = event.value;
        }
        
        // Değişikliğin UI'a yansıması için
        this.cdr.detectChanges();
      }

    openUpload(){
        this.openUploadPicture = true;
        }

        closeDialog(){
        this.openUploadPicture = false;
        }

        getUploadedUrl(event: any){
        if (!event || !event.url) return;
        
        // Medya URL'sini ve bilgilerini güncelle
        this.uploadedPictureUrl = event.url;
        this.questionContentInfo.contentURL = this.uploadedPictureUrl;
        this.questionContentInfo.contentURLSigned = event.signedUrl;
        
        // Medya yüklendiğinde, isMediaChanged'i true yap
        // Böylece medya değişikliği doğru şekilde takip edilir
        this.isMediaChanged = true;
        
        // Geçici medya bilgilerini temizle
        this.tempMediaInfo = null;
        
        // Değişikliklerin UI'a yansıması için
        this.cdr.detectChanges();
      }

    onAddChoiceText() {
        this.checkAddAnswerValidity();
        if(this.checkValid == false){
            return this.messageService.add({
                severity: 'error', summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.CannotAddAnswer, life: 3000
              });
        }
        else{
            const choiceContentInfo = new ChoiceContentListItem();
            choiceContentInfo.choiceText = this.choiceContent.choiceText;
            choiceContentInfo.isCorrect = this.choiceContent.isCorrect;
            choiceContentInfo.order = this.choiceContentArray.length+1;
            this.choiceContentArray.push(choiceContentInfo);
            this.choiceContentArray = [...this.choiceContentArray];
            
            if (choiceContentInfo.isCorrect) {
                this.updateCorrectAnswer(choiceContentInfo);
            }
            
            this.choiceContent.choiceText = null;
            this.choiceContent.isCorrect = false;
            this.checkIsThereCorrectAnswer();

            let bottomElem = this.bottomDiv.nativeElement;

            if (bottomElem) {
                bottomElem.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
            }
        }
      }

      onCorrectAnswerChange(selectedChoice: ChoiceContentListItem) {
        this.updateCorrectAnswer(selectedChoice);
        this.checkIsThereCorrectAnswer();
        this.cdr.detectChanges();
      }
      
      updateCorrectAnswer(selectedChoice: ChoiceContentListItem) {
        this.choiceContentArray.forEach(choice => {
            choice.isCorrect = false;
        });
        
        selectedChoice.isCorrect = true;
        this.choiceContentArray = [...this.choiceContentArray];
      }

      checkIsThereCorrectAnswer(){
        if((this.choiceContentArray.find(x => x.isCorrect == true)) != undefined)
            this.checkCorrectAnswer = true;
        else
            this.checkCorrectAnswer = false;
      }

      onDelete(choiceText:string){
        var index = this.choiceContentArray.findIndex(x => x.choiceText == choiceText);
        if(index != -1){
            const deletedChoice = this.choiceContentArray[index];
            const wasCorrect = deletedChoice.isCorrect;
            
            this.choiceContentArray.splice(index,1);
            if (wasCorrect) {
                this.checkIsThereCorrectAnswer();
            }
        }
      }

      onDeleteQuestion(question:QuestionListItem){
        var index = this.questionToDisplayArray.findIndex(x => x.questionContent.questionText == question.questionContent.questionText);
        if(index != -1){
            this.questionToDisplayArray.splice(index,1);
        }
      }


      async onAddQuestion(){
        this.checkAddQuestionValidity();
        let isMediaError = false;
        if(this.checkValid == false){
            return this.messageService.add({
                severity: 'error', summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.CannotAddQuestion, life: 3000
              });
        }
        else{
            // Düzenleme modunda mı kontrol et
            const isEditMode = !!this.questionToEdit;
            
            // Yeni bir soru oluştur veya düzenlenen soruyu kopyala
            let questionInfo: QuestionListItem;
            
            if (isEditMode) {
                // Düzenleme modunda, mevcut soruyu kopyala ve sadece değişen alanları güncelle
                questionInfo = { ...this.questionToEdit };
                
                // Mevcut ID ve dummyID'yi koru
                // Bu alanlar zaten kopyalandı, tekrar atamaya gerek yok
            } else {
                // Yeni soru oluştur
                questionInfo = new QuestionListItem();
                questionInfo.dummyID = uuidv4();
            }
            
            // Ortak alanları güncelle
            questionInfo.duration = this.stepDurationType === this.stepDurationTypeEnum.QuestionBased ? this.questionDuration : 0;
            questionInfo.weight = this.questionWeight;

            // Anahtar kelimeleri hazırla
            this.keywordList = [];
            this.keywords.forEach(element => {
                const keywordInfo = new KeywordListItem();
                keywordInfo.name = element;
                this.keywordList.push(keywordInfo);
            });
            
            // Medya işleme - Basitleştirilmiş
            if (this.selectedQuestionContentType !== 0) {
                // Medya tipi seçildi
                
                // Soru tipi Multiple Choice değilse, medya içeriğini temizle
                if (this.selectedAnswerType !== this.MultipleChoiceAnswerType) {
                    this.selectedQuestionContentType = 0;
                    this.uploadedPictureUrl = '';
                    this.questionContentInfo.contentURL = '';
                    this.questionContentInfo.contentURLSigned = '';
                    this.questionContentInfo.contentURLType = 0;
                    
                    // Medya hatası yok
                    isMediaError = false;
                } else {
                    // Medya tipi değişti mi kontrol et
                    const mediaTypeChanged = isEditMode && 
                        this.questionToEdit?.questionContent?.contentURLType !== this.selectedQuestionContentType;
                    
                    // Medya URL'si var mı kontrol et
                    if (this.uploadedPictureUrl) {
                        // Medya URL'si var, medya tipini ayarla
                        switch(this.selectedQuestionContentType) {
                            case this.VideoQuestionContentType:
                                this.questionContentInfo.contentURLType = EQuestionContentURLType.Video;
                                break;
                            case this.ImageQuestionContentType:
                                this.questionContentInfo.contentURLType = EQuestionContentURLType.Image;
                                break;
                            case this.AudioQuestionContentType:
                                this.questionContentInfo.contentURLType = EQuestionContentURLType.Audio;
                                break;
                        }
                        
                        // Medya hatası yok
                        isMediaError = false;
                    } else {
                        // Medya URL'si yok, medya bileşenlerini kontrol et
                        try {
                            let mediaUploaded = false;
                            
                            switch(this.selectedQuestionContentType) {
                                case this.VideoQuestionContentType:
                                    if (this.childVideo) {
                                        const res = await this.childVideo.onConfirm();
                                        if (res) {
                                            mediaUploaded = true;
                                            this.questionContentInfo.contentURLType = EQuestionContentURLType.Video;
                                        }
                                    }
                                    break;
                                case this.ImageQuestionContentType:
                                    if (this.childPicture) {
                                        const res = await this.childPicture.onConfirm();
                                        if (res) {
                                            mediaUploaded = true;
                                            this.questionContentInfo.contentURLType = EQuestionContentURLType.Image;
                                        }
                                    }
                                    break;
                                case this.AudioQuestionContentType:
                                    if (this.childAudio) {
                                        const res = await this.childAudio.onConfirm();
                                        if (res) {
                                            mediaUploaded = true;
                                            this.questionContentInfo.contentURLType = EQuestionContentURLType.Audio;
                                        }
                                    }
                                    break;
                            }
                            
                            // Medya yüklendi mi kontrol et
                            if (!mediaUploaded) {
                                // Medya tipi değişti ve yeni medya yüklenmedi
                                if (mediaTypeChanged) {
                                    // Medya içeriğini temizle
                                    this.questionContentInfo.contentURLType = 0;
                                    this.questionContentInfo.contentURL = '';
                                    this.questionContentInfo.contentURLSigned = '';
                                    this.uploadedPictureUrl = '';
                                    
                                    // Medya hatası yok - medya tipi değiştiğinde ve yeni medya yüklenmediğinde
                                    // medya içeriğini temizliyoruz, bu bir hata değil
                                    isMediaError = false;
                                    
                                } 
                                // Düzenleme modunda ve medya değişmediğinde, mevcut medya bilgilerini kullan
                                else if (isEditMode && !this.isMediaChanged && this.questionToEdit?.questionContent?.contentURL) {
                                    this.questionContentInfo.contentURLType = this.questionToEdit.questionContent.contentURLType;
                                    this.questionContentInfo.contentURL = this.questionToEdit.questionContent.contentURL;
                                    this.questionContentInfo.contentURLSigned = this.questionToEdit.questionContent.contentURLSigned;
                                    
                                    // Medya hatası yok
                                    isMediaError = false;
                                } else {
                                    // Medya yüklenmedi ve mevcut medya yok, hata ver
                                    isMediaError = true;
                                }
                            }
                        } catch (error) {
                            console.error('Medya işleme hatası:', error);
                            
                            // Medya tipi değişti ve yeni medya yüklenmedi
                            if (mediaTypeChanged) {
                                // Medya içeriğini temizle
                                this.questionContentInfo.contentURLType = 0;
                                this.questionContentInfo.contentURL = '';
                                this.questionContentInfo.contentURLSigned = '';
                                this.uploadedPictureUrl = '';
                                
                                // Medya hatası yok - medya tipi değiştiğinde ve yeni medya yüklenmediğinde
                                // medya içeriğini temizliyoruz, bu bir hata değil
                                isMediaError = false;
                            }
                            // Düzenleme modunda ve medya değişmediğinde, mevcut medya bilgilerini kullan
                            else if (isEditMode && !this.isMediaChanged && this.questionToEdit?.questionContent?.contentURL) {
                                this.questionContentInfo.contentURLType = this.questionToEdit.questionContent.contentURLType;
                                this.questionContentInfo.contentURL = this.questionToEdit.questionContent.contentURL;
                                this.questionContentInfo.contentURLSigned = this.questionToEdit.questionContent.contentURLSigned;
                                
                                // Medya hatası yok
                                isMediaError = false;
                            } else {
                                // Medya işleme hatası ve mevcut medya yok, hata ver
                                isMediaError = true;
                            }
                        }
                    }
                }
            } else {
                // Medya tipi seçilmedi, içeriği temizle
                this.questionContentInfo.contentURLType = 0;
                this.questionContentInfo.contentURL = '';
                this.questionContentInfo.contentURLSigned = '';
            }

            if(isMediaError){
                this.messageService.add({
                    severity: 'error',
                    summary: this.localizationService.translatedTextList.Error,
                    detail: this.localizationService.translatedTextList.EmptyMediaError,
                    life: 3000
                });
            }else{
                // Soru içeriğini güncelle
                questionInfo.keywords = this.keywordList;
                questionInfo.choiceContents = [...this.choiceContentArray];
                questionInfo.choiceContents.forEach((item, index) => {
                    item.order = index + 1;
                  });
                questionInfo.expectedAnswerType = this.selectedAnswerType;
                
                // Soru içeriğini oluştur veya güncelle
                if (!questionInfo.questionContent) {
                    questionInfo.questionContent = new QuestionContentListItem();
                }
                
                // Soru metnini her zaman güncelle
                questionInfo.questionContent.questionText = this.questionText;
                
                // Medya içeriğini güncelle
                questionInfo.questionContent.contentURLType = this.questionContentInfo.contentURLType;
                questionInfo.questionContent.contentURL = this.questionContentInfo.contentURL;
                questionInfo.questionContent.contentURLSigned = this.questionContentInfo.contentURLSigned;
                
                questionInfo.questionCategory = this.selectedCategory;
                questionInfo.languageID = this.selectedLanguageQuestion;
                
                // Özel soru olarak işaretle
                questionInfo.isCustomQuestion = true;
                
                // Kategori bazlı kavram ayarları
                if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest || 
                    this.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest) {
                    questionInfo.questionConceptID = this.selectedConcept?.id;
                } else if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest) {
                    questionInfo.questionConcept = this.createNewQuestionConcept();
                } else {
                }
                
                // Soruyu kaydet
                this.saveQuestions(questionInfo);
                this.clear();
            }
        }
      }

      clear() {
        
        // Temel alanları sıfırla
        this.checkCorrectAnswer = false;
        this.choiceContent.choiceText = '';
        this.choiceContent.isCorrect = false;
        this.choiceContentArray = [];
        this.keywords = [];
        this.keywordList = [];
        
        // Ek alanları sıfırla
        this.questionText = '';
        this.questionInputLength = 0;
        this.selectedAnswerType = null;
        this.questionDuration = null;
        this.questionWeight = 3;
        this.newConceptName = '';
        this.selectedConcept = null;
        this.questionConceptList = [];
        
        // Soru içerik tipini temizle
        this.selectedQuestionContentType = 0;
        this.uploadedPictureUrl = '';
        this.questionContentInfo = new QuestionContentListItem();
        this.isMediaChanged = false;
        this.tempMediaInfo = null;
        
        // Kategori bazlı alanları sıfırla
        if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage) {
          this.selectedCategory = '';
        } else if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest || 
                   this.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest) {
          this.selectedConcept = null;
          this.newConceptName = '';
        }
                
        // UI güncellemesi için
        this.cdr.detectChanges();
    }

      checkAddAnswerValidity(){
        if(this.choiceContent.choiceText == null || this.choiceContent.choiceText == ""){
            this.checkValid = false;
        }
        else
            this.checkValid = true;
      }

      checkAddQuestionValidity(){
        this.checkValid = true;

        if(this.stepDurationType === this.stepDurationTypeEnum.QuestionBased){
            if(this.questionDuration == null || this.questionDuration == undefined || this.questionDuration == 0){
                this.checkValid = false;
            }
        }

        if(this.questionWeight == null || this.questionWeight == undefined || this.questionWeight == 0){
            this.checkValid = false;
        }

        if(this.selectedAnswerType == null || this.selectedAnswerType == ""){
            this.checkValid = false;
        }
        if(this.selectedAnswerType == this.MultipleChoiceAnswerType){
            if(this.questionText == null || this.questionText == "" || this.choiceContentArray.length < 2 || this.questionInputLength > 750 || this.questionInputLength < 5 )
                this.checkValid = false;
        }
        if(this.selectedAnswerType == this.OpenEndedAnswerType){
            if(this.questionText == null || this.questionText == "" || this.questionInputLength > 750 || this.questionInputLength < 5 )
                this.checkValid = false;
            
            if((this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest) || 
               (this.questionCollectionCategory == QuestionCollectionCategoryEnum.MTCompetenceTest)) {
                if(this.keywords.length < 3) {
                    this.checkValid = false;
                }
            }
        }
        if(this.selectedAnswerType == this.VideoAnswerType){
            if(this.questionText == null || this.questionText == "" || this.questionInputLength > 750 || this.questionInputLength < 5 )
                this.checkValid = false;
            
            if((this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest) || 
               (this.questionCollectionCategory == QuestionCollectionCategoryEnum.MTCompetenceTest)) {
                if(this.keywords.length < 3) {
                    this.checkValid = false;
                }
            }
        }

        if(this.keywords.length < 3 &&
           (this.selectedAnswerType === QuestionExpectedAnswerType.Video || this.selectedAnswerType === QuestionExpectedAnswerType.OpenEnded) &&
           ((this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest) || 
            (this.questionCollectionCategory == QuestionCollectionCategoryEnum.MTCompetenceTest))){
            this.isKeywordError = true;
            this.checkValid = false;
        } else {
            this.isKeywordError = false;
        }

        if((this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest) || (this.questionCollectionCategory == QuestionCollectionCategoryEnum.MTCompetenceTest)){
            if(this.selectedConcept === null || this.selectedConcept === undefined)
                this.checkValid = false;
        }

        if(this.questionCollectionCategory == QuestionCollectionCategoryEnum.TechnicalTest){
            if(this.newConceptName === null || this.newConceptName === undefined || this.newConceptName === "")
                this.checkValid = false;
        }
      }

      // Düzenleme modunda olup olmadığını kontrol eden getter
      get isEditMode(): boolean {
          return !!this.questionToEdit;
      }

      saveQuestions(question){
        this.saveYourQuestions.emit([question]);
      }

      questionCount(event){
        if(event.textValue == ''){
          this.questionInputLength = 0;

        }
        else{
          this.questionInputLength = event.textValue.length;
        }
      }

      validateWeight(weight) {
        if (weight < 1) {
          this.questionWeight = 1;
        } else if (weight > 5) {
          this.questionWeight = 5;
        }
      }

      isSubmitDisabled(): boolean {
        const isQuestionLengthValid = this.questionInputLength >= this.minLenght && this.questionInputLength <= this.maxLenght;
        const hasEnoughKeywords = this.keywords.length > 2;
        const isDurationAssigned = (this.stepDurationType === this.stepDurationTypeEnum.QuestionBased)
            ? (this.questionDuration != null && this.questionDuration !== 0)
            : true;
        const isWeightAssigned = this.questionWeight != null && this.questionWeight !== 0;
        const isLanguageSelected = this.selectedLanguageQuestion ?? false;
    
        if (this.selectedAnswerType === QuestionExpectedAnswerType.MultipleChoice) {
            const hasChoices = this.choiceContentArray && this.choiceContentArray.length > 1;
            const hasCorrectChoice = hasChoices && this.choiceContentArray.some(choice => choice.isCorrect);
            if (!hasChoices || !hasCorrectChoice) {
                return true;
            }
        }
    
        if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest ||
            this.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest) {
            
            const isConceptSelected = this.selectedConcept != null;
            return !(
                isQuestionLengthValid &&
                hasEnoughKeywords &&
                isConceptSelected &&
                isDurationAssigned &&
                isWeightAssigned &&
                isLanguageSelected
            );
        } else if (this.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest) {
            const newConcept = this.newConceptName != null && this.newConceptName.trim().length > 0;
            return !(
                isQuestionLengthValid &&
                newConcept &&
                isDurationAssigned &&
                isWeightAssigned &&
                isLanguageSelected
            );
        } else {
            return !(isQuestionLengthValid && hasEnoughKeywords && isDurationAssigned && isWeightAssigned);
        }
    }

      getQuestionConceptList(callback?: () => void){
        let conceptType = this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest ? QuestionCollectionCategoryEnum.CompetenceTest : QuestionCollectionCategoryEnum.MTCompetenceTest;
        const questionConceptFilter =
        {
            languageID: this.selectedLanguageQuestion,
            type: conceptType
        };
        this.questionConceptService.getListByFilter(questionConceptFilter).subscribe(data =>{
            this.questionConceptList = data;
            
            // Eğer callback varsa çağır
            if (callback) {
                callback();
            }
        });
      }

      createNewQuestionConcept(){
        // newConceptName boş ise, varsayılan bir değer kullan
        if (!this.newConceptName || this.newConceptName.trim() === '') {
            this.newConceptName = 'Unnamed Concept';
        }
        
        const newQuestionConcept = new QuestionConceptPostItem();
        newQuestionConcept.organizationID = this.orgID;
        newQuestionConcept.languageID = this.selectedLanguageQuestion;
        newQuestionConcept.name = this.newConceptName;
        newQuestionConcept.type = this.EQuestionCollectionCategoryEnum.TechnicalTest;
        newQuestionConcept.entityState = EntityStateEnum.Added;
        
        return newQuestionConcept;
      }

      validateWholeNumber(event: Event): void {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        if (!/^\d+$/.test(value)) {
          input.value = value.replace(/\D/g, '');
        }
    }

    // Medya içeriğini değiştirmek için
    changeMedia() {
        // Medya değişikliği moduna geç
        this.isMediaChanged = true;
        
        // Mevcut medya türünü ve URL'sini kaydet
        const currentMediaType = this.selectedQuestionContentType;
        const currentMediaUrl = this.uploadedPictureUrl;
        const currentContentURL = this.questionContentInfo.contentURL;
        const currentContentURLSigned = this.questionContentInfo.contentURLSigned;
        
        // Mevcut medya bilgilerini geçici olarak sakla
        if (this.uploadedPictureUrl) {
            this.tempMediaInfo = {
                url: currentMediaUrl,
                contentURL: currentContentURL,
                contentURLSigned: currentContentURLSigned,
                contentURLType: currentMediaType
            };
        }
        
        // Medya içeriğini temizle - bu önemli, böylece yeni medya yüklenebilir
        this.uploadedPictureUrl = '';
        this.questionContentInfo.contentURL = '';
        this.questionContentInfo.contentURLSigned = '';
        
        // Değişikliğin UI'a yansıması için
        this.cdr.detectChanges();
      }

    // Medya hata yönetimi metodları
    handleImageError(event: any) {
        console.warn('Resim yükleme hatası:', event);
        // Resim yüklenemediğinde, medya değişikliği moduna geç
        this.isMediaChanged = true;
        this.cdr.detectChanges();
    }
    
    handleVideoError(event: any) {
        console.warn('Video yükleme hatası:', event);
        // Video yüklenemediğinde, medya değişikliği moduna geç
        this.isMediaChanged = true;
        this.cdr.detectChanges();
    }
    
    handleAudioError(event: any) {
        console.warn('Ses yükleme hatası:', event);
        // Ses yüklenemediğinde, medya değişikliği moduna geç
        this.isMediaChanged = true;
        this.cdr.detectChanges();
    }

    // Soru tipi değiştirildiğinde medya içeriğini temizle
    clearMediaContentOnAnswerTypeChange(newAnswerType: number) {
        // Multiple Choice'dan başka bir tipe geçildiğinde medya içeriğini temizle
        if (newAnswerType !== this.MultipleChoiceAnswerType) {
            // Medya içeriğini temizle
            this.selectedQuestionContentType = 0;
            this.oldRadioButtonEventValue = 0;
            this.uploadedPictureUrl = '';
            this.questionContentInfo.contentURL = '';
            this.questionContentInfo.contentURLSigned = '';
            this.questionContentInfo.contentURLType = 0;
            this.isMediaChanged = true;
            this.tempMediaInfo = null;
        }
        
        // Değişikliklerin UI'a yansıması için
        this.cdr.detectChanges();
    }

    isMaxChoiceCountReached(): boolean {
        return this.choiceContentArray.length >= this.MAX_CHOICE_COUNT;
    }
}
