import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { QuesitonWeightIcon, QuestionWeightToolTipIcon } from '@app/shared/common/constants';
import { EQuestionCategories, GPTEvalStatus, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { QuestionListItem } from '@app/vinter/models/question-list-item';

@Component({
  selector: 'app-step-question-result-list',
  templateUrl: './step-question-result-list.component.html',
  styleUrls: ['./step-question-result-list.component.scss']
})
export class StepQuestionResultListComponent implements OnInit{


  @Input() questionList: any;
  @Input() applicationStepId: string;

  QuestionExpectedAnswerType = QuestionExpectedAnswerType;
  questionCollectionCategories = QuestionCollectionCategoryEnum;
  questionCategoryType = EQuestionCategories;
  questionWeightIconPath = QuesitonWeightIcon;
  questionWeightToolTipIconPath = QuestionWeightToolTipIcon;
  answerSidebarVisible : boolean = false;
  selectedQuestion: any;
  GPTEvalStatus = GPTEvalStatus;

  constructor() { }


  ngOnInit(): void {
  }

  openAnswerSidebar(relation:any){
    this.selectedQuestion = relation.question;
    this.selectedQuestion.order = relation.order;
    this.selectedQuestion.weight = relation.weight;
    this.answerSidebarVisible = true;
  }

  onHideAnswerSidebar(isScoreChanged){
    this.answerSidebarVisible = false;
    this.selectedQuestion = null;
    if(isScoreChanged){
        window.location.reload();
    }
  }

  getPreviousOrNextQuestion(event){
  var questionRelationsCopy = JSON.parse(JSON.stringify(this.questionList.questionCollectionQuestionRelations));
  const sortedRelations = questionRelationsCopy.sort(
      (a, b) => b.order - a.order
    );
  const lastQuestion = sortedRelations[0];
  if(event != 0 && event >! lastQuestion.order){
    const previousQuestionRelation = this.questionList.questionCollectionQuestionRelations.filter(x => x.order == event)[0]
    this.selectedQuestion = previousQuestionRelation.question;
    this.selectedQuestion.order = previousQuestionRelation.order;
    this.selectedQuestion.weight = previousQuestionRelation.weight;
  }

  }

}
