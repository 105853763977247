import { EventEmitter, Input, OnInit, Output, OnChanges, Component, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { vinterOrganizationGuid } from '@app/shared/common/constants';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { OrganizationListItem } from '@app/vinter/models/organization-list-item';
import { QuestionCollectionListItem } from '@app/vinter/models/question-collection-list';
import { SelectedQuestionCollectionItem } from '@app/vinter/models/shared/selected-question-collection-item';
import { OrganizationService } from '@app/vinter/services/organization.service';
import { QuestionCollectionService } from '@app/vinter/services/question-collection.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { QuestionExpectedAnswerType, QuestionCollectionCategoryEnum, EntityStateEnum, EQuestionCollectionSubCategory, PositionFormEditModeEnum, EFormType, EQuestionCollectionLanguages, EStepDurationType } from '@app/shared/common/enums';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { QuestionCollectionStepQuestionCustomItem } from '@app/vinter/models/shared/question-collection-question-custom-item';
import { CredentialsService, LocalizationService } from '@app/core';
import { LayoutService } from '@app/layout/service/app.layout.service';

@Component({
    selector: 'app-question-collection-list',
    templateUrl: './question-collection-list.component.html',
    styleUrls: ['./question-collection-list.component.scss'],
    providers: [ConfirmationService, MessageService]

})
export class QuestionCollectionListComponent implements OnInit, OnChanges {
    orgNameColorArray = [
        { 'backgroundColor': '#D0F9CF', 'textColor': '#32C52F' },
        { 'backgroundColor': 'var(--blue-100)', 'textColor': 'var(--blue-500)' },
        { 'backgroundColor': '#FFDCDC', 'textColor': '#FF5454' },
        { 'backgroundColor': '#FFF4D9', 'textColor': '#EFAC00' },
    ]
    distinctQuestionCollectionCategoryName: string[];
    VideoAnswerType: number;
    MultipleChoiceAnswerType: number;
    OpenEndedAnswerType: number;
    questionCollections: any[] = [];
    questionCollectionsDisplay: any[] = [];
    questionCollectionCategories: any[] = [];
    organizations: OrganizationListItem[] = [];
    organizationsCreatedBy: any[] = [];
    selectedLanguage: any;
    collectionLanguageList: any[] = [];
    selectedOrganization: any;
    selectedQuestionCollectionCategory: any;
    searchText: string;
    vinterGuid: string;
    tooltipMessage: string;
    selectedQuestionCollection: SelectedQuestionCollectionItem = new SelectedQuestionCollectionItem();
    imgPath: string = "assets/demo/images/vinter/vinterlogo.jpg";
    openEndedAnswerCount: number = 0;
    videoAnswerCount: number = 0;
    codingAnswerCount: number = 0;
    multipleChoiceAnswerCount: number = 0;
    addButtonDisabled: boolean = true;
    disabledQuestionListCard: boolean = true;
    isOceanOrForeignLangSelected:boolean = false;
    @Input() subCategory:number = 0;
    tempSelectedQuestionListCategory: any = null;
    questionCollectionQuestions: QuestionListItem[] = [];
    cardDisable: boolean = false;
    menuVisible: boolean = false;
    questionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
    @Input() isAddPersonalQuestionBtnVisible: boolean = true;
    @Input() questionCollectionFormInfo;
    @Input() stepDurationType;
    @Input() selectedQuestionList;
    @Input() createdQuestionList;
    @Input() saveQuestionListCategory;
    @Input() formType: number;
    @Input() positionEditFormMode: PositionFormEditModeEnum;
    formTypeEnum = EFormType;
    @Input() languageId: string = '';
    @Input() positionName: string = '';
    language : string = '';
    selectedCreatedBy: any;
    selectedOrganizationId: string;
    mainOrgId:any;
    dialogVisible: boolean = false;
    foreignLanguageQuestionCollectionCategory: string = QuestionCollectionCategoryEnum.ForeignLanguage
    @Output() goBackOneStep: EventEmitter<any> = new EventEmitter<any>();
    @Output() next: EventEmitter<any> = new EventEmitter<any>();
    @Output() onViewQuestions: EventEmitter<SelectedQuestionCollectionItem> = new EventEmitter<SelectedQuestionCollectionItem>();
    @Output() onSelectedCategory: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaveStepQuestions: EventEmitter<QuestionCollectionStepQuestionCustomItem> = new EventEmitter<QuestionCollectionStepQuestionCustomItem>();
    @Output() questionCollectionDuration: EventEmitter<number> = new EventEmitter<number>();

    @Output() onCreateNewQuestion: EventEmitter<string> = new EventEmitter<string>();
    @Output() onPreviewQuestions: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onAddedAllListQuestions: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveQuestionListInfo: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeSubStep: EventEmitter<any> = new EventEmitter<any>();


    isPreviewButtonClicked: boolean = false;
    isQuestionWeightChanged: boolean = false;
    EQuestionCollectionSubCategory = EQuestionCollectionSubCategory;
    defaultQuestionWeight: number = 3;
    stepDurationTypeEnum = EStepDurationType;
    isCodingQuestionLicenseValid: boolean


    constructor(private service: QuestionCollectionService,
        private localizationService: LocalizationService,
        private messageService: MessageService,
        private appInfoService: AppInfoService,
        private organizationService: OrganizationService,
        public router: Router,
        private layoutService : LayoutService,
        private credentialServices : CredentialsService) { }

    ngOnInit(): void {
        this.isCodingQuestionLicenseValid = this.credentialServices.getIsExistCodingQuestionLicense();
        this.mainOrgId=this.credentialServices.getMainOrganizationID();
        this.setLanguage();
        this.VideoAnswerType = QuestionExpectedAnswerType.Video;
        this.MultipleChoiceAnswerType = QuestionExpectedAnswerType.MultipleChoice;
        this.OpenEndedAnswerType = QuestionExpectedAnswerType.OpenEnded;
        this.vinterGuid = vinterOrganizationGuid;
        this.GetDataForQuestionCollectionListDisplay();
        this.questionCollectionCategories = this.appInfoService.getQuestionCollectionCategoryArray();
        this.getQuestionTypeCount();
        this.selectQuestionCategory();
        this.getMainOrganizationForCreatedBy();
        this.showErrorMessage();
    }

    setLanguage(){
        this.languageId = (this.languageId ?? false) ? this.languageId: this.credentialServices.getLanguage();
        this.collectionLanguageList = this.appInfoService.getQuestionCollectionLanguageList();
        this.language  = this.collectionLanguageList.filter(x => x.id === this.languageId).map(x=>(x.name))[0];
        this.selectedLanguage = this.collectionLanguageList.find(x => x.id === this.languageId);
    }

    searchByLanguageChange(event: any){
        this.selectedLanguage = event.value ?? false? event.value : null;
        this.onSearchChange();
    }

    get questionCollectionListDuration (): boolean | number {

        if(this.selectedQuestionList.length <= 0){
            return 0;
        }
        else{
            if(this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage
                || this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest
                || this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation
                || this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility){

                    if(this.questionCollectionFormInfo.duration !== 0)
                        return this.questionCollectionFormInfo.duration;
                    else
                        return 0;
            }
            else if(this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest
                || this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest
                || this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest){
                    if(this.stepDurationType == this.stepDurationTypeEnum.QuestionBased)
                        return this.selectedQuestionList.reduce((sum, question) => sum + question.duration, 0);
                    else if(this.stepDurationType == this.stepDurationTypeEnum.StepBased)
                        return this.questionCollectionFormInfo.duration;
            }
            else{
                return 0;
            }
        }

    }

    set questionCollectionListDuration (val: boolean | number) {
        
        this.questionCollectionFormInfo.duration = val;
    }

    setTimerButtonVisible(){
        let visible = (this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest  ||
            this.questionCollectionFormInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage ||
            this.questionCollectionFormInfo.filteredQuestionCollectionCategory == QuestionCollectionCategoryEnum.Pronunciation ||
            this.questionCollectionFormInfo.filteredQuestionCollectionCategory == QuestionCollectionCategoryEnum.CognitiveAbility ) ? false : true;
        return visible;
    }

    ngOnChanges() {
        this.getQuestionTypeCount();
        this.checkAllQuestionsOfCollectionAreAdded();
    }

    checkAllQuestionsOfCollectionAreAdded(){
        this.questionCollectionsDisplay.forEach(element => {
            let selectAll = true;
            element.questionCollectionQuestionRelations.forEach(relation => {
                if(!(this.selectedQuestionList.map(x => x.id)).includes(relation.questionID) && !relation.isDeprecated){
                    selectAll = false;
                }
            })
            if(selectAll)
                element.isAllAdded = true;
            else
                element.isAllAdded = false;
        });
    }

    getMainOrganizationForCreatedBy(){

        let mainOrgObj = {
            id: this.credentialServices.getMainOrganizationID(),
            organizationName: this.credentialServices.getMainOrganizationName()
        };
        this.organizationsCreatedBy.push(mainOrgObj);

        let vinterOrgObj = {
            id: this.credentialServices.getVinterOrganizationID(),
            organizationName: 'Vinter'
        };
        this.organizationsCreatedBy.push(vinterOrgObj);

    }

    showErrorMessage(): string {
        if (this.selectedQuestionList.length <= 0){
            this.tooltipMessage = this.localizationService.translatedTextList.ListLengthMessage;
        }
        else{
            this.tooltipMessage="";
        }
        return this.tooltipMessage;
    }

    searchCreatedBy(event: any){
        this.selectedOrganizationId = event.value ?? false? event.value.id : null;
        this.onSearchChange();
    }

    clearOrganizationSelection(){
        this.selectedOrganizationId = null;
        this.onSearchChange();
    }

    getQuestionTypeCount() {
        this.questionCollectionQuestions = [];
        this.selectedQuestionList.forEach(element => {
            if(!element.isDeprecated)
                this.questionCollectionQuestions.push(element);
        });
        this.openEndedAnswerCount = this.questionCollectionQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.OpenEnded).length;
        this.videoAnswerCount = this.questionCollectionQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.Video).length;
        this.multipleChoiceAnswerCount = this.questionCollectionQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.MultipleChoice).length;
        this.codingAnswerCount = this.questionCollectionQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.Coding).length;

    }

    GetDataForQuestionCollectionListDisplay() {
        let isTemprature=true;

        const obsQuestionCollections = this.service.getListByFilterWithQuestionCountForCreate(
            { QuestionCollectionCategory: this.questionCollectionFormInfo.filteredQuestionCollectionCategory,LanguageID: this.questionCollectionFormInfo.languageId, isTemprature: isTemprature });        const obsOrganizations = this.organizationService.getOrganizationNamesWithId();

        forkJoin([obsQuestionCollections, obsOrganizations]).subscribe(
            ([respQuestionCollections, respOrganizations]) => {
                this.questionCollections = respQuestionCollections;
                this.questionCollectionsDisplay = JSON.parse(JSON.stringify(respQuestionCollections.filter(x => x.isDeprecated != true)));
                this.distinctQuestionCollectionCategoryName = [...new Set(this.questionCollectionsDisplay.map(x => (x.questionCollectionCategoryName)))];
                this.questionCollectionsDisplay = this.questionCollectionsDisplay.map(x => {
                    return {
                        orgBackColor: this.orgNameColorArray[this.distinctQuestionCollectionCategoryName.indexOf(x.questionCollectionCategoryName) % 4].backgroundColor,
                        orgTextColor: this.orgNameColorArray[this.distinctQuestionCollectionCategoryName.indexOf(x.questionCollectionCategoryName) % 4].textColor,
                        ...x
                    }
                });
                this.setQuestionListDisabled();
                this.organizations = respOrganizations;
                this.checkAllQuestionsOfCollectionAreAdded();
                this.onSearchChange();
            });
    }

    setQuestionListDisabled() {
        this.questionCollectionsDisplay = this.questionCollectionsDisplay.map((item) => {
            return { ...item, disabled: false };
        });
        if (this.saveQuestionListCategory !== null) {
            this.updateCardDisableStates();
        }
    }

    updateCardDisableStates() {
        if (this.saveQuestionListCategory !== null) {
            if (this.questionCollectionsDisplay ?? false) {
                this.questionCollectionsDisplay.forEach((card) => {
                    if (
                        card.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage ||
                        card.questionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation ||
                        card.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility ||
                        card.questionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest 
                    ) {
                        card.disabled = true;
                    } else {
                        card.disabled = false;
                    }

                });
            }
        }
    }

    checkQuestionWeightForList(){
        let addedNewQuestionList = this.selectedQuestionList.filter(data => data.dummyID ?? false);
        let addedFromListQuestionList = this.selectedQuestionList.filter(data => !(data.dummyID ?? false));

        let checkAddedQuestionWeight = addedFromListQuestionList.length === 0 ? addedNewQuestionList.every(data => data.weight === this.defaultQuestionWeight) : false;

        if(checkAddedQuestionWeight && !this.isPreviewButtonClicked){
            return true;
        }
        if(!this.isPreviewButtonClicked && this.isQuestionWeightChanged){
            return true;
        }
        else{
            return false;
        }
    }

    openWeightDialog(){
        if(this.questionCollectionFormInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest || this.questionCollectionFormInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest
            || this.questionCollectionFormInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest
        ){
            this.dialogVisible = !this.dialogVisible;
        }
        else{
            this.saveAllDataForStep();
        }
    }

    changeQuestionWeight(){
        this.dialogVisible = !this.dialogVisible;
        this.previewQuestions();
    }

    saveAllDataForStep() {
        let previewQuestions = [];
        this.selectedQuestionList.forEach(element => {
            previewQuestions.push(element);
        });
        if (previewQuestions?.length > 0) {
            previewQuestions = previewQuestions.map((item, i) => {
                return { ...item, order: i + 1 };
            });

            if (this.formType == this.formTypeEnum.Position) {
                previewQuestions = this.buildQuestionStructureForStep(previewQuestions);
                let questionCollectionQuestionCustom = new QuestionCollectionStepQuestionCustomItem();
                questionCollectionQuestionCustom.stepInfo = this.questionCollectionFormInfo;
                questionCollectionQuestionCustom.stepInfo.subCategory = this.subCategory
                questionCollectionQuestionCustom.questions = previewQuestions;

                if(questionCollectionQuestionCustom.stepInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest ||
                questionCollectionQuestionCustom.stepInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest ||
                questionCollectionQuestionCustom.stepInfo.questionCollectionCategory === QuestionCollectionCategoryEnum.MTCompetenceTest){

                    questionCollectionQuestionCustom.stepInfo.duration = this.stepDurationType === this.stepDurationTypeEnum.QuestionBased ? previewQuestions.reduce((total, item) => total + item.duration, 0) : this.questionCollectionFormInfo.duration;
                }

                this.onSaveStepQuestions.emit(questionCollectionQuestionCustom);
            }

        }
        else {
            this.messageService.add({
                severity: 'error',
                summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.CanNotSaveQuestionCollectionWithoutQuestions,
                life: 3000
            });
        }
    }

    buildQuestionStructureForStep(previewQuestions) {
        previewQuestions.forEach(element => {
            if(!(element.id ?? false)){
                delete element['xmin'];
                delete element['id'];
                element.entityState = EntityStateEnum.Added;
            }else{
                element.entityState = EntityStateEnum.Unchanged;
            }

            if(!(element.questionContent.id ?? false)){
                delete element.questionContent['id'];
                delete element.questionContent['xmin'];
                delete element.questionContent['questionID'];
                element.questionContent.entityState = EntityStateEnum.Added;
            }else{
                element.entityState = EntityStateEnum.Unchanged;
            }
            if (element.expectedAnswerType == this.MultipleChoiceAnswerType) {
                element.choiceContents.forEach(x => {
                    if(!(x.id ?? false)){
                        delete x['id'];
                        delete x['xmin'];
                        delete x['questionId'];
                        x.entityState = EntityStateEnum.Added;
                    }else{
                        x.entityState = EntityStateEnum.Unchanged;
                    }
                });
            }
            if (element.expectedAnswerType == this.OpenEndedAnswerType || element.expectedAnswerType == this.VideoAnswerType) {
                element.keywords.forEach(x => {
                    if(!(x.id ?? false)){
                        delete x['id'];
                        delete x['xmin'];
                        delete x['questionId'];
                        delete x['positionId'];
                        x.entityState = EntityStateEnum.Added;
                    }else{
                        x.entityState = EntityStateEnum.Unchanged;
                    }
                });
            }
        });
        return previewQuestions;
    }

    selectQuestionCategory() {
        if (this.selectedQuestionCollectionCategory !== null && this.selectedQuestionCollectionCategory !== undefined) {
            if (this.questionCollectionQuestions.length === 0) {
                if (this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.ForeignLanguage
                    || this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.PersonalityTest
                    || this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.Pronunciation
                    || this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.CognitiveAbility) {
                    this.addButtonDisabled = false;
                    this.cardDisable = true;
                    this.disabledQuestionListCard = true;
                }
                else {
                    this.addButtonDisabled = true;
                    this.cardDisable = true;
                    this.disabledQuestionListCard = true;
                }
            }
            else {
                if (this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.ForeignLanguage
                    || this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.PersonalityTest
                    || this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.Pronunciation
                    || this.selectedQuestionCollectionCategory.id === QuestionCollectionCategoryEnum.CognitiveAbility) {
                    this.addButtonDisabled = false;
                    this.cardDisable = true;
                    this.disabledQuestionListCard = false;
                }
                else {
                    this.addButtonDisabled = true;
                    this.cardDisable = true;
                    this.disabledQuestionListCard = true;
                }
            }

        }
        if (this.saveQuestionListCategory !== null) {
            this.tempSelectedQuestionListCategory = this.saveQuestionListCategory;
            if (this.saveQuestionListCategory === QuestionCollectionCategoryEnum.ForeignLanguage
                || this.saveQuestionListCategory === QuestionCollectionCategoryEnum.PersonalityTest
                || this.saveQuestionListCategory === QuestionCollectionCategoryEnum.Pronunciation
                || this.saveQuestionListCategory === QuestionCollectionCategoryEnum.CognitiveAbility) {
                this.cardDisable = false;
                this.addButtonDisabled = false;
                this.disabledQuestionListCard = false;
            }
            else {
                this.cardDisable = true;
                this.updateCardDisableStates();
                this.addButtonDisabled = true;
                this.disabledQuestionListCard = true;
            }
        }
    }

    goViewQuestions(id: string, title: string, organizationID: string, category: string, description: string, duration: number, subCategory : number, showSubStep: boolean, languageID: string) {
        if (this.disabledQuestionListCard) {
            this.selectedQuestionCollection.id = id;
            this.selectedQuestionCollection.title = title;
            this.selectedQuestionCollection.description = description;
            this.selectedQuestionCollection.organizationID = organizationID;
            this.selectedQuestionCollection.duration = duration;
            this.selectedQuestionCollection.category = category;
            this.selectedQuestionCollection.subCategory = subCategory
            this.selectedQuestionCollection.languageID = languageID;
            this.onViewQuestions.emit(this.selectedQuestionCollection);
            this.onSelectedCategory.emit(this.selectedQuestionCollectionCategory);
            if(showSubStep){
                this.changeSubStep.emit();
            }
        }
    }

    goCreateNewQuestion() {
        this.onCreateNewQuestion.emit(null);
    }

    previewQuestions() {
        this.isPreviewButtonClicked = true;
        this.onPreviewQuestions.emit(true);
    }


    onMouseEnter() {
        if (this.disabledQuestionListCard) {
            return;
        }
    }

    onMouseLeave() {
        if (this.disabledQuestionListCard) {
            return;
        }
    }


    onSearchChange() {
        var searchString;
        var searchResult;
        searchResult = JSON.parse(JSON.stringify(this.questionCollections)).filter(x => x.isDeprecated != true);
        if(this.searchText !== undefined && this.searchText !== "" ){
            searchString = this.searchText.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase()
            searchResult = searchResult.filter(x => x.description.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase().includes(searchString) || x.title.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase().includes(searchString));
        }
        else{
            searchResult = JSON.parse(JSON.stringify(this.questionCollections));
            searchResult = searchResult.filter(x => x.isDeprecated != true);
        }

        if(this.selectedOrganizationId ?? false ){
            searchResult = searchResult.filter(x => x.organizationID.includes(this.selectedOrganizationId));
            searchResult = searchResult.filter(x => x.isDeprecated != true);
        }

        if(this.selectedLanguage ?? false ){
            searchResult = searchResult.filter(x => x.languageID.includes(this.selectedLanguage.id));
        }

        this.questionCollectionsDisplay = searchResult ? searchResult.map(x => {
            return {
                orgBackColor: this.orgNameColorArray[this.distinctQuestionCollectionCategoryName.indexOf(x.questionCollectionCategoryName) % 4].backgroundColor,
                orgTextColor: this.orgNameColorArray[this.distinctQuestionCollectionCategoryName.indexOf(x.questionCollectionCategoryName) % 4].textColor,
                ...x
            }
        }) : [];

        this.checkAllQuestionsOfCollectionAreAdded();
    }

    searchFilter() {
        this.service.getListByFilterWithQuestionCount(
            {
                questionCollectionCategory: this.selectedQuestionCollectionCategory === null
                    || this.selectedQuestionCollectionCategory === undefined ? null : this.selectedQuestionCollectionCategory.id, organizationID: this.selectedOrganization === null
                        || this.selectedOrganization === undefined ? null : this.selectedOrganization.id
            }).subscribe(data => {
                this.questionCollections = data;
                this.questionCollectionsDisplay = JSON.parse(JSON.stringify(data));
                if (this.selectedQuestionCollectionCategory === null || this.selectedQuestionCollectionCategory === undefined) {
                    if (this.saveQuestionListCategory ?? false) {
                        this.cardDisable = true;
                        this.addButtonDisabled = true;
                        this.updateCardDisableStates();
                    }
                    else {
                        this.cardDisable = false;
                        this.addButtonDisabled = false;
                    }

                } else {
                    this.selectQuestionCategory();
                }
                this.onSearchChange();
            });

    }

    resetAllSelectedQuestions(){
        this.questionCollectionsDisplay.forEach(x=>{
            x.isAllAdded=false;
        })
    }

    onAddClicked(questionCollection: QuestionCollectionListItem) {
        let duration = null;
        this.service.GetQuestionCollectionWihtQuestionsChoice(questionCollection.id).subscribe(element => {
            if (element.subCategory == EQuestionCollectionSubCategory.OCEAN50 ||
                element.subCategory == EQuestionCollectionSubCategory.OCEAN120 ||
                element.questionCollectionCategory == QuestionCollectionCategoryEnum.ForeignLanguage ||
                element.questionCollectionCategory == QuestionCollectionCategoryEnum.Pronunciation ||
                element.questionCollectionCategory == QuestionCollectionCategoryEnum.CognitiveAbility
                ){
                this.resetAllSelectedQuestions();
                this.isOceanOrForeignLangSelected = true;
                this.selectedQuestionList.splice(0, this.selectedQuestionList.length);
                if(element.questionCollectionCategory == QuestionCollectionCategoryEnum.ForeignLanguage ||
                    element.questionCollectionCategory == QuestionCollectionCategoryEnum.Pronunciation ||
                    element.questionCollectionCategory == QuestionCollectionCategoryEnum.CognitiveAbility
                ){
                    duration = element.duration;

                }
                else if(element.questionCollectionCategory == QuestionCollectionCategoryEnum.PersonalityTest && element.subCategory == EQuestionCollectionSubCategory.OCEAN50){
                    duration = element.duration;

                }
                else if(element.questionCollectionCategory == QuestionCollectionCategoryEnum.PersonalityTest && element.subCategory == EQuestionCollectionSubCategory.OCEAN120){
                    duration = element.duration;
                }
                else{
                    duration = this.questionCollectionListDuration;
                }
            }
            this.subCategory=element.subCategory;
            element.questions.forEach((item) => {
                if(!(this.selectedQuestionList.find(x => x.id == item.id) ?? false)){
                    if(!item.isDeprecated){
                        item.order = this.selectedQuestionList.length + 1;
                        this.selectedQuestionList.push(item);
                    }
                }
            });
            if(element.questionCollectionCategory == QuestionCollectionCategoryEnum.TechnicalTest || element.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest
                || element.questionCollectionCategory == QuestionCollectionCategoryEnum.MTCompetenceTest
            ){
                duration =  this.selectedQuestionList.reduce((sum, question) => sum + question.duration, 0);
            }

            this.isQuestionWeightChanged = true;
            this.saveQuestionListInfo.emit({title: questionCollection.title, duration: duration})
            this.onAddedAllListQuestions.emit(this.selectedQuestionList);
            questionCollection.isAllAdded = true;
            this.getQuestionTypeCount();
            this.goViewQuestions(element.id, element.title, element.organizationID, element.questionCollectionCategory, element.description, element.duration, element.subCategory, false, element.languageID);
        });
    }

    onRemoveClicked(questionCollection: QuestionCollectionListItem) {
        this.service.GetQuestionCollectionWihtQuestionsChoice(questionCollection.id).subscribe(element => {
            if (element.subCategory == EQuestionCollectionSubCategory.OCEAN50 ||
                element.subCategory == EQuestionCollectionSubCategory.OCEAN120 ||
                element.questionCollectionCategory == QuestionCollectionCategoryEnum.ForeignLanguage ||
                element.questionCollectionCategory == QuestionCollectionCategoryEnum.Pronunciation ||
                element.questionCollectionCategory == QuestionCollectionCategoryEnum.CognitiveAbility 
                )
                this.isOceanOrForeignLangSelected = false;
            element.questions.forEach((item) => {
                if(this.selectedQuestionList.find(x => x.id == item.id)){
                    let removeIndex = this.selectedQuestionList.map(x => x.id).indexOf(item.id);
                    this.selectedQuestionList.splice(removeIndex, 1);
                }
            });
            this.onAddedAllListQuestions.emit(this.selectedQuestionList);
            questionCollection.isAllAdded = false;
            this.getQuestionTypeCount();
        });
        this.questionCollectionFormInfo.duration=0;

    }
    onDurationChange(event) {
        this.questionCollectionDuration.emit(event);

    }
}
