import { TranslateModule, TranslateLoader, } from '@ngx-translate/core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { PanelModule } from 'primeng/panel';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './shared/components/loader/spinner/spinner.component';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoginModule } from './shared/components/login/login.module';
import { configurationFactory, localizationFactory } from './shared/common/functions';
import { ConfigurationService, CustomHttpInterceptor, LocalizationService } from './core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { SpinnerInterceptorService } from './shared/components/loader/spinner-interceptor.service';
import { MessageService } from 'primeng/api';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { ApplicantWelcomeModule } from './shared/components/applicant-welcome/applicant-welcome.module';
import { SignupModule } from './shared/components/signup/signup.module';

import { registerLocaleData } from '@angular/common';
import localeTR from '@angular/common/locales/tr';
import { ResetPasswordModule } from './shared/components/reset-password/reset-password.module';
import { LandingModule } from './shared/components/landing/landing.module';
import { TimelineModule } from 'primeng/timeline';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { ApplicantModule } from './vinter/components/applicant/applicant.module';
import { RegisterModule } from './shared/components/register/register.module';
import { LoginWithKeyComponent } from './shared/components/login-with-key/login-with-key.component';

registerLocaleData(localeTR);

export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    PrivacyPolicyComponent,
    SpinnerComponent,
    LoginWithKeyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppLayoutModule,
    PanelModule,
    ButtonModule,
    ToastModule,
    ProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoginModule,
    ApplicantWelcomeModule,
    SignupModule,
    RegisterModule,
    ResetPasswordModule,
    CommonModule,
    ApplicantModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    LandingModule,
    TimelineModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      deps: [HttpClient, ConfigurationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: localizationFactory,
      deps: [HttpClient, LocalizationService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorService, multi: true },
    {provide: APP_BASE_HREF, useValue: '/'},

    MessageService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
