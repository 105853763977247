<div class="flex gap-3 flex-wrap mt-3">
    <div class="flex-nowrap flex gap-2 align-items-center" *ngIf="questionPageHiddenLog > 0">
        <img src="assets/vinter/icons/cheating-icon.svg">
        <p class="font-medium page-hidden">{{"Key.SwitchTab" | translate: {value: questionPageHiddenLog } }}</p>
    </div>
    <div *ngIf="answer?.isFaceDetected && answer?.isAllowedDifferentFaceAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-icon.svg" class="detect-img">
        <p class="font-medium face-detected">{{"Key.FaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.isMultiFaceDetected && answer?.isAllowedMultipleFaceAnalysis" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/multiface-detected-icon.svg" class="detect-img">
        <p class="font-medium multiface-detected">{{"Key.MultiFaceDetected" | translate }}</p>
    </div>
    <div *ngIf="answer?.faceDetectionStatus === EFaceDetectionStatus.VerifiedAndNonFace ||
     answer?.faceDetectionStatus === EFaceDetectionStatus.UnverifiedAndNonFace" class="flex-nowrap flex gap-2 align-items-center">
        <img src="assets/vinter/icons/face-detection-status-icon.svg" class="detect-img">
        <p class="font-medium impropriety-speech">{{"Key.FaceDetectionStatus" | translate }}</p>
    </div>
</div>

<div class="flex">
    <img src="assets/vinter/icons/text-questioncoktan-secmeli-cevap-icon-arrow-back-up.svg">
    <h5 class="text-justify answer-title font-semibold text-lg ml-2">
        {{"Key.Answer" | translate}}
    </h5>
</div>

<div class="ml-5 flex" *ngIf="answer === null">
    <img src="assets/vinter/icons/not-answered.svg">
    <p class="ml-2 empty-answer-text">{{"Key.QuestionNotAnswered" | translate}}</p>
</div>

<div *ngIf="answer?.answerContent ?? false" class="answer-container lg:ml-4 grid">
    <div class="col-12 md:col-12 lg:col-6 lg:px-2">
        <div class="answer-text-container overflow-y-auto p-3 border-1 border-solid border-gray-50 border-round-lg">
            <span *ngFor="let word of erroredDataText" [style.background-color]="word.class" class="question-answer line-height-3"
                tooltipPosition="top"
                [ngClass]="{'text-white errored-text': word.isError,'whiteSpace': word.word.trim() === ''}"
                (mouseover)="errorPopup(word, op,$event)" (mouseleave)="op.hide()"
                tooltipStyleClass="pronunciationTooltip">
                {{ word.word }}
            </span>
            <p-overlayPanel #op>
                <ng-template pTemplate="content">
                    <div class="flex flex-wrap">
                        <div *ngFor="let error of errorPopupData" class="w-full">
                            <span class="text-gray-600 text-s font-semibold">{{'Key.'+error.errorCategory |
                                translate}}</span>
                            <p class="text-gray-600 text-xs mb-1">
                                {{"("+error.ruleID+")"}}</p>
                            <p class="text-red-500 text-xs line-through font-italic mb-0 mt-3">
                                {{error.wrongText}}</p>
                            <p class="text-blue-500 text-xs mb-0">{{error.description}}</p>
                        </div>
                    </div>
                </ng-template>
            </p-overlayPanel>
        </div>
    </div>
    
    <div class="answer-area col-12 md:col-12 lg:col-6 lg:pl-2 flex flex-wrap">
            <div class="w-full">
                <span class="text-lg font-semibold text-gray-900 ml-3 mb-2"> {{'Key.Errors' | translate}}</span>
                <ng-container *ngIf="isErrorNotFound">
                    <div class="flex w-h-fill-error absolute z-5 align-items-center ">
                        <div class="col-3">
                        </div>
                        <div
                            class="col-6 border-round-md h-10rem p-5 bg-white flex align-items-center justify-content-center flex-wrap ">
                            <img src="assets/vinter/icons/text-grammar-error-regular.svg" class="grammar-error-icon-1">
                            <span class="text-gray-700 font-bold text-center ">
                                {{'Key.ErrorNotFound' | translate}}</span>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </ng-container>
                <div class="w-full flex" [ngClass]="isErrorNotFound ? 'blur':'' "
                    *ngFor="let item of filterErrorArray">
                    <div class="col-10 flex pb-2 gap-1">
                        <p-badge class="pr-1" [ngClass]="item.label" [value]="item.number"></p-badge>
                        <p class="font-semibold" [ngClass]="item.isSelected ? 'text-gray-700':'text-gray-400'">
                            {{'Key.'+item.label | translate}}</p>
                    </div>
                    <div class="col-2 pb-2 flex justify-content-center align-items-center">
                        <p-inputSwitch [(ngModel)]="item.isSelected" (onChange)="getErroredAnswerText()">
                        </p-inputSwitch>
                    </div>
                </div>
            </div>

            <div class="col-12 align-content-center custom-display flex flex-wrap border-round-lg border-1 border-solid border-blue-50 mt-5">
                    <div class="flex w-full" [ngClass]="isWritingAnalysisEmpty ? 'blur':'' ">
                        <div class="col-6 pl-0 pr-0 text-lg font-semibold text-gray-900">
                            {{'Key.WritingScore' | translate}}
                        </div>
                        <div class="col-6 flex align-items-center justify-content-end gap-2 white-space-nowrap">
                            <p-progressBar class="w-full"
                                [value]="answer?.writingAnalysis ?? false ? answer?.writingAnalysis?.finalScore : 0"
                                [showValue]="false"></p-progressBar>
                            <span class="font-bold text-blue-700 text-xs">{{(answer?.writingAnalysis?.finalScore ?? false) ? answer?.writingAnalysis?.finalScore?.toFixed(0):0}}
                            </span>
                        </div>
                    </div>
                    <div class="w-full flex align-items-center justify-content-center">
                        <div *ngIf="this.answer?.answerContent.gptEvalStatus === gptEvalStatusEnum.Retry" 
                            class="retry-container border-2 border-solid w-15rem h-13rem bg-white w-h-fill absolute z-5 align-items-center">
                            <div class="grid">
                                <div class="col-12 flex justify-content-center">
                                    <img src="../../../../../assets/vinter/icons/gpt-sleep.svg" class="w-4rem pt-3" >
                                </div>
                                <div class="col-12 flex justify-content-center text-gray-700 font-medium pt-0 text-center">
                                    {{"Key.GptRetryMessage" | translate}}
                                </div>
                                <div class="col-12 flex justify-content-center">
                                    <img src="../../../../../assets/vinter/icons/retry-icon.svg" (click)="setGptStatus()" class="w-1rem mr-2 cursor-pointer">
                                    <p class="text-blue-500 cursor-pointer" (click)="setGptStatus()">{{"Key.Retry" | translate}}</p>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="this.answer?.answerContent.gptEvalStatus !== gptEvalStatusEnum.Retry && isWritingAnalysisEmpty" class="flex w-h-fill absolute z-5 align-items-center">
                            <div class="col-4">
                            </div>
                            <div
                                class="col-4 w-h-min p-5 bg-white flex align-items-center justify-content-center flex-wrap ">
                                <img src="assets/vinter/icons/text-grammar-error-regular.svg" class="grammar-error-icon-2">
                                <span class="text-gray-700 font-bold text-center pt-2">
                                    <div [ngSwitch]="answer?.answerContent?.gptEvalStatus">
                                        <div *ngSwitchCase="gptEvalStatusEnum.ClientFail">
                                            {{ answer?.answerContent.aiFinalLog }}
                                        </div>
                                        <div *ngSwitchCase="gptEvalStatusEnum.Fail">
                                            {{"Key.GptFailStatusMessage" | translate}}
                                        </div>
                                        <div *ngSwitchCase="gptEvalStatusEnum.Waiting">
                                            {{"Key.WaitForAIComment" | translate}}
                                        </div>
                                        <div *ngSwitchCase="gptEvalStatusEnum.VideoFail">
                                            {{"Key.WaitForAIComment" | translate}}
                                        </div>
                                        <div *ngSwitchDefault>
                                            {{'Key.ErrorNotFound' | translate}}
                                        </div>

                                    </div>
                                </span>
                            </div>
                            <div class="col-4"></div>
                        </div>
                        <p-accordion [ngClass]="isWritingAnalysisEmpty ? 'blur':'' " class="w-full"
                            [activeIndex]="0">
                            <ng-container *ngFor="let item of writingSubtitles; let i = index">
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="flex align-items-center w-full">
                                            <div class="flex align-items-center col-6 gap-2">
                                                <span class="text-sm text-gray-700 font-medium">{{item.title}}</span>
                                                <img src="assets/vinter/icons/info-fill.svg" [pTooltip]="item.info" class="info-fill-icon">
                                            </div>
                                            <div class="flex align-items-center justify-content-end col-6 gap-2 white-space-nowrap">
                                                <p-progressBar class="w-full progress-sub"
                                                    [value]="item?.score ?? false ? item?.score:0"
                                                    [showValue]="false"></p-progressBar>
                                                <span class="font-bold text-xs text-blue-700">{{item?.score?.toFixed(0)}}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <p class="m-0 text-gray-500 text-sm text-justify">
                                            {{item?.evalText}}
                                        </p>
                                    </ng-template>
                                </p-accordionTab>
                            </ng-container>
                        </p-accordion>
                    </div>
            </div>
    </div>
</div>
