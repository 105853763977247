<div class="layout-topbar" [style.display]="display">
    <div class="topbar-start">
        <button #menubutton type="button" class="buttonFont topbar-menubutton p-link p-trigger"
        [ngStyle]="{'display': (menuButtonDisplay) ? '' : 'none'}"  (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <div *ngIf="userGroupCode === userGroupCodeEnum.APPLICANT" class="pl-4">
            <a [routerLink]="['/my-positions/list']">
                <img src="../../assets/vinter/images/vinter_topbar_logo.svg" style="height:3rem;" viewBox="0 0 124 22" fill="none">
            </a>
        </div>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <!-- <li class="topbar-search">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search" class="w-12rem sm:w-full">
                </span>
            </li>
            <li class="ml-3 mr-0">
                <button pButton type="button" icon="pi pi-cog" class="p-button-text p-button-secondary p-button-rounded flex-shrink-0" (click)="onConfigButtonClick()"></button>
            </li> -->
            <li class="topbar-notification">
                <div class="border-1 border-circle border-gray-50 cursor-pointer" (click)="openNotifications()"
                [ngClass]="{'mr-3': !isShowLogOut}">
                    <p-avatar icon="pi pi-bell" styleClass="cursor-pointer" size="large" shape="circle" [style]="{ 'background-color': '#FFFFFF'}"/>
                    <p-badge *ngIf="notificationVisible" class="absolute notification-badge"></p-badge>
                </div>
                <p-overlay [(visible)]="notificationOverlay" contentStyleClass="surface-overlay shadow-2 border-round" class="topbar-card">
                    <div class="flex justify-content-between pr-3 pt-3 pl-3">
                        <p class="text-gray-700 font-semibold text-sm">{{"Key.Notifications" | translate}}</p>
                        <p *ngIf="userNotifiactions.length != 0" class="text-xs font-medium text-blue-500 cursor-pointer" (click)="seeAllNotifications()">{{"Key.SeeAll" | translate}}</p>
                    </div>
                    <hr class="m-0 border-gray-100">
                    <div *ngIf="notificationSkeleton" class="p-3">
                        <p-skeleton height="5rem" styleClass="w-full mb-3" />
                        <p-skeleton height="5rem" styleClass="w-full" />
                    </div>
                    <div *ngIf="!notificationSkeleton && userNotifiactions.length != 0">
                        <div class="" *ngFor="let notification of userNotifiactions" [ngClass]="{'green-background': notification.status == userNotificationStatus.NotRead}">
                            <div class="flex flex-nowrap p-3 cursor-pointer" (click)="redirectToDestinationUrl(notification.urlPath)">
                                <div class="flex align-items-center">
                                    <p-avatar [image]="notification.iconPath" styleClass="mr-3 " size="large" shape="circle" />
                                </div>
                                <div class="custom-max-width pr-3">
                                    <span class="text-sm font-semibold text-gray-900" [innerHTML]="notification.text"></span>
                                    <span class="text-xs font-medium text-gray-500 ml-2">{{notification.createdAt | date:'dd/MM/yyyy'}}</span>
                                </div>
                                <div class="flex justify-content-end align-items-center">
                                    <p-badge *ngIf="notification.status == userNotificationStatus.NotRead" class="green-notification-badge" severity="success"></p-badge>
                                </div>
                            </div>
                            <hr class="m-0 border-gray-100">
                        </div>
                    </div>
                    <div *ngIf="!notificationSkeleton && userNotifiactions.length == 0" class="flex justify-content-center flex-wrap my-5">
                        <p-image src="../../assets/vinter/icons/no_notification-circle.png"  alt="Image" />
                        <div class="text-gray-900 font-semibold text-sm w-full flex justify-content-center mt-3">{{"Key.NoNotification" | translate}}</div>
                    </div>
                </p-overlay>
            </li>
            <div *ngIf="profileVisibility">

            <li class="topbar-profile">
                <p-button styleClass="buttonFont p-button p-button-text text-primary flex-row-reverse" class="topBarButtonLabel" (click)="toggle()" [label]="userName">
                    <p-avatar *ngIf="imageUrl"  [image]="imageUrl" styleClass="mr-2" size="large" shape="circle"></p-avatar>
                    <p-avatar *ngIf="!imageUrl" class="responsibleAvatar" [label]="userChar" shape="circle" styleClass="border-2 surface-border surface-ground"></p-avatar>
                </p-button>
                <p-overlay [(visible)]="overlayVisible"
                    contentStyleClass="p-4 surface-overlay shadow-2 border-round" class="topbar-card">
                    <a class="w-full flex topbar-profile-text" href="/user/profile" [ngClass]="{'mb-3': isShowLogOut}"><i class="pi pi-user"></i>{{"Key.Profile" | translate}}</a>
                    <a href="/logout" *ngIf="isShowLogOut" class="log-out-icon"><i class="pi pi-sign-out"></i>{{"Key.SignOut" | translate}}</a>
                </p-overlay>
            </li>
            </div>

            <div *ngIf="!profileVisibility && isShowLogOut">
                <div class="p-4">
                <a href="/logout" class="log-out-icon"><i class="pi pi-sign-out"></i>{{"Key.SignOut" | translate}}</a>
                </div>
            </div>
        </ul>
    </div>
</div>
<p-toast position="top-right" key="notificationToast">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-nowrap">
            <div class="flex align-items-center">
                <p-avatar [image]="message.summary" styleClass="mr-3 " size="large" shape="circle" />
            </div>
            <div class="custom-max-width pr-3">
                <span class="text-sm font-semibold text-gray-900" [innerHTML]="message.detail"></span>
            </div>
            <!-- <div class="flex justify-content-end align-items-center">
                <p-badge class="green-notification-badge" severity="success"></p-badge>
            </div> -->
        </div>
    </ng-template>
</p-toast>
