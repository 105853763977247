import { Component, Input, OnInit, Pipe, PipeTransform, ViewChild, Renderer2, ViewChildren, QueryList, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService, LocalizationService } from '@app/core';
import { ApplicationStepStatus, EQuestionCategories, EQuestionCollectionSubCategory, LanguageEnum, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import * as html2pdf from 'html2pdf.js';
import { SuggestApplicantComponent } from '../suggest-applicant/suggest-applicant.component';
import { ApplicationService } from '@app/vinter/services/application.service';
import { SpinnerHandlerService } from '@app/shared/components/loader/spinner-handler.service';


@Component({
  selector: 'app-applicant-pdf-report',
  templateUrl: './applicant-pdf-report.component.html',
  styleUrls: ['./applicant-pdf-report.component.scss']
})
export class ApplicantPdfReportComponent implements OnInit {

  @Input() positionId;
  @Input() positionTitle;
  @Input() imageURL;
  @Input() imageURLBase64;
  @Input() applicantFirstName;
  @Input() applicantLastName;
  @Input() applicantFullName;
  @Input() applicantEmail;
  @Input() applicantPhoneNumber;
  @Input() linkedinProfileURL;
  @Input() overallRating;
  @Input() applicationStepsData;
  @Input() applicantInvitedPositions;
  @Input() applicationId;
  @Output() dialogVisibiltyEmitter: EventEmitter<void> = new EventEmitter<void>();


  QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
  answeredQuestionsWithId = [];
  languageAnsweredQuestions = [];
  deviceTypes: any[];
  questionCategories: any[];
  questionCollectionCategories: any[];
  EQuestionCategories = EQuestionCategories;
  QuestionCollectionSubCategory = EQuestionCollectionSubCategory;
  QuestionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  lang: string;
  overlayVisible: boolean = false;
  isSwitchedTab: boolean = false;
  isMultipleMonitor: boolean = false;
  isFaceDetectedLog: boolean = false;
  isAllowedFaceDetectedLog: boolean = false;
  isAllowedMultipleFaceLog: boolean = false;
  isMultipleFaceLog: boolean = false;
  isImproprietySpeechLog: boolean = false;
  isPdfOpen: boolean = false;
  isAIPdfOpen: boolean = false;
  aiReportData:any=null;
  isLocked: boolean;
  isCompletedPosition: boolean = false;
  stepCompletedDate: Date;
  currentLanguageId:string;
  LanguageEnum = LanguageEnum;

  showPendingAIReportBtn:boolean=false;
  showRequestAIReportBtn:boolean=false;
  showDownloadAIReportBtn:boolean=false;
  showAiReportAddedNewStepInfo:boolean=false;
  showAiReportPendingInfo:boolean=false;

  @ViewChild(SuggestApplicantComponent) suggestApplicantChild: SuggestApplicantComponent;
  suggestSidebarVisible: any;
  @ViewChild('applicantInfoPdf') private normalPdf: QueryList<any>;
  constructor(
    public translate: LocalizationService,
    public route: ActivatedRoute,
    public applicationService:ApplicationService,
    private spinnerService: SpinnerHandlerService,
    public router: Router,
    private appInfoService: AppInfoService,
    private renderer: Renderer2,
    private credentialsService: CredentialsService) { }

  ngOnInit(): void {
    this.currentLanguageId = this.credentialsService.getLanguage();
    this.lang = this.translate.langCode;
    this.deviceTypes = this.appInfoService.getDeviceTypes();
    this.questionCategories = this.appInfoService.getQuestionCategories();
    this.questionCollectionCategories = this.appInfoService.getQuestionCollectionCategoryArray();
    this.calculateAnsweredQuestions();
    this.getCheatingAnalysis();
    this.isLocked = this.credentialsService.getIsLocked();
    this.getAIBasedStepReportButtonStatus();
    this.getCompletedDatePosition();
  }

  suggestApplicant(){

    this.suggestApplicantChild.sidebarVisible = true;
    this.suggestApplicantChild.ngOnInit();

  }

  getCompletedDatePosition(){
    const stepsWithId = this.applicationStepsData.filter(step => step.id);
    this.isCompletedPosition =  stepsWithId.every(step => step.completedDate !== null);

    if(this.isCompletedPosition){
      this.stepCompletedDate = stepsWithId[stepsWithId.length - 1].completedDate;
    }
  }

  suggestedApplicant(event: any){
    this.suggestSidebarVisible = event;
  }

  isCompletedAnyApplicationStepStatus(applicationStepList:any[]):boolean{
    const found = applicationStepList.find(applicationStep => applicationStep.status === ApplicationStepStatus.ApplicantCompleted || applicationStep.status === ApplicationStepStatus.HRCompleted);
    return !!found;
  }

  completedStepTime(start, complete){
    const startDate = new Date(start);
    const completeDate = new Date(complete);

    let timeDiff = Math.abs(completeDate.getTime() - startDate.getTime());
    const differenceInMinutes = timeDiff / (1000 * 60);
    return differenceInMinutes;
  }

  calculateLanguageQuestionsCount(data: any) {

    let categoryStats = {
      Listening: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Reading: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Grammer: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Vocabulary: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Writing: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      Speaking: { correct: 0, incorrect: 0, total: 0, positionStepId:'' }
    };

    data.positionStep.questionCollection.questionCollectionQuestionRelations.forEach(element => {
      const categoryKey = Object.keys(EQuestionCategories).find(key => EQuestionCategories[key] === element.question.questionCategory);
      if (categoryKey) {
        categoryStats[categoryKey].total++;
        categoryStats[categoryKey].positionStepId = data.positionStep.id;
        if (element.question.isAnswered && element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.MultipleChoice) {
          if (element.question.gptScore === 100) {
            categoryStats[categoryKey].correct++;
          } else {
            categoryStats[categoryKey].incorrect++;
          }
        }
        if (element.question.isAnswered && element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.OpenEnded) {
          categoryStats[categoryKey].correct += element.question.gptScore;
        }
      }

    });

    if (categoryStats.Grammer.total > 0) {
      Object.keys(categoryStats).forEach(category => {
        const stats = categoryStats[category];
        const categoryObject = this.questionCategories.find(item => item.name === this.translate.translateText(category));
        const categoryId = categoryObject ? categoryObject.id : null;

        this.languageAnsweredQuestions.push({
          id: categoryId,
          category: category,
          positionStepId: stats.positionStepId,
          questionCount: stats.total,
          answeredQuestionsCount: stats.correct + stats.incorrect,
          notAnsweredQuestionsCount: stats.total - (stats.correct + stats.incorrect),
          correctAnsweredQuestionsCount: stats.correct,
          incorrectAnsweredQuestionsCount: stats.incorrect,
          averageScores: stats.correct / stats.total,
          totalScores: stats.correct*100
        });
      });
    }
  }
  
  calculateAnsweredQuestions() {
    let categoryStats = {
      TechnicalTest: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      CognitiveAbility: { correct: 0, incorrect: 0, total: 0, positionStepId:'' },
      CompetenceTest: { correct: 0, incorrect: 0, total: 0, positionStepId:'' }
    };

    this.applicationStepsData.forEach(data => {

      if (data?.positionStep?.questionCollection?.questionCollectionCategory === QuestionCollectionCategoryEnum.TechnicalTest ||
        data?.positionStep?.questionCollection?.questionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility ||
        data?.positionStep?.questionCollection?.questionCollectionCategory === QuestionCollectionCategoryEnum.CompetenceTest) {

        data?.positionStep?.questionCollection?.questionCollectionQuestionRelations.forEach(element => {
          const categoryKey = Object.keys(QuestionCollectionCategoryEnum).find(key => QuestionCollectionCategoryEnum[key] === data?.positionStep?.questionCollection?.questionCollectionCategory);
          if (categoryKey) {
            if (element.question.isAnswered && element.question.expectedAnswerType === this.QuestionExpectedAnswerTypeEnum.MultipleChoice) {
              categoryStats[categoryKey].total++;
              categoryStats[categoryKey].positionStepId=data.positionStep.id;
              if (element.question.gptScore === 100) {
                categoryStats[categoryKey].correct++;
              }
              else {
                categoryStats[categoryKey].incorrect++;
              }
            }
          }
        });

      }

      if (data?.positionStep?.questionCollection?.questionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage) {
        this.calculateLanguageQuestionsCount(data);
      }
    });

    if (categoryStats.TechnicalTest.total > 0 || categoryStats.CognitiveAbility.total > 0 || categoryStats.CompetenceTest.total > 0) {

      Object.keys(categoryStats).forEach(category => {
        const stats = categoryStats[category];
        const categoryObject = this.questionCollectionCategories.find(item => item.name === this.translate.translateText(category));
        const categoryId = categoryObject ? categoryObject.id : null;
        this.answeredQuestionsWithId.push({
          id: categoryId,
          category: category,
          questionCount: stats.total,
          positionStepId:stats.positionStepId,
          answeredQuestionsCount: stats.correct + stats.incorrect,
          notAnsweredQuestionsCount: stats.total - (stats.correct + stats.incorrect),
          correctAnsweredQuestionsCount: stats.correct,
          incorrectAnsweredQuestionsCount: stats.incorrect,
          totalScores: stats.correct*5
        });
      });
    }

  }

  getCurrentStepQuestions(id, stepId) {
    return this.languageAnsweredQuestions.find(x => x.id === id && x.positionStepId === stepId);
  }

  getCheatingAnalysis() {
    this.isSwitchedTab = this.applicationStepsData.some(x => x.isSwitchedTab === true);
    this.isMultipleMonitor = this.applicationStepsData.some(x => x.isMultipleMonitor === true);
    this.isAllowedFaceDetectedLog = this.applicationStepsData.some(x => x.isAllowedFaceDetectedLog === true);
    this.isAllowedMultipleFaceLog = this.applicationStepsData.some(x => x.isAllowedMultipleFaceLog === true);
    this.isFaceDetectedLog = this.applicationStepsData.some(x => x.isFaceDetectedLog === true);
    this.isMultipleFaceLog = this.applicationStepsData.some(x => x.isMultipleFaceLog === true);
    this.isImproprietySpeechLog = this.applicationStepsData.some(x => x.isImproprietySpeechLog === true);
  }

  onButtonClick() {
    this.router.navigate(['/position/detail', this.positionId]);
  }

  toggle() {
    this.overlayVisible = !this.overlayVisible;
  }

  onBeforeShowHandler(event) {
    let parentElement = event.element.parentElement;
    const computedStyle = window.getComputedStyle(parentElement);
    const currentLeft = parseInt(computedStyle.left, 10);
    this.renderer.setStyle(parentElement, 'left', `${currentLeft - 50}px`);
  }

  getHiddenElementHeight(parentId, elementId) {
    const parentElement = document.getElementById(parentId);
    const element = document.getElementById(elementId);

    const originalDisplay = parentElement.style.display;

    parentElement.style.display = 'block';
    parentElement.style.visibility = 'hidden';
    parentElement.style.position = 'absolute';

    const height = element.offsetHeight;

    parentElement.style.display = originalDisplay;
    parentElement.style.visibility = '';
    parentElement.style.position = '';

    return height;
  }


  generatePDF() {
    this.isPdfOpen = true;
    setTimeout(() => {
        const element = document.getElementById('applicantInfoPdf');
        const childHeight = this.getHiddenElementHeight('parentDivId', 'applicantInfoPdf');

        const opt = {
          margin: [10, 10, 10, 10],
          filename: 'Report_' + this.applicantFullName + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          pagebreak: { avoid: "#area", mode: "css" },
          html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true, allowTaint: true, useCORS: true },
          jsPDF: { unit: 'px', format: [595, 842], orientation: 'portrait' },
        };

        html2pdf().from(element).set(opt).save();
        this.isPdfOpen = false;
    }, 0);
  }

  generateSummaryPDF() {
    this.isPdfOpen = true;
    setTimeout(() => {
        const element = document.getElementById('applicantInfoSummaryPdf');
        const opt = {
        margin: [10, 10, 10, 10],
        filename: 'Summary_Report_' + this.applicantFullName + '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        pagebreak: { avoid: "#area", mode: "css" },
        html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true, allowTaint: true, useCORS: true },
        jsPDF: { unit: 'px', format: [595, 842], orientation: 'portrait' },
    };

        html2pdf().from(element).set(opt).save();
        this.isPdfOpen = false;
    }, 0);

  }

  checkLicenseLockAndGeneratePdf(){
    if(this.isLocked){
      this.dialogVisibiltyEmitter.emit();
    }
    else{
      this.generatePDF();
    }
  }

  checkLicenseLockAndGenerateSummaryPdf(){
    if(this.isLocked){
      this.dialogVisibiltyEmitter.emit();
    }
    else{
      this.generateSummaryPDF();
    }
  }

  checkLicenseLockAndRequestAIBasedPdf(){
    if(this.isLocked){
      this.dialogVisibiltyEmitter.emit();
    }
    else{
      this.applicationService.AIBasedReportRequest({ id: this.applicationId }).subscribe(x=>{
        this.toggleShowAiReportPendingInfo();
        this.getAIBasedStepReportButtonStatus();
      })
    }
  }


  getAIBasedStepReportButtonStatus(){
    this.applicationService.GetAIBasedStepReportButtonStatus({ id: this.applicationId }).subscribe(x=>{
      this.showPendingAIReportBtn=x.showPending;
      this.showRequestAIReportBtn=x.showRequest;
      this.showDownloadAIReportBtn=x.showDownload;
    })
  }

  
  checkLicenseLockAndDownloadAIBasedPdf(){

    if(this.isLocked){
      this.dialogVisibiltyEmitter.emit();
    }
    else{
      this.applicationService.GetAIBasedStepReport({ id: this.applicationId }).subscribe(reportData=>{

        if(!reportData.isNewStepReportAdded){
          this.aiReportData=reportData;

          for (let index = 0; index < this.applicationStepsData.length; index++) {
            let r=reportData?.steps?.find(x=>x.applicationStepId==this.applicationStepsData[index].id)
            if(r){
              this.applicationStepsData[index].reportText=r.reportText;
              this.applicationStepsData[index].currentOrder=r.currentOrder;
              this.applicationStepsData[index].currentApplicantCount=r.currentApplicantCount;
            }
            else{
              this.applicationStepsData[index].reportText=null;
              this.applicationStepsData[index].currentOrder=null;
              this.applicationStepsData[index].currentApplicantCount=null;
            }
          }

          this.isAIPdfOpen = true;
          setTimeout(() => {
              const element = document.getElementById('applicantAIBasedPdf');
              const opt = {
              margin: [10, 10, 10, 10],
              filename: 'AIBased_Report_' + this.applicantFullName + '.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              pagebreak: { avoid: "#area", mode: "css" },
              html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true, allowTaint: true, useCORS: true },
              jsPDF: { unit: 'px', format: [595, 842], orientation: 'portrait' },
          };
              html2pdf().from(element).set(opt).save();
              this.isAIPdfOpen = false;
          }, 0);
        }
        else{
          this.aiReportData=null;
          this.toggleShowAiReportAddedNewStepInfo();
        }
        

        this.getAIBasedStepReportButtonStatus();
      })
    }
  }

  toggleShowAiReportAddedNewStepInfo(){
    this.showAiReportAddedNewStepInfo=!this.showAiReportAddedNewStepInfo;
  }

  toggleShowAiReportPendingInfo(){
    this.showAiReportPendingInfo=!this.showAiReportPendingInfo;
  }
  

}

@Pipe({
  name: 'deviceTypes',
})
export class DeviceTypePipe implements PipeTransform {
  transform(row: any, list: any[]): any {
    return list.find(x => x.id === row).name;
  }
}
