import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicensePlanDialogComponent } from '@app/shared/components/license-plan-dialog/license-plan-dialog.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { LicensePlanComparePricingComponent } from '@app/shared/components/license-plan-dialog/license-plan-compare-pricing/license-plan-compare-pricing.component';
import { TableModule } from 'primeng/table';
import { LicensePlanPaywallComponent } from '@app/shared/components/license-plan-paywall/license-plan-paywall.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';



@NgModule({
  declarations: [
    LicensePlanDialogComponent,
    LicensePlanPaywallComponent,
    LicensePlanComparePricingComponent
  ],
  imports: [
    CommonModule,
    RadioButtonModule,
    InputSwitchModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    SkeletonModule,
    DialogModule,
    TableModule
  ],
  exports: [
    LicensePlanDialogComponent,
    LicensePlanPaywallComponent,
    LicensePlanComparePricingComponent
  ]
})
export class SharedLicensePlanDialogVinterModule { }
