<div class="logo"></div>
<div
    class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden background"
>
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
        <div class="col-12 xl:col-5">
            <div class="h-full w-full m-0 py-5 px-5 loginCard">
                <div class="w-full md:w-10 mx-auto">
                    <div class="header-container mb-4">
                        <div class="logo-container">
                            <div>
                                <img
                                    data-cy="image-logo"
                                    src="{{ vinterLogoWithoutTextPath }}"
                                    alt="Vinter logo"
                                    class="w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div
                            data-cy="label-page-title-login"
                            class="pageTitleLogin"
                        >
                            {{ "Key.Welcome" | translate }}
                        </div>
                        <p
                            data-cy="label-subtitle-text-login-notoken"
                            class="subTitleTextLogin"
                            [ngClass]="{ hidden: !hasToken }"
                        >
                            {{ "Key.PasswordDescription" | translate }}
                        </p>
                        <p
                            data-cy="label-subtitle-text-login-token"
                            class="subTitleTextLogin"
                            [ngClass]="{ hidden: hasToken }"
                        >
                            {{ "Key.ResetPasswordDescription" | translate }}
                        </p>
                    </div>

                    <form *ngIf="!hasToken" [formGroup]="mailForm" class="form">
                        <div class="p-fluid w-full pt-4">
                            <div class="flex mb-2 align-items-center">
                                <i class="pi pi-envelope"></i>
                                <label
                                    data-cy="label-text-title-has-notoken"
                                    class="text-title"
                                    >{{ "Key.Mail" | translate }}</label
                                >
                                <div
                                    data-cy="indicator-error-message-notoken"
                                    class="error-message flex"
                                >
                                    <p
                                        *ngIf="
                                            mailForm.controls.mail.touched &&
                                            mailForm.controls.mail.errors
                                                ?.required
                                        "
                                    >
                                        *
                                    </p>
                                </div>
                            </div>
                            <input
                                data-cy="input-mail-notoken"
                                type="mail"
                                pInputText
                                formControlName="mail"
                                required
                            />
                        </div>
                        <button
                            data-cy="button-reset-password-notoken"
                            pButton
                            [disabled]="mailForm.invalid"
                            pRipple
                            label="{{ 'Key.Next' | translate }}"
                            class="buttonFontLogin bg-indigo-500 w-full p-3 login-Button"
                            style="margin-top: 30px"
                            (click)="resetPassword()"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                        ></button>
                    </form>
                    <form *ngIf="hasToken" [formGroup]="passForm" class="form">
                        <div class="p-fluid w-full pt-3">
                            <div *ngIf="hasToken" class="">
                                <div class="flex mb-2">
                                    <label
                                        data-cy="label-text-title-token"
                                        class="text-title"
                                        >{{ "Key.Password" | translate }}</label
                                    >
                                    <div
                                        data-cy="indicator-error-message-token"
                                        class="error-message flex"
                                    >
                                        <p
                                            class="line-height-1"
                                            *ngIf="
                                                passForm.controls.password
                                                    .touched &&
                                                passForm.controls.password
                                                    .errors?.required
                                            "
                                        >
                                            *
                                        </p>
                                    </div>
                                </div>
                                <p-password
                                    data-cy="input-password-token"
                                    required
                                    formControlName="password"
                                    [toggleMask]="true"
                                    [feedback]="false"
                                ></p-password>
                            </div>
                        </div>
                        <button
                            data-cy="button-reset-password-button"
                            pButton
                            [disabled]="passForm.invalid"
                            pRipple
                            label="{{ 'Key.Next' | translate }}"
                            class="buttonFontLogin bg-indigo-500 w-full p-3 login-Button"
                            style="margin-top: 30px"
                            (click)="resetPassword()"
                            icon="pi pi-arrow-right"
                            iconPos="right"
                        ></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-center"></p-toast>
