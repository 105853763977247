import { AuthenticationService } from '@app/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@app/vinter/services/application.service';
@Component({
    selector: 'app-applicant-detail-v2',
    templateUrl: './applicant-detail-v2.component.html',
    styleUrls: ['./applicant-detail-v2.component.scss']
})
export class ApplicantDetailV2Component implements OnInit {

    applicationId: string;

    constructor(public route: ActivatedRoute,
        public applicationService: ApplicationService,
        public authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(params => {
            this.applicationId = params.get('applicationId');
            if (this.applicationId == null) {
                var cryptedData = params.get('crypted');
                this.applicationService.GetDecryptedResultPageApplication({ cryptedData: cryptedData }).subscribe(resp => {
                    this.authenticationService.setEncryptedCredentials(resp.accessToken);
                    this.applicationId = resp.applicationId;
                });
            }
        });
    }

}
