import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';
import { UserNotificationItem } from '../models/user-notifiaction/user-notification-item';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.UserNotification, notifyServ, localizationService, configService);
  }

  getUserNotifications(filter: any): Observable<UserNotificationItem[]> {
    return this.postWithoutSpinner("GetUserNotifications", filter, true);
  }

  setUserNotificationsToRead(): Observable<any> {
    return this.postWithoutSpinner("SetUserNotificationsToRead", true);
  }
}
