import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EApplicantBehaviourEventType, EFaceDetectionStatus, EntityStateEnum, GPTEvalStatus, ProcessStatus } from '@app/shared/common/enums';
import { AnswerContentFormItem } from '@app/vinter/models/answer-content-form-item';
import { PronunciationVideoAnswerItem } from '@app/vinter/models/answer/pronunciation-video-answer-item';
import { AnswerContentService } from '@app/vinter/services/answer-content.service';
import { ApplicantBehaviourEventLogService } from '@app/vinter/services/applicant-behaviour-event-log.service';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'app-pronunciation-video-answer',
  templateUrl: './pronunciation-video-answer.component.html',
  styleUrls: ['./pronunciation-video-answer.component.scss']
})
export class PronunciationVideoAnswerComponent implements OnInit {
  @Input() answer: PronunciationVideoAnswerItem;
  gptEvalStatusEnum = GPTEvalStatus;
  mispronunciationCount = 0;
  omissionCount = 0;
  insertionCount = 0;
  pronunciationWords = [];
  showMispronunciation: boolean = true;
  showOmission: boolean = true;
  showInsertion: boolean = true;
  processStatusEnum = ProcessStatus;
  pronunciationArray: any[] = [];
  EFaceDetectionStatus = EFaceDetectionStatus
  @ViewChild('pronunciationVideo') pronunciationTarget: ElementRef;
  @ViewChild('pronunciationDiv') pronunciationDiv: ElementRef;
  questionPageHiddenLog: number;
  

constructor(public api: VgApiService,
    private answerContentService: AnswerContentService,
    private eventLogService: ApplicantBehaviourEventLogService
){}

ngOnChanges(changes: SimpleChanges): void {
  this.answer;
  if(this.answer){
     this.setPronunciationItems();
     this.setPronunciationEntries(); 
     this.getPageHiddenLog();
  }
}
  ngOnInit(): void {
    this.answer;
    if(this.answer){
       this.setPronunciationItems();
       this.setPronunciationEntries(); 
       this.getPageHiddenLog();
    }
    
  }
  setPronunciationItems(){
    if (this.answer?.answerContentPronunciationAnalysis != null && this.answer?.answerContentPronunciationAnalysis.analysisResultDetail) {
      let all = (JSON.parse(this.answer.answerContentPronunciationAnalysis.analysisResultDetail.toString()));
      let respArray = all.response;
  
      let wordArray = [];
      let wordIndex = 0;
      for (let i = 0; i < respArray.length; i++) {
          const pItem = respArray[i];
          for (let n = 0; n < pItem.NBest.length; n++) {
              const nItem = pItem.NBest[n];
              for (let w = 0; w < nItem.Words.length; w++) {
                  const wItem = nItem.Words[w];
                  let wordObj: any = {};
                  wordObj.index = wordIndex++;
                  wordObj.word = wItem.Word;
                  wordObj.score = wItem.PronunciationAssessment.AccuracyScore;
                  wordObj.errorType = wItem.PronunciationAssessment.ErrorType;
  
  
                  if (wordObj.errorType == "Omission") {
                      wordObj.styleClass = "Omission";
                      this.omissionCount++;
                  }
                  else {
                      wordObj.start = wItem.Offset / 10000000;
                      wordObj.end = wordObj.start + wItem.Duration / 10000000;
                      wordObj.start = wordObj.start.toFixed(2);
                      wordObj.end = wordObj.end.toFixed(2);
  
                      if (wordObj.errorType == "Mispronunciation") {
                          wordObj.styleClass = "Mispronunciation";
                          this.mispronunciationCount++;
                      }
                      else if (wordObj.errorType == "Insertion") {
                          wordObj.styleClass = "Insertion";
                          this.insertionCount++;
                      }
                  }
  
  
                  if (wItem.Phonemes != null) {
                      wordObj.phonemes = wItem.Phonemes.map(wi => (
                          {
                              phoneme: wi.Phoneme,
                              score: wi.PronunciationAssessment.AccuracyScore
                          }
                      ))
                  }
                  wordArray.push(wordObj);
              }
          }
      }
      this.pronunciationWords = wordArray;
  }
  }

  jumpToSecondPronunciation(start: number) {
    if (this.api && start) {
        this.pronunciationTarget.nativeElement.currentTime = start;
        this.highlightPronunciation(this.pronunciationTarget.nativeElement);
        this.pronunciationTarget.nativeElement.play();
    }
}

highlightPronunciation(target: any) {
  this.pronunciationWords.forEach((word, index) => {
      word.index = 'p' + index;
      word.highlighted = target.currentTime >= word.start && target.currentTime < word.end;
  });

  this.highlightPronunciationInView();
}

highlightPronunciationInView() {
  let activeWord = this.pronunciationWords.find(word => word.highlighted);

  if (activeWord) {
      let highlightedElement = this.pronunciationDiv.nativeElement.querySelector(`#p${activeWord.index}`);

      if (highlightedElement) {
          highlightedElement.scrollIntoView({
              behavior: "smooth",
              block: "nearest"
          });
      }
  }
}

getPronunciationTooltip(wordObj) {
  let html = "<div style='display:flex;flex-direction: column;'>" +
      "<div class='col-12' style='padding-top: 0;padding-left: 0;padding-right: 0;'>" +
      "<span style='color: var(--gray-600);font-weight: 700;'>" +
      wordObj.word +
      ":</span>" +
      "<span style='color: var(--blue-500);margin-left: 10px;'>" +
      wordObj.score +
      "</span></div>" +
      "<div class='col-12 flex' style='padding: 0;'>";

  let phonemes = "";
  if (wordObj.phonemes) {
      wordObj.phonemes.forEach(p => {
          phonemes += "<div style='margin-right: 8px;text-align: center;'><div style='margin-bottom: 5px;'>" + p.phoneme + "</div><div style='color: var(--blue-300);'>" + p.score + "</div></div>"
      });
  }
  html += phonemes + "</div>" + "</div>"
  return html;
}

setGptStatus(){
    let answerContentForm : AnswerContentFormItem = new AnswerContentFormItem();
    answerContentForm = this.answer?.answerContent;
    answerContentForm.gptEvalStatus = this.gptEvalStatusEnum.Waiting;
    answerContentForm.processStatus = this.processStatusEnum.AIContainerWaiting;
    answerContentForm.entityState = EntityStateEnum.Modified;
    answerContentForm.xmin = this.answer?.answerContent.xmin;

    this.answerContentService.SaveAsync(answerContentForm).subscribe();
  }

setPronunciationEntries(){
    const analysis = this.answer?.answerContentPronunciationAnalysis || {};
    const allowedKeys = [
      'accuracy',
      'completeness',
      'fluencyDisruptorScore',
      'fluencyScore',
      'fluencyTimeScore'
    ];
    const filteredAnalysis = Object.fromEntries(
      Object.entries(analysis).filter(([key]) => allowedKeys.includes(key))
    );
    this.pronunciationArray = Object.entries(filteredAnalysis)
      .filter(([_, value]) => value != null)
      .map(([name, value]) => ({ name, value }));

    return this.pronunciationArray;
  }

  getPageHiddenLog(){
    let eventLogFilter: any = {};
    eventLogFilter.applicationStepId = this.answer.applicationStepId;
    eventLogFilter.eventType = EApplicantBehaviourEventType.PageHidden;
    eventLogFilter.questionId = this.answer.questionId;
    this.eventLogService.getListByFilter(eventLogFilter).subscribe(eventLog => {
        this.questionPageHiddenLog = eventLog.length;
    });
  }
  
  

}
