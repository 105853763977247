import { Component, Input } from '@angular/core';
import { EQuestionCategories } from '@app/shared/common/enums';

@Component({
  selector: 'app-quick-english-test-card',
  templateUrl: './quick-english-test-card.component.html',
  styleUrls: ['./quick-english-test-card.component.scss']
})
export class QuickEnglishTestCardComponent {
    @Input() step: any;
    @Input() report: any;
    @Input() pageNumber: number = 0;
    
    firstPartReportText: string = '';
    secondPartReportText: string = '';
  
    applicantPercentage: number = 0;

    @Input() languageAnsweredQuestions: any;
  
    questionCategories = EQuestionCategories;
    skillLevel: any[] = [];
  
  
    ngOnInit() {
  
    }
    ngAfterViewInit(): void {
      this.splitReportText();
      this.calculatePercentage();
    }
  
    calculatePercentage(){
      this.applicantPercentage = (this.report.currentOrder / this.report.currentApplicantCount ) * 100;
    }
      
    splitReportText() {
      const maxChars = 350;
      if (this.report.reportText.length > maxChars) {
          let splitIndex = this.report.reportText.lastIndexOf(' ', maxChars);
          if (splitIndex === -1) {
              splitIndex = maxChars;
          }
          this.firstPartReportText = this.report.reportText.slice(0, maxChars);
          this.secondPartReportText = this.report.reportText.slice(maxChars).trim();
      } 
      else {
          this.firstPartReportText = this.report.reportText;
          this.secondPartReportText = '';
      }
    }
  


}
