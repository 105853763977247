import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserConsentComponent } from './user-consent.component';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    UserConsentComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    TranslateModule
  ],
  exports: [
    UserConsentComponent
    ]
})
export class UserConsentModule { }
