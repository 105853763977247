<div class="card p-2 px-0">
    <div style="min-height: 55vh;">
        <div *ngIf="isLoading" class="loading-spinner">
            <p></p>
        </div>
        <p-tabView *ngIf="!isLoading" [scrollable]="true" (onClose)="resetAnswerPage()"
            (activeIndexChange)="changeActiveIndex($event)" [(activeIndex)]="activeIndex">
            <ng-template pTemplate="previousicon">
                <div id="nextButton" class="flex" [ngClass]="checkPrevIconVisibility()"><img
                        class="img-hor-vert align-self-center ml-2 arrow-dimension"
                        src="/assets/vinter/icons/right-arrow-icon.svg" /></div>
            </ng-template>
            <ng-template plate pTemplate="nexticon">
                <div id="nextButton" class="flex"
                    [ngClass]="{'hidden':checkNextIconVisibility()==='hidden', 'flex':checkNextIconVisibility()!=='hidden'}">
                    <img class="align-self-center mr-2 arrow-dimension"
                        src="/assets/vinter/icons/right-arrow-icon.svg" />
                </div>
            </ng-template>

            <ng-container *ngFor="let item of applicationSteps; let i = index">

                <p-tabPanel [headerStyleClass]="'hiring-process'"
                    *ngIf="i === 0 && this.generalApplicationFormId ?? false && generalApplicationFormQuestions.length > 0"
                    header="{{'Key.GeneralApplication' | translate}}">
                    <ng-template pTemplate="header">
                        <span>{{'Key.PreAssessment' | translate}}</span>
                        <div *ngIf="item.generalApplicationAutomationExecutionAt!=null"
                            style="display: flex;justify-content: flex-start;align-items: center;">
                            <img pTooltip="{{'Key.AutomationExecutionAt' | translate}}:{{item.generalApplicationAutomationExecutionAt | date:'dd/MM/yy HH:mm'}}"
                                tooltipPosition="top" width="14px" style="margin-left: 10px;"
                                src="../../../../../assets/vinter/icons/Vector.svg">
                        </div>
                        <div class="outer-ring-completed">
                            <p-chip class="tabview" icon="pi pi-circle-fill"
                                [style]="{'background-color': '#CFF0BF', 'color': '#37AC00'}"></p-chip>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="p-3 pb-0">
                            <div class="w-full flex col-12 p-1 linear-background">
                                <div class="col p-0 flex justify-content-end">
                                    <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted"
                                        class="flex pb-3 p-3 align-items-center"
                                        style="border-radius: 6px; color: #37AC00">

                                        <i class="pi pi-circle-fill" style="color: #37AC00"></i>
                                        <p
                                            class="completed-date-text flex align-items-center justify-content-center mb-0">
                                            {{'Key.Completed' | translate}} </p>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0 ml-1"
                                            style="font-size: 12px;">- {{ item.completedDate | date:'dd.MM.yy' }}</p>
                                    </div>
                                </div>
                            </div>                           
                        </div>

                        <app-general-application-form-result [applicationId]="applicationId"></app-general-application-form-result>
                    </ng-template>
                    
                </p-tabPanel>
                <p-tabPanel [headerStyleClass]="'hiring-process'">
                    <ng-template pTemplate="header">
                        <span>{{item.positionStepTitle}}</span>
                        <div *ngIf="item.automationExecutionAt!=null"
                            style="display: flex;justify-content: flex-start;align-items: center;">
                            <img pTooltip="{{'Key.AutomationExecutionAt' | translate}}:{{item.automationExecutionAt | date:'dd/MM/yy HH:mm'}}"
                                tooltipPosition="top" style="width: 14px;margin-left: 10px;"
                                src="../../../../../assets/vinter/icons/Vector.svg">
                        </div>
                        <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted"
                            class="outer-ring-completed">
                            <p-chip class="tabview" icon="pi pi-circle-fill"
                                [style]="{'background-color': '#CFF0BF', 'color': '#37AC00'}"></p-chip>
                        </div>
                        <div *ngIf="item.status === applicationStepStatus.InProgress || item.status === applicationStepStatus.Ready"
                            class="outer-ring-inProgress">
                            <p-chip class="tabview" icon="pi pi-circle-fill"
                                [style]="{'background-color': '#CFDBF9', 'color': '#2962FF'}"></p-chip>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="p-3">
                            <div *ngIf="item.order !== positionStepKanbanStaticTypes.Offer"
                                class="w-full flex col-12 p-1 px-3 linear-background align-items-center">
                                <div
                                    *ngIf="item.status !== applicationStepStatus.NotStarted && item.status !== applicationStepStatus.Ready && item.status !== applicationStepStatus.InProgress && item.questionCollectionCategory !== questionCollectionCategoryEnum.PersonalityTest">
                                    <app-behavioral-analysis-section [item]="item"> </app-behavioral-analysis-section>
                                </div>
                                <div *ngIf="item.questionCollectionCategory === questionCollectionCategoryEnum.PersonalityTest && item.questionCollectionSubCategory == questionCollectionSubCategory.OCEAN120 && 
                                item.status !== applicationStepStatus.NotStarted && item.status !== applicationStepStatus.Ready && item.status !== applicationStepStatus.InProgress"
                                    class="flex align-items-center">
                                    <p
                                        class="mb-0 text-lg font-semibold flex align-items-center align-self-center justify-content-center mr-2">
                                        {{'Key.AnalysisDetail' | translate}} </p>

                                    <div class="cursor-pointer p-2 mb-0 flex border-1 border-primary-300 border-round bg-white align-items-center"
                                        (click)="viewAnalysisSidebar()">
                                        <span class="mr-2 text-blue-500">{{"Key.View" | translate}}</span>
                                        <img class="flex" src="assets/vinter/icons/eye-outline.svg" width="18px"
                                            height="18px">
                                    </div>
                                </div>

                                <div class="col p-0 flex justify-content-end">
                                    <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted"
                                        class="flex pb-3 p-3 align-items-center"
                                        style="border-radius: 6px; color: #37AC00">

                                        <i class="pi pi-circle-fill" style="color: #37AC00"></i>
                                        <p
                                            class="completed-date-text flex align-items-center justify-content-center mb-0">
                                            {{'Key.Completed' | translate}} </p>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0 ml-1"
                                            style="font-size: 12px;">- {{ item.completedDate | date:'dd.MM.yy' }}</p>

                                        <img *ngIf="item.questionCollectionCategory === questionCollectionCategoryEnum.PersonalityTest && item.isTimesUp"
                                            class="step-ocean-error" pTooltip="{{'Key.TimesUp' | translate}}"
                                            tooltipPosition="top" width="18px" height="18px"
                                            src="../../../../../assets/vinter/icons/message-rounded-error.svg" />


                                    </div>
                                    <div *ngIf=" item.status === applicationStepStatus.Ready"
                                        class="flex pb-3 p-3 align-items-center"
                                        style="border-radius: 6px; color: #2962FF">
                                        <i class="pi pi-circle-fill text-gray-500"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0 text-gray-500"
                                            >{{'Key.StepPending' | translate}} </p>

                                    </div>
                                    <div *ngIf="item.status === applicationStepStatus.InProgress"
                                        [ngClass]="{'pr-0': item.isStepHasError}"
                                        class="flex pb-3 p-3 align-items-center"
                                        style="border-radius: 6px; color: #2962FF">

                                        <i class="pi pi-circle-fill" style="color: #2962FF"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0"
                                            style="color: #2962FF">{{'Key.StepInProgress' | translate}} </p>

                                        <img *ngIf="item.isStepHasError" pTooltip="{{'Key.StepHasError' | translate}}"
                                            tooltipPosition="top" class="step-has-error" width="18px" height="18px"
                                            src="../../../../../assets/vinter/icons/message-rounded-error.svg" />
                                    </div>
                                    <div *ngIf="item.status === applicationStepStatus.NotStarted"
                                        class="flex pb-3 p-3 align-items-center"
                                        style="border-radius: 6px; color: #9B9EA3">
                                        <i class="pi pi-circle-fill" style="color: #9B9EA3"></i>
                                        <p class="completed-date-text flex align-items-center justify-content-center mb-0"
                                            style="color: #9B9EA3">{{'Key.NotStarted' | translate}} </p>

                                    </div>
                                </div>
                                <div *ngIf="this.applicationStepId === this.applicationSteps[i].id && item.questionCollectionCategory !== this.questionCollectionCategoryEnum.Custom
                                    && checkRedoStepVisible(item)"
                                    class="col-4 ml-3 w-auto flex justify-content-end height-fit-content flex align-items-center redo-step border-1">
                                    <img (click)="showRedoPopup()" class="custom-svg-question-icon"
                                        style="cursor: pointer; margin-right: 0.3rem; height:18px"
                                        src="/assets/vinter/icons/redoStep.svg" />
                                    <p (click)="showRedoPopup()" class="redo-step-text height-fit-content flex align-items-center">
                                        {{'Key.StepRedo' | translate}}
                                    </p>
                                </div>
                            </div>

                            <div
                                *ngIf="item.status !== applicationStepStatus.NotStarted && item.order !== positionStepKanbanStaticTypes.Offer">

                                <div *ngIf="!(item.completedDate ?? false)">
                                    <div class="flex flex-wrap justify-content-center align-items-center mt-8">
                                        <div
                                            class="col-12 flex justify-content-center align-items-center empty-title pl-0">
                                            <img class="empty-icon"
                                                src="../../../../../assets/vinter/icons/empty-result-list-svg.svg" />
                                        </div>
                                        <div
                                            class="col-12 flex flex-wrap justify-content-center text-align-center empty-title pl-0 text-lg font-semibold">
                                            <div
                                                class="col-6 flex justify-content-center align-items-center text-center empty-col">
                                                <span>
                                                    {{'Key.PendingStepMessage1' | translate}}
                                                    <span class="text-blue-400 font-semibold text-lg">
                                                        &nbsp;{{"’"+ item.positionStepTitle + "’"}}&nbsp;
                                                    </span>
                                                    {{"Key.PendingStepMessage2" | translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(item.completedDate ?? false)">
                                    <!-- NEW COMPONENTS ADD START -->
                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.PersonalityTest">
                                        <div *ngIf="item.isTimesUp">
                                            <div class="flex flex-wrap justify-content-center align-items-center mt-8">
                                                <div
                                                    class="col-12 flex justify-content-center align-items-center empty-title pl-0">
                                                    <img class="empty-icon"
                                                        src="../../../../../assets/vinter/icons/empty-result-list-svg.svg" />
                                                </div>
                                                <div
                                                    class="col-12 flex flex-wrap justify-content-center text-align-center empty-title pl-0 text-lg font-semibold">
                                                    {{'Key.IncompleteStepMessage' | translate}}
                                                </div>
                                                <div
                                                    class="col-12 flex flex-wrap justify-content-center text-align-center empty-timesup-title pl-0 text-lg font-semibold">
                                                    {{'Key.TimesUpMessage' | translate}}

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="!item.isTimesUp">
                                            <app-ocean-step-result [applicationStepId]="item.id"
                                                [openViewSidebar]="openOceanSidebar"></app-ocean-step-result>
                                        </div>
                                    </div>

                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.TechnicalTest">
                                        <app-technical-step-result
                                            [applicationStepId]="item.id"></app-technical-step-result>
                                    </div>
                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.ForeignLanguage">
                                        <app-foreign-language-step-result
                                            [applicationStepId]="item.id"></app-foreign-language-step-result>
                                    </div>
                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.CompetenceTest">
                                        <app-competence-step-result
                                            [applicationStepId]="item.id"></app-competence-step-result>
                                    </div>
                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.MTCompetenceTest">
                                        <app-mt-competency-inventory-step-result
                                            [applicationStepId]="item.id"></app-mt-competency-inventory-step-result>
                                    </div>
                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.CognitiveAbility">
                                        <app-cognitive-step-result
                                            [applicationStepId]="item.id"></app-cognitive-step-result>
                                    </div>
                                    <div
                                        *ngIf="item !== null && item.questionCollectionCategory === questionCollectionCategoryEnum.Pronunciation">
                                        <app-pronunciation-step-result
                                            [applicationStepId]="item.id"></app-pronunciation-step-result>
                                    </div>
                                    <!-- END -->
                                </div>
                            </div>
                            <div
                                *ngIf="item.status !== applicationStepStatus.NotStarted && item.order === positionStepKanbanStaticTypes.Offer">
                                <div *ngIf="item.positionStep.id === positionStepKanbanStaticTypes.Offer">
                                    <app-applicant-step-offer
                                        [applicationId]="applicationId"></app-applicant-step-offer>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="item.status === applicationStepStatus.NotStarted">
                            <div class="col-12 flex justify-content-center mt-8">
                                <img src="assets/vinter/icons/application-step-pending.svg">
                            </div>
                            <div class="col-12 flex justify-content-center text-lg font-semibold">
                                <div class="col-6 flex justify-content-center align-items-center text-center "
                                    style="width: 500px;">
                                    <span>
                                        {{"Key.PendingStepMessage1" | translate}}
                                        <span class="text-blue-400 font-semibold text-lg">
                                            &nbsp;{{"’"+ item.positionStepTitle + "’"}}&nbsp;
                                        </span>
                                        {{"Key.PendingStepMessage2" | translate}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <p-dialog (onHide)="closeRedoPopup()" [(visible)]="popupOpen" [modal]="true"
                            [baseZIndex]="10000" [style]="{ width: '35rem'}">
                            <div class="row flex justify-content-center" style="margin-bottom: 1rem;">
                                <img class="custom-svg-question-icon align-self-center"
                                    src="/assets/vinter/icons/redoStep-big.svg" />
                            </div>
                            <div class="justify-content-center">
                                <div class="redo-step-title-warning">{{'Key.StepRedoTitle' | translate}}</div>
                                <div class="redo-step-title-warning">{{'Key.StepRedoConfirm' | translate}}</div>
                            </div>
                            <div class="justify-content-center">
                                <div class="redo-step-text-warning">{{"’"+ item.positionStepTitle + "’"}}
                                    {{'Key.StepRedoDescription' | translate}}</div>
                            </div>
                            <div class="flex justify-content-center">
                                <div style="padding-right: 0.7rem;">
                                    <p-button label="{{'Key.Reset' | translate}}" styleClass="p-button"
                                        (click)="confirmRedo()"></p-button>
                                </div>
                                <div style="padding-left: 0.7rem;">
                                    <p-button label="{{'Key.GoBack' | translate}}" styleClass="p-button-outlined"
                                        (click)="closeRedoPopup()"></p-button>
                                </div>
                            </div>
                        </p-dialog>

                    </ng-template>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>
</div>