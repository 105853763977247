import { Injectable } from '@angular/core';
import { Credentials, RememberContext } from '../../shared/common/interfaces';
import { JwtHelperService } from '@auth0/angular-jwt';
import { credentialKey, cryptedCredentialKey, mailKey, pinnedTabIndex, rememberKey, sideNavMenuKey, vinterOrganizationGuid } from '../../shared/common/constants';
import { el } from '@fullcalendar/core/internal-common';
import { LocalizationService } from '../localization.service';

@Injectable({
    providedIn: 'root'
})
export class CredentialsService {
    private jwtHelper = new JwtHelperService();
    private _credentials: Credentials | null = null;
    private _cryptedCredentials: Credentials | null = null;

    constructor(private localizationService: LocalizationService) {
        const savedCredentials = sessionStorage.getItem(credentialKey) || localStorage.getItem(credentialKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }

    isAuthenticated(): boolean {
        return !!this.credentials;
    }

    isCryptedAuthenticated(): boolean {
        return !!this.cryptedCredentials;
    }

    get credentials(): Credentials | null {
        return this._credentials;
    }

    get cryptedCredentials(): Credentials | null {
        return this._cryptedCredentials;
    }


    getRoles(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]; }
    getUserName(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["userName"]; }
    getExpiresAt(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["expiration"]; }
    getOrganizationID(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["organizationAuths"]; }
    getLanguage(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["language"]; }
    getLanguageCode(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["languageCode"]; }
    getName(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]; }
    getAttendanceStatus(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["attendanceStatus"]; }
    getNameIdentifier(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] }
    getEmail(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["email"] }
    getVinterOrganizationID(): string { return vinterOrganizationGuid }
    getMainOrganizationID(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["mainOrg"] }
    getMainOrganizationCode(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["mainOrgCode"] }
    getMainOrganizationName(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["mainOrgName"] }
    getUserGroups(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["userGroups"] }
    getUserFirstName(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["firstName"]; }
    getIsLocked(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isLocked"] === 'True'; }
    getUserType(): string { return this.jwtHelper.decodeToken(this._credentials?.token)["userType"]; }
    getIsHasLicense(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isHasLicense"] === 'True'; }
    getIsExistCodingQuestionLicense(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isCodingQuestionLicenseValid"] === 'True'; }
    getIsExistAtsIntegrationLicense(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isAtsIntegrationLicenseValid"] === 'True'; }
    getIsExistAutomationLicense(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isAutomationLicenseValid"] === 'True'; }
    getIsExistAIReportLicense(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isAIReportLicenseValid"] === 'True'; }
    getIsExistCVParserLicense(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isCvParseLicenseValid"] === 'True'; }
    getIsAtsIntegrationLicensePending(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isAtsIntegrationLicensePending"] === 'True'; }
    getAutomationLicensePending(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isAutomationLicensePending"] === 'True'; }
    getIsCodingLicensePending(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isCodingQuestionLicensePending"] === 'True'; }
    getIsAIReportLicensePending(): boolean { return this.jwtHelper.decodeToken(this._credentials?.token)["isAIReportLicensePending"] === 'True'; }
    
    getCryptedAttendanceStatus(): string { return this.jwtHelper.decodeToken(this._cryptedCredentials?.token)["attendanceStatus"]; }

    setCredentials(credentials?: any, remember?: boolean) {
        this._credentials = credentials || null;
        if (credentials) {
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(credentialKey, JSON.stringify(credentials));
        } else {
            sessionStorage.removeItem(credentialKey);
            localStorage.removeItem(credentialKey);
            localStorage.removeItem(sideNavMenuKey);

        }
    }

    setCryptedCredentials(credentials?: any, remember?: boolean) {
        this._cryptedCredentials = credentials || null;
        if (credentials) {
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(cryptedCredentialKey, JSON.stringify(credentials));
        } else {
            sessionStorage.removeItem(cryptedCredentialKey);
            localStorage.removeItem(cryptedCredentialKey);
            localStorage.removeItem(sideNavMenuKey);
        }
    }

    getJwtToken() {
        let savedCredentials = localStorage.getItem(credentialKey);
        let token: Credentials = JSON.parse(savedCredentials);

        if(token==null || token==undefined)
             throw this.localizationService.translateText("Unauthorized");

        return token.token;
    }

    getJwtTokenWithoutAuth() {
        let savedCredentials = localStorage.getItem(credentialKey);
        let token: Credentials = JSON.parse(savedCredentials);

        if(token==null || token==undefined)
             throw this.localizationService.translateText("Unauthorized");

        return token.tokenWithoutAuth;
    }

    getRefreshToken() {
        let savedCredentials = localStorage.getItem(credentialKey);
        let token: Credentials = JSON.parse(savedCredentials);

        if(token==null || token==undefined)
             throw this.localizationService.translateText("Unauthorized");

        return token.refreshToken;
    }

    getRememberValues() {
        let rememberValues: RememberContext = {
            mail: localStorage.getItem(mailKey),
            remember: localStorage.getItem(rememberKey) === 'true'
        }

        return rememberValues;
    }

    setRememberValues(mail?: string, remember?: string) {
        if(mail && remember){
            localStorage.setItem(mailKey, mail);
            localStorage.setItem(rememberKey, remember);
        }
        else{
            localStorage.removeItem(mailKey);
            localStorage.removeItem(rememberKey);
        }
    }

    setPinnedTabIndex(tabIndex: number) {
        localStorage.setItem(pinnedTabIndex, tabIndex.toString());
        return tabIndex;
    }

    getPinnedTabIndex() {
        return localStorage.getItem(pinnedTabIndex) ? parseInt(localStorage.getItem(pinnedTabIndex)) : 0;
    }
}
