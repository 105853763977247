import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiControllerEnum } from '../common/enums';
import { Observable } from 'rxjs';
import { HttpBaseService, NotifyService, LocalizationService, ConfigurationService } from '@app/core';


@Injectable()
export class LanguageService extends HttpBaseService {

  constructor(
    private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService
  ) {
    super(http, ApiControllerEnum.Language, notifyServ, localizationService, configService);
  }

  getLanguagesFromApi(): Observable<any[]> {
    return this.getAll<any>("GetAllLanguages");
  }
}