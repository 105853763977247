import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CredentialsService, LocalizationService } from '@app/core';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { TopbarService } from './service/topbar.service';
import { MenuVisibilityService } from '@app/shared/services/menu-visibility.service';
import { EMenuVisibility, EProfileVisibility, EUserNotificationStatus, UserGroupCodeEnum } from '@app/shared/common/enums';
import { ProfileVisibilityService } from '@app/shared/services/profile-visibility.service';
import { Subscription } from 'rxjs';
import { NotificationCheckerService } from '@app/vinter/services/notification-checker.service';
import { UserNotificationService } from '@app/vinter/services/user-notification.service';
import { UserNotificationItem } from '@app/vinter/models/user-notifiaction/user-notification-item';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit, OnDestroy {

    items!: MenuItem[];
    userName: string;
    overlayVisible: boolean = false;
    imageUrl: string;
    userChar:string;
    display="flex"
    menuButtonDisplay=true
    profileVisibility = true
    userGroupCode : string = "";
    userGroupCodeEnum : any;
    private notificationListener: Subscription;
    notificationVisible: boolean = false;
    notificationOverlay: boolean = false;
    userNotifiactions: UserNotificationItem[] = [];
    userNotificationStatus = EUserNotificationStatus;
    notificationSkeleton: boolean;
    @ViewChild('menubutton') menuButton!: ElementRef;

    constructor(public layoutService: LayoutService,
        private credentialsService: CredentialsService,
        private menuVisibilityService:MenuVisibilityService,
        private topbarService: TopbarService,
        private profileVisibilityService: ProfileVisibilityService,
        private notificationCheckerService: NotificationCheckerService,
        private userNotificationService: UserNotificationService,
        private messageService: MessageService,
        private router: Router) {
    }
    ngOnInit(): void {
        this.userGroupCodeEnum = UserGroupCodeEnum;
        this.userGroupCode = this.credentialsService.getUserGroups();
        this.userName = this.credentialsService.getName().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        this.getUserImageUrl();
        this.userChar = this.getFirstAndLastLetter(this.userName);

        this.menuVisibilityService.menuVisibility$.subscribe(visibility=>{
            if(visibility == EMenuVisibility.ShowTopBarSideBar || visibility == EMenuVisibility.ShowTopBar){
                this.display="flex";
            }
            else{
                    this.display="none";
            }

            if(visibility == EMenuVisibility.ShowTopBar){
                this.menuButtonDisplay = false;
            }
            else{
                    this.menuButtonDisplay = true;
            }

        })

        this.profileVisibilityService.profileVisibility$.subscribe(visibility=>{
            if(visibility == EProfileVisibility.ShowProfile){
                this.profileVisibility=true;
            }
            else{
                this.profileVisibility=false;
            }
        })

        this.notificationListener = this.notificationCheckerService.changeNotification$.subscribe((newNotificationStatus) => {
            this.notificationVisible = newNotificationStatus;
        });
        this.userNotificationService.getUserNotifications({limit: 5}).subscribe(data => {
            if(data.find(x => x.status == this.userNotificationStatus.NotRead))
                this.notificationVisible = true;
        });
    }

    ngOnDestroy() {
        if (this.notificationListener) {
          this.notificationListener.unsubscribe();
        }
    }

    setNewNotificationStatus(changeNotificationStatus: boolean) {
        this.notificationVisible = changeNotificationStatus;
        this.userNotificationService.getUserNotifications({limit: 5}).subscribe(data => {
            this.userNotifiactions = data;
            this.notificationSkeleton = false;
            this.userNotificationService.setUserNotificationsToRead().subscribe();
        });
    }

    openNotifications(){
        this.userNotifiactions = [];
        this.notificationSkeleton = true;
        this.notificationOverlay = !this.notificationOverlay;
        this.setNewNotificationStatus(false);
    }

    seeAllNotifications(){
        this.openNotifications();
        this.router.navigate(["notifications"]);
    }

    redirectToDestinationUrl(redirectUrl: string){
        this.openNotifications();
        this.router.navigate([redirectUrl], { replaceUrl: true })
        .then(() => {
            window.location.reload();
          });
    }

    toggle() {
        this.overlayVisible = !this.overlayVisible;
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }
    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    getUserImageUrl(){
        this.topbarService.getUserImageUrl().subscribe(result =>{
            this.imageUrl = result.imageUrl;
        });
    }
    getFirstAndLastLetter(inputString: string): string {
        inputString = inputString.trim();
        const firstLetter = inputString.charAt(0);
        const lastSpaceIndex = inputString.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
          const charAfterLastSpace = inputString.charAt(lastSpaceIndex + 1);
          return `${firstLetter.toUpperCase()}${charAfterLastSpace.toUpperCase()}`;
        } else {
          return firstLetter.toUpperCase();
        }
      }

      onReject() {
        this.messageService.clear('notificationToast');
    }
}
